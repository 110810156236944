import React from 'react';
import PropTypes from 'prop-types';

class RefreshAt extends React.Component {

  constructor(props) {
    super(props);
    this.onTimeOut = this.onTimeOut.bind(this);
    this.state = {
      intervalId: null,
      endAt: new Date(props.endAt)
    };
  }

  componentDidMount() {
    const defaultInterval = 5 * 60 * 1000;
    const interval = this.props.interval || defaultInterval;
    const intervalId = setInterval(this.onTimeOut, interval);
    this.setState({ intervalId });
  }

  componentWillUnmount() {
    const { intervalId } = this.state;
    if (intervalId) clearInterval(intervalId);
  }

  onTimeOut() {
    const now = new Date();
    const { endAt, intervalId } = this.state;
    const { alertText } = this.props;

    if (endAt < now) {
      if (alertText) alert(alertText);
      if (intervalId) clearInterval(intervalId);
      this.setState({ intervalId: null });
      window.location.reload(true);
    }
  }

  render() {
    return <span></span>;
  }

}

RefreshAt.propTypes = {
  alertText: PropTypes.string,
  endAt: PropTypes.string.isRequired,
  interval: PropTypes.number
};

export default RefreshAt;
