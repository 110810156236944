import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import ItemImage from './ItemImage';
import SlimLoader from '../SlimLoader';
import Personalization from './Personalization';
import CustomAnswer from './CustomAnswer';
import { every, filter, find, map } from 'lodash';
import { useDebounce, useToggle } from '../custom-hooks';

export default function ShoppingBagItem({
  item,
  birdBank,
  birdBankItem,
  onChangeSignupLineItem,
  onDeleteSignupLineItem,
  saveSignupLineItem,
}) {
  const {
    id,
    description,
    color,
    size,
    amount,
    variant,
    quantity,
    images,
    personalizations,
    answers,
    oversized,
  } = item;

  const isFirstRun = useRef(true);

  const [cachedQuantity, setCachedQuantity] = useState(quantity);

  const [loading, toggleLoading] = useToggle(false);

  const image = <ItemImage images={images} />;

  const addQuantity = e => {
    e.preventDefault();

    if (parseInt(e.target.value) < 1) return;

    const quantity = e.target.value ? Number(e.target.value) : e.target.value;

    onChangeSignupLineItem(id, { quantity });
  };

  const highlightText = e => e.target.select();

  const debouncedItemDetails = useDebounce([quantity], 1000);

  useEffect(() => {
    if (quantity) setCachedQuantity(quantity);
  }, [quantity]);

  const allAnswers = every(answers, ({ answer }) => answer);

  const allPersonalizationsProvided = every(
    personalizations,
    ({ name, number }) => name || number
  );

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    if (!item.quantity) return;
    if (!allAnswers) return;
    if (!allPersonalizationsProvided) return;

    toggleLoading(true);

    saveSignupLineItem(item.id, () => toggleLoading(false));
  }, debouncedItemDetails);

  const handleQuantityBlur = () =>
    onChangeSignupLineItem(id, { quantity: cachedQuantity });

  const itemQuantity = (
    <div
      className={classNames(
        'bb-qty-field',
        'form-compact--small',
        'form-light',
        'form-light--bordered'
      )}
    >
      <input
        type="number"
        id={`${id}`}
        value={quantity}
        onChange={addQuantity}
        onBlur={handleQuantityBlur}
        min="1"
        disabled={item.outOfStock}
        onFocus={highlightText}
        pattern="\d*"
        className={
          item.outOfStock
            ? `disabled-view size-quantity-${item.id}`
            : `size-quantity-${item.id}`
        }
      />
    </div>
  );

  const removeItemFromBag = e => {
    e.preventDefault();
    toggleLoading(true);
    onDeleteSignupLineItem(id, () => toggleLoading(false));
  };

  const displayFreeShipping = birdBank.individualShip && (
    <span className="label bb-label--notice inline mlm">Shipping Included</span>
  );

  const priceBreakdownWithQuantity = item.outOfStock ? (
    <div className="flex-rows flex-rows--center-v">
      <em className="disabled-admin-view">
        <span className="mrs">{itemQuantity}</span>
        {birdBank.showPricingInfo && (
          <div>
            <span className="mrs">@</span>
            <span>{amount}</span>
          </div>
        )}
      </em>
    </div>
  ) : (
    <SlimLoader isActive={loading}>
      <div className="flex-rows flex-rows--center-v">
        <span className="mrs">{itemQuantity}</span>
        {birdBank.showPricingInfo && (
          <div>
            <span className="mrs">@</span>
            <span>{amount}</span>
          </div>
        )}
        {displayFreeShipping}
      </div>
    </SlimLoader>
  );

  const oversizedAlert = oversized && birdBank.showPricingInfo && (
    <p className="bb-alert-text man">
      <small>Sizes 2XL and above incur an additional $2.00 fee.</small>
    </p>
  );

  const outOfStockAlert = item.outOfStock ? (
    <em className="bb-alert-text disabled-admin-view">
      &nbsp;Out Of Stock - This item will be removed when you click&nbsp;
      <strong>Checkout</strong>.
    </em>
  ) : null;

  const displayVariant = variant ? <p className="mvn">{variant}</p> : null;

  const handlePersonalizationUpdate = (changes, locationId) => {
    toggleLoading(false);
    onChangeSignupLineItem(id, {
      personalizations: personalizations.map(personalization => {
        if (personalization.locationId != locationId) return personalization;
        return { ...personalization, ...changes };
      }),
    });
  };

  const handlePersonalizationBlur = () => {
    toggleLoading(true);
    saveSignupLineItem(id, () => toggleLoading(false));
  };

  const filteredHeatPressLocations = filter(birdBankItem.heatPressLocations, {
    personalized: true,
  });

  const personalizationsFields = filteredHeatPressLocations.map(
    (heatPressLocation, index) => {
      const personalization = find(personalizations, {
        locationId: heatPressLocation.id,
      });
      return (
        <Personalization
          key={index}
          onChange={e => handlePersonalizationUpdate(e, heatPressLocation.id)}
          onBlur={handlePersonalizationBlur}
          location={heatPressLocation}
          {...personalization}
        />
      );
    }
  );

  const handleAnswerChange = (changes, questionId) => {
    toggleLoading(false);
    onChangeSignupLineItem(id, {
      answers: answers.map(answer => {
        if (answer.questionId != questionId) return answer;
        return { ...answer, ...changes };
      }),
    });
  };

  const answerFields = birdBankItem.questions.map(question => {
    const answer = find(answers, { questionId: question.id });
    return (
      <CustomAnswer
        key={question.id}
        onChange={answer => handleAnswerChange(answer, question.id)}
        question={question}
        onBlur={handlePersonalizationBlur}
        {...answer}
      />
    );
  });

  return (
    <div className="react-modal-line-item bb-shopping-bag-line-item">
      <div className="bb-shopping-bag-image">{image}</div>
      <div className="bb-shopping-bag-description">
        <h4 className="bb-shopping-bag-title truncate-two-line">
          {`${description}`}
        </h4>
        <span className="txt-capitalize">{color.toLowerCase()}</span>
        {outOfStockAlert}
        {displayVariant}
        <p className="mvn">{size}</p>
        {priceBreakdownWithQuantity}
        {oversizedAlert}
        {personalizationsFields}
        {answerFields}
      </div>
      <SlimLoader isActive={loading}>
        <button
          className="bb-button-close bb-button-close--red"
          type="button"
          onClick={removeItemFromBag}
        >
          <span>Remove From Bag</span>
        </button>
      </SlimLoader>
    </div>
  );
}
