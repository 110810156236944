import React from 'react';
import classNames from 'classnames';

const Icon = props => {
  const iconProps = {
    className: classNames({
      fa: true,
      [`fa-${props.type}`]: props.type,
    }),
    style: {
      color: props.color || '#000',
    },
  };

  return <i {...iconProps} />;
};

export default Icon;
