import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ColorSelect from './color-select';
import { filter } from 'lodash';

class ScreenPrintSelect extends Component {
  static propTypes = {
    prefix: PropTypes.string,
    location: PropTypes.object,
    decorationOptions: PropTypes.object,
    printColorPmsCodes: PropTypes.arrayOf(PropTypes.string)
  }

  render(){
    let colors = this.props.printColorPmsCodes.map( pms_code => {
      let color_object = filter(GlobalData.printColorOptions, { value: pms_code })[0];
      return color_object ? color_object.label : '';
    }).join(', ');

    let colorSelect = this.props.locked ?
      <p>Colors: { colors }</p>
      :
      <div>
        <ColorSelect
          decorationType="screen_print"
          name={this.props.prefix + '[print_color_pms_codes]'}
          value={this.props.printColorPmsCodes}
          allowCustom={GlobalData.allowCustomPms}
          multi={true}
        />
      </div>;

    return(
      <div>
        {colorSelect}
      </div>
    );
  }
}

export default ScreenPrintSelect;