import React, { createContext, useReducer } from 'react';
import * as Types from '../../base-types';
import { AttachmentUpdate, AttachmentUpdateType } from '../../attachment-types';
import { RemoteData, LoadState } from '../../remote-data-types';
import { GetResponse } from './Store';
import * as Permissions from './Permissions';

type DirectSaveState = RemoteData<string, void>;

export interface Revision extends Types.UpdatedRevision {
  permissions: Permissions.Revision;
  errors: Types.Error[];
}

export interface Location extends Omit<Types.UpdatedLocation, 'revisions'> {
  permissions: Permissions.Location;
  attachmentValidation: Types.AttachmentValidation;
  revisionAttachmentValidation: Types.AttachmentValidation;
  revisions: Revision[];
  dirty: boolean;
  saveStatus: LoadState;
  errors: Types.Error[];
}

export interface LineItem extends Types.UpdatedLineItem {
  permissions: Permissions.LineItem;
  attachmentValidation: Types.AttachmentValidation;
  dirty: boolean;
  saveStatus: LoadState;
  errors: Types.Error[];
}

export interface ArtJob extends Types.ArtJob {
  permissions: Permissions.ArtJob;
  dirty: boolean;
  saveStatus: LoadState;
  directSaveStatus: DirectSaveState;
  errors: Types.Error[];
}

interface State {
  links: null | {
    order: string;
    locations: string;
  };
  artJob: ArtJob | null;
  locations: Location[];
  lineItems: LineItem[];
  order: Types.Order | null;
  loadStatus: LoadState;
  notifications: Types.Notifications | null;
  isSubmitting: boolean;
}

export type Action =
  | { type: 'loading-began' }
  | { type: 'loading-succeeded'; payload: GetResponse }
  | { type: 'loading-failed'; payload: string }
  | { type: 'update-art-tier'; payload: Types.ArtTier }
  | { type: 'update-greek'; payload: boolean }
  | { type: 'update-collegiate'; payload: boolean }
  | { type: 'update-college-marks'; payload: boolean }
  | { type: 'update-utees-original-art'; payload: boolean }
  | { type: 'update-print-color-change'; payload: boolean }
  | { type: 'update-art-job-save-status'; payload: LoadState }
  | {
      type: 'update-art-job-direct-save-status';
      payload: DirectSaveState;
    }
  | {
      type: 'update-notifications';
      payload: Types.Notifications;
    }
  | { type: 'update-errors'; payload: Types.Error[] }
  | {
      type: 'update-location-errors';
      id: Types.Id;
      payload: Types.Error[];
    }
  | {
      type: 'update-location-colors';
      id: Types.Id;
      payload: Types.DecorationColor[];
    }
  | {
      type: 'update-location-dimensions';
      id: Types.Id;
      payload: Types.Dimensions;
    }
  | {
      type: 'update-location-style';
      id: Types.Id;
      payload: Types.DecorationStyle;
    }
  | {
      type: 'update-location-production-notes';
      id: Types.Id;
      payload: string;
    }
  | {
      type: 'update-location-thread-count';
      id: Types.Id;
      payload: string;
    }
  | {
      type: 'update-location-proof-attachment';
      id: Types.Id;
      payload: AttachmentUpdate;
    }
  | {
      type: 'update-location-proof';
      id: Types.Id;
      payload: {
        attachment: Types.Attachment | null;
        status: number;
      };
    }
  | {
      type: 'update-location-save-status';
      id: Types.Id;
      payload: LoadState;
    }
  | {
      type: 'update-line-item-errors';
      id: Types.Id;
      payload: Types.Error[];
    }
  | {
      type: 'update-line-item-primary-image';
      id: Types.Id;
      payload: {
        attachment: Types.Attachment | null;
        status: number;
      };
    }
  | {
      type: 'update-line-item-secondary-image';
      id: Types.Id;
      payload: {
        attachment: Types.Attachment | null;
        status: number;
      };
    }
  | {
      type: 'update-line-item-primary-image-attachment';
      id: Types.Id;
      payload: AttachmentUpdate;
    }
  | {
      type: 'update-line-item-secondary-image-attachment';
      id: Types.Id;
      payload: AttachmentUpdate;
    }
  | {
      type: 'update-line-item-save-status';
      id: Types.Id;
      payload: LoadState;
    }
  | {
      type: 'update-line-item-dirty';
      id: Types.Id;
      payload: boolean;
    }
  | {
      type: 'update-location-dirty';
      id: Types.Id;
      payload: boolean;
    }
  | {
      type: 'update-dirty';
      payload: boolean;
    }
  | {
      type: 'update-location-revisions';
      id: Types.Id;
      payload: Revision[];
    }
  | { type: 'submitting-began' }
  | { type: 'submitting-finished' };

function attachmentStatusEnumToType(n: number): AttachmentUpdateType {
  if (n === 1) return AttachmentUpdateType.AddAttachment;
  else if (n === 2) return AttachmentUpdateType.RemoveAttachment;
  return AttachmentUpdateType.DontChangeAttachment;
}

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'loading-began':
      return { ...state, loadStatus: LoadState.Loading };

    case 'loading-succeeded':
      const decoratedLocations: Location[] = action.payload.locations.map(
        l => ({
          ...l,
          proofUpdate: { type: AttachmentUpdateType.DontChangeAttachment },
          proofStatus: attachmentStatusEnumToType(l.proofStatus),
          dirty: false,
          saveStatus: LoadState.NotAsked,
          revisions: l.revisions.map(r => ({
            ...r,
            attachmentUpdate: {
              type: AttachmentUpdateType.DontChangeAttachment,
            },
            errors: [],
          })),
          threadCount:
            l.decorationOptions && l.decorationOptions.kind === 'embroidery'
              ? l.decorationOptions.threadCount.toString()
              : '',
          errors: [],
        })
      );

      const decoratedLineItems: LineItem[] = action.payload.lineItems.map(
        l => ({
          ...l,
          primaryImageUpdate: {
            type: AttachmentUpdateType.DontChangeAttachment,
          },
          secondaryImageUpdate: {
            type: AttachmentUpdateType.DontChangeAttachment,
          },
          compositePrimaryStatus: attachmentStatusEnumToType(
            l.compositePrimaryStatus
          ),
          compositeSecondaryStatus: attachmentStatusEnumToType(
            l.compositeSecondaryStatus
          ),
          dirty: false,
          saveStatus: LoadState.NotAsked,
          errors: [],
        })
      );

      const decoratedArtJob: ArtJob = {
        ...action.payload.artJob,
        dirty: false,
        saveStatus: LoadState.NotAsked,
        directSaveStatus: { state: LoadState.NotAsked },
        errors: [],
      };

      const successPayload = {
        ...action.payload,
        artJob: decoratedArtJob,
        locations: decoratedLocations,
        lineItems: decoratedLineItems,
      };

      return { ...state, ...successPayload, loadStatus: LoadState.Success };

    case 'loading-failed':
      return { ...state, loadStatus: LoadState.Failure };

    case 'update-notifications':
      return {
        ...state,
        notifications: action.payload,
      };

    case 'update-errors':
      if (!state.artJob) return state;
      return {
        ...state,
        artJob: { ...state.artJob, errors: action.payload },
      };

    case 'update-art-tier':
      if (!state.artJob) return state;
      return {
        ...state,
        artJob: { ...state.artJob, artTier: action.payload },
      };

    case 'update-greek':
      if (!state.artJob) return state;
      return {
        ...state,
        artJob: { ...state.artJob, greek: action.payload, dirty: true },
      };

    case 'update-collegiate':
      if (!state.artJob) return state;
      return {
        ...state,
        artJob: { ...state.artJob, collegiate: action.payload, dirty: true },
      };

    case 'update-college-marks':
      if (!state.artJob) return state;
      return {
        ...state,
        artJob: { ...state.artJob, collegeMarks: action.payload },
      };

    case 'update-utees-original-art':
      if (!state.artJob) return state;
      return {
        ...state,
        artJob: {
          ...state.artJob,
          uteesOriginalArt: action.payload,
          dirty: true,
        },
      };

    case 'update-print-color-change':
      if (!state.artJob) return state;
      return {
        ...state,
        artJob: {
          ...state.artJob,
          printColorChange: action.payload,
          dirty: true,
        },
      };

    case 'update-location-errors':
      return {
        ...state,
        locations: state.locations.map(l =>
          action.id === l.id ? { ...l, errors: action.payload } : l
        ),
      };

    case 'update-location-colors':
      return {
        ...state,
        locations: state.locations.map(l =>
          action.id === l.id ? { ...l, colors: action.payload, dirty: true } : l
        ),
      };

    case 'update-location-dimensions':
      return {
        ...state,
        locations: state.locations.map(l =>
          action.id === l.id
            ? { ...l, dimensions: action.payload, dirty: true }
            : l
        ),
      };

    case 'update-location-style':
      return {
        ...state,
        locations: state.locations.map(l =>
          action.id === l.id
            ? { ...l, decorationStyle: action.payload, dirty: true }
            : l
        ),
      };

    case 'update-location-production-notes':
      return {
        ...state,
        locations: state.locations.map(l =>
          action.id === l.id
            ? { ...l, productionNotes: action.payload, dirty: true }
            : l
        ),
      };

    case 'update-location-thread-count':
      return {
        ...state,
        locations: state.locations.map(l =>
          action.id === l.id
            ? { ...l, threadCount: action.payload, dirty: true }
            : l
        ),
      };

    case 'update-location-proof':
      return {
        ...state,
        locations: state.locations.map(l =>
          action.id === l.id
            ? {
                ...l,
                proof: action.payload.attachment,
                proofUpdate: {
                  type: AttachmentUpdateType.DontChangeAttachment,
                },
                proofStatus: attachmentStatusEnumToType(action.payload.status),
              }
            : l
        ),
      };

    case 'update-location-save-status':
      return {
        ...state,
        locations: state.locations.map(l =>
          action.id === l.id
            ? {
                ...l,
                saveStatus: action.payload,
              }
            : l
        ),
      };

    case 'update-line-item-errors':
      return {
        ...state,
        lineItems: state.lineItems.map(l =>
          action.id === l.id
            ? {
                ...l,
                errors: action.payload,
              }
            : l
        ),
      };

    case 'update-line-item-primary-image':
      return {
        ...state,
        lineItems: state.lineItems.map(l =>
          action.id === l.id
            ? {
                ...l,
                compositePrimary: action.payload.attachment,
                compositePrimaryStatus: attachmentStatusEnumToType(
                  action.payload.status
                ),
                primaryImageUpdate: {
                  type: AttachmentUpdateType.DontChangeAttachment,
                },
              }
            : l
        ),
      };

    case 'update-line-item-secondary-image':
      return {
        ...state,
        lineItems: state.lineItems.map(l =>
          action.id === l.id
            ? {
                ...l,
                compositeSecondary: action.payload.attachment,
                compositeSecondaryStatus: attachmentStatusEnumToType(
                  action.payload.status
                ),
                secondaryImageUpdate: {
                  type: AttachmentUpdateType.DontChangeAttachment,
                },
              }
            : l
        ),
      };

    case 'update-location-proof-attachment':
      return {
        ...state,
        locations: state.locations.map(l =>
          action.id === l.id
            ? {
                ...l,
                proofUpdate: action.payload,
                dirty:
                  action.payload.type !==
                  AttachmentUpdateType.DontChangeAttachment,
              }
            : l
        ),
      };

    case 'update-line-item-primary-image-attachment':
      const dirty =
        action.payload.type !== AttachmentUpdateType.DontChangeAttachment;

      return {
        ...state,
        lineItems: state.lineItems.map(l =>
          action.id === l.id
            ? {
                ...l,
                primaryImageUpdate: action.payload,
                dirty,
              }
            : l
        ),
      };

    case 'update-line-item-secondary-image-attachment':
      return {
        ...state,
        lineItems: state.lineItems.map(l =>
          action.id === l.id
            ? {
                ...l,
                secondaryImageUpdate: action.payload,
                dirty:
                  action.payload.type !==
                  AttachmentUpdateType.DontChangeAttachment,
              }
            : l
        ),
      };

    case 'update-line-item-save-status':
      return {
        ...state,
        lineItems: state.lineItems.map(l =>
          action.id === l.id
            ? {
                ...l,
                saveStatus: action.payload,
              }
            : l
        ),
      };

    case 'update-dirty':
      if (!state.artJob) return state;
      return {
        ...state,
        artJob: {
          ...state.artJob,
          dirty: action.payload,
        },
      };

    case 'update-art-job-save-status':
      if (!state.artJob) return state;
      return {
        ...state,
        artJob: {
          ...state.artJob,
          saveStatus: action.payload,
        },
      };

    case 'update-art-job-direct-save-status':
      if (!state.artJob) return state;
      return {
        ...state,
        artJob: {
          ...state.artJob,
          directSaveStatus: action.payload,
        },
      };

    case 'update-location-dirty':
      return {
        ...state,
        locations: state.locations.map(l =>
          action.id === l.id
            ? {
                ...l,
                dirty: action.payload,
              }
            : l
        ),
      };

    case 'update-location-revisions':
      return {
        ...state,
        locations: state.locations.map(l =>
          action.id === l.id
            ? {
                ...l,
                revisions: action.payload,
              }
            : l
        ),
      };

    case 'update-line-item-dirty':
      return {
        ...state,
        lineItems: state.lineItems.map(l =>
          action.id === l.id
            ? {
                ...l,
                dirty: action.payload,
              }
            : l
        ),
      };

    case 'submitting-began':
      return { ...state, isSubmitting: true };

    case 'submitting-finished':
      return { ...state, isSubmitting: false };
  }
}

const initialState: State = {
  links: null,
  artJob: null,
  order: null,
  locations: [],
  lineItems: [],
  loadStatus: LoadState.NotAsked,
  notifications: null,
  isSubmitting: false,
};

export const ArtSubmissionContext = createContext<{
  state: State;
  dispatch: (action: Action) => any;
}>({
  state: initialState,
  dispatch: action => {
    console.error('This should not happen!');
  },
});

export function ArtSubmissionContextProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ArtSubmissionContext.Provider value={{ state, dispatch }}>
      {props.children}
    </ArtSubmissionContext.Provider>
  );
}
