import React, { Component } from 'react';
import Select from 'react-select';
import MultiStateLoader from './MultiStateLoader';
import classNames from 'classnames';

class ArtDashboardJobs extends Component {
  render() {
    const { artist, artists, onArtistChange, onArtTierChange } = this.props;

    const artTierOptions = artist.meta.artTiers.map(({ id, name }) => ({
      value: id,
      label: name,
    }));

    const artistOptions = artists.map(({ id, attributes }) => ({
      value: id,
      label: attributes.name.firstName + ' ' + attributes.name.lastName,
    }));

    const artJobRow = artJob => {
      const {
        artTierLoadingState,
        artistLoadingState,
        operationState,
      } = artJob;

      const {
        name,
        artDueIn,
        artDueInAlert,
        status,
        artTier,
        artistId,
      } = artJob.attributes;

      const rowClasses = classNames({
        row: true,
        mbm: true,
        pam: true,
        undoable: !!operationState && operationState.type === 'UNDO',
      });

      const undoButton =
        operationState && operationState.type === 'UNDO' ? (
          <button
            type="button"
            className="button button--warning Select-button-attached"
            onClick={() =>
              onArtistChange(
                operationState.artistId,
                artJob.id,
                artist.id,
                true
              )
            }
          >
            Undo
          </button>
        ) : null;

      return (
        <div className={rowClasses} key={artJob.id}>
          <div className="col-5">
            <strong>
              <a
                href={artJob.links.order}
                target="_blank"
                rel="noopener noreferrer"
              >
                {name}
              </a>
            </strong>
            <div>
              <small className={artDueInAlert}>
                <i className="fa fa-clock-o mrs" />
                {artDueIn}

                {status && (
                  <span className="mls label label--default">{status}</span>
                )}
              </small>
            </div>
          </div>
          <div className="col-3 react-select-form">
            <MultiStateLoader state={artTierLoadingState}>
              <div className="pvs">
                <Select
                  clearable={false}
                  onChange={val => {
                    if (val !== null) {
                      onArtTierChange(val.value, artJob.id, artist.id);
                    }
                  }}
                  value={artTier}
                  options={artTierOptions}
                />
              </div>
            </MultiStateLoader>
          </div>
          <div className="col-4">
            <MultiStateLoader state={artistLoadingState}>
              <div className="media media--center pvs">
                <div className="media__body react-select-form">
                  <Select
                    clearable={false}
                    onChange={val => {
                      if (val !== null) {
                        onArtistChange(val.value, artJob.id, artist.id);
                      }
                    }}
                    value={artistId}
                    options={artistOptions}
                  />
                </div>
                <div className="media__obj--rev">{undoButton}</div>
              </div>
            </MultiStateLoader>
          </div>
        </div>
      );
    };

    return <div>{artist.relationships.artJobs.map(artJobRow)}</div>;
  }
}

export default ArtDashboardJobs;
