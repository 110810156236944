import React from 'react';

export default function ItemColor(props) {
  const { isColorSelected, item, onColorSelect, color } = props;

  const image = item.images[0].previewUrl;

  const imageElement = <img src={image} key={image} />;

  return (
    <span
      className={`${
        isColorSelected ? 'selected-color color-selector' : 'color-selector'
      }`}
      onClick={() => onColorSelect(color, item)}
    >
      {imageElement}
    </span>
  );
}
