import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import ColorSelect from './color-select';
import { filter } from 'lodash';

class CustomTagSelect extends Component {
  static propTypes = {
    prefix: PropTypes.string,
    location: PropTypes.object,
    decorationOptions: PropTypes.object,
    printColorPmsCodes: PropTypes.arrayOf(PropTypes.string)
  }

  constructor(props) {
    super(props);

    this.state = {
      custom_tag_type: this.props.decorationOptions ?
        this.props.decorationOptions.custom_tag_type : ''
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(name, e) {
    this.setState({ [name]: e.value });
  }

  render(){
    let { printColorPmsCodes } = this.props;

    let customTagTypeOptions = [
      { value: 'screen_print', label: 'Screen Print' },
      { value: 'heat_press', label: 'Heat Press' },
    ];

    let colors = this.props.printColorPmsCodes.map(pms_code => {
      let color_object = filter(GlobalData.printColorOptions, { value: pms_code })[0];
      return color_object ? color_object.label : '';
    }).join(', ');

    let value = printColorPmsCodes;

    let colorSelect = this.props.location.locked ?
      <p>Colors: { colors }</p>
      : <div className="custom-tag-color-select">
        <ColorSelect
          decorationType="custom_tag"
          name={this.props.prefix + '[print_color_pms_codes][]'}
          value={value}
          allowCustom={GlobalData.allowCustomPms}
          multi={false}
        />
      </div>;

    return(
      <div>
        <div className="custom-tag-type">
          <Select
            placeholder="Custom Tag Type"
            name={this.props.prefix + '[custom_tag_type]'}
            options={customTagTypeOptions}
            onChange={this.handleChange.bind(this, 'custom_tag_type')}
            value={this.state.custom_tag_type}
            disabled={this.props.locked}
          />
        </div>

        {colorSelect}
      </div>
    );
  }
}

export default CustomTagSelect;