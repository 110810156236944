import React, { Component } from 'react';
import { debounce } from 'lodash';
import { extractValue } from '../utility';
import { localPatchJSON, extractErrorMessage } from '../fetch-local';

class ReceivableFields extends Component {

  constructor(props) {
    super(props);
    this.setReceivableDebounced = debounce(this.setReceivable, 1000);
  }

  componentWillUnmount() {
    this.setReceivableDebounced.cancel();
  }

  onChange = event => {
    const { props } = this;
    const target = event.target;
    const value = extractValue(target);
    const name = target.name;

    const newLineItem = {
      ...props,
      data: {
        ...props.data,
        [name]: value,
      },
    };

    props.onPricingUpdate(newLineItem);
  }

  setReceivable() {
    const { props } = this;

    props.onPricingUpdate({ ...props, isLoading: true });

    localPatchJSON(props.links.update, {
      line_item: {
        receivable_attributes: {
          blank_arrival_date: props.data.blankArrivalDate,
          tracking_number: props.data.trackingNumber,
          supplier: props.data.supplier
        }
      }
    }).then(data => {
      props.onPricingUpdate(data.lineItem, data.order);
    }).catch(error => {
      extractErrorMessage(error, 'There was an issue').then(error => {
        props.onPricingUpdate({ ...props, error });
      });
    });

  }

  componentDidUpdate(prevProps) {
    const shouldUpdate =
      prevProps.data.blankArrivalDate !== this.props.data.blankArrivalDate ||
      prevProps.data.trackingNumber !==
      this.props.data.trackingNumber ||
      prevProps.data.supplier !== this.props.data.supplier;

    if (shouldUpdate) {
      this.setReceivableDebounced();
    }
  }

  render() {
    const {
      blankArrivalDate,
      trackingNumber,
      supplier,
      receivable
    } = this.props.data;

    let customerSupplied =
      this.props.data.attributes.includes('Customer Supplied');
    const { currentUser } = this.props.meta;

    if (customerSupplied) {
      if (currentUser.canEditCustomerSupplied) {
        return(
          <React.Fragment>
            <div className="form-compact form-light form-light--bordered">
              <p>Supplied Apparel Info.</p>
              <input
                title="Blank Arrival Date"
                className="mbm"
                type="date"
                name="blankArrivalDate"
                value={blankArrivalDate || ''}
                placeholder="Blank Arrival Date"
                onChange={this.onChange}
              />
              <input
                className="mbm"
                type="text"
                name="trackingNumber"
                value={trackingNumber || ''}
                placeholder="Tracking #"
                onChange={this.onChange}
              />
              <input
                className="mbl"
                type="text"
                name="supplier"
                value={supplier || ''}
                placeholder="Supplier"
                onChange={this.onChange}
              />
            </div>
          </React.Fragment>
        );
      } else {
        if (receivable) {
          return(
            <React.Fragment>
              <dl className="dl-horizontal">
                <p>Supplied Apparel Info.</p>

                <dt>Blank Arrival Date</dt>
                <dd>{blankArrivalDate || 'Not Yet Provided'}</dd>

                <dt>Tracking #</dt>
                <dd>{trackingNumber || 'Not Yet Provided'}</dd>

                <dt>Supplier</dt>
                <dd>{supplier || 'Not Yet Provided'}</dd>
              </dl>
            </React.Fragment>
          );
        } else { return (null); }
      }
    } else { return(null); }
  }

}

export default ReceivableFields;
