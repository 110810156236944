import React, { Component } from 'react';
import OriginalSelect from 'react-select';
import VirtualizedSelect from 'react-virtualized-select';
import createFilterOptions from 'react-select-fast-filter-options';

class UncontrolledSelect extends Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      value: props.value
    };
  }

  handleChange(value) {
    this.setState({ value });
  }

  render() {
    const value = this.state.value;
    const { options, hasLargeDataSet } = this.props;

    const Select = hasLargeDataSet ? (
      VirtualizedSelect
    ) : OriginalSelect;

    const filterOptions = hasLargeDataSet ? {
      filterOptions: createFilterOptions({ options })
    } : {};

    const selectProps = {
      ...this.props,
      ...filterOptions,
      onChange: this.handleChange,
      value
    };

    return <div id={this.props.id}><Select {...selectProps} /></div>;
  }
}

export default UncontrolledSelect;
