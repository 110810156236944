import React, { useState, useEffect, useCallback, useRef } from 'react';
import Select from 'react-select';
import { localPutForm } from '../fetch-local';
import Loader from './loader';
import classNames from 'classnames';

interface DataValue {
  value: number;
  formatted: string;
}
interface Supplier {
  id: number;
  label: string;
  price?: DataValue;
  inventory?: DataValue;
  isPreferred?: boolean;
}

interface Option extends Supplier {
  value: string;
}

interface OptionProps {
  children: string;
  option: Option;
  className: string;
  isDisabled: boolean;
  isFocused: boolean;
  isSelected: boolean;
  onFocus: (a: Option, any) => any;
  onSelect: (a: Option, any) => any;
}

const SupplierOption = (props: OptionProps) => {
  const handleMouseDown = e => {
    e.preventDefault();
    e.stopPropagation();
    props.onSelect(props.option, e);
  };

  const handleMouseEnter = e => {
    props.onFocus(props.option, e);
  };

  const handleMouseMove = e => {
    if (props.isFocused) return;
    props.onFocus(props.option, e);
  };

  return (
    <div
      className={classNames(
        props.className,
        'supplier-dropdown-item',
        'phm',
        'pvs'
      )}
      onMouseDown={handleMouseDown}
      onMouseEnter={handleMouseEnter}
      onMouseMove={handleMouseMove}
      title={props.option.label}
      role="button"
      tabIndex={-1}
    >
      <span className={classNames({ 'txt-strong': props.option.isPreferred })}>
        {props.children}
      </span>

      {props.option.inventory && (
        <span className="txt-success txt-right">
          {props.option.inventory.formatted}
        </span>
      )}

      {props.option.price && (
        <span className="txt-right">{props.option.price.formatted}</span>
      )}
    </div>
  );
};

interface ValueProps {
  children: JSX.Element;
  placeholder: string;
  value: Option;
}

const SupplierValue = ({ value, children }: ValueProps) => {
  return (
    <div className="Select-value" title={value.label}>
      <span className="Select-value-label">{children}</span>
    </div>
  );
};

interface Props {
  label: string;
  orderId: number;
  lineItemId?: number;
  suppliers: Supplier[];
  isPreferred?: boolean;
}

const SupplierGroupSelect = ({ suppliers, lineItemId, orderId }: Props) => {
  const [value, setValue] = useState<string | null>(null);
  const options: Option[] = suppliers.map(supplier => {
    return { ...supplier, value: supplier.id.toString() };
  });

  const [loading, setLoading] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const isLineItemLevel: boolean = !!lineItemId;
  const isOrderLevel: boolean = !isLineItemLevel;

  const updateValue = useCallback(
    (value: string) => {
      setValue(value);

      if (!value) return;

      if (isLineItemLevel) {
        window.dispatchEvent(
          new CustomEvent('lineItemSupplierSet', {
            detail: {
              lineItemId: lineItemId,
              supplierId: value,
            },
          })
        );
      } else {
        window.dispatchEvent(
          new CustomEvent('orderSupplierSet', {
            detail: {
              orderId: orderId,
              supplierId: value,
            },
          })
        );
      }
    },
    [isLineItemLevel, lineItemId, orderId]
  );

  useEffect(() => {
    const handleEvent = e => {
      if (isOrderLevel || e.detail.orderId !== orderId) return;
      const supplier = e.detail.supplierId.toString();
      updateValue(supplier);
    };

    window.addEventListener('orderSupplierSet', handleEvent);

    return () => {
      window.removeEventListener('orderSupplierSet', handleEvent);
    };
  }, [lineItemId, orderId, isOrderLevel, updateValue]);

  return (
    <div className="section txt-left" ref={containerRef}>
      <div className="media" style={{ gap: '1rem' }}>
        <div className="media--obj" style={{ width: '100%' }}>
          <Select
            onChange={e => {
              const value = e ? e.value : e;
              updateValue(value);

              setLoading(true);
              setTimeout(() => {
                if (!containerRef.current) return;
                const form = containerRef.current.closest('form');
                if (!form) return;
                let data = new FormData(form);

                localPutForm(
                  `/orders/purchase_apparel/${orderId}`,
                  data
                ).finally(() => setLoading(false));
              }, 1000);
            }}
            optionComponent={SupplierOption}
            options={options}
            placeholder={'Select Supplier'}
            value={value}
            valueComponent={SupplierValue}
            matchPos="start"
          />
        </div>
        <div className="media--obj">{loading && <Loader />}</div>
      </div>
    </div>
  );
};

export default SupplierGroupSelect;
