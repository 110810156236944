(function($) {
  $(function() {
    $('.product-list-carousel').slick({
      dots: true,
      prevArrow:
        '<a href="#" class="slick-prev"><i class="fa fa-chevron-left fa-lg"></i></a>',
      nextArrow:
        '<a href="#" class="slick-next"><i class="fa fa-chevron-right fa-lg"></i></a>',
      customPaging: function(slider, i) {
        return '<a href="#" class="card-carousel-dot" ><i class="fa fa-circle fa-sm"></i></a>';
      },
    });

    $('.card-carousel').slick({
      dots: true,
      prevArrow:
        '<a href="#" class="slick-prev"><i class="fa fa-chevron-left fa-lg"></i></a>',
      nextArrow:
        '<a href="#" class="slick-next"><i class="fa fa-chevron-right fa-lg"></i></a>',
      customPaging: function(slider, i) {
        return '<a href="#" class="card-carousel-dot" ><i class="fa fa-circle fa-sm"></i></a>';
      },
    });

    $('.art-proof-carousel').slick({
      dots: false,
      lazyLoaded: true,
      slidesToShow: 4,
      mobileFirst: true,
      prevArrow: $('.art-proof-carousel__prev'),
      nextArrow: $('.art-proof-carousel__next'),
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            infinite: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 300,
          settings: {
            centerMode: true,
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 200,
          settings: {
            centerMode: true,
            slidesToShow: 1,
          },
        },
      ],
    });

    $('.line-item-carousel').slick({
      dots: true,
      slidesToShow: 1,
    });

    $('.fancybox').fancybox();
    $('.fancybox-image').fancybox({ type: 'image' });
  });

  $(document).on(
    'click',
    '.card-carousel-dot, .slick-next, .slick-prev',
    function(event) {
      event.preventDefault();
    }
  );
})(window.jQuery);
