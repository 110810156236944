import { Id, NewRevision } from '../../../base-types';
import {
  localPatchJSON,
  localDelete,
  localPostJSON,
  localPutJSON,
} from '../../../fetch-local';
import { AttachmentUpdateType } from '../../../attachment-types';
import { Revision } from '../ArtSubmissionContext';

const resourcesUri = (locationId: Id): string =>
  `/locations/${locationId}/revisions.json`;

const resourceUri = (revisionId: Id): string => `/revisions/${revisionId}.json`;

export async function create(revision: NewRevision): Promise<Revision[]> {
  const body = {
    revision: {
      description: revision.description,
      attachment: revision.attachment,
    },
  };

  const { revisions } = await localPostJSON(
    resourcesUri(revision.locationId),
    body
  );

  return revisions;
}

export async function update(revision: Revision): Promise<Revision[]> {
  const { attachmentUpdate } = revision;

  let revisionBody: {
    description: string;
    status: 1 | 0;
    attachment?: string | null;
  } = {
    description: revision.description,
    status: revision.completed ? 1 : 0,
  };

  if (attachmentUpdate.type === AttachmentUpdateType.RemoveAttachment) {
    await destroyImage(revision);
    revisionBody = { ...revisionBody, attachment: null };
  }

  if (attachmentUpdate.type === AttachmentUpdateType.AddAttachment) {
    const attachment = attachmentUpdate.signedId;
    revisionBody = { ...revisionBody, attachment };
  }

  const { revisions } = await localPatchJSON(resourceUri(revision.id), {
    revision: revisionBody,
  });

  return revisions;
}

export async function destroy(revision: Revision): Promise<Revision[]> {
  const { revisions } = await localDelete(resourceUri(revision.id));
  return revisions;
}

export async function indexUpdate(
  locationId: Id,
  updateRevisions: Revision[]
): Promise<Revision[]> {
  if (updateRevisions.length === 0) return [];

  const body = {
    revisions: updateRevisions.reduce(
      (result, revision, index) => ({
        ...result,
        [revision.id]: { rank: index + 1 },
      }),
      {}
    ),
  };

  const { revisions } = await localPutJSON(resourcesUri(locationId), body);
  return revisions;
}

export async function destroyImage(revision: Revision): Promise<Revision[]> {
  const uri = resourceUri(revision.id) + `?image=true`;
  const { revisions } = await localDelete(uri);
  return revisions;
}
