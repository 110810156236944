import React from 'react';
import EditableField from './EditableField';
import CustomQuestionManager from './CustomQuestionManager';

const BirdBankItemDetail = ({ birdBankItem, birdBankItemUpdate, editing }) => {
  const {
    productColor,
    showImprintColors,
    imprintColorsSeparated,
    quantity,
    questions,
    totalSignups,
    personalizationQuestions,
  } = birdBankItem;

  let variant = birdBankItem.variant || '';

  const itemImprintColors = showImprintColors ? (
    <React.Fragment>
      <dt>Ink Color(s):</dt>
      <dd>{imprintColorsSeparated}</dd>
    </React.Fragment>
  ) : null;

  const handleVariantChange = e => {
    const variant = e.target.value;
    birdBankItemUpdate(birdBankItem, { variant });
  };

  const imprintColorsClassNames =
    'form-vertical form-light form-light--bordered form-compact';

  const variantField = (
    <EditableField
      renderRead={() =>
        (showImprintColors || variant) && (
          <React.Fragment>
            <dt>Variant:</dt>
            <dd>
              {variant || (
                <span className="txt-normal txt-alert">Missing!</span>
              )}
            </dd>
          </React.Fragment>
        )
      }
      renderEdit={() =>
        showImprintColors ? (
          <div className={imprintColorsClassNames}>
            <label>
              Variant <span className="txt-normal txt-alert">(required)</span>
            </label>
            <input
              className="mbn"
              onChange={handleVariantChange}
              value={variant}
            />
            <small className="txt-muted2">
              Ex. Rose Gold Foil, Delta Gamma, etc.
            </small>
          </div>
        ) : null
      }
      editing={editing}
    />
  );

  const addCustomQuestion = newQuestion => {
    birdBankItemUpdate(birdBankItem, {
      questions: [...questions, newQuestion],
    });
  };

  const updateCustomQuestion = (updatedQuestion, qIndex) => {
    const updatedQuestions = questions.map((question, index) => {
      if (index === qIndex) return { ...question, question: updatedQuestion };
      else return question;
    });

    birdBankItemUpdate(birdBankItem, { questions: updatedQuestions });
  };

  const removeCustomQuestion = qIndex => {
    const updatedQuestions = questions[qIndex].id
      ? questions.map((question, index) => {
          if (index == qIndex) return { ...question, _destroy: true };
          else return { ...question };
        })
      : questions.filter((_, i) => i !== qIndex);

    birdBankItemUpdate(birdBankItem, { questions: updatedQuestions });
  };

  const personalizationQuestionList = personalizationQuestions.length > 0 && (
    <div>
      <h4 className="mbn">Personalizations (automatically added)</h4>
      <ul>
        {personalizationQuestions.map((question, i) => (
          <li key={i}>{question}</li>
        ))}
      </ul>
    </div>
  );

  return (
    <div>
      <dl className="dl-flex">
        <dt>Order Total:</dt>
        <dd>{quantity}</dd>
        <dt>Item Color:</dt>
        <dd>{productColor}</dd>
        {itemImprintColors}
        {variantField}
      </dl>

      {personalizationQuestionList}

      <CustomQuestionManager
        editingEnabled={!(totalSignups > 0)}
        editing={editing}
        questions={questions}
        onChange={updateCustomQuestion}
        onRemove={removeCustomQuestion}
        onAdd={addCustomQuestion}
      />
    </div>
  );
};

export default BirdBankItemDetail;
