import React from 'react';
import ItemSelect from './ItemSelect';
import PrintColorSelect from './PrintColorSelect';
import SizeSelect from './SizeSelect';

const StockAlertForm = props => {
  return (
    <div className="row pbl">
      <form onSubmit={props.onChange}>
        <div className="col-3">
          <ItemSelect {...props} />
        </div>

        <div className="col-3">
          <PrintColorSelect {...props} />
        </div>

        <div className="col-3">
          <SizeSelect {...props} />
        </div>

        <div className="col-2">
          {props.product && (
            <input
              type="date"
              placeholder="Restock Date"
              name="restockDate"
              value={props.restockDate || ''}
              onChange={props.onRestockDateChange}
            />
          )}
        </div>

        <div className="col-1">
          <input type="submit" value="Save" className="button" />
        </div>
      </form>
    </div>
  );
};

export default StockAlertForm;
