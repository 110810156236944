import React from 'react';
import Select from 'react-select';

class BbSearch extends React.Component {
  constructor(props) {
    super(props);
    let { initialValue } = props;

    this.state = {
      value: initialValue ? initialValue : ''
    };
  }

  componentDidUpdate(prevProps, prevState) {
    let { autoSubmit } = this.props;
    if (!autoSubmit) return;

    let shouldSubmit = prevState.value !== this.state.value && this.state.value;
    if (shouldSubmit) {
      let select = this._select;
      select.refs.value0.form.submit();
    }
  }

  render() {
    let {
      name,
      options,
      className,
      placeholder
    } = this.props;

    let selectProps = {
      name,
      className,
      options,
      placeholder,
      clearable: false,
      ref: c => this._select = c,
      onChange: (value) => {
        this.setState({ value });
      },
      value: this.state.value,
      simpleValue: true
    };

    return <Select {...selectProps} />;
  }
}

export default BbSearch;
