import React from 'react';
import EditableField from './EditableField';
import { formatMoney } from 'utility';
import PropTypes from 'prop-types';

const BirdBankPriceFields = props => {
  const {
    priceFixed,
    finalPriceSet,
    finalPrice,
    upperPrice,
    lowerPrice,
    fixedPrice,
  } = props.birdBankItem;

  const updateUpperPrice = e => {
    const upperPrice = e.target.value;
    props.birdBankItemUpdate(props.birdBankItem, { upperPrice });
  };
  const updateLowerPrice = e => {
    const lowerPrice = e.target.value;
    props.birdBankItemUpdate(props.birdBankItem, { lowerPrice });
  };
  const updateFixedPrice = e => {
    const fixedPrice = e.target.value;
    props.birdBankItemUpdate(props.birdBankItem, { fixedPrice });
  };

  const setPriceFixed = e => {
    if (!e.target.checked) return;
    const priceFixed = e.target.value == 'true';
    props.birdBankItemUpdate(props.birdBankItem, { priceFixed });
  };

  const showErrors = () => {
    const { errors } = props;
    if (errors.length === 0) return null;

    return (
      <React.Fragment>
        {errors.map((error, index) => (
          <div className="media txt-alert mts" key={index}>
            <div className="media__obj">
              <i className="fa fa-exclamation-triangle" />
            </div>
            <div className="media__body">{error}</div>
          </div>
        ))}
      </React.Fragment>
    );
  };

  return (
    <EditableField
      editing={props.editing && !finalPriceSet}
      renderEdit={() => (
        <div className="bird-bank-price-fields-edit">
          <div
            className="flex-rows flex-rows--center-v form-light
                          form-light--bordered form-compact mbm"
          >
            <div className="media">
              <div className="media__obj">
                <label>
                  <input
                    type="radio"
                    name=""
                    value={true}
                    onChange={setPriceFixed}
                    checked={priceFixed}
                  />
                  <span className="txt-normal mls">Fixed:</span>
                </label>
              </div>
              <div className="media__body">
                <input
                  size={4}
                  type="number"
                  min="0"
                  step="0.01"
                  value={fixedPrice}
                  disabled={!priceFixed}
                  onChange={updateFixedPrice}
                />
              </div>
            </div>
          </div>
          <div
            className="flex-rows flex-rows--center-v form-light
                       form-light--bordered form-compact"
          >
            <div className="media">
              <div className="media__obj">
                <label>
                  <input
                    type="radio"
                    name=""
                    value={false}
                    onChange={setPriceFixed}
                    checked={!priceFixed}
                  />
                  <span className="txt-normal mls">Range</span>:
                </label>
              </div>
              <div className="media__body">
                <input
                  size={4}
                  type="number"
                  min="0"
                  step="0.01"
                  value={lowerPrice}
                  disabled={priceFixed}
                  onChange={updateLowerPrice}
                />
                <span className="mhs">to</span>
                <input
                  size={4}
                  type="number"
                  min="0"
                  step="0.01"
                  value={upperPrice}
                  disabled={priceFixed}
                  onChange={updateUpperPrice}
                />
              </div>
            </div>
          </div>
          {showErrors()}
        </div>
      )}
      renderRead={() => {
        let priceType, displayPrice;

        if (finalPriceSet) {
          priceType = 'Final Price';
          displayPrice = formatMoney(finalPrice);
        } else if (priceFixed) {
          priceType = 'Fixed Price';
          displayPrice = formatMoney(fixedPrice);
        } else {
          priceType = 'Ranged Price';
          displayPrice = [
            formatMoney(lowerPrice),
            formatMoney(upperPrice),
          ].join(' - ');
        }

        return (
          <div className="bird-bank-price-fields">
            <p className="bird-bank-item-price">{displayPrice}</p>
            <p className="bird-bank-price-label">{priceType}</p>
          </div>
        );
      }}
    />
  );
};

BirdBankPriceFields.propTypes = {
  birdBankItemUpdate: PropTypes.func,
  editing: PropTypes.bool,
};

export default BirdBankPriceFields;
