import React, { Component, useEffect, useState, useRef } from 'react';
import StockAlertForm from './StockAlertForm';
import {
  localGet,
  localPutJSON,
  localPostJSON,
  localDelete,
} from '../fetch-local';
import Table from './Table';
import MultiStateLoader, {
  NOT_ASKED,
  LOADING,
  SUCCESS,
  FAILURE,
} from './MultiStateLoader';
import { includes } from 'lodash';

function RestockDateInput(props) {
  const [loadingState, setLoadingState] = useState(NOT_ASKED);
  const [inputValue, setInputValue] = useState(props.value || '');
  const debounceTimer = useRef(null);

  useEffect(() => {
    if (loadingState !== SUCCESS) return;
    const loadingTimeout = setTimeout(() => {
      setLoadingState(NOT_ASKED);
    }, 1000);
    return () => clearTimeout(loadingTimeout);
  }, [loadingState]);

  function handleChange(e) {
    const newValue = e.target.value;
    setInputValue(newValue);

    if (debounceTimer.current) clearTimeout(debounceTimer.current);

    debounceTimer.current = setTimeout(() => {
      setLoadingState(LOADING);
      props.onChange(
        { restock_date: newValue },
        () => setLoadingState(SUCCESS),
        () => setLoadingState(FAILURE)
      );
    }, 2000);
  }

  return (
    <MultiStateLoader state={{ type: loadingState }}>
      <input
        type="date"
        value={inputValue}
        onChange={handleChange}
        disabled={loadingState === LOADING}
      />
    </MultiStateLoader>
  );
}

export default class StockManager extends Component {
  constructor(props) {
    super(props);

    this.onProductChange = this.onProductChange.bind(this);
    this.onColorChange = this.onColorChange.bind(this);
    this.onSizeSelect = this.onSizeSelect.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onRestockDateChange = this.onRestockDateChange.bind(this);
    this.onColumnSort = this.onColumnSort.bind(this);
    this.multipleAlerts = this.multipleAlerts.bind(this);
    this.updateStockAlert = this.updateStockAlert.bind(this);

    const restockDateUI = {
      id: 4,
      label: 'Restock Date',
      value: o => o.attributes.restockDate,
      render: o =>
        this.multipleAlerts(o) ? (
          <span className="txt-completed">{o.attributes.restockDate}</span>
        ) : this.props.permissions.canEdit ? (
          <RestockDateInput
            value={o.attributes.restockDateValue}
            onChange={(attributes, onSuccess, onFailure) =>
              this.updateStockAlert(o.id, attributes, onSuccess, onFailure)
            }
          />
        ) : (
          o.attributes.restockDate
        ),
      cellProps: { className: 'txt-center' },
    };

    const columns = [
      {
        id: 1,
        label: 'Product',
        value: o => o.attributes.brandAndStyle,
        render: o =>
          this.multipleAlerts(o) ? (
            <span className="txt-completed">{o.attributes.brandAndStyle}</span>
          ) : (
            o.attributes.brandAndStyle
          ),
        cellProps: { className: 'txt-left' },
      },
      {
        id: 2,
        label: 'Color',
        value: o => o.attributes.colorName,
        render: o =>
          this.multipleAlerts(o) ? (
            <span className="txt-completed">{o.attributes.colorName}</span>
          ) : (
            o.attributes.colorName
          ),
        cellProps: { className: 'txt-center' },
      },
      {
        id: 3,
        label: 'Size',
        value: o => o.attributes.sizeName,
        render: o =>
          this.multipleAlerts(o) ? (
            <span className="txt-completed"> {o.attributes.sizeName}</span>
          ) : (
            o.attributes.sizeName
          ),
        cellProps: { className: 'txt-center' },
      },
      restockDateUI,
      {
        id: 6,
        label: this.props.permissions.canEdit ? 'Inventory' : '',
        value: o => o.inventoryData.totalQuantityAvailable,
        render: o => {
          let className = '';
          if (o.inventoryData.totalQuantityAvailable > 50) {
            className = 'label label--spaced label--success pointer';
          } else if (o.inventoryData.totalQuantityAvailable > 0) {
            className = 'label label--spaced label--warning pointer';
          } else {
            className = 'pointer';
          }
          return this.props.permissions.canEdit ? (
            <span
              className={`${className}`}
              title={o.inventoryData.lastUpdated}
            >
              {o.inventoryData.totalQuantityAvailable === 'N/A'
                ? ''
                : o.inventoryData.totalQuantityAvailable}
            </span>
          ) : (
            <></>
          );
        },
        cellProps: { className: 'txt-center' },
      },
      {
        id: 5,
        value: o => o.links.destroyPath,
        render: o =>
          this.props.permissions.canEdit ? (
            <MultiStateLoader
              state={{
                type: this.state.dataUi[o.id]
                  ? this.state.dataUi[o.id].deleteLoader
                  : NOT_ASKED,
              }}
            >
              <button
                id={o}
                onClick={e => this.renderDestroyLink(e, o)}
                className="button-naked button-colored"
              >
                <i className="fa fa-trash txt-alert" />
              </button>
            </MultiStateLoader>
          ) : (
            ''
          ),
        cellProps: { className: 'txt-right' },
      },
    ];

    this.state = {
      isLoading: false,
      data: props.stockAlerts,
      outOfStockProducts: props.outOfStockProducts,
      dataUi: props.stockAlerts.reduce(
        (result, stockAlert) => ({
          ...result,
          [stockAlert.id]: { deleteLoader: NOT_ASKED },
        }),
        {}
      ),
      columns,
      product: null,
      colorOptions: [],
      color: null,
      sizeOptions: [],
      size: null,
      restockDate: null,
    };
  }

  onProductChange(product) {
    this.setState({ product });

    if (product) {
      let productId = product.value;
      const colorsEndpoint = `/api/v1/print_colors?product_id=${productId}`;
      localGet(colorsEndpoint).then(data => {
        const colorOptions = data.map(color => ({
          value: color.id.toString(),
          label: color.name,
          hex_code: color.hex_code ? color.hex_code.toString() : '',
        }));
        this.setState({ colorOptions });
      });

      const sizesEndpoint = `/api/v1/products/${productId}/sizes`;
      localGet(sizesEndpoint).then(data => {
        const sizeOptions = data.sizes.map(size => ({
          value: size.id.toString(),
          label: size.size,
        }));
        this.setState({ sizeOptions });
      });
    }
  }

  onColorChange(color) {
    this.setState({ color });
  }

  onSizeSelect(size) {
    this.setState({ size });
  }

  onRestockDateChange(e) {
    this.setState({ restockDate: e.target.value });
  }

  onChange(e) {
    e.preventDefault();

    this.setState({ isLoading: true });

    const endpoint = this.props.links.createPath;
    const attributes = {
      stock_alert: {
        product_id: this.state.product.value,
        color_id: this.state.color ? this.state.color.value : null,
        size_id: this.state.size ? this.state.size.value : null,
        restock_date: this.state.restockDate,
      },
    };

    const fetch = localPostJSON(endpoint, attributes);
    fetch
      .then(data => {
        this.setState({
          data: data.stockAlerts,
          outOfStockProducts: data.outOfStockProducts,
          isLoading: false,
          product: null,
          color: null,
          size: null,
          restockDate: null,
        });
      })
      .catch(() => {
        this.setState({ data: this.state.data, isLoading: false });
      });
  }

  onColumnSort(columnId, sortDirection) {
    this.setState(prevState => ({
      columns: prevState.columns.map(o => {
        if (o.id === columnId) {
          return { ...o, sort: sortDirection };
        } else return { ...o, sort: null };
      }),
    }));
  }

  updateStockAlert(id, attributes, onSuccess, onFailure) {
    const stockAlert = this.state.data.find(o => o.id === id);
    const endpoint = stockAlert.links.updatePath;
    const fetch = localPutJSON(endpoint, { stock_alert: attributes });

    fetch
      .then(data => {
        this.setState(prevState => ({
          data: prevState.data.map(o => {
            if (o.id === id) {
              return data;
            } else {
              return o;
            }
          }),
        }));
        onSuccess();
      })
      .catch(() => {
        this.setState({ data: this.state.data });
        onFailure();
      });
  }

  renderDestroyLink(e, stockAlert) {
    e.preventDefault();
    let result = window.confirm(
      'Are you sure you would like to restock this item?'
    );

    if (result) {
      const fetch = localDelete(stockAlert.links.destroyPath);

      this.setState(prevState => ({
        dataUi: {
          ...prevState.dataUi,
          [stockAlert.id]: {
            ...prevState.dataUi[stockAlert.id],
            deleteLoader: LOADING,
          },
        },
      }));

      fetch
        .then(data => {
          this.setState(prevState => ({
            data: data.stockAlerts,
            dataUi: {
              ...prevState.dataUi,
              [stockAlert.id]: {
                ...prevState.dataUi[stockAlert.id],
                deleteLoader: SUCCESS,
              },
            },
            outOfStockProducts: data.outOfStockProducts,
          }));
        })
        .catch(() => {
          this.setState(prevState => ({
            data: this.state.data,
            dataUi: {
              ...prevState.dataUi,
              [stockAlert.id]: {
                ...prevState.dataUi[stockAlert.id],
                deleteLoader: FAILURE,
              },
            },
          }));
        });
    } else {
      return null;
    }
  }

  multipleAlerts(stockAlert) {
    const productOutOfStock = includes(
      this.state.outOfStockProducts,
      stockAlert.attributes.product.id
    );
    return productOutOfStock && !stockAlert.attributes.outOfStock;
  }

  render() {
    const stockAlertTableProps = {
      data: this.state.data,
      columns: this.state.columns,
      onColumnSort: this.onColumnSort,
      tableProps: { className: 'table' },
      keyPropertyName: 'id',
    };

    const toolTip =
      this.props.stockAlerts.length === 0 ? (
        <p>*If an item becomes out of stock it will be listed below.</p>
      ) : null;

    const stockAlertForm = this.props.permissions.canEdit ? (
      <StockAlertForm
        onChange={this.onChange}
        product={this.state.product}
        onProductChange={this.onProductChange}
        productOptions={this.props.productOptions}
        color={this.state.color}
        colorOptions={this.state.colorOptions}
        onColorChange={this.onColorChange}
        size={this.state.size}
        sizeOptions={this.state.sizeOptions}
        onSizeSelect={this.onSizeSelect}
        restockDate={this.state.restockDate}
        onRestockDateChange={this.onRestockDateChange}
      />
    ) : null;

    const renderData = this.state.isLoading ? (
      <i className="fa fa-circle-o-notch fa-spin" />
    ) : (
      <Table {...stockAlertTableProps} />
    );

    return (
      <div className="l-section">
        <h2 className="primary-headline pbl">Out of Stock</h2>
        {stockAlertForm}
        {toolTip}
        {renderData}
      </div>
    );
  }
}
