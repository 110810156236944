import React from 'react';

export default function TrashButton(props) {
  return (
    <i
      className="fa fa-trash"
      style={{ cursor: 'pointer' }}
      onClick={props.onClick}
    />
  );
}
