$(document).ready(function() {
  filterDepartmentsAndApparelClasses();
  filterWarehouses();
  filterArtists();
});

let filterArtists = function() {
  let artists = $('#order_artist_id').html();

  $('#order_art_team_id').change(function() {
    let artTeams = $('#order_art_team_id :selected').text();
    // regex to remove all white space or unidentifiable characters
    let escapeArtTeam = artTeams.replace(
      /([ #;&,.+*~':"!^$[\]()=>|/@])/g,
      '\\$1'
    );
    let artistOptions = $(artists)
      .filter('optgroup[label=' + escapeArtTeam + ']')
      .html();

    if (artistOptions) {
      $('#order_artist_id').html(artistOptions);
    } else {
      $('#order_artist_id').empty();
    }
  });
};

const filterDepartmentCallback = (departments, apparelClasses) => {
  // if there is no currently selected division, don't filter anything
  const $divisionSelector = $('#product_division_id');
  if ($divisionSelector.length === 0 || $divisionSelector.val() === '') return;

  const divisionId = $('#product_division_id :selected').val();

  let departmentOptions = $(departments)
    .filter(`optgroup[data-division-id=${divisionId}]`)
    .html();

  if (departmentOptions) {
    $('#product_department_id').html(departmentOptions);
  } else {
    $('#product_department_id').empty();
  }

  filterApparelClassCallback(apparelClasses);
};

const filterApparelClassCallback = apparelClasses => {
  // if there is no currently selected department, don't filter anything
  const $departmentSelector = $('#product_department_id');
  if ($departmentSelector.length === 0 || $departmentSelector.val() === '')
    return;

  let departmentId = $('#product_department_id :selected').val();

  let apparelClassOptions = $(apparelClasses)
    .filter(`optgroup[data-department-id=${departmentId}]`)
    .html();

  if (apparelClassOptions) {
    $('#product_apparel_class_id').html(apparelClassOptions);
  } else {
    $('#product_apparel_class_id').empty();
  }
};

const filterDepartmentsAndApparelClasses = () => {
  let departments = $('#product_department_id').html();
  let apparelClasses = $('#product_apparel_class_id').html();
  $('#product_division_id').change(() =>
    filterDepartmentCallback(departments, apparelClasses)
  );
  $('#product_department_id').change(() =>
    filterApparelClassCallback(apparelClasses)
  );
  filterDepartmentCallback(departments, apparelClasses);
};

let filterWarehouses = function() {
  let warehouses = $('.warehouse-filter').html();

  $('.supplier-select').change(function() {
    let supplier = $(this)
      .find(':selected')
      .html();
    let escapeSupplier = supplier.replace(/&amp;/g, '&');
    let warehouseOptions = $(warehouses)
      .filter('optgroup[label="' + escapeSupplier + '"]')
      .html();
    let filterSelector = $(this).data('filters');

    if (warehouseOptions) {
      $(filterSelector).html(warehouseOptions);
    } else {
      $(filterSelector).empty();
    }
  });
};
