(function($) {
  const defaultOptions = {
    buttons: ['close'],
    animationEffect: false,
  };

  $(function() {
    $('.fancybox').fancybox(defaultOptions);

    $('.fancybox-image').fancybox({
      ...defaultOptions,
      type: 'image',
    });

    $('.fancybox-art').fancybox({
      ...defaultOptions,
      type: 'image',
      afterLoad: (instance, current) => {
        const multiplier = 20;
        current.width = current.width * multiplier;
        current.height = current.height * multiplier;
      },
    });
  });

  $(document).on(
    'click',
    '.card-carousel-dot, .slick-next, .slick-prev',
    function(event) {
      event.preventDefault();
    }
  );
})(window.jQuery);
