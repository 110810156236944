import React, { useState, useEffect } from 'react';
import { Id, Permissions } from '../base-types';
import { Post as PostType } from './PostsApp/types';
import { get, create, destroy, update } from './PostsApp/Store';
import Post from './PostsApp/Post';
import { RemoteData, LoadState } from '../remote-data-types';
import Loader from './loader';
import { snakeCase } from 'lodash';
import AddPostForm from './PostsApp/AddPostForm';
import EditPostForm from './PostsApp/EditPostForm';
import ErrorBox from './ErrorBox';

interface Props {
  postableType: string;
  postableId: Id;
  postableName: string;
  collectionLabel?: string;
}

interface AppState {
  posts: PostType[];
  permissions: Permissions;
}

type Result = RemoteData<string, AppState>;

export default function PostsApp(props: Props) {
  const [appState, setAppState] = useState<Result>({
    state: LoadState.NotAsked,
  });

  const [showAddForm, setShowAddForm] = useState<boolean>(false);
  const [editingPostId, setEditingPostId] = useState<Id | null>(null);

  const collectionUri = `/posts.json?postable_type=${snakeCase(
    props.postableType
  )}&postable_name=${snakeCase(props.postableName)}&postable_id=${
    props.postableId
  }`;

  useEffect(() => {
    setAppState({ state: LoadState.IndeterminateProgressLoading });
    get(collectionUri)
      .then(({ posts, permissions }) =>
        setAppState({ state: LoadState.Success, data: { posts, permissions } })
      )
      .catch(() =>
        setAppState({ state: LoadState.Failure, error: 'Unable to load posts' })
      );
  }, [collectionUri]);

  let view = <div>not loading anything</div>;

  const collectionLabel = props.collectionLabel || 'comments';

  const createPost = post =>
    create(collectionUri, post).then(({ posts, permissions }) =>
      setAppState({ state: LoadState.Success, data: { posts, permissions } })
    );

  const savePost = post =>
    update(post).then(({ posts, permissions }) =>
      setAppState({ state: LoadState.Success, data: { posts, permissions } })
    );

  if (appState.state === LoadState.IndeterminateProgressLoading) {
    view = <Loader isActive={true} />;
  } else if (appState.state === LoadState.Failure) {
    view = <ErrorBox errors={[appState.error]} />;
  } else if (appState.state === LoadState.Success) {
    const posts = appState.data.posts;
    const canCreateNewPost =
      appState.data.permissions === Permissions.ReadWrite;
    view = (
      <>
        {posts.length === 0 && (
          <p>
            <em className="txt-muted2">
              There are currently no {collectionLabel}.
            </em>
          </p>
        )}
        {posts.map(post =>
          editingPostId === post.id ? (
            <EditPostForm
              key={post.id}
              post={post}
              savePost={savePost}
              postDidSave={() => setEditingPostId(null)}
              onCancel={() => setEditingPostId(null)}
            />
          ) : (
            <Post
              key={post.id}
              post={post}
              readonly={post.permissions === Permissions.ReadOnly}
              onEdit={p => setEditingPostId(p.id)}
              onDestroy={async p => {
                await destroy(p);
                setAppState({
                  state: LoadState.Success,
                  data: {
                    ...appState.data,
                    posts: posts.filter(rp => rp.id !== p.id),
                  },
                });
              }}
            />
          )
        )}

        {canCreateNewPost &&
          (showAddForm ? (
            <AddPostForm
              onCancel={() => setShowAddForm(false)}
              addPost={createPost}
            />
          ) : (
            <button
              onClick={() => setShowAddForm(true)}
              className="button button--secondary button--small mtm"
            >
              Add
            </button>
          ))}
      </>
    );
  }

  return <div>{view}</div>;
}
