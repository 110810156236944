const svg = 'image/svg+xml';
const png = 'image/png';
const jpg = 'image/jpeg';

const doc = 'application/msword';
const docx =
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
const ppt = 'application/vnd.ms-powerpoint';
const pptx =
  'application/vnd.openxmlformats-officedocument.presentationml.presentation';

const txt = 'text/plain';
const rtf = 'application/rtf';
const pdf = 'application/pdf';

export function humanizeMIMEType(s: string): string {
  switch (s) {
    case svg:
      return 'SVG';
    case png:
      return 'PNG';
    case jpg:
      return 'JPEG';
    case doc:
    case docx:
      return 'Microsoft Word Document';
    case ppt:
    case pptx:
      return 'Microsoft PowerPoint Document';
    case txt:
      return 'plaintext';
    case rtf:
      return 'RTF';
    case pdf:
      return 'PDF';
    default:
      return s;
  }
}

export default {
  svg,
  png,
  jpg,
  doc,
  docx,
  ppt,
  pptx,
};

export const imageMIMETypes = [png, jpg, svg];

export const microsoftOfficeTypes = [doc, docx, ppt, pptx];

export const documentMIMETypes = [txt, rtf, pdf];
