export const getApplicationEnvironment = () => window.OrderApp.environment;

export const isTest = () => getApplicationEnvironment() === 'test';

export const isDevelopment = () =>
  getApplicationEnvironment() === 'development';

export const isStaging = () => getApplicationEnvironment() === 'staging';

export const isProduction = () => getApplicationEnvironment() === 'production';
