import React, { useEffect, useState, ReactNode } from 'react';
import classNames from 'classnames';
import IconSvg from './IconSvg';
import { Color } from '../BrandColor';

type NotificationType = 'success' | 'alert' | 'warning' | 'notice' | 'support';

interface Props {
  children: ReactNode;
  small?: boolean;
  float?: boolean;
  type?: NotificationType;
  title?: string;
  dismissible?: boolean;
  autoDismiss?: boolean;
}

function iconColorForType(type: NotificationType): Color {
  switch (type) {
    case 'success':
      return Color.Green;
    case 'alert':
      return Color.Red;
    case 'warning':
      return Color.Yellow;
    case 'notice':
      return Color.TealBlue;
    case 'support':
      return Color.DarkGray;
  }
}

const Notification = (props: Props) => {
  const float = props.float || false;
  const small = props.small || false;
  const dismissible = props.dismissible || false;
  const autoDismiss = props.autoDismiss || false;
  const type: NotificationType = props.type || 'notice';
  const { title, children } = props;

  const [showNotification, setShowNotification] = useState(true);

  const classes = classNames('notification', `notification--${type}`, {
    'notification--float': float,
    'notification--compact': small,
    'notification--small': small,
    'notification--dismiss': dismissible,
  });

  useEffect(() => {
    if (!autoDismiss || !showNotification) return;
    const timeout = setTimeout(() => setShowNotification(false), 3000);
    return () => clearTimeout(timeout);
  }, [showNotification, autoDismiss]);

  return (
    <>
      {showNotification && (
        <div className={classes}>
          {title && <div className="notification__title">{title}</div>}
          {children}
          {dismissible && (
            <button
              className="notification--dismiss__button"
              onClick={() => setShowNotification(false)}
              type="button"
            >
              <IconSvg icon="close" color={iconColorForType(type)} />
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default Notification;
