import React from 'react';
import Select from 'react-select';

const SizeSelect = props => {

  if (!props.product)
    return null;
  else {
    return(
      <div className='react-select-form'>
        <Select
          name='size-select'
          placeholder='Select Size*'
          options={props.sizeOptions}
          onChange={props.onSizeSelect.bind(this)}
          value={props.size}
        />
        <small>*Leave blank if all sizes are out of stock</small>
      </div>
    );
  }
};

export default SizeSelect;
