import Rails from 'rails-ujs';

(function($) {
  let valueForInput = function(el) {
    let $el = $(el);
    if ($el.is(':checkbox')) {
      return $el.is(':checked');
    } else {
      return $el.val();
    }
  };

  let activeRequests = 0;

  $(document).on('autosave.Submit', 'form.autosave', function() {
    let timeout = $(this).data('timeout');
    let debounce = parseInt($(this).data('debounce')) || 2000;
    clearTimeout(timeout);
    $(this).data('timeout', null);
    $(this).data(
      'timeout',
      setTimeout(
        function() {
          if ($(this).data('remote') === true) {
            // Note that his requires the 'rails-ujs' npm package
            // https://stackoverflow.com/a/46573492/456337
            Rails.fire(this, 'submit');
          } else {
            $(this).submit();
          }

          asyncStateUpdate(this, 'loading');
        }.bind(this),
        debounce
      )
    );
  });

  $(function() {
    $('.autosave :input').each(function() {
      updatePreviousValue(this);
    });
  });

  $(document).on('keyup change', 'form.autosave :input', function() {
    if ($(this).data('previous-value') === valueForInput(this)) {
      return;
    }

    let el = this;
    let form = $(this)
      .closest('form')
      .get(0);

    updatePreviousValue(el);
    asyncStateUpdate(form, 'dirty');

    $(form).trigger('autosave.Submit');
  });

  $(document).on('ajax:success', '.autosave', function() {
    asyncStateUpdate(this, 'success');
  });

  $(document).on('ajax:error', '.autosave', function(e) {
    let message;
    let form = $(this).closest('form');

    if (
      form.hasClass('edit_line_item') &&
      typeof e.originalEvent.detail[0] == 'string'
    ) {
      message = e.originalEvent.detail[0];
    }

    asyncStateUpdate(this, 'error', message);
  });

  $(document).on('ajax:success', '.autosave.cm-edit-org', function() {
    $($(this).data('changes'))
      .find('input')
      .prop('disabled', false);
  });

  function asyncStateUpdate(form, state, message) {
    let iconClass;
    let messageClass;
    switch (state) {
      case 'dirty':
        iconClass = 'fa-ellipsis-h';
        break;
      case 'loading':
        iconClass = 'fa-circle-o-notch fa-spin';
        break;
      case 'success':
        iconClass = 'fa-check txt-success';
        messageClass = 'mls txt-left txt-success';
        break;
      case 'error':
        iconClass = 'fa-warning txt-alert';
        messageClass = 'mls txt-left txt-alert';
        break;
      default:
        iconClass = 'fa-check';
        break;
    }
    let icon = '<i class="fa ' + iconClass + '"></i>';
    if (message) {
      icon += '<span class="' + messageClass + '">' + message + '</span>';
    }
    let iconContainer = $(form).find('.state-icon');
    iconContainer.html(icon);
  }

  function updatePreviousValue(el) {
    $(el).data('previous-value', valueForInput(el));
  }
})(window.jQuery);
