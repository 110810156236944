(function() {
  let FillInAll = function(params) {
    let self = this,
      $node = params.$node,
      onKey = function(e) {
        $('[name*=discount]').each(function() {
          this.value = $node.val();
        });
        return false;
      },
      init = function() {
        $node.on('keyup', onKey);
      };

    init();
  };

  $('[name^="master_discount"]').each(function() {
    new FillInAll({
      $node: $(this)
    });
  });
}());
