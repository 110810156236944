import React, { useContext, useState, useEffect, useMemo } from 'react';
import ProgressBar from '../ProgressBar';
import humanSize from '../../humanSize';
import useImageDirectUpload from '../../useImageDirectUpload';
import { LoadState } from '../../remote-data-types';
import readFileData from '../../readFileData';
import { containsFiles, getDataTransferItems } from '../../fileUtility';
import classnames from 'classnames';
import { DecorationType, DecorationColor, Permissions } from '../../base-types';
import { getDimensionsInInches, getSwatches } from '../../extractColors';
import {
  ArtSubmissionContext,
  Location as DecoratedLocation,
} from './ArtSubmissionContext';
import { modalConfirm } from '../../modal';
import { AttachmentUpdate, AttachmentUpdateType } from '../../attachment-types';
import { getColors } from '../../stores/Color';
import LightBox from '../LightBox';
import validateFile from '../../validateFile';
import { all } from 'bluebird';
import { createCustomColor } from '../../stores/Color';
import { curry } from 'lodash';

interface Props {
  location: DecoratedLocation;
  directUploadUrl: string;
  acceptedMIMETypes: string[];
  maxSize: number;
}

const onDocumentDragOver = event => event.preventDefault();
const onDocumentDrop = event => event.preventDefault();
const handleDragOver = event => event.preventDefault();

export default function ArtProofUploader(props: Props) {
  const { directUploadUrl, acceptedMIMETypes, maxSize, location } = props;
  const canEdit = location.permissions.proof === Permissions.ReadWrite;

  const existingAttachment =
    location.proofUpdate.type !== AttachmentUpdateType.RemoveAttachment
      ? location.proof
      : null;
  const { dispatch } = useContext(ArtSubmissionContext);
  const { id, decorationType } = location;

  const [file, setFile] = useState<File | null>(null);
  const [imageData, setImageData] = useState<string | null>(null);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [dragCounter, setDragCounter] = useState(0);

  const isDraggingOver = dragCounter > 0;

  const options = useMemo(
    () => ({
      acceptedMIMETypes,
      maxSize,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...acceptedMIMETypes, maxSize]
  );

  useEffect(() => {
    async function extractDataFromFile() {
      if (!file) return;
      setIsConfirmed(false);

      const failure = validateFile(file, options);
      if (failure) return;

      const dimensions = await getDimensionsInInches(file);

      const wasConfirmed = await modalConfirm({
        title: 'Please confirm the dimensions',
        text: `The following dimensions were found for this file:
                      ${dimensions.width}in x ${dimensions.height}in. Is this correct?`,
        confirmLabel: 'Approve',
        cancelLabel: 'Cancel',
      });

      setIsConfirmed(wasConfirmed);

      if (!wasConfirmed) return;

      dispatch({
        type: 'update-location-dimensions',
        id: id,
        payload: dimensions,
      });

      setImageData(await readFileData(file));

      const colorNames = await getSwatches(file);

      const permittedDecorationTypes: DecorationType[] = [
        DecorationType.ScreenPrint,
        DecorationType.Embroidery,
      ];

      if (
        !permittedDecorationTypes.includes(decorationType) ||
        colorNames.length < 1
      ) {
        dispatch({
          type: 'update-location-colors',
          id: id,
          payload: [],
        });

        return;
      }

      const colors = (await getColors()).filter(
        c => c.decorationType === decorationType
      );

      let standardColors: DecorationColor[] = [];
      let customColorNames: string[] = [];

      colorNames.forEach(colorName => {
        const foundColor = colors.find(
          c => colorName.toLowerCase() === c.name.toLowerCase()
        );
        if (foundColor) {
          standardColors = [...standardColors, foundColor];
        } else {
          customColorNames = [...customColorNames, colorName];
        }
      });

      const createCustomPrintColor = curry(createCustomColor)(decorationType);
      const customColors = await all(
        customColorNames.map(createCustomPrintColor)
      );

      const payload = [...standardColors, ...customColors];

      dispatch({
        type: 'update-location-colors',
        id: id,
        payload,
      });
    }

    extractDataFromFile();
  }, [decorationType, id, file, dispatch, options]);

  useEffect(() => {
    document.addEventListener('dragover', onDocumentDragOver, false);
    document.addEventListener('drop', onDocumentDrop, false);
    return () => {
      document.removeEventListener('dragover', onDocumentDragOver);
      document.removeEventListener('drop', onDocumentDrop);
    };
  }, []);

  const fileReadyToUpload =
    file !== null && (isConfirmed || validateFile(file, options)) ? file : null;
  const result = useImageDirectUpload(
    directUploadUrl,
    fileReadyToUpload,
    options
  );

  useEffect(() => {
    let payload: AttachmentUpdate = {
      type: AttachmentUpdateType.DontChangeAttachment,
    };

    if (result.state === LoadState.Success) {
      payload = {
        type: AttachmentUpdateType.AddAttachment,
        signedId: result.data,
      };
    }

    setIsConfirmed(false);
    dispatch({
      type: 'update-location-proof-attachment',
      id,
      payload,
    });
  }, [id, dispatch, result]);

  const onSelectFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setFile(file);
    }
  };

  const progressBar = file && result.state === LoadState.Loading && (
    <div className="mtm">
      <ProgressBar {...result} />
    </div>
  );

  const imagePreview =
    (file && imageData) ||
    (location.proofStatus === AttachmentUpdateType.RemoveAttachment
      ? null
      : existingAttachment && existingAttachment.previewUrls.fullsize);

  const errorMessage = result.state === LoadState.Failure && file && (
    <div className="notification notification--alert">{result.error}</div>
  );

  const handleDragLeave = event => {
    event.preventDefault();
    setDragCounter(c => c - 1);
  };

  const handleDragEnter = event => {
    event.preventDefault();
    setDragCounter(c => c + 1);
  };

  const handleDrop = event => {
    event.preventDefault();
    event.stopPropagation();

    const rawFileList = getDataTransferItems(event);
    const files = Array.from(rawFileList);

    if (containsFiles(event) && files.length > 0) {
      setFile(files[0]);
    }

    setDragCounter(0);
  };

  const draggableProps = canEdit
    ? {
        onDrop: handleDrop,
        onDragOver: handleDragOver,
        onDragEnter: handleDragEnter,
        onDragLeave: handleDragLeave,
      }
    : {};

  const uploaderClass = classnames('upload-area', {
    'upload-area--dragged-over': isDraggingOver,
    'upload-area--file': file || existingAttachment,
  });

  let uploaderStyle = {};

  if (imagePreview) {
    const url = encodeURI(imagePreview);
    const backgroundImage = `url("${url}")`;
    uploaderStyle = { backgroundImage };
  }

  const isNotShowingImage = !imagePreview;

  const disableUploadImageButton =
    result.state === LoadState.Loading ||
    result.state === LoadState.IndeterminateProgressLoading;

  const uploadImageButton = canEdit && (
    <div className="uploader">
      <div className="uploader__panel">
        <label
          className={classnames({
            'attachment-button': true,
            'attachment-button--small': true,
            'attachment-button--secondary': true,
            mtm: true,
            'is-disabled': disableUploadImageButton,
          })}
          {...draggableProps}
        >
          {existingAttachment ? 'Replace' : 'Upload'} Image
          <input
            type="file"
            accept={acceptedMIMETypes.join(',')}
            onChange={onSelectFiles}
            disabled={disableUploadImageButton}
          />
        </label>
      </div>
    </div>
  );

  const dragAndDropLabel = isNotShowingImage && (
    <span style={{ pointerEvents: 'none' }}>
      {canEdit ? 'Drag file to upload' : 'Art proof has not been uploaded yet'}
    </span>
  );

  const uploadInfo = fileReadyToUpload && (
    <p className="mtn mbs">
      <small>
        {fileReadyToUpload.name}
        <i className="txt-muted2 mls">{humanSize(fileReadyToUpload.size)}</i>
      </small>
    </p>
  );

  return (
    <>
      <div {...draggableProps}>
        <div className="uploader">
          <div className="uploader__panel">
            <LightBox src={imagePreview}>
              <label
                className={uploaderClass}
                {...draggableProps}
                style={uploaderStyle}
              >
                {dragAndDropLabel}
              </label>
            </LightBox>
          </div>

          <div className="uploader__main">
            {progressBar}
            {uploadImageButton}
            {uploadInfo}
            {errorMessage}
          </div>
        </div>
      </div>

      {location.permissions.proof === Permissions.ReadWrite &&
        location.proofStatus !== AttachmentUpdateType.RemoveAttachment &&
        location.proof &&
        location.proofUpdate.type ===
          AttachmentUpdateType.DontChangeAttachment && (
          <button
            type="button"
            title="Remove this image"
            className="button button--alert button--small mtm"
            onClick={() => {
              setFile(null);
              setImageData(null);
              setIsConfirmed(false);

              let payload: AttachmentUpdate = {
                type: AttachmentUpdateType.RemoveAttachment,
              };

              dispatch({
                type: 'update-location-proof-attachment',
                id,
                payload,
              });

              dispatch({
                type: 'update-location-colors',
                id: id,
                payload: [],
              });
            }}
          >
            Remove
          </button>
        )}
    </>
  );
}
