const formSelector = '[data-app="purchasing-order"]';
const orderRowSelector = 'tr';
const releaseButtonSelector = '[data-button="release"]';

const findForms = () => $(formSelector);

const getData = () => {
  return findForms().map(function() {
    let form = $(this);
    let orderRow = form.closest(orderRowSelector);
    let releaseButton = orderRow.find(releaseButtonSelector);

    return { form, orderRow, releaseButton };
  });
};

const actionToIconClass = action => {
  switch (action.type) {
    case 'DIRTY':
      return 'fa-ellipsis-h';
    case 'LOADING':
      return 'fa-circle-o-notch fa-spin';
    case 'SUCCESS':
      return 'fa-check txt-success';
    case 'ERROR':
      return 'fa-warning txt-alert';
    default:
      return null;
  }
};

const update = (state, action) => {
  let { type, payload } = action;
  let { form, orderRow, releaseButton, field } = payload;

  let isReadOnly = state > 0;

  field.siblings('[class^="fa"]').remove();
  releaseButton.prop('disabled', isReadOnly);

  let iconClass = actionToIconClass(action);
  if (iconClass) {
    let icon = $(`<i class="fa ${iconClass} mls"></i>`);
    field.after(icon);
    if (type === 'SUCCESS') {
      icon.delay(2500).fadeOut();
    }
  }
};

const createAction = (type, payload) => ({ type, payload });

const purchasingOrder = () => {
  let warehouses = $('.warehouse-filter').html();
  let data = getData();

  $('.purchase-supplier-select-indv').each(function() {
    const el = $(this);
    // Filter warehouse drop-down based on supplier (if applicable)
    let supplierID = el.find(':selected').val();
    let warehouses = el
      .closest('tr')
      .find('.warehouse-filter')
      .html();

    let warehouseOptions = $(warehouses)
      .filter(`optgroup[data-supplier-id="${supplierID}"]`)
      .html();
    let filterSelector = el.data('filters');

    if (warehouseOptions) {
      $(filterSelector).html(warehouseOptions);
    } else {
      $(filterSelector).empty();
    }
  });

  data.each(function() {
    let elements = this;
    let { form, orderRow, releaseButton } = elements;

    let numberOfPending = 0;

    let onChange = event => {
      let field = $(event.currentTarget);
      let payload = { ...elements, field };

      update(++numberOfPending, createAction('LOADING', payload));

      let request = $.ajax({
        method: form.attr('method'),
        dataType: 'json',
        url: form.attr('action'),
        data: form.serialize(),
      });

      request.done(data => {
        if (field.hasClass('purchase-supplier-select')) {
          $.each(data, function(id, cost) {
            $(`[data-size-id="${id}"]`).val(cost);
          });
        }
        update(--numberOfPending, createAction('SUCCESS', payload));
      });
      request.fail(() =>
        update(--numberOfPending, createAction('ERROR', payload))
      );
    };

    form.on('change', ':input', function(event) {
      let el = $(this);

      // Fill columns if mass-select drop-down
      if (el.hasClass('fill_col_values')) {
        let targetSelector = el.data('changes');
        let selectedValue = el.val();
        $(targetSelector).val(selectedValue);
      }

      // Filter warehouse drop-down based on supplier (if applicable)
      if (el.hasClass('purchase-supplier-select')) {
        let supplierID = el.find(':selected').val();
        let warehouseOptions = $(warehouses)
          .filter(`optgroup[data-supplier-id="${supplierID}"]`)
          .html();
        let filterSelector = el.data('filters');

        if (warehouseOptions) {
          $(filterSelector).html(warehouseOptions);
        } else {
          $(filterSelector).empty();
        }
      }

      // Finally, persist the form
      onChange(event);
    });
  });
};

export default () => {
  $(purchasingOrder);
};
