import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SalesTaxObject from './SalesTaxObject';

class SalesTaxManager extends Component {
  static propTypes = {
    salesTaxes: PropTypes.arrayOf(PropTypes.object)
  }
  constructor(props) {
    super(props);
  }
  render(){
    let taxObjects = this.props.salesTaxes.map((taxObject, index) => (
      <SalesTaxObject
        key={index}
        taxObject={taxObject}
      />
    ));

    return(
      <table className='table--responsive'>
        <thead>
          <tr>
            <th>State</th>
            <th>Collecting Tax</th>
            <th>Charge Use Tax</th>
          </tr>
        </thead>
        <tbody>
          {taxObjects}
        </tbody>
      </table>
    );
  }
}

export default SalesTaxManager;
