import React, { useState } from 'react';
import Modal from 'react-modal';
import IconSvg from './IconSvg';
import { Color } from '../BrandColor';
import { localPatchJSON } from '../fetch-local';

// NOTE: We were getting an error to use setAppElement to hide
// the app while the modal is open, for use by screenreaders. So
// it's currently being set to `main`.

Modal.setAppElement('.main');

interface Props {
  licensingQueuePath: string;
  anchor?: string | null;
}

export default function CollegeLicensingRejectionModal(props: Props) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [reason, setReason] = useState('');
  const [loading, setLoading] = useState(false);

  const maySubmit = !reason.match(/^\s*$/);

  function handleOpen() {
    setModalIsOpen(true);
  }

  function closeModal() {
    setModalIsOpen(false);
  }

  function submitDenial() {
    localPatchJSON(props.licensingQueuePath, {
      step: 'reject',
      message: reason,
    }).then(() => {
      window.location.reload();
    });
    setLoading(true);
  }

  return (
    <>
      <button
        className="button button--small button--alert button--full"
        type="button"
        onClick={handleOpen}
      >
        Deny with note…
      </button>

      <Modal
        isOpen={modalIsOpen}
        className="react-modal"
        overlayClassName="react-modal-overlay"
        contentLabel="Example Modal"
        closeTimeoutMS={200}
      >
        <div className="react-modal-header flex-rows flex-rows--space-b flex-rows--align-c">
          <h4 className="react-modal-header__title">Reason for denial</h4>
          <button className="button-naked" type="button" onClick={closeModal}>
            <IconSvg icon="close" color={Color.Gray} />
          </button>
        </div>

        <div className="react-modal-body react-modal-content">
          <textarea
            onChange={e => setReason(e.target.value)}
            value={reason}
            placeholder="Add reason for denial..."
          ></textarea>
        </div>

        <div className="react-modal-footer">
          <div className="react-modal-footer__cta">
            <button
              onClick={submitDenial}
              disabled={!maySubmit || loading}
              className="button button--alert"
              type="button"
            >
              {loading ? <i className="fa fa-spinner fa-spin" /> : 'Deny'}
            </button>

            <button type="button" className="button-naked" onClick={closeModal}>
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
