import React, { Component } from 'react';

class CustomerReorderForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      name: this.props.order ? this.props.order.name : '',
      purchaseOrder: this.props.order ? this.props.order.purchaseOrder : ''
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(name, e) {
    this.setState({ [name]: e.target.value });
  }

  render() {
    const { order, copiedJobNumber, isBusiness } = this.props;

    const businessInfo = isBusiness ?
      <fieldset>
        <input
          type='hidden'
          name="order[business_id]"
          value={order.business_id}
        />

        <label>Customer PO Number</label>
        <input
          className=''
          type='text'
          name='order[purchase_order]'
          value={this.state.purchaseOrder || ''}
          placeholder='Customer PO Number'
          onChange={this.handleChange.bind(this, 'purchaseOrder')}
        />
      </fieldset> : null;

    return(
      <div className='l-section'>
        <h3 className='secondary-headline'>Info we need now.</h3>

        <div className="row form-vertical form-full">
          <div className="col-6">
            <fieldset>
              <label>Order Name</label>
              <input
                className=''
                type='text'
                name='order[name]'
                value={this.state.name || ''}
                placeholder='Order Name'
                onChange={this.handleChange.bind(this, 'name')}
              />
            </fieldset>

            {businessInfo}

            <input
              type='hidden'
              name="order[copied_order_id]"
              value={copiedJobNumber}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default CustomerReorderForm;
