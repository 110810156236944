export interface Address {
  address: string;
  address2: string;
  locality: string;
  region: string;
  postalCode: string;
  country: string;
}

export type AddressIssue = string[] | null | undefined;

export interface AddressIssues {
  base?: AddressIssue;
  address?: AddressIssue;
  address2?: AddressIssue;
  locality?: AddressIssue;
  region?: AddressIssue;
}

export enum AddressStatus {
  Verified = 'VERIFIED',
  Corrected = 'CORRECTED',
  Failed = 'FAILED',
}
