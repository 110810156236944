import * as Types from '../../base-types';
import { NewPost, EditablePost, Post } from './types';

import {
  localGet,
  localPostJSON,
  localDelete,
  localPutJSON,
} from '../../fetch-local';

interface AttachmentResponse {
  id: number;
  url: string;
  filename: string;
  contentType: string;
  previewUrls: {
    thumbnail: Types.MaybeString;
    fullsize: Types.MaybeString;
  };
}

interface PostResponse {
  id: number;
  message: string;
  isEdited: boolean;
  createdAt: string;
  updatedAt: string;
  user: {
    id: number;
    firstName: string;
    lastName: string;
  };
  attachments: AttachmentResponse[];
  links: {
    self: string;
  };
  permissions: Types.Permissions;
}

interface PostsResponse {
  data: PostResponse[];
  permissions: Types.Permissions;
}

interface CollectionResponseResult {
  posts: Post[];
  permissions: Types.Permissions;
}

function hydratePost(post: PostResponse): Post {
  return {
    ...post,
    createdAt: new Date(post.createdAt),
    updatedAt: new Date(post.updatedAt),
  };
}

export async function get(uri: string): Promise<CollectionResponseResult> {
  const { data, permissions }: PostsResponse = await localGet(uri);
  return {
    posts: data.map(hydratePost),
    permissions,
  };
}

export async function create(
  uri: string,
  post: NewPost
): Promise<CollectionResponseResult> {
  const { data, permissions }: PostsResponse = await localPostJSON(uri, {
    post: { message: post.message, attachments: post.attachments },
  });
  return { posts: data.map(hydratePost), permissions };
}

export async function update(
  post: EditablePost
): Promise<CollectionResponseResult> {
  const payload = {
    post: { message: post.message, attachments: post.newAttachments },
    removed_attachments: post.removedAttachments,
  };

  const { data, permissions }: PostsResponse = await localPutJSON(
    post.links.self,
    payload
  );

  return { posts: data.map(hydratePost), permissions };
}

export async function destroy(post: Post): Promise<void> {
  return await localDelete(post.links.self);
}
