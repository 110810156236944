import React, { useState } from 'react';
import OriginalSelect from 'react-select';
import Modal from 'react-modal';
import IconSvg from './IconSvg';
import { Color } from '../BrandColor';
import { localPutJSON } from '../fetch-local';
import MultiStateLoader, {
  LOADING,
  SUCCESS,
  FAILURE,
} from './MultiStateLoader';

Modal.setAppElement('.main');

interface Props {
  name: string;
  id: string;
  type: string;
  options: object;
  inputProps: object;
  value: string;
  organizationName: string;
  salesRepName: string;
  opportunities: Array<Object>;
  updatePath: string;
}

export default function SalesRepAssignment(props: Props) {
  const {
    name,
    id,
    type,
    options,
    inputProps,
    organizationName,
    opportunities,
    salesRepName,
    updatePath,
  } = props;

  const [value, setValue] = useState(props.value);
  const [newlyAssignedSalesRepName, setNewlyAssignedSalesRepName] = useState(
    props.value
  );
  const [isOpen, setIsOpen] = useState(false);
  const [loadingState, setLoadingState] = useState({});

  const title =
    type === 'opportunity'
      ? `Reassigning CM on Opportunity`
      : `Reassigning CM on ${organizationName}`;

  const numOpportunities = opportunities ? opportunities.length : 0;

  const confirmationMsg =
    type === 'opportunity' ? (
      <p>
        Please confirm you would like to update the CM to{' '}
        <strong>{newlyAssignedSalesRepName}</strong> for this opportunity.
      </p>
    ) : numOpportunities >= 1 ? (
      <>
        <dl className="dl-horizontal">
          <dt>Organization:</dt>
          <dd>{organizationName}</dd>
          <dt>From:</dt>
          <dd>{salesRepName}</dd>
          <dt>To:</dt>
          <dd>{newlyAssignedSalesRepName}</dd>
          <dt>Opportunites:</dt>
          <dd>{numOpportunities}</dd>
        </dl>
        <p>
          <strong>{newlyAssignedSalesRepName}</strong> will receive an email
          confirmation of this assignment.
        </p>
      </>
    ) : (
      <p>
        Please confirm you would like to update the assigned CM for{' '}
        <strong>{organizationName}</strong> to{' '}
        <strong>{newlyAssignedSalesRepName}</strong>.
      </p>
    );

  const selectProps = {
    id,
    inputProps,
    name,
    options,
    onChange: (event: object) => setValueAssignment(event),
    value,
  };

  const confirmationModal = (
    <Modal
      isOpen={isOpen}
      className="react-modal"
      overlayClassName="react-modal-overlay"
      onRequestClose={e => handleModalEvents(e)}
      contentLabel="Confirmation Modal"
      shouldCloseOnEsc={true}
    >
      <div className="react-modal-header flex-rows flex-rows--space-b flex-rows--center-v">
        <h4 className="react-modal-header__title">{title}</h4>
        <button
          className="button-naked"
          type="button"
          onClick={e => handleModalEvents(e)}
        >
          <IconSvg icon="close" color={Color.Gray} />
        </button>
      </div>

      <div className="react-modal-body react-modal-content stack">
        {confirmationMsg}
      </div>

      <div className="react-modal-footer">
        <div className="react-modal-footer__cta">
          <div>
            <button
              type="button"
              className="button"
              onClick={() => handleModalEvents('confirm')}
            >
              Confirm and Change
            </button>

            <button
              className="button-naked"
              onClick={e => handleModalEvents(e)}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );

  function setValueAssignment(event) {
    if (event) {
      setIsOpen(true);
      setLoadingState({ type: LOADING });
      setValue(event.value);
      setNewlyAssignedSalesRepName(event.label);
    } else {
      setValue(event);
      localPutJSON(updatePath, {
        [`${type}`]: { sales_rep_id: value },
      })
        .then(() => {
          setLoadingState({ type: SUCCESS });
        })
        .catch(error => {
          setLoadingState({ type: FAILURE, messsage: error.message });
        });
    }
  }

  function handleModalEvents(event) {
    setIsOpen(false);

    switch (event) {
      case 'confirm':
        localPutJSON(updatePath, {
          [`${type}`]: { sales_rep_id: value },
        })
          .then(() => {
            setLoadingState({ type: SUCCESS });
          })
          .catch(error => {
            setLoadingState({ type: FAILURE, messsage: error.message });
          });
        break;
      default:
        setValue(props.value);
        setLoadingState({});
        break;
    }
  }

  return (
    <>
      {confirmationModal}
      <MultiStateLoader state={loadingState}>
        <OriginalSelect {...selectProps} />
      </MultiStateLoader>
    </>
  );
}
