import React from 'react';

const DragHandle = props => {
  return (
    <div className="reorder-handle" {...props}>
      <span className="grippy" title="Drag this item to preferred position" />
    </div>
  );
};

export default DragHandle;
