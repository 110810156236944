import React from 'react';
import PropTypes from 'prop-types';
import { intersection } from 'lodash';

class Revision extends React.Component {

  static propTypes = {
    revision: PropTypes.object,
    onToggle: PropTypes.func
  };

  state = {
    loading: false
  };

  _toggle = (event) => {
    this._startLoading();
    this.props.onToggle(this.props.revision, event.target.checked, this._stopLoading);
  };

  _startLoading = () => {
    this.setState({ loading: true });
  };

  _stopLoading = () => {
    this.setState({ loading: false });
  };

  _rawDescription = () => {
    return { __html: this.props.revision.description };
  };

  _completeField = () => {
    let revision_complete = this.props.revision.status == 'complete';
    return <input type="checkbox" id={'revision-'+this.props.revision.id+'-complete'} name={'revision-'+this.props.revision.id+'-complete'} onChange={this._toggle} checked={revision_complete} />;
  };

  render() {
    let input;
    if (intersection(['uberadmin', 'artist'], GlobalData.currentUserRoles).length > 0) {
      input = this.state.loading === true ? (<i className="fa fa-spin fa-spinner"></i>) : this._completeField();
    } else {
      input = '•';
    }
    return (
      <div className={'revision revision-'+(this.props.revision.status)} id={'revision-'+this.props.revision.id}>
        <label htmlFor={'revision-'+this.props.revision.id+'-complete'}>
          {input}
          <span className="revision-description mls" dangerouslySetInnerHTML={this._rawDescription()}>
          </span>
        </label>
      </div>
    );
  }
}

export default Revision;
