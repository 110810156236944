import * as Types from '../../base-types';
import { NewTask, EditableTask, Task } from './types';

import {
  localGet,
  localPostJSON,
  localDelete,
  localPutJSON,
} from '../../fetch-local';

interface AttachmentResponse {
  id: number;
  url: string;
  filename: string;
  contentType: string;
  previewUrls: {
    thumbnail: Types.MaybeString;
    fullsize: Types.MaybeString;
  };
}

interface TaskResponse {
  id: number;
  description: string;
  completed: boolean;
  isEdited: boolean;
  viewed: boolean;
  createdAt: string;
  updatedAt: string;
  user: {
    id: number;
    firstName: string;
    lastName: string;
  };
  attachments: AttachmentResponse[];
  links: {
    self: string;
  };
  permissions: Types.Permissions;
}

interface TasksResponse {
  data: TaskResponse[];
  permissions: Types.Permissions;
}

interface CollectionResponseResult {
  tasks: Task[];
  permissions: Types.Permissions;
}

function hydrateTask(task: TaskResponse): Task {
  return {
    ...task,
    createdAt: new Date(task.createdAt),
    updatedAt: new Date(task.updatedAt),
  };
}

export async function get(uri: string): Promise<CollectionResponseResult> {
  const { data, permissions }: TasksResponse = await localGet(uri);
  return {
    tasks: data.map(hydrateTask),
    permissions,
  };
}

export async function create(
  uri: string,
  task: NewTask
): Promise<CollectionResponseResult> {
  const { data, permissions }: TasksResponse = await localPostJSON(uri, {
    task: {
      description: task.description,
      viewed: task.viewed,
      attachments: task.attachments
    },
  });
  return { tasks: data.map(hydrateTask), permissions };
}

export async function update(
  task: EditableTask
): Promise<CollectionResponseResult> {
  const payload = {
    task: {
      description: task.description,
      completed: task.completed,
      viewed: task.viewed,
      attachments: task.newAttachments
    },
    removed_attachments: task.removedAttachments,
  };

  const { data, permissions }: TasksResponse = await localPutJSON(
    task.links.self,
    payload
  );

  return { tasks: data.map(hydrateTask), permissions };
}

export async function destroy(task: Task): Promise<void> {
  return await localDelete(task.links.self);
}
