import PropTypes from 'prop-types';
import React from 'react';
import ColorSelect from './color-select';

class LocationTackleTwillFields extends React.Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    location: PropTypes.string,
    decoration_type: PropTypes.string,
    prefix: PropTypes.string,
    tackle_twill_foreground_thread_color_pms_code: PropTypes.string,
    tackle_twill_foreground_custom_fabric: PropTypes.string,
    tackle_twill_foreground_stitch_type: PropTypes.string,
    tackle_twill_background_thread_color_pms_code: PropTypes.string,
    tackle_twill_background_custom_fabric: PropTypes.string,
    tackle_twill_background_stitch_type: PropTypes.string,
    tackle_twill_layers: PropTypes.string,
  };

  static defaultProps = {
    tackle_twill_layers: '1',
    tackle_twill_foreground_stitch_type: 'zig_zag',
    tackle_twill_background_stitch_type: 'zig_zag',
  };

  state = {
    tackle_twill_layers: this.props.tackle_twill_layers,
    customForegroundFabric: this.props.tackle_twill_foreground_custom_fabric,
    customBackgroundFabric: this.props.tackle_twill_background_custom_fabric,
  };

  updateLayersValue = newValue => {
    this.setState({ tackle_twill_layers: newValue.toString() });
    this.updateLocationProperties();
  };

  updateLocationProperties = () => {
    let {
      tackle_twill_foreground_thread_color,
      tackle_twill_foreground_fabric_color,
      tackle_twill_foreground_custom_fabric,
      tackle_twill_background_thread_color,
      tackle_twill_background_fabric_color,
      tackle_twill_background_custom_fabric,
    } = this.refs;

    tackle_twill_foreground_thread_color = tackle_twill_foreground_thread_color
      ? tackle_twill_foreground_thread_color.state.selectedColors[0]
      : null;
    tackle_twill_foreground_fabric_color = tackle_twill_foreground_fabric_color
      ? tackle_twill_foreground_fabric_color.state.selectedColors[0]
      : null;

    tackle_twill_background_thread_color = tackle_twill_background_thread_color
      ? tackle_twill_background_thread_color.state.selectedColors[0]
      : null;
    tackle_twill_background_fabric_color = tackle_twill_background_fabric_color
      ? tackle_twill_background_fabric_color.state.selectedColors[0]
      : null;

    if (tackle_twill_foreground_thread_color == '')
      tackle_twill_foreground_thread_color = null;

    if (tackle_twill_foreground_fabric_color == '')
      tackle_twill_foreground_fabric_color = null;

    if (tackle_twill_background_thread_color == '')
      tackle_twill_background_thread_color = null;

    if (tackle_twill_background_fabric_color == '')
      tackle_twill_background_fabric_color = null;

    let changes = {
      tackle_twill_letters: this.refs.tackle_twill_letters.value
        ? parseInt(this.refs.tackle_twill_letters.value)
        : null,
      tackle_twill_layers: this.state.tackle_twill_layers,
      tackle_twill_foreground_thread_color_pms_code: tackle_twill_foreground_thread_color,
      tackle_twill_foreground_fabric_color_pms_code: tackle_twill_foreground_fabric_color,
      tackle_twill_background_thread_color_pms_code: tackle_twill_background_thread_color,
      tackle_twill_background_fabric_color_pms_code: tackle_twill_background_fabric_color,
    };

    if (this.state.customForegroundFabric)
      changes['tackle_twill_foreground_custom_fabric'] =
        tackle_twill_foreground_custom_fabric.value;

    if (this.state.customBackgroundFabric)
      changes['tackle_twill_background_custom_fabric'] =
        tackle_twill_background_custom_fabric.value;

    this.props.locationsModel.updateLocation(this.props.nonce, changes);
  };

  toggleBackground = event => {
    event.preventDefault();
    this.setState({
      tackle_twill_layers: this.state.tackle_twill_layers == '1' ? '2' : '1',
    });
  };

  toggleCustomForegroundFabric = event => {
    event.preventDefault();
    this.setState(
      { customForegroundFabric: !this.state.customForegroundFabric },
      () => {
        if (this.refs.tackle_twill_foreground_custom_fabric)
          this.refs.tackle_twill_foreground_custom_fabric.focus();
      }
    );
  };

  toggleCustomBackgroundFabric = event => {
    event.preventDefault();
    this.setState(
      { customBackgroundFabric: !this.state.customBackgroundFabric },
      () => {
        if (this.refs.tackle_twill_background_custom_fabric)
          this.refs.tackle_twill_background_custom_fabric.focus();
      }
    );
  };

  render() {
    let toggleBackgroundButton = (
      <span className="pull-right">
        <a
          href="#"
          onClick={this.toggleBackground}
          className="pan button--small button button-naked button-colored"
        >
          <i
            className={
              'txt-small fa fa-' +
              (this.state.tackle_twill_layers >= 2 ? 'minus' : 'plus')
            }
          />
          &nbsp;{this.state.tackle_twill_layers >= 2 ? 'Remove' : 'Add'}
        </a>
        <input
          type="hidden"
          value={this.state.tackle_twill_layers}
          name={this.props.prefix + '[tackle_twill_layers]'}
        />
      </span>
    );

    let foregroundFabricSelect = this.state.customForegroundFabric ? (
      <div>
        <label className="is-hidden-visually">Fabric</label>
        <input
          type="text"
          className="mbn"
          placeholder="Fabric Name & Number"
          name={this.props.prefix + '[tackle_twill_foreground_custom_fabric]'}
          value={this.props.tackle_twill_foreground_custom_fabric}
          ref="tackle_twill_foreground_custom_fabric"
          onChange={this.updateLocationProperties}
        />
        <input
          type="hidden"
          name={
            this.props.prefix +
            '[tackle_twill_foreground_fabric_color_pms_code]'
          }
          value=""
        />
      </div>
    ) : (
      <div>
        <ColorSelect
          decorationType="tackle_twill"
          multi={false}
          className="mbn"
          name={
            this.props.prefix +
            '[tackle_twill_foreground_fabric_color_pms_code]'
          }
          value={[this.props.tackle_twill_foreground_fabric_color_pms_code]}
          ref="tackle_twill_foreground_fabric_color"
          allowCustom={GlobalData.allowCustomPms}
          onChange={this.updateLocationProperties}
        />
        <input
          type="hidden"
          name={this.props.prefix + '[tackle_twill_foreground_custom_fabric]'}
          value=""
        />
      </div>
    );

    let backgroundFabricSelect = this.state.customBackgroundFabric ? (
      <div>
        <label className="is-hidden-visually">Fabric</label>
        <input
          type="text"
          className="mbn"
          placeholder="Fabric Name & Number"
          name={this.props.prefix + '[tackle_twill_background_custom_fabric]'}
          value={this.props.tackle_twill_background_custom_fabric}
          ref="tackle_twill_background_custom_fabric"
          onChange={this.updateLocationProperties}
        />
        <input
          type="hidden"
          name={
            this.props.prefix +
            '[tackle_twill_background_fabric_color_pms_code]'
          }
          value=""
        />
      </div>
    ) : (
      <div>
        <ColorSelect
          decorationType="tackle_twill"
          multi={false}
          className="mbn"
          name={
            this.props.prefix +
            '[tackle_twill_background_fabric_color_pms_code]'
          }
          value={[this.props.tackle_twill_background_fabric_color_pms_code]}
          ref="tackle_twill_background_fabric_color"
          allowCustom={GlobalData.allowCustomPms}
          onChange={this.updateLocationProperties}
        />
        <input
          type="hidden"
          name={this.props.prefix + '[tackle_twill_background_custom_fabric]'}
          value=""
        />
      </div>
    );

    let backgroundFields =
      parseInt(this.state.tackle_twill_layers) > 1 ? (
        <div className="background-fields">
          <div className="row mbm">
            <a
              href="#"
              onClick={this.toggleCustomBackgroundFabric}
              style={{ marginTop: 3 }}
              className="pull-right txt-extra-small txt-upcase txt-strong"
            >
              Use{' '}
              {this.state.customBackgroundFabric
                ? 'Default Fabrics'
                : 'Custom Fabric'}
            </a>
            <label className="txt-small">Fabric</label>
            {backgroundFabricSelect}
          </div>

          <div className="row">
            <div className="col-8">
              <label className="txt-small">Thread Color</label>
              <ColorSelect
                decorationType="embroidery"
                multi={false}
                name={
                  this.props.prefix +
                  '[tackle_twill_background_thread_color_pms_code]'
                }
                value={[
                  this.props.tackle_twill_background_thread_color_pms_code,
                ]}
                ref="tackle_twill_background_thread_color"
                allowCustom={GlobalData.allowCustomPms}
                onChange={this.updateLocationProperties}
              />
            </div>
            <div className="col-4 mtl">
              <label className="input-radio small">
                <input
                  type="radio"
                  name={
                    this.props.prefix + '[tackle_twill_background_stitch_type]'
                  }
                  value="zig_zag"
                  defaultChecked={
                    this.props.tackle_twill_background_stitch_type == 'zig_zag'
                  }
                />
                Zig-Zag
              </label>
              <label className="input-radio small">
                <input
                  type="radio"
                  name={
                    this.props.prefix + '[tackle_twill_background_stitch_type]'
                  }
                  value="satin"
                  defaultChecked={
                    this.props.tackle_twill_background_stitch_type == 'satin'
                  }
                />
                Satin
              </label>
            </div>
          </div>
        </div>
      ) : (
        <div className="mvs txt-center empty-text">No Background</div>
      );

    return (
      <div>
        <div className="row">
          <div className="col-12">
            <label
              htmlFor={'location-' + this.props.nonce + '-tackle-twill-letters'}
            >
              How many letters?
            </label>
            <input
              id={'location-' + this.props.nonce + '-tackle-twill-letters'}
              type="number"
              min="1"
              step="1"
              name={this.props.prefix + '[tackle_twill_letters]'}
              placeholder="How many letters?"
              onChange={this.updateLocationProperties}
              ref="tackle_twill_letters"
              defaultValue={this.props.tackle_twill_letters}
            />
          </div>
        </div>

        <h4 className="quaternary-headline mbs">Style</h4>

        <div className="row mbm">
          <a
            href="#"
            onClick={this.toggleCustomForegroundFabric}
            style={{ marginTop: 3 }}
            className="pull-right txt-extra-small txt-upcase txt-strong"
          >
            Use{' '}
            {this.state.customForegroundFabric
              ? 'Default Fabrics'
              : 'Custom Fabric'}
          </a>
          <label className="txt-small">Fabric</label>
          {foregroundFabricSelect}
        </div>

        <div className="row">
          <div className="col-8">
            <label className="txt-small">Thread Color</label>
            <ColorSelect
              decorationType="embroidery"
              multi={false}
              name={
                this.props.prefix +
                '[tackle_twill_foreground_thread_color_pms_code]'
              }
              value={[this.props.tackle_twill_foreground_thread_color_pms_code]}
              ref="tackle_twill_foreground_thread_color"
              allowCustom={GlobalData.allowCustomPms}
              onChange={this.updateLocationProperties}
            />
          </div>

          <div className="col-4 mtl">
            <label className="input-radio small">
              <input
                type="radio"
                name={
                  this.props.prefix + '[tackle_twill_foreground_stitch_type]'
                }
                value="zig_zag"
                defaultChecked={
                  this.props.tackle_twill_foreground_stitch_type == 'zig_zag'
                }
              />
              Zig-Zag
            </label>
            <label className="input-radio small">
              <input
                type="radio"
                name={
                  this.props.prefix + '[tackle_twill_foreground_stitch_type]'
                }
                value="satin"
                defaultChecked={
                  this.props.tackle_twill_foreground_stitch_type == 'satin'
                }
              />
              Satin
            </label>
          </div>
        </div>

        <div className="toggle-background">
          <h4 className="quaternary-headline mbs">
            {toggleBackgroundButton}
            Background Style
          </h4>

          {backgroundFields}
        </div>

        <div className="row" />
      </div>
    );
  }
}

export default LocationTackleTwillFields;
