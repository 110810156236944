import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';
import { isArray, intersection, isEqual } from 'lodash';
import rendererLookup from '../rendererLookup';

const GlobalData =  window.GlobalData;

class EditInPlace extends Component {

  static propTypes = {
    defaultValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.number)
    ]),
    prefix: PropTypes.string,
    fieldName: PropTypes.string,
    valueRenderer: PropTypes.string,
    valueRendererProps: PropTypes.object,
    fieldRenderer: PropTypes.string,
    fieldRendererProps: PropTypes.object
  }

  static defaultProps = {
    valueComponent: 'TextRenderer',
    valueRendererProps: {},
    fieldRendererProps: {}
  }

  constructor(props) {
    super(props);

    this.state = {
      editingActive: false,
      value: props.defaultValue,
      parentWidth: 100
    };
  }

  componentDidMount() {
    let theNode = findDOMNode(this);

    this.setState({
      parentWidth: theNode.parentElement.parentElement.offsetWidth
    });
  }

  _handleChange = (value) => {
    this.setState({ value: value });
  }

  _edit() {
    this.setState({ editingActive: true });
  }

  _inputField() {
    const Field = rendererLookup(this.props.fieldRenderer);

    let fieldOptions = {
      value: this.state.value,
      onChange: this._handleChange,
      ...this.props.fieldRendererProps
    };

    return <Field {...fieldOptions} />;
  }

  _renderedValue() {
    const Value = rendererLookup(this.props.valueRenderer);

    if (Value) {
      let valueRendererProps = {
        ...this.props.valueRendererProps,
        value: this.state.value
      };

      return <Value {...valueRendererProps} />;
    } else {
      return this.state.value;
    }
  }

  _handleDoubleClick = (event) => {
    event.preventDefault();
    if (this._canEdit()) {
      this._edit();
    }
  }

  _done = (event) => {
    event.preventDefault();
    this.setState({
      editingActive: false
    });
  }

  _hiddenFields() {
    if(isArray(this.state.value)) {
      let _this = this;
      return this.state.value.map(function(val) {
        let name = `${_this.props.prefix}[${_this.props.fieldName}][]`;
        return (
          <input
            type="hidden"
            name={name}
            value={val}
            key={`location-color-${val}`}
          />
        );
      });
    }
    else {
      return (
        <input
          type="hidden"
          name={`${this.props.prefix}[${this.props.fieldName}]`}
          value={this.state.value}
        />
      );
    }
  }

  _revertChanges = (event) => {
    event.preventDefault();
    this.setState({
      value: this.props.defaultValue
    });
  }

  _message() {
    const { editingActive, value } = this.state;
    const { defaultValue } = this.props;
    const showMessage = !editingActive && !isEqual(value, defaultValue);

    if (showMessage) {
      return (
        <span>
          You have unsaved edits on this field. Submit the form to save.
          <a href="#" onClick={this._revertChanges}>
            <i className="fa fa-undo fa-fw"></i>
            Revert
          </a>
        </span>
      );
    }
  }

  _canEdit() {
    if (window.GlobalData.artJobBlocked){
      return false;
    } else {
      const roles = ['uberadmin', 'artist', 'admin'];
      return intersection(GlobalData.currentUserRoles, roles).length > 0;
    }
  }

  _controls() {
    if (!this._canEdit()) {
      return;
    }
    let controls;
    if (this.state.editingActive) {
      controls = (
        <a href="#" onClick={this._done}>
          <i className="fa fa-close fa-fw"></i>
          Done Editing
        </a>
      );
    }
    else {
      controls = (
        <a href="#" onClick={this._handleDoubleClick}>
          <i className="fa fa-pencil fa-fw"></i>
          Edit
        </a>
      );
    }

    return (
      <span>
        {controls} {this._message()}
      </span>
    );
  }

  render() {
    let field, hiddenFields;

    if (this.state.editingActive) {
      field = this._inputField();
    } else {
      field = (
        <span onDoubleClick={this._handleDoubleClick}>
          {this._renderedValue()}
        </span>
      );
    }

    if (this.state.value != this.props.defaultValue) {
      hiddenFields = this._hiddenFields();
    }

    return (
      <div className="edit-in-place">
        <div
          className="edit-in-place--controls"
          style={{ minWidth: this.state.parentWidth }}
        >
          {this._controls()}
        </div>
        {field}
        {hiddenFields}
      </div>
    );
  }
}

export default EditInPlace;
