import React from 'react';

interface Props {
  date: Date | string;
}

function dateLabel(date: Date): string {
  let options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };

  const now = new Date();
  const currentDay = now.toDateString() === date.toDateString();
  const currentYear = now.getFullYear() === date.getFullYear();

  if (currentDay) {
    options = {
      hour: 'numeric',
      minute: '2-digit',
    };
  } else if (currentYear) {
    options = {
      month: 'short',
      day: 'numeric',
    };
  }

  return new Intl.DateTimeFormat('en', options).format(date);
}

export function dateTitle(date: Date): string {
  let options: Intl.DateTimeFormatOptions = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    timeZoneName: 'short',
  };

  return new Intl.DateTimeFormat('en', options).format(date);
}

export default function Timestamp(props: Props) {
  const date: Date =
    typeof props.date === 'string'
      ? new Date(Date.parse(props.date))
      : props.date;

  const title = dateTitle(date);

  return (
    <time className="txt-muted" dateTime={title} title={title}>
      {dateLabel(date)}
    </time>
  );
}
