import { formatMoney } from './utility';
import $ from 'jquery';

const totalValue = $('<span></span>');
const checkAllInput = $('<input type="checkbox" />');

interface Payment {
  amount: number;
  checkbox: any;
  isChecked: boolean;
}

const payment2Data = (payment): Payment => {
  const checkbox = $(payment).find('input[type="checkbox"]');

  return {
    amount: Number(
      $(payment)
        .find('[data-amount]')
        .data('amount')
    ),
    checkbox: checkbox,
    isChecked: checkbox.prop('checked'),
  };
};

const payments = (): Payment[] =>
  $('[data-payment]')
    .toArray()
    .map(payment2Data);

const toggleCheckboxes = (val: boolean) => {
  payments().forEach(p => p.checkbox.prop('checked', val));
  render();
};

const render = () => {
  const allChecked: boolean = payments().reduce(
    (memo, p) => memo && p.isChecked,
    true
  );
  checkAllInput.prop('checked', allChecked);

  const activePayments = payments().filter(p => p.isChecked);
  const total = activePayments
    .map(p => p.amount)
    .reduce((memo, num) => memo + num, 0);
  totalValue.text(formatMoney(total));
};

export default () => {
  $('#reconciled-total')
    .empty()
    .append(totalValue);
  payments().forEach(p => p.checkbox.change(render));

  checkAllInput.change(event => {
    const val: boolean = $(event.target).is(':checked');
    toggleCheckboxes(val);
  });
  $('[data-reconcile-check-all').prepend(checkAllInput);

  render();
};
