function onChange(event) {
  const hiddenFieldsetSelector = event.target.dataset.fieldsetHide;
  if (hiddenFieldsetSelector) {
    const fieldset: HTMLFieldSetElement | null = document.querySelector(hiddenFieldsetSelector);

    if (fieldset) {
      fieldset.classList.add('hide-form');
      fieldset.disabled = true;
    }
  }

  const visibleFieldsetSelector = event.target.dataset.fieldsetShow;
  if (visibleFieldsetSelector) {
    const fieldset: HTMLFieldSetElement | null = document.querySelector(visibleFieldsetSelector);

    if (fieldset) {
      fieldset.classList.remove('hide-form');
      fieldset.disabled = false;
    }
  }

  const toggleableFieldsetSelector = event.target.dataset.fieldsetToggle;
  if (toggleableFieldsetSelector) {
    const fieldset: HTMLFieldSetElement | null = document.querySelector(toggleableFieldsetSelector);

    if (fieldset) {
      if (event.target.checked) {
        fieldset.classList.remove('hide-form');
        fieldset.disabled = false;
      } else {
        fieldset.classList.add('hide-form');
        fieldset.disabled = true;
      }
    }
  }
}

export default function setup() {
  document.querySelectorAll('[data-fieldset-hide], [data-fieldset-show], [data-fieldset-toggle]').forEach((element: HTMLFieldSetElement) => {
    if (element) {
      element.addEventListener('change', onChange);
    }
  });
}
