let LocationsModel = LocationsModelClass;

function LocationsModelClass(locations) {
  this.locations = locations ? locations : [];
  this.deletedLocations = [];
  if (locations.length > 0) {
    locations.forEach(
      function(location) {
        location.nonce = this.nonce(10);
      }.bind(this)
    );
  }
  this.onChanges = [];
}

LocationsModel.prototype.subscribe = function subscribe(onChange) {
  this.onChanges.push(onChange);
};

LocationsModel.prototype.inform = function inform() {
  this.onChanges.forEach(function(cb) {
    cb();
  });
};

LocationsModel.prototype.nonce = function nonce(length) {
  let text = '';
  let possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};

LocationsModel.prototype.addLocation = function addLocation(props = {}) {
  let newLocation = {
    id: null,
    nonce: this.nonce(10),
    description: '',
    prefix: '',
    location: null,
    decoration_type: null,
    art_files: [],
    print_color_pms_codes: [],
    ...props,
  };

  this.locations = this.locations.concat(newLocation);
  this.inform();
};

LocationsModel.prototype.updateLocation = function updateLocation(
  nonce,
  changes
) {
  this.locations = this.locations.map(
    function(location) {
      if (location.nonce == nonce) {
        if (
          changes.hasOwnProperty('decoration_type') &&
          changes.decoration_type != location.decoration_type
        ) {
          changes['print_color_pms_codes'] = [];
        }
        return $.extend(location, changes);
      } else {
        return location;
      }
    }.bind(this)
  );
  this.inform();
};

LocationsModel.prototype.removeLocation = function removeLocation(nonce) {
  this.locations = this.locations.filter(
    function(location) {
      if (location.nonce != nonce) {
        return true;
      } else {
        if (location.id) {
          this.deletedLocations.push(location);
        }
        return false;
      }
    }.bind(this)
  );
  this.inform();
};

module.exports = LocationsModel;
