$(function(){
  let onClass = 'on';
  let showClass = 'show';
  let selector = '.float-label input, .float-label textarea, .float-label select';

  $(document).on('blur', selector, function(){
    $(this).prev('label').removeClass(onClass);
  });

  $(document).on('focus', selector, function(){
    $(this).prev('label').addClass(onClass);
  });

  $(document).on('keyup checkval change', selector, function(){
    let label = $(this).prev('label');
    if(this.value !== ''){
      label.addClass(showClass);
    } else {
      label.removeClass(showClass);
    }
  });

  $(selector).trigger('checkval');
});
