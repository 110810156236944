

let comments_path = '/api/v1/locations/:location_id/comments';
let CommentsModel = CommentsModelClass;

function CommentsModelClass(location_id) {
  this.location_id = location_id;
  this.endpoint_path = comments_path.replace(':location_id', this.location_id);
  this.comments = [];
  this.previouslyCompleted = [];
  this.onChanges = [];

  this.getComments();
}

CommentsModel.prototype.subscribe = function subscribe(onChange) {
  this.onChanges.push(onChange);
};

CommentsModel.prototype.inform = function inform(onChange) {
  this.onChanges.forEach(function(cb) { cb(); });
};

CommentsModel.prototype.getComments = function getComments() {
  let _this = this;
  $.ajax({
    url: this.endpoint_path,
    dataType: 'json',
    success: function(data) {
      let comments = data.map(function(comment) {
        return comment;
      });

      _this.comments = comments;

      _this.inform();
    }
  });
};

CommentsModel.prototype.addComment = function addComment(comment, success) {
  let _this = this;
  $.ajax({
    url: this.endpoint_path,
    method: 'POST',
    data: {
      comment: comment
    },
    dataType: 'json',
    success: function(data) {
      _this.comments.push(data);
      _this.inform();
      (success || Function())();
    }
  });
};

export default CommentsModel;
