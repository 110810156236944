import React, { useState } from 'react';
import Modal from 'react-modal';
import { Color } from '../BrandColor';
import IconSvg, { Icon } from './IconSvg';

Modal.setAppElement('.main');

interface Props {
  message: string;
  disclaimer?: string;
  icon: Icon;
}

export default function OneTimeAlert(props: Props) {
  const [isOpen, setIsOpen] = useState(true);

  const closeModal = () => setIsOpen(false);

  return (
    <>
      <Modal
        isOpen={isOpen}
        className="react-modal react-modal--one-time-alert react-modal--center"
        overlayClassName="react-modal-overlay"
        contentLabel="Example Modal"
        closeTimeoutMS={200}
      >
        <div className="react-modal-body react-modal-content">
          {props.icon && (
            <IconSvg icon={props.icon} color={Color.LightGray} size={128} />
          )}
          <p className="mvs">{props.message}</p>
          {props.disclaimer && (
            <p className="mvs mtl txt-small">{props.disclaimer}</p>
          )}
        </div>

        <div className="react-modal-footer">
          <div className="react-modal-footer__cta">
            <button
              type="button"
              className="button button--full button--large large-modal-overlay"
              onClick={closeModal}
            >
              Got It
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
