import PrintColorsRenderer from './components/PrintColorsRenderer';
import LocationRenderer from './components/location_renderer';
import ColorSelect from './components/color-select';
import LocationSelect from './components/location-select';

export default name => {
  if (name === 'PrintColorsRenderer') return PrintColorsRenderer;
  else if (name === 'LocationRenderer') return LocationRenderer;
  else if (name === 'ColorSelect') return ColorSelect;
  else if (name === 'LocationSelect') return LocationSelect;
  return null;
};
