import Promise from 'bluebird';

const errorMessage = 'There was an error reading this file';

export default (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    const throwError = () => {
      reject(new Error(errorMessage));
    };

    reader.onloadend = () => {
      const data = reader.result;
      if (data && typeof data === 'string') {
        resolve(data);
      } else {
        throwError();
      }
    };

    reader.onerror = throwError;

    reader.readAsDataURL(file);
  });
