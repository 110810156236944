import React, { useEffect, useRef, useState, useCallback } from 'react';
import DragHandleSmall from '../DragHandleSmall';
import classnames from 'classnames';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Id, NewRevision, Error, Permissions } from '../../base-types';
import { destroy, update, create, indexUpdate } from './Revisions/Store';
import ActionMenu from '../ActionMenu';
import TextField from '../TextField';
import ImageDirectUploader from '../ImageDirectUploader';
import { LoadState } from '../../remote-data-types';
import { isBlank } from '../../stringUtility';
import { AttachmentUpdateType } from '../../attachment-types';
import LightBox from '../LightBox';
import { Location, Revision } from './ArtSubmissionContext';
import { extractErrorMessage } from '../../fetch-local';
import ErrorBox from '../ErrorBox';
import IconSvg from '../IconSvg';
import { Color } from '../../BrandColor';

interface Props {
  initialRevisions: Revision[];
  location: Location;
  directUploadUrl: string;
  didUpdate: (revisions: Revision[]) => any;
}

const DEFAULT_SERVER_ERROR = ['An unknown error occured, please try again'];

function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

const getRowStyle = (isDragging, draggableStyle) => ({
  background: isDragging ? '#d5eef2' : 'none',
  boxShadow: isDragging ? '5px 5px 5px 0px rgba(0,0,0,0.01)' : 'none',
  padding: '0.25rem',
  borderRadius: '5px',
  ...draggableStyle,
});

const getTableStyle = isDraggingOver => ({
  background: isDraggingOver ? 'none' : 'none',
});

export default function Revisions(props: Props) {
  const { canAddRevisions } = props.location.permissions;

  const createNewRevision = (): NewRevision => ({
    completed: false,
    description: '',
    attachment: null,
    locationId: props.location.id,
    userName: '',
  });

  const [newRevision, setNewRevision] = useState<NewRevision>(
    createNewRevision()
  );

  const [
    newRevisionIsUploadingAttachment,
    setNewRevisionIsUploadingAttachment,
  ] = useState(false);

  const [
    updatedRevisionIsUploadingAttachment,
    setUpdatedNewRevisionIsUploadingAttachment,
  ] = useState(false);

  const [showForm, setShowForm] = useState<boolean>(false);
  const [revisions, setRevisions] = useState<Revision[]>(
    props.initialRevisions
  );

  const [revisionInEditingMode, setRevisionInEditingMode] = useState<Id | null>(
    null
  );
  const [editingRevision, setEditingRevision] = useState<Revision | null>(null);

  const [newRevisionErrors, setNewRevisionErrors] = useState<Error[]>([]);

  const [pendingProofUpdate, requestProofUpdate] = useState<boolean>(
    props.location.pendingItemProofs || false
  );

  const inputEl = useRef(null);
  const editingInputEl = useRef(null);

  const onDragEnd = async result => {
    // We are eager setting state, assuming that all will go well!
    // (And if not, no big deal.)
    setRevisions(r => {
      const reorderedRevisions = reorder(
        r,
        result.source.index,
        result.destination.index
      );
      indexUpdate(props.location.id, reorderedRevisions);
      return reorderedRevisions;
    });
  };

  const RevisionUI = (revision: Revision) => {
    const id = `revision-${revision.id}-complete`;

    const revisionUI = (
      <div className="input-checkbox">
        <input
          type="checkbox"
          id={id}
          disabled={!revision.permissions.canComplete}
          onChange={async e => {
            const completed = e.target.checked;
            const updatedRevision: Revision = {
              ...revision,
              completed,
              attachmentUpdate: {
                type: AttachmentUpdateType.DontChangeAttachment,
              },
            };
            const revisions = await update(updatedRevision);
            updateRevisions(revisions);
            props.didUpdate(revisions);
          }}
          checked={revision.completed}
        />
        <label>
          {revision.completed ? (
            <span className="txt-muted2">{revision.description}</span>
          ) : (
            revision.description
          )}
          {revision.userName && (
            <p className="mvn txt-muted2 txt-extra-small">
              Requested by {revision.userName}
            </p>
          )}
        </label>
      </div>
    );

    const mayNotUpdateRevision: boolean =
      !editingRevision ||
      isBlank(editingRevision.description) ||
      updatedRevisionIsUploadingAttachment;

    const revisionEditingUI = editingRevision && (
      <ImageDirectUploader
        directUploadUrl={props.directUploadUrl}
        acceptedMIMETypes={
          props.location.revisionAttachmentValidation.allowedContentTypes
        }
        maxSize={props.location.revisionAttachmentValidation.maxSize}
        onChange={editingDirectUploadChanged}
      >
        {({ isDraggingOver, buttonView, uploadPreview }) => (
          <div
            className={classnames(
              'callout-light',
              'callout-rounded',
              'callout-light-bordered',
              'pam',
              'mtm',
              {
                'callout--dragged-over': isDraggingOver,
              }
            )}
          >
            {isDraggingOver && (
              <p className="callout--drag-text">
                Drop your file to add it to the revision.
              </p>
            )}
            <label htmlFor="edit-revision-description" className="better-label">
              Description
            </label>

            <div className="flex-rows">
              <TextField
                id="edit-revision-description"
                ref={editingInputEl}
                value={editingRevision.description}
                onChange={e => {
                  const description = e.target.value;
                  setEditingRevision(r =>
                    r === null ? null : { ...r, description }
                  );
                }}
                onSubmit={updateRevision}
                onCancel={() => {
                  setEditingRevision(null);
                  setRevisionInEditingMode(null);
                }}
              />
              {buttonView}
            </div>

            {editingRevision.attachment &&
              editingRevision.attachmentUpdate.type ===
                AttachmentUpdateType.DontChangeAttachment && (
                <div className="upload-item flex-rows flex-rows--center-v mtm">
                  {editingRevision.attachment.previewUrls.thumbnail && (
                    <>
                      <LightBox
                        src={editingRevision.attachment.previewUrls.fullsize}
                      >
                        <img
                          className="item-preview"
                          src={editingRevision.attachment.previewUrls.thumbnail}
                          alt={editingRevision.attachment.filename}
                          width="30"
                          height="30"
                        />
                      </LightBox>
                      <span
                        className="attachment-title mhm"
                        title={editingRevision.attachment.filename}
                      >
                        {editingRevision.attachment.filename}
                      </span>
                    </>
                  )}
                  {!editingRevision.attachment.previewUrls.thumbnail &&
                    editingRevision.attachmentUpdate.type ===
                      AttachmentUpdateType.DontChangeAttachment && (
                      <>
                        <IconSvg
                          icon="document"
                          color={Color.TealBlue}
                          size={30}
                        />
                        <span
                          className="attachment-title mhm"
                          title={editingRevision.attachment.filename}
                        >
                          {editingRevision.attachment.filename}
                        </span>
                      </>
                    )}

                  <button
                    type="button"
                    title="Remove this file"
                    className="button-naked"
                    onClick={() => {
                      setEditingRevision(r =>
                        r === null
                          ? null
                          : {
                              ...r,
                              attachmentUpdate: {
                                type: AttachmentUpdateType.RemoveAttachment,
                              },
                            }
                      );
                    }}
                  >
                    <IconSvg icon="close" color={Color.Red} size={24} />
                  </button>
                </div>
              )}

            <div className="mtm">
              {uploadPreview}

              <ErrorBox errors={editingRevision.errors} />

              <div className="mtm">
                <button
                  className="button button--small"
                  onClick={updateRevision}
                  disabled={mayNotUpdateRevision}
                >
                  Save Changes
                </button>

                <button
                  onClick={() => {
                    setRevisionInEditingMode(null);
                    setEditingRevision(null);
                  }}
                  className=" button-naked txt-small"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </ImageDirectUploader>
    );

    const revisionMenuUI = (
      <ActionMenu dropdownRight dropdownSmall>
        {!revision.itemProofOnly && (
          <button
            onClick={() => {
              setEditingRevision({
                ...revision,
                attachmentUpdate: {
                  type: AttachmentUpdateType.DontChangeAttachment,
                },
              });
              setRevisionInEditingMode(revision.id);
            }}
          >
            Edit
          </button>
        )}
        <button
          onClick={async () => {
            if (
              window.confirm('Are you sure you want to delete this revision?')
            ) {
              const revisions = await destroy(revision);
              updateRevisions(revisions);
              revision.itemProofOnly && requestProofUpdate(false);
              props.didUpdate(revisions);
            }
          }}
        >
          Delete
        </button>
      </ActionMenu>
    );

    return (
      <>
        {revisionInEditingMode !== revision.id && (
          <div className="revision-content" key={revision.id}>
            <div className="revision-subcontent">{revisionUI}</div>
            {revision.permissions.access === Permissions.ReadWrite &&
              revisionMenuUI}
          </div>
        )}
        {revisionInEditingMode === revision.id && revisionEditingUI}
      </>
    );
  };

  const isDragDisabled: boolean = !canAddRevisions || !!revisionInEditingMode;

  const DraggableRevision = (revision: Revision, index: number) => {
    const { attachment } = revision;

    const revisionAttachmentsUI = attachment && (
      <div className="flex-rows flex-rows--content-v" key={attachment.url}>
        {attachment.previewUrls.thumbnail ? (
          <>
            <LightBox src={attachment.previewUrls.fullsize}>
              <img
                src={attachment.previewUrls.thumbnail}
                className="item-preview mrm"
                alt=""
                width="30"
                height="30"
              />
            </LightBox>
            <span className="attachment-title mhm" title={attachment.filename}>
              {attachment.filename}
            </span>
            <a href={attachment.url}>
              <IconSvg icon="download" color={Color.TealBlue} size={24} />
            </a>
          </>
        ) : (
          <>
            <IconSvg icon="document" color={Color.TealBlue} size={30} />
            <span className="attachment-title mhm" title={attachment.filename}>
              {attachment.filename}
            </span>
            <a href={attachment.url}>
              <IconSvg icon="download" color={Color.TealBlue} size={24} />
            </a>
          </>
        )}
      </div>
    );

    return (
      <Draggable
        key={revision.id.toString()}
        draggableId={revision.id.toString()}
        index={index}
        isDragDisabled={isDragDisabled}
      >
        {(provided, snapshot) => {
          const dragHandleProps = isDragDisabled
            ? { style: { opacity: '0.3', cursor: 'not-allowed' } }
            : provided.dragHandleProps;

          const handleTitle = isDragDisabled
            ? 'You may not reorder revisions while editing'
            : 'Drag this revision to preferred position';

          return (
            <>
              <div
                className="revision-container"
                ref={provided.innerRef}
                {...provided.draggableProps}
                style={getRowStyle(
                  snapshot.isDragging,
                  provided.draggableProps.style
                )}
              >
                {canAddRevisions && (
                  <DragHandleSmall
                    dragProps={dragHandleProps}
                    title={handleTitle}
                  />
                )}
                <div className="full-width">
                  {RevisionUI(revision)}
                  {!editingRevision && (
                    <div className="revision-attachment">
                      {revisionAttachmentsUI}
                    </div>
                  )}
                </div>
              </div>
            </>
          );
        }}
      </Draggable>
    );
  };

  const focusField = () => {
    if (inputEl && inputEl.current) {
      // @ts-ignore: Object is possibly 'null'
      inputEl.current.focus();
    }
  };

  const updateRevisions = (revisions: Revision[]) => {
    setRevisions(revisions.map(r => ({ ...r, errors: [] })));
  };

  useEffect(() => {
    if (showForm) focusField();
  }, [showForm]);

  async function submitRevision() {
    try {
      const revisions = await create(newRevision);
      setNewRevisionErrors([]);
      updateRevisions(revisions);
      setNewRevision(createNewRevision());
      setShowForm(false);
      focusField();
      props.didUpdate(revisions);
    } catch (e) {
      const messages = await extractErrorMessage(e, DEFAULT_SERVER_ERROR);
      setNewRevisionErrors(messages);
    }
  }

  async function updateRevision() {
    if (!(revisionInEditingMode && editingRevision)) return;

    try {
      const revisions = await update(editingRevision);
      setNewRevisionErrors([]);
      updateRevisions(revisions);
      setEditingRevision(null);
      setRevisionInEditingMode(null);
    } catch (e) {
      const errors = await extractErrorMessage(e, DEFAULT_SERVER_ERROR);
      setEditingRevision(r => (r === null ? null : { ...r, errors }));
    }
  }

  const directUploadChanged = useCallback(
    result => {
      if (result.state === LoadState.Success) {
        const attachment = result.data;
        setNewRevision(r => ({ ...r, attachment }));
        setNewRevisionIsUploadingAttachment(false);
      } else if (
        result.state === LoadState.Loading ||
        result.state === LoadState.IndeterminateProgressLoading
      ) {
        const attachment = null;
        setNewRevision(r => ({ ...r, attachment }));
        setNewRevisionIsUploadingAttachment(true);
      } else {
        const attachment = null;
        setNewRevision(r => ({ ...r, attachment }));
        setNewRevisionIsUploadingAttachment(false);
      }
    },
    [setNewRevision, setNewRevisionIsUploadingAttachment]
  );

  const editingDirectUploadChanged = useCallback(
    result => {
      if (result.state === LoadState.Success) {
        const signedId = result.data;
        setEditingRevision(r =>
          r
            ? {
                ...r,
                attachmentUpdate: {
                  type: AttachmentUpdateType.AddAttachment,
                  signedId,
                },
              }
            : r
        );

        setUpdatedNewRevisionIsUploadingAttachment(false);
      } else if (
        result.state === LoadState.Loading ||
        result.state === LoadState.IndeterminateProgressLoading
      ) {
        setEditingRevision(r =>
          r
            ? {
                ...r,
                attachmentUpdate: {
                  type: AttachmentUpdateType.DontChangeAttachment,
                },
              }
            : r
        );
        setUpdatedNewRevisionIsUploadingAttachment(true);
      } else {
        setEditingRevision(r =>
          r
            ? {
                ...r,
                attachmentUpdate: {
                  type: AttachmentUpdateType.DontChangeAttachment,
                },
              }
            : r
        );
        setUpdatedNewRevisionIsUploadingAttachment(false);
      }
    },
    [setEditingRevision, setUpdatedNewRevisionIsUploadingAttachment]
  );

  const mayNotAddNewRevision =
    isBlank(newRevision.description) || newRevisionIsUploadingAttachment;

  return (
    <>
      <h3 className="mtm mbs better-label">Revisions</h3>

      {revisions.length === 0 && (
        <p>
          <em className="txt-muted2">There are currently no revisions.</em>
        </p>
      )}

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getTableStyle(snapshot.isDraggingOver)}
            >
              {revisions.map(DraggableRevision)}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <div className="flex-rows flex-rows--center-v mtm">
        {canAddRevisions && !showForm && (
          <button
            onClick={e => setShowForm(true)}
            className="button button--secondary button--small"
            disabled={pendingProofUpdate}
          >
            Add a Revision
          </button>
        )}

        {pendingProofUpdate && (
          <div
            className="mts"
            data-tooltip="Revisions to the artwork cannot be requested while awaiting updated line item proofs."
          >
            <IconSvg icon="warning" color={Color.Yellow} size={32} />
          </div>
        )}
      </div>

      {showForm && (
        <ImageDirectUploader
          directUploadUrl={props.directUploadUrl}
          acceptedMIMETypes={
            props.location.revisionAttachmentValidation.allowedContentTypes
          }
          maxSize={props.location.revisionAttachmentValidation.maxSize}
          onChange={directUploadChanged}
        >
          {({ isDraggingOver, buttonView, uploadPreview }) => (
            <div
              className={classnames(
                'callout-light',
                'callout-rounded',
                'callout-light--bordered',
                'pam',
                'mtm',
                {
                  'callout--dragged-over': isDraggingOver,
                }
              )}
            >
              {isDraggingOver && (
                <p className="callout--drag-text">
                  Drop your file to add it to the revision.
                </p>
              )}
              <label
                htmlFor="new-revision-description"
                className="better-label"
              >
                Description
              </label>

              <div className="flex-rows">
                <TextField
                  id="new-revision-description"
                  ref={inputEl}
                  value={newRevision.description}
                  onChange={e => {
                    const description = e.target.value;
                    setNewRevision(r => ({ ...r, description }));
                  }}
                  onSubmit={submitRevision}
                  onCancel={() => {
                    setNewRevision(createNewRevision());
                    setShowForm(false);
                    setNewRevisionErrors([]);
                  }}
                />

                {buttonView}
              </div>

              <div className="mtm">
                {uploadPreview}

                <ErrorBox errors={newRevisionErrors} />

                <div className="mtm">
                  <button
                    disabled={mayNotAddNewRevision}
                    className="button button--small"
                    onClick={submitRevision}
                  >
                    Add
                  </button>

                  <button
                    onClick={() => {
                      setNewRevision(createNewRevision());
                      setShowForm(false);
                      setNewRevisionErrors([]);
                    }}
                    className=" button-naked txt-small"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}
        </ImageDirectUploader>
      )}
    </>
  );
}
