import React from 'react';
import { DecorationColor } from '../base-types';
import Color from './Color';

interface Props {
  color: DecorationColor;
}

export default function ColorItem({ color }: Props) {
  return (
    <Color
      name={color.name}
      hexColor={`#${color.hex || 'ffffff'}`}
      backgroundColor="#ffffff"
    />
  );
}
