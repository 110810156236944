// NOTES: Purpose of this function is to clear address fields
//        when a new customer profile has been selected on
//        duplicate orders

(function() {
  $('.customer_select').click(function(e) {
    let newCustomer = e.currentTarget.id === 'customer_existing_false';
    let existingCustomer = e.currentTarget.id === 'customer_existing_true';
    let addressOneBlank = $('#order_customer_address_one').val().length === 0;
    let addressOnePresent = $('#order_customer_address_one').val().length > 0;

    if (
      (newCustomer && addressOneBlank) ||
      (existingCustomer && addressOneBlank)
    ) {
      $('#order_customer_first_name')
        .val('')
        .change();
      $('#order_customer_last_name')
        .val('')
        .change();
      $('#order_customer_address_one')
        .val('')
        .change();
      $('#order_customer_address_two')
        .val('')
        .change();
      $('#order_customer_city')
        .val('')
        .change();
      $('#order_customer_state')
        .val('')
        .change();
      $('#order_customer_zip')
        .val('')
        .change();
    } else if (
      (newCustomer && addressOnePresent) ||
      (existingCustomer && addressOnePresent)
    ) {
      $('#order_customer_first_name')
        .val($('#order_customer_first_name').data('originalfirstname'))
        .change();
      $('#order_customer_last_name')
        .val($('#order_customer_last_name').data('originallastname'))
        .change();
      $('#order_customer_address_one')
        .val($('#order_customer_address_one').data('originaladdressone'))
        .change();
      $('#order_customer_address_two')
        .val($('#order_customer_address_two').data('originaladdresstwo'))
        .change();
      $('#order_customer_city')
        .val($('#order_customer_city').data('originalcity'))
        .change();
      $('#order_customer_state')
        .val($('#order_customer_city').data('originalstate'))
        .change();
      $('#order_customer_zip')
        .val($('#order_customer_city').data('originalzip'))
        .change();
    }
  });
})();
