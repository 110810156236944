import React from 'react';
import PropTypes from 'prop-types';
import { formatPhoneNumber } from '../PhoneHelper';

const PhoneField = props => {
  const { onChange } = props;

  const handleChange = event => {
    onChange(formatPhoneNumber(event.target.value));
  };

  const baseProps = {
    type: 'tel',
    pattern: '\\([0-9]{3}\\) [0-9]{3}-[0-9]{4}',
    placeholder: '(###) ###-####',
  };
  const overriddenProps = {
    onChange: handleChange,
  };
  const inputProps = { ...baseProps, ...props, ...overriddenProps };

  return <input {...inputProps} />;
};

PhoneField.propTypes = {
  value: PropTypes.string,
};

export default PhoneField;
