import debounce from 'lodash.debounce';

export default class Uploader {
  onUpdateProgress: (event: any) => any;

  constructor(onUpdateProgress) {
    this.onUpdateProgress = onUpdateProgress;
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener(
      'progress',
      debounce(event => this.onUpdateProgress(event), 20)
    );
  }
}
