import PropTypes from 'prop-types';
import React from 'react';

class LocationFile extends React.Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    index: PropTypes.number,
    prefix: PropTypes.string,
    file_url: PropTypes.string,
    preview_url: PropTypes.string,
    file_cache: PropTypes.string,
    file_data: PropTypes.string,
    preview: PropTypes.string,
    file_name: PropTypes.string,
    onRemove: PropTypes.func,
  };

  state = {
    isNew: !this.props.id && !this.props.file_cache,
    isDeleted: false,
  };

  prefix_input = name =>
    `${this.props.prefix}[art_files_attributes][${this.props.index}][${name}]`;

  remove = event => {
    event.preventDefault();
    if (window.confirm('Are you sure you want to remove this image?')) {
      if (this.state.isNew) {
        this.props.onRemove.call(this.props.index);
      } else {
        this.setState({ isDeleted: true });
      }
    }
  };

  imagePreview = () => {
    let backgroundColors = {
      PSD: '#5889c4',
      AI: '#ffc14f',
      XLSX: '#91cda0',
      XLS: '#91cda0',
      CSV: '#91cda0',
      PDF: '#cc4b4c',
      DOC: '#0096e6',
      DOCX: '#0096e6',
      default: 'lightgray',
    };

    let colors = {
      default: 'white',
    };

    let previewTag;

    if (this.props.preview) {
      const previewStyles = {
        color: colors[this.props.preview.toUpperCase()] || colors['default'],
        textAlign: 'center',
        lineHeight: '2.1em',
        backgroundColor:
          backgroundColors[this.props.preview.toUpperCase()] ||
          backgroundColors['default'],
      };

      previewTag = (
        <div style={previewStyles}>{this.props.preview.toUpperCase()} </div>
      );
    } else {
      const previewSrc =
        this.props.preview_url ||
        this.props.preview ||
        this.props.file_data ||
        this.props.file_url;

      previewTag = <img src={previewSrc} />;
    }

    return (
      <div className="image-preview-container">
        {previewTag}
        <div className="image-preview-index">{this.props.index + 1}</div>
      </div>
    );
  };

  hiddenImageFields = () => {
    let inputs = new Array();

    if (this.state.isNew) {
      inputs.push(
        <input
          type="hidden"
          key={'file_data_filename' + this.props.index}
          name={this.prefix_input('file_data_filename')}
          value={this.props.file_name || ''}
        />
      );

      inputs.push(
        <input
          type="hidden"
          key={'file_data_uriname' + this.props.index}
          name={this.prefix_input('file_data_uri')}
          value={this.props.file_data || ''}
        />
      );
    } else {
      inputs.push(
        <input
          type="hidden"
          key={'file_id_' + this.props.id}
          name={this.prefix_input('id')}
          value={this.props.id || ''}
        />
      );

      inputs.push(
        <input
          type="hidden"
          key={'file_cache_' + this.props.id}
          name={this.prefix_input('file_cache')}
          value={this.props.file_cache || ''}
        />
      );

      if (this.state.isDeleted) {
        inputs.push(
          <input
            type="hidden"
            key={'file_destroy_' + this.props.id}
            name={this.prefix_input('_destroy')}
            value="1"
          />
        );
      }
    }

    return inputs;
  };

  render() {
    let styles = {
      display: this.state.isDeleted ? 'none' : 'inline-block',
    };
    return (
      <div className="location-file image-preview" style={styles}>
        {this.imagePreview()}
        <a href="#" onClick={this.remove} className="close-button">
          <i className="fa fa-close fa-4x" />
        </a>
        {this.hiddenImageFields()}
      </div>
    );
  }
}

export default LocationFile;
