(function() {
  let Reveal = function(params) {
    let template = params.template,
      $node = params.$node,
      text = $node.data('reveal'),
      button = $('<a href="#"></a>'),
      onClick = function() {
        $node.slideDown(300);
        button.hide();
        return false;
      },
      init = function() {
        button.click(onClick).text(text);
        template.append(button);
        $node.hide().after(template);
      };

    init();
  };

  $('[data-reveal]').each(function() {
    new Reveal({
      template: $('<p></p>'),
      $node: $(this)
    });
  });
}());

