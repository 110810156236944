import React, { useEffect } from 'react';
import { every, find } from 'lodash';
import classNames from 'classnames';
import { useToggle } from '../../custom-hooks';
import Loader from '../../loader';
import PhoneField from '../../PhoneField';

import { validateEmail } from '../../../utility';

const PersonalInformationFields = ({
  firstName,
  lastName,
  email,
  phone,
  onChange,
  questions,
  answers,
  onSubmit,
  updateAnswer,
  failedToSave,
  errorMessage,
}) => {
  const [loading, toggleLoading] = useToggle(false);
  const isValid =
    firstName &&
    lastName &&
    validateEmail(email) &&
    phone &&
    every(answers, 'answer');

  const handleSubmit = e => {
    e.preventDefault();
    toggleLoading(true);
    onSubmit(
      () => {},
      () => toggleLoading(false)
    );
  };

  useEffect(() => toggleLoading(false), [failedToSave]);

  const answerFields = questions.map(({ id, question }) => {
    const answer = find(answers, { questionId: id });

    return (
      <React.Fragment key={id}>
        <label htmlFor={`signup-question-${id}`}>{question}</label>
        <input
          id={`signup-question-${id}`}
          type="text"
          value={answer.answer}
          onChange={e => updateAnswer(id, e.target.value)}
        />
      </React.Fragment>
    );
  });

  const failureMessage = failedToSave && (
    <div className="bb-notification bb-notification--warning">
      <p className="man">
        There was a problem saving your information. Please check the fields and
        try again. If it still does not save, reload the page and try again.
      </p>
      {errorMessage && <p>{errorMessage}</p>}
    </div>
  );

  return (
    <form onSubmit={handleSubmit}>
      <div
        className={classNames(
          'bb-form-vertical',
          'form-compact--small',
          'form-light',
          'form-light--bordered',
          'mtm'
        )}
      >
        <label htmlFor="signup-first-name">First Name</label>
        <input
          id="signup-first-name"
          type="text"
          value={firstName || ''}
          onChange={e => onChange({ firstName: e.target.value })}
        />
        <label htmlFor="signup-last-name">Last Name</label>
        <input
          id="signup-last-name"
          type="text"
          value={lastName || ''}
          onChange={e => onChange({ lastName: e.target.value })}
        />
        <label htmlFor="signup-email">Email</label>
        <input
          id="signup-email"
          type="email"
          value={email || ''}
          onChange={e => onChange({ email: e.target.value })}
        />

        <label htmlFor="signup-phone">Phone</label>
        <PhoneField
          id="signup-phone"
          value={phone || ''}
          onChange={e => onChange({ phone: e })}
        />

        {answerFields}
        {failureMessage}
        <button
          disabled={!isValid || loading}
          className="bb-button  bb-button--small mtl"
          type="submit"
        >
          Next
        </button>
        <div className="pull-right pts">
          <Loader isActive={loading} />
        </div>
      </div>
    </form>
  );
};

export default PersonalInformationFields;
