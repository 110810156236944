import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import classNames from 'classnames';

import EditableField from './EditableField';

const defaultQuestion = { question: '' };

const CustomQuestionManager = props => {
  const { editingEnabled, editing, onAdd, onChange, onRemove } = props;

  const [questions, setQuestions] = useState(props.questions);

  const questionsForDisplay = onChange ? props.questions : questions;

  const newestInput = useRef(null);

  const handleQuestionChange = (e, q_index) => {
    if (onChange) onChange(e.target.value, q_index);
    else {
      setQuestions(
        questions.map((question, index) => {
          if (index != q_index) return question;

          return { ...question, question: e.target.value };
        })
      );
    }
  };

  const handleQuestionRemoval = (e, q_index) => {
    e.preventDefault();

    if (onRemove) onRemove(q_index);
    else
      setQuestions(previousQuestions => {
        if (previousQuestions[q_index].id)
          return previousQuestions.map((question, index) => {
            if (index == q_index) return { ...question, _destroy: true };
            else return { ...question };
          });
        else return previousQuestions.filter((_, i) => i !== q_index);
      });
  };

  useEffect(() => {
    if (newestInput.current) newestInput.current.focus();
  }, [questionsForDisplay.length]);

  const questionFields = questionsForDisplay.map((question, index) => {
    return (
      <EditableField
        key={index}
        editing={editingEnabled && editing}
        renderEdit={() => (
          <React.Fragment>
            {!question._destroy && (
              <div
                className={classNames(
                  'mts',
                  'flex-rows',
                  'form-light',
                  'form-light--bordered',
                  'form-compact'
                )}
              >
                <strong className="mrs">{index + 1}:</strong>
                <input
                  type="text"
                  value={question.question}
                  onChange={e => handleQuestionChange(e, index)}
                  ref={
                    index + 1 == questionsForDisplay.length ? newestInput : null
                  }
                />
                <a
                  href="#"
                  onClick={e => handleQuestionRemoval(e, index)}
                  className="mls"
                >
                  <Icon type="times-circle" color="#FE4438" />
                </a>
              </div>
            )}
          </React.Fragment>
        )}
        renderRead={() => (
          <React.Fragment>
            <p className="mbn">
              <strong>Question {index + 1}:</strong>
            </p>
            <p className="mtn">{question.question}</p>
          </React.Fragment>
        )}
      />
    );
  });

  const addQuestion = e => {
    e.preventDefault();
    if (onAdd) onAdd({ ...defaultQuestion });
    else setQuestions([...questions, { ...defaultQuestion }]);
  };

  const addQuestionButton = editingEnabled
    ? editing && (
        <button onClick={addQuestion} className="button button--small">
          Add a Question
        </button>
      )
    : null;

  return (
    <React.Fragment>
      {questionFields}
      <div className="mts">{addQuestionButton}</div>
    </React.Fragment>
  );
};

CustomQuestionManager.propTypes = {
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      question: PropTypes.string,
    })
  ),
  prefix: PropTypes.string,
  editing: PropTypes.bool,
  onChange: PropTypes.func,
};

CustomQuestionManager.defaultProps = {
  editing: true,
};

export default CustomQuestionManager;
