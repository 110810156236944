import React, { FunctionComponent, useState } from 'react';
import Modal from 'react-modal';
import Select from 'react-select';
import IconSvg from './IconSvg';
import { Color } from '../BrandColor';
import { localDelete } from '../fetch-local';

// NOTE: We were getting an error to use setAppElement to hide
// the app while the modal is open, for use by screenreaders. So
// it's currently being set to `main`.

Modal.setAppElement('.main');

type ArchiveReason = {
  label: string;
  value: string;
};

export interface Props {
  archiveFormPath: string;
  archiveReasons?: ArchiveReason[];
  cardWrapperSelector: string;
  columnTotalPriceSelector: string;
  columnType: string;
  filterAttributes: { [key: string]: boolean | null | number | string };
}

const ArchiveCardIcon: FunctionComponent<Props> = ({
  archiveFormPath,
  archiveReasons = [],
  columnType,
  filterAttributes,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [reasonForArchive, setReasonForArchive] = useState();

  function handleOpen() {
    setModalIsOpen(true);
  }

  function handleClose() {
    setModalIsOpen(false);
  }

  function handleSetReasonForArchive(event) {
    setReasonForArchive(event ? event.value : null);
  }

  function handleArchive() {
    const defaultData = {
      column_type: columnType,
      filters_card_filter: filterAttributes,
    };
    const formData =
      archiveReasons.length === 0
        ? defaultData
        : Object.assign({}, defaultData, {
            filters_card_filter: filterAttributes,
            order: {
              archive_reason: reasonForArchive,
            },
          });

    localDelete(archiveFormPath, formData)
      .then(() => {
        window.location.reload();
      })
      .catch(error => {
        console.error(error);
      });
  }

  return (
    <>
      <button
        onClick={handleOpen}
        type="button"
        title="Archive order"
      >
        <i className="fa fa-archive fa-fw"></i> Archive
      </button>

      <Modal
        isOpen={modalIsOpen}
        className="react-modal"
        overlayClassName="react-modal-overlay"
        contentLabel="Approval Modal"
        closeTimeoutMS={200}
      >
        <div className="react-modal-header flex-rows flex-rows--space-b flex-rows--align-c">
          <h4 className="react-modal-header__title">Confirm Archive</h4>
          <button
            className="button-naked"
            type="button"
            onClick={handleClose}
            title="Close modal"
          >
            <IconSvg icon="close" color={Color.Gray} />
          </button>
        </div>

        <div className="react-modal-body react-modal-content">
          {archiveReasons.length === 0 && (
            <p>Are you sure you want to archive this item?</p>
          )}
          {archiveReasons.length >= 1 && (
            <div className="react-select-form">
              <Select
                placeholder="Add reason for archiving..."
                name="order[reason_for_archiving]"
                onChange={handleSetReasonForArchive}
                options={archiveReasons}
                value={reasonForArchive}
              />
            </div>
          )}
        </div>

        <div className="react-modal-footer">
          <div className="react-modal-footer__cta">
            <button
              disabled={archiveReasons.length > 0 && !Boolean(reasonForArchive)}
              onClick={handleArchive}
              className="button button--alert"
              type="button"
            >
              Archive
            </button>

            <button
              type="button"
              className="button-naked"
              onClick={handleClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ArchiveCardIcon;
