import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PhoneFieldControlledWrapper from './PhoneFieldControlledWrapper';
import Checkbox from './checkbox';
import { find } from 'lodash';
import Select from 'react-select';
import CustomAnswer from './SignupManager/CustomAnswer';
import Address from './Address';
import { isTest } from '../environment';

class SignupForm extends Component {
  static propTypes = {
    birdBank: PropTypes.object.isRequired,
    currentUser: PropTypes.object,
    signup: PropTypes.object.isRequired,
    organizationOptions: PropTypes.array,
    schoolOptions: PropTypes.array,
    graduationYearOptions: PropTypes.array,
    chapterPositionOptions: PropTypes.array,
    prefix: PropTypes.string,
  };

  constructor(props) {
    super(props);

    let signup = this.props.signup;

    let {
      firstName,
      lastName,
      email,
      phone,
      organizationId,
      organizationNameIfOther,
      schoolId,
      schoolNameIfOther,
      address,
      addressTwo,
      city,
      state,
      zip,
      noCharge,
      graduationYear,
      chapterPosition,
      answers,
    } = signup;

    this.state = {
      firstName,
      lastName,
      email,
      phone,
      organizationId,
      organizationNameIfOther,
      schoolId,
      schoolNameIfOther,
      address,
      addressTwo,
      city,
      state,
      zip,
      noCharge,
      graduationYear,
      chapterPosition,
      answers,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.changeAnswer = this.changeAnswer.bind(this);
  }

  handleChange(name, e) {
    this.setState({ [name]: e.value });
  }

  handleAddressChange(address, e) {
    console.log(address);
    this.setState({
      address: address.addressLine1,
      addressTwo: address.addressLine2,
      city: address.city,
      state: address.state,
      zip: address.zip,
    });
  }

  onUpdate(name, valProp, event) {
    this.setState({ [name]: event.target[valProp || 'value'] });
  }

  handleOrganizationSelect(e) {
    let newValue = e === null ? '' : e.value;
    this.setState({
      organizationId: newValue,
      organizationNameIfOther: '',
    });
  }

  handleOrganizationCreation(e) {
    this.setState({
      organizationNameIfOther: e.value,
      organizationId: '',
    });
  }

  handleSchoolSelect(e) {
    let newValue = e === null ? '' : e.value;
    this.setState({ schoolId: newValue, schoolNameIfOther: '' });
  }

  handleSchoolCreation(e) {
    this.setState({ schoolNameIfOther: e.value, schoolId: '' });
  }

  handleGraduationYearSelect(e) {
    let newValue = e === null ? '' : e.value;
    this.setState({ graduationYear: newValue });
  }

  handleChapterPositionSelect(e) {
    let newValue = e === null ? '' : e.value;
    this.setState({ chapterPosition: newValue });
  }

  changeAnswer(questionId, changes) {
    this.setState(previousState => {
      return {
        answers: previousState.answers.map(answer => {
          if (answer.questionId != questionId) return answer;
          return { ...answer, ...changes };
        }),
      };
    });
  }

  render() {
    let birdBank = this.props.birdBank;
    let signup = this.props.signup;

    let organizationSelectValue =
      this.state.organizationId !== ''
        ? this.state.organizationId
        : this.state.organizationNameIfOther;

    let organizationOptions = this.props.organizationOptions;
    if (this.state.organizationNameIfOther !== '') {
      organizationOptions.push({
        label: this.state.organizationNameIfOther,
        value: this.state.organizationNameIfOther,
      });
    }

    let {
      schoolOptions,
      graduationYearOptions,
      chapterPositionOptions,
    } = this.props;

    if (this.state.schoolNameIfOther !== '') {
      schoolOptions.push({
        label: this.state.schoolNameIfOther,
        value: this.state.schoolNameIfOther,
      });
    }

    let organizationForm = birdBank.nationalOrder ? (
      <input
        type="hidden"
        name="signup[organization_id]"
        value={birdBank.organization}
      />
    ) : (
      <div>
        <input
          type="hidden"
          value={this.state.organizationNameIfOther}
          name="signup[organization_name_if_other]"
        />
        <input
          type="hidden"
          value={this.state.organizationId}
          name="signup[organization_id]"
        />

        <div id="signup-organization-select" className="react-select-form">
          <label htmlFor="organization">Organization</label>
          <Select.Creatable
            id="organization-select"
            options={organizationOptions}
            value={organizationSelectValue}
            placeholder="Select an Organization"
            onChange={e => this.handleOrganizationSelect(e)}
            onNewOptionClick={e => this.handleOrganizationCreation(e)}
            clearable={true}
          />
        </div>
      </div>
    );

    let schoolSelectValue =
      this.state.schoolId !== ''
        ? this.state.schoolId
        : this.state.schoolNameIfOther;

    const customAnswers = this.props.birdBank.questions.map(
      (question, index) => {
        const answer = find(this.state.answers, { questionId: question.id });
        const fieldPrefix = `signup[answers_attributes][${index}]`;
        return (
          <React.Fragment key={question.id}>
            <CustomAnswer
              {...answer}
              question={question}
              onChange={answer => this.changeAnswer(question.id, answer)}
            />
            <input
              type="hidden"
              name={`${fieldPrefix}[id]`}
              value={answer.id}
            />
            <input
              type="hidden"
              name={`${fieldPrefix}[answer]`}
              value={answer.answer}
            />
          </React.Fragment>
        );
      }
    );

    let showFullSignupForm = birdBank.showFullSignupForm ? (
      <div>
        {organizationForm}
        <div className="react-select-form">
          <label htmlFor="chapter_position">Chapter Position</label>
          <Select
            options={chapterPositionOptions}
            id="chapter_position"
            name="signup[chapter_position]"
            placeholder="Chapter Position"
            value={this.state.chapterPosition}
            onChange={e => this.handleChapterPositionSelect(e)}
            clearable={true}
          />
        </div>
        <div className="row">
          <div id="signup-school-select" className="col-8 react-select-form">
            <input
              type="hidden"
              value={this.state.schoolNameIfOther}
              name="signup[school_name_if_other]"
            />
            <input
              type="hidden"
              value={this.state.schoolId}
              name="signup[school_id]"
            />
            <label htmlFor="school">School</label>
            <Select.Creatable
              id="school-select"
              options={schoolOptions}
              value={schoolSelectValue}
              placeholder="Select a School"
              onChange={e => this.handleSchoolSelect(e)}
              onNewOptionClick={e => this.handleSchoolCreation(e)}
              clearable={true}
            />
            {customAnswers}
          </div>
          <div className="col-4 react-select-form">
            <label htmlFor="graduation_year">Graduation Year</label>
            <Select
              options={graduationYearOptions}
              id="graduation_year"
              name="signup[graduation_year]"
              placeholder="Graduation Year"
              value={this.state.graduationYear}
              onChange={e => this.handleGraduationYearSelect(e)}
              clearable={true}
            />
          </div>
        </div>
      </div>
    ) : null;

    let noChargeCheckbox =
      birdBank.nationalOrder && this.props.editable ? (
        <div>
          <label>
            <Checkbox
              name="signup[no_charge]"
              checked={this.state.noCharge}
              disabled={signup.noCharge}
              onChange={this.onUpdate.bind(this, 'noCharge', 'checked')}
            />{' '}
            No Charge
          </label>
        </div>
      ) : null;

    let notificationAlert =
      birdBank.addressRequired && this.props.currentUser && signup.id ? (
        <div className="pll prl mbl notification notification--alert">
          <strong>Please Note: </strong>
          Changing the shipping address might affect the sales tax rate. If the
          sales tax rate
          <strong> increases</strong>, authorized payments may fail.
        </div>
      ) : null;

    let shippingForm = birdBank.individualShip ? (
      <div className="col-5 push-1" id="signup_shipping_address">
        <h3 className="secondary-headline">Where can we find you?</h3>
        {notificationAlert}
        <fieldset>
          <Address
            addressLine1={{ name: 'address', value: this.state.address }}
            addressLine2={{ name: 'address_two', value: this.state.addressTwo }}
            city={{ name: 'city', value: this.state.city }}
            state={{ name: 'state', value: this.state.state }}
            zip={{ name: 'zip', value: this.state.zip }}
            onChange={address => {
              this.handleAddressChange(address);
            }}
            scope="signup"
            preventSubmitUnlessValidated={!isTest()}
          />
        </fieldset>
      </div>
    ) : null;

    let signupFormRender =
      birdBank.status === 'pending' ? (
        <div className="l-container ptl">
          <p>Customer name and address form goes here!</p>
        </div>
      ) : (
        <div className="row form-vertical form-full">
          <div className="col-6">
            <h3 className="secondary-headline">Tell us who you are.</h3>
            <fieldset>
              <div className="row">
                <div className="col-6">
                  <div className="form-light form-light--bordered">
                    <label htmlFor="first_name">First Name</label>
                    <input
                      type="text"
                      id="first_name"
                      placeholder="First Name"
                      required="true"
                      name="signup[first_name]"
                      value={this.state.firstName}
                      onChange={this.handleChange.bind(this, 'firstName')}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-light form-light--bordered">
                    <label htmlFor="last_name">Last Name</label>
                    <input
                      type="text"
                      id="last_name"
                      placeholder="Last Name"
                      name="signup[last_name]"
                      required="true"
                      value={this.state.lastName}
                      onChange={this.handleChange.bind(this, 'lastName')}
                    />
                  </div>
                </div>
              </div>
              <div className="form-light form-light--bordered">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  pattern="[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?"
                  placeholder="Email"
                  name="signup[email]"
                  required="true"
                  value={this.state.email}
                  onChange={this.handleChange.bind(this, 'email')}
                />
              </div>
              <div className="form-light form-light--bordered">
                <label htmlFor="phone">Phone</label>
                <PhoneFieldControlledWrapper
                  name="signup[phone]"
                  value={this.state.phone}
                />
              </div>

              {showFullSignupForm}

              {noChargeCheckbox}
            </fieldset>
          </div>
          {shippingForm}
        </div>
      );

    return <div>{signupFormRender}</div>;
  }
}

export default SignupForm;
