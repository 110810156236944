import React, { useState } from 'react';
import PhoneField from './PhoneField';
import { formatPhoneNumber } from '../PhoneHelper';

const PhoneFieldControlledWrapper = props => {
  const { value } = props;
  const [phoneNumber, setPhoneNumber] = useState(value);

  const fieldProps = {
    ...props,
    value: formatPhoneNumber(phoneNumber),
    onChange: setPhoneNumber,
  };

  return <PhoneField {...fieldProps} />;
};

export default PhoneFieldControlledWrapper;
