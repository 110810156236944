import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';

class ProductSelect extends React.Component {
  static propTypes = {
    options: PropTypes.array,
  };

  constructor(props) {
    super(props);
    let selectedOptions = props.value
      .map(option => {
        return props.options.filter(
          selectOption => selectOption.value === option
        )[0];
      })
      .filter(option => option);

    this.state = {
      selectedOptions,
    };
  }

  addSelectedOption = selectedOptions => {
    this.setState({ selectedOptions });
  };

  render() {
    return (
      <div>
        <Select
          multi={true}
          onChange={this.addSelectedOption}
          options={this.props.options}
          value={this.state.selectedOptions.map(({ value }) => value)}
        />
        {this.selectedOptions()}
      </div>
    );
  }

  selectedOptions = () => {
    let fieldName = 'product_collection[product_ids][]';

    if (this.state.selectedOptions.length > 0)
      return this.state.selectedOptions.map(function(option, index) {
        return (
          <input
            type="hidden"
            key={index}
            name={fieldName}
            value={option.value}
          />
        );
      });
    else {
      return <input type="hidden" name={fieldName} value="" />;
    }
  };
}

export default ProductSelect;
