import React, { createRef, Component } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import Lightbox from 'react-images';

const PrevArrow = props => (
  <button onClick={props.onClick} className={props.className}>
    <i className="fa fa-chevron-left fa-lg" />
  </button>
);

const NextArrow = props => (
  <button onClick={props.onClick} className={props.className}>
    <i className="fa fa-chevron-right fa-lg" />
  </button>
);

export default class LineItemImageViewer extends Component {
  constructor() {
    super();
    this.slider = createRef();
    this.state = {
      lightboxIsOpen: false,
      currentImageIndex: 0,
    };
  }

  static propTypes = {
    images: PropTypes.arrayOf(
      PropTypes.shape({
        previewUrl: PropTypes.string,
        fullSizeUrl: PropTypes.string,
        processingWarning: PropTypes.string,
      })
    ),
  };

  moveLightboxBy = int => {
    this.setState(prevState => {
      const newIndex =
        (prevState.currentImageIndex + int) % this.props.images.length;
      return { currentImageIndex: newIndex };
    });
  };

  gotoPrevious = () => {
    this.moveLightboxBy(-1);
  };

  gotoNext = () => {
    this.moveLightboxBy(1);
  };

  closeLightbox = () => {
    this.setState({ lightboxIsOpen: false });
  };

  afterSliderChange = currentImageIndex => {
    this.setState({ currentImageIndex });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.currentImageIndex !== prevState.currentImageIndex) {
      this.slider.current.slickGoTo(this.state.currentImageIndex);
    }
  }

  onImageSelect = e => {
    e.preventDefault();
    this.setState({ lightboxIsOpen: true });
  };

  render() {
    const { images } = this.props;

    const imageElements = images.map(img => (
      <div key={img.previewUrl}>
        <div>
          <a
            className="full-width"
            href="#"
            onClick={this.onImageSelect}
            key={img.previewUrl}
          >
            <img className="full-width" src={img.previewUrl} />
          </a>

          <p>
            <small>
              <em>{img.processingWarning}</em>
            </small>
          </p>
        </div>
      </div>
    ));

    const imageSrcs = images.map(img => ({ src: img.fullSizeUrl }));

    const slider =
      images.length > 1 ? (
        <Slider
          dots={true}
          arrow={true}
          infinite={false}
          slidesToShow={1}
          prevArrow={<PrevArrow />}
          nextArrow={<NextArrow />}
          afterChange={this.afterSliderChange}
          ref={this.slider}
        >
          {imageElements}
        </Slider>
      ) : (
        imageElements[0]
      );

    return (
      <React.Fragment>
        <Lightbox
          images={imageSrcs}
          isOpen={this.state.lightboxIsOpen}
          onClickPrev={this.gotoPrevious}
          onClickNext={this.gotoNext}
          onClose={this.closeLightbox}
          currentImage={this.state.currentImageIndex}
        />

        {slider}
      </React.Fragment>
    );
  }
}
