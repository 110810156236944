import React, { forwardRef } from 'react';

// interface Props extends React.InputHTMLAttributes<HTMLTextAreaElement> {
interface Props extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => any;
  onSubmit?: () => any;
  onCancel?: () => any;
  readonly?: boolean;
}

function TextArea(props: Props, ref: any) {
  const { onSubmit, onCancel, readonly, ...rest } = props;
  const showTextOnly: boolean = !!props.readonly;

  function onKeyDown(e) {
    if (onSubmit && e.keyCode === 13) onSubmit();
    if (onCancel && e.keyCode === 27) onCancel();
  }

  if (showTextOnly) {
    return <div>{props.value}</div>;
  }

  return (
    <textarea
      className="txt-resize--v"
      {...rest}
      onKeyDown={onKeyDown}
      ref={ref}
    />
  );
}

export default forwardRef(TextArea);
