import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { filter, sortBy } from 'lodash';
import BirdBankItemForm from './BirdBankItemForm';
import { localPatchJSON } from '../fetch-local';

export default class BirdBankItemManager extends Component {
  static propTypes = {
    birdBankItems: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
      })
    ),
    itemSubstitutionEnabled: PropTypes.bool,
    paymentsProcessing: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      birdBankItems: props.birdBankItems,
    };
  }

  onDragEnd = result => {
    const { destination, source } = result;
    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    this.setState(
      prevState => {
        let result = sortBy(prevState.birdBankItems, ['position']);

        const [removed] = result.splice(source.index, 1);

        result.splice(destination.index, 0, removed);

        const birdBankItems = result.map((birdBankItem, index) => ({
          ...birdBankItem,
          position: index + 1,
        }));

        return { birdBankItems };
      },
      () => {
        const positions = this.state.birdBankItems
          .map(({ id, position }) => ({
            [id]: { position },
          }))
          .reduce((accum, current) => ({ ...accum, ...current }), {});
        localPatchJSON(
          `/bird_banks/${this.props.birdBankId}/bird_bank_items/move`,
          { bird_bank_items: positions }
        );
      }
    );
  };

  updateBirdBankItem = (birdBankItemToUpdate, updater) => {
    this.setState(prevState => {
      const birdBankItems = prevState.birdBankItems.map(birdBankItem => {
        if (birdBankItem != birdBankItemToUpdate) return birdBankItem;
        return { ...birdBankItem, ...updater };
      });

      return { birdBankItems: birdBankItems };
    });
  };

  removeBirdBankItem = birdBankItemToRemove => {
    this.setState(prevState => {
      const birdBankItems = filter(
        prevState.birdBankItems,
        birdBankItem => birdBankItem != birdBankItemToRemove
      );
      return { birdBankItems };
    });
  };

  birdBankItems() {
    const sortedBirdBankItems = sortBy(this.state.birdBankItems, ['position']);

    return sortedBirdBankItems.map((birdBankItem, index) => (
      <BirdBankItemForm
        key={birdBankItem.id}
        {...{ birdBankItem, index }}
        birdBankItemUpdate={this.updateBirdBankItem}
        removeBirdBankItem={this.removeBirdBankItem}
        itemSubstitutionEnabled={this.props.itemSubstitutionEnabled}
        paymentsProcessing={this.props.paymentsProcessing}
        editingEnabled={this.props.editingEnabled}
        voidingEnabled={this.props.voidingEnabled}
        refundingEnabled={this.props.refundingEnabled}
      />
    ));
  }

  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {provided => (
            <div ref={provided.innerRef}>
              {this.birdBankItems()}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}
