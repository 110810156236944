function addEventListeners(element: HTMLTableHeaderCellElement) {
  element.addEventListener('click', sortColumn);
}

function setupTable(element: HTMLTableElement) {
  element.querySelectorAll('[data-sortable]').forEach(addEventListeners);
}

let sortAscending = true;

function sortColumn(event) {
  sortAscending = !sortAscending;
  event.target.closest('.table--sortable').querySelectorAll('[data-sortable-direction]').forEach(element => element.dataset.sortableDirection = null);
  event.target.dataset.sortableDirection = sortAscending ? 'asc' : 'desc';
  const sortMode = event.target.dataset.sortable;
  const columnIndex = Array.from(event.target.parentNode.querySelectorAll('th')).indexOf(event.target);

  const tableBody = event.target.closest('.table--sortable').querySelector('tbody');
  const rows = Array.from(tableBody.querySelectorAll('tr')).map(row => {
    tableBody.removeChild(row);
    return row;
  }).sort((a: HTMLTableRowElement, b: HTMLTableRowElement) => {
    const firstRowTD: HTMLTableDataCellElement = a.querySelectorAll('td')[columnIndex];
    const firstRowColumn = valueOf(firstRowTD.dataset.sortableValue === undefined ? firstRowTD.textContent : firstRowTD.dataset.sortableValue, sortMode);
    const secondRowTD: HTMLTableDataCellElement = b.querySelectorAll('td')[columnIndex];
    const secondRowColumn = valueOf(secondRowTD.dataset.sortableValue === undefined ? secondRowTD.textContent : secondRowTD.dataset.sortableValue, sortMode);

    if (firstRowColumn === null && secondRowColumn === null) {
      return 0;
    } else if (firstRowColumn === null) {
      return sortAscending ? -1 : 1;
    } else if (secondRowColumn === null) {
      return sortAscending ? 1 : -1;
    } else if (firstRowColumn === secondRowColumn) {
      return 0;
    } else if (firstRowColumn > secondRowColumn) {
      return sortAscending ? -1 : 1;
    } else {
      return sortAscending ? 1 : -1;
    }
  });

  tableBody.append(...rows);
}

function valueOf(value: string | null, sortMode) {
  if (value === null || value.length === 0) {
    return value;
  }

  if (sortMode === 'number') {
    return parseFloat(value);
  } else if (sortMode === 'usd') {
    return parseFloat(value.replaceAll(',', '').replace('$', ''));
  } else if (sortMode == 'date') {
    return(new Date(value));
  } else {
    return value;
  }
}

export default function setup() {
  document.querySelectorAll('.table--sortable').forEach(setupTable);
}
