import React, { useState } from 'react';
import classNames from 'classnames';
import Icon from './Icon';
import IconSvg from './IconSvg';
import PropTypes from 'prop-types';
import { find } from 'lodash';

const ButtonDropDown = ({
  items,
  iconOnly,
  iconSize,
  buttonText,
  buttonIcon,
  buttonSmall,
  buttonSecondary,
  buttonSearchInput,
  linkAttributes,
  dropdownSmall,
  dropdownTop,
  dropdownBottom,
  dropdownRight,
  dropdownLeft,
  dropdownRightCollapse,
  dropdownLeftCollapse,
  testingHook,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const buttonDropDownContainerClass = classNames({
    'button-dropdown-container': true,
    'button-dropdown-container--open': isOpen,
    'button-dropdown-container--search-input': buttonSearchInput,
  });

  const buttonClass = classNames({
    button: true,
    'button-dropdown': true,
    'button--small': buttonSmall,
    'button--secondary': buttonSecondary,
  });

  const buttonDropDownMenuClass = classNames({
    'button-dropdown-menu': true,
    'button-dropdown-menu--open': isOpen,
    'button-dropdown-menu--small': dropdownSmall,
    'button-dropdown-menu--top': dropdownTop,
    'button-dropdown-menu--bottom': dropdownBottom,
    'button-dropdown-menu--right': dropdownRight,
    'button-dropdown-menu--left': dropdownLeft,
    'button-dropdown-menu--right-collapse': dropdownRightCollapse,
    'button-dropdown-menu--left-collapse': dropdownLeftCollapse,
  });

  const handleClick = e => {
    e.preventDefault();
    setIsOpen(v => !v);
  };

  const dropdownItems = items.filter(i => i !== null);

  const activeLink = find(items, { active: true });

  const activeButtonText = activeLink ? activeLink['label'] : buttonText;

  return (
    <div className={buttonDropDownContainerClass}>
      {iconOnly ? (
        <button
          className="button-naked pan d-flex"
          onClick={handleClick}
          title={activeButtonText}
          data-testing-selector={testingHook}
        >
          <span className="is-hidden-visually">{buttonText}</span>
          <IconSvg icon="more" size={iconSize} />
        </button>
      ) : (
        <button
          className={buttonClass}
          onClick={handleClick}
          title={activeButtonText}
          data-testing-selector={testingHook}
        >
          {buttonIcon ? <Icon type={buttonIcon} color="inherit" /> : null}
          {activeButtonText}
        </button>
      )}
      <ul className={buttonDropDownMenuClass}>
        {dropdownItems.map(({ href, icon, label, onClick, active }, index) => {
          const attributes = {
            target: '_blank',
            rel: 'noopener noreferrer',
            className: 'button-dropdown-menu__item',
            ...linkAttributes,
          };
          return (
            !active && (
              <li key={index}>
                <a
                  {...attributes}
                  href={href}
                  onClick={e => {
                    onClick && onClick(e);
                    setIsOpen(false);
                  }}
                >
                  {icon ? <Icon type={icon} color="inherit" /> : null}
                  {icon ? <span className="mls">{label}</span> : label}
                </a>
              </li>
            )
          );
        })}
      </ul>
    </div>
  );
};

ButtonDropDown.propTypes = {
  items: PropTypes.array,
  iconOnly: PropTypes.bool,
  iconSize: PropTypes.string,
  buttonText: PropTypes.string,
  buttonIcon: PropTypes.string,
  buttonSmall: PropTypes.bool,
  buttonSecondary: PropTypes.bool,
  buttonSearchInput: PropTypes.bool,
  linkAttributes: PropTypes.object,
  dropdownSmall: PropTypes.bool,
  dropdownTop: PropTypes.bool,
  dropdownBottom: PropTypes.bool,
  dropdownRight: PropTypes.bool,
  dropdownLeft: PropTypes.bool,
  dropdownRightCollapse: PropTypes.bool,
  dropdownLeftCollapse: PropTypes.bool,
  testingHook: PropTypes.string,
};

export default ButtonDropDown;
