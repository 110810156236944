// NOTE: the dragging implementation is heavily based on react-dropzone
//       https://github.com/react-dropzone/react-dropzone

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Cropper from 'cropperjs';
import { uniqueId } from 'lodash';

import {
  humanFileSize,
  uploadFileToS3,
  getDataTransferItems,
  contains,
} from '../utility';

import {
  getImageURIContents,
  getDimensionsInInches,
  getSwatches,
  isFileAtOrUnderSize,
} from '../image';

import { modalConfirm } from '../modal';

const sizeToGeometry = ({ x, y, width, height }) =>
  `${Math.round(width)}x${Math.round(height)}+${Math.round(x)}+${Math.round(
    y
  )}`;

// https://stackoverflow.com/a/25095250/456337
const containsFiles = event => {
  if (event.dataTransfer.types) {
    for (let i = 0; i < event.dataTransfer.types.length; i++) {
      if (event.dataTransfer.types[i] == 'Files') {
        return true;
      }
    }
  }

  return false;
};

class CropperUI extends Component {
  componentDidMount() {
    this.cropper = new Cropper(this.image, {
      viewMode: 1,
      preview: this.preview,
      rotatable: false,
      scalable: false,
      zoomable: false,
      aspectRatio: this.props.aspectRatio,
      crop: this.props.onChange,
    });
  }

  componentWillUnmount() {
    this.cropper.destroy();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.src !== this.props.src && this.cropper) {
      this.cropper.replace(nextProps.src);
    }
  }

  render() {
    let { src, showPreviewOnly } = this.props;

    let style = {
      maxWidth: '100%',
    };

    let cropperProps = {
      src,
      style,
      ref: image => (this.image = image),
    };

    let visibility = showPreviewOnly
      ? {
          visibility: 'visible',
        }
      : {
          position: 'absolute',
          visibility: 'hidden',
          top: '-100%',
          left: '-100%',
        };

    let previewProps = {
      className: 'cropper-preview-frame',
      style: {
        width: 300,
        height: 300,
        overflow: 'hidden',
        ...visibility,
      },
      ref: preview => (this.preview = preview),
    };

    return (
      <div>
        <div style={{ display: showPreviewOnly ? 'none' : 'block' }}>
          <img {...cropperProps} />
        </div>
        <div {...previewProps} />
      </div>
    );
  }
}

class ImageUploader extends Component {
  constructor(props) {
    super(props);

    this.handleFileChoice = this.handleFileChoice.bind(this);
    this.handleCrop = this.handleCrop.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.cancelUpload = this.cancelUpload.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.handleDragOver = this.handleDragOver.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleUndoRemove = this.handleUndoRemove.bind(this);
    this.onDocumentDrop = this.onDocumentDrop.bind(this);
    this.onDocumentDragOver = this.onDocumentDragOver.bind(this);

    this.state = {
      imageToUpload: null,
      previewSrc: null,
      geometry: null,
      error: null,
      isLoading: false,
      directUploadKey: null,
      isRemoved: false,
      imageDimensions: null,
    };

    this.elementId = uniqueId('image-uploder-');
  }

  componentDidMount() {
    if (this.props.preventDropOnDocument) {
      document.addEventListener('dragover', this.onDocumentDragOver, false);
      document.addEventListener('drop', this.onDocumentDrop, false);
    }
  }

  componentWillUnmount() {
    if (this.props.preventDropOnDocument) {
      document.removeEventListener('dragover', this.onDocumentDragOver);
      document.removeEventListener('drop', this.onDocumentDrop);
    }
  }

  onDocumentDragOver(e) {
    e.preventDefault();
  }

  onDocumentDrop(e) {
    if (this.draggableNode === e.target) {
      return;
    }
    e.preventDefault();
  }

  resetState() {
    this.setState({
      previewSrc: null,
      imageToUpload: null,
      directUploadKey: null,
      geometry: null,
      error: null,
      isLoading: false,
      isRemoved: false,
      imageDimensions: null,
    });
  }

  cancelUpload() {
    this.resetImageInput();
    this.resetState();
  }

  // Reset things back to their original state, i.e. the server image
  cancelUploadWithErrorMessage(errorMessage) {
    this.resetImageInput();
    this.setState({
      imageToUpload: null,
      previewSrc: null,
      geometry: null,
      isLoading: false,
      error: errorMessage,
    });
  }

  handleRemove() {
    this.setState({
      previewSrc: null,
      imageToUpload: null,
      directUploadKey: null,
      geometry: null,
      error: null,
      isLoading: false,
      isRemoved: true,
      imageDimensions: false,
    });
  }

  handleUndoRemove() {
    this.setState({ isRemoved: false });
  }

  handleUpload() {
    const file = this.state.imageToUpload;

    if (file) {
      this.setState({ isLoading: true });

      uploadFileToS3(file)
        .then(values => {
          this.setState({ isLoading: false, directUploadKey: values[0] });
        })
        .catch(() => {
          this.setState({
            isLoading: false,
            error: `
              There was a problem uploading your image. Please try again later.
            `,
          });
        });
    }
  }

  resetImageInput() {
    const input = this.imageInput;
    if (input) {
      input.type = '';
      input.type = 'file';
    }
  }

  handleFileChoice(f = null) {
    const { maxFileSize, showCropperUI, fileTypeWhiteList } = this.props;

    const getChosenFileFromFileInput = () => {
      const input = this.imageInput;
      if (input && input.files && input.files[0]) {
        return input.files[0];
      } else {
        return null;
      }
    };

    const file = f || getChosenFileFromFileInput();

    if (file) {
      const validateFileSize = file => {
        if (!isFileAtOrUnderSize(file, maxFileSize)) {
          const size = humanFileSize(maxFileSize);
          return `Your file exceeds ${size}. Please upload a smaller file.`;
        }
        return false;
      };

      const validateFileType = file => {
        if (!contains(fileTypeWhiteList, file.type)) {
          return 'We do not support this type of file.';
        }
        return false;
      };

      this.setState({ isLoading: true, directUploadKey: null });

      getImageURIContents(file).then(imageURIContents => {
        const fileSizeError = validateFileSize(file);
        const fileTypeError = validateFileType(file);

        if (fileSizeError) {
          this.cancelUploadWithErrorMessage(fileSizeError);
        } else if (fileTypeError) {
          this.cancelUploadWithErrorMessage(fileTypeError);
        } else {
          if (this.props.confirmDimensions) {
            getDimensionsInInches(file).then(({ width, height }) => {
              modalConfirm({
                title: 'Please confirm the dimensions',
                text: `The following dimensions were found for this file:
                      ${width}in x ${height}in. Is this correct?`,
                confirmLabel: 'Approve',
                cancelLabel: 'Cancel',
              }).then(wasConfirmed => {
                if (wasConfirmed) {
                  this.setState(
                    {
                      imageToUpload: file,
                      previewSrc: imageURIContents,
                      error: null,
                      isLoading: false,
                      imageDimensions: {
                        width,
                        height,
                      },
                    },
                    () => {
                      // Finally, if we are not cropping simply proceed with
                      // uploading the image
                      if (!showCropperUI) {
                        this.handleUpload();
                      }
                    }
                  );
                } else {
                  this.cancelUploadWithErrorMessage(
                    `Please re-save your file with the correct dimensions and
                    upload again.`
                  );
                }
              });
            });
          } else {
            this.setState(
              {
                imageToUpload: file,
                previewSrc: imageURIContents,
                error: null,
                isLoading: false,
              },
              () => {
                // Finally, if we are not cropping simply proceed with uploading
                // the image
                if (!showCropperUI) {
                  this.handleUpload();
                }
              }
            );
          }

          // TODO: make sure this is only run after modal is approved above,
          // when the modal is shown that is
          getSwatches(file).then(swatchNames => {
            swatchNames.forEach(n => console.log(n));
          });
        }
      });
    }
  }

  handleCrop(e) {
    const geometry = {
      x: e.detail.x,
      y: e.detail.y,
      width: e.detail.width,
      height: e.detail.height,
    };
    this.setState({ geometry: geometry });
  }

  handleDragOver(e) {
    e.preventDefault();
  }

  handleDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    const fileList = getDataTransferItems(e);

    if (containsFiles(e) && fileList.length > 0) {
      this.handleFileChoice(fileList[0]);
    }
  }

  renderPreviewImage() {
    const { previewSrc, directUploadKey, isRemoved } = this.state;
    const { showCropperUI, currentImgSrc, aspectRatio } = this.props;
    const previewImageVisible = showCropperUI && previewSrc && !directUploadKey;
    const draggableProps = {
      onDrop: this.handleDrop,
      onDragOver: this.handleDragOver,
      ref: input => {
        this.draggableNode = input;
      },
    };

    if (previewSrc && !showCropperUI) {
      return (
        <div {...draggableProps}>
          <img className="bordered" src={previewSrc} width="200" />
        </div>
      );
    } else if (previewImageVisible || previewSrc) {
      return (
        <div {...draggableProps}>
          <CropperUI
            src={this.state.previewSrc}
            onChange={this.handleCrop}
            aspectRatio={aspectRatio}
            showPreviewOnly={!previewImageVisible && !!previewSrc}
          />
        </div>
      );
    } else if (currentImgSrc && !isRemoved) {
      return (
        <div {...draggableProps}>
          <img className="bordered" src={currentImgSrc} width="200" />
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    const {
      error,
      isLoading,
      previewSrc,
      imageToUpload,
      directUploadKey,
      geometry,
      isRemoved,
      imageDimensions,
    } = this.state;

    const {
      modelUploaderAttr,
      modelUploaderGeometryAttr,
      modelUploaderRemoveAttr,
      modelUploaderDimensionsWidthAttr,
      modelUploaderDimensionsHeightAttr,
      allowRemove,
      currentImgSrc,
      showCropperUI,
      includeDimensions,
    } = this.props;

    let cancelButtonProps = {
      onClick: this.cancelUpload,
      className: 'button button--outline button-naked button--small mbm',
      type: 'button',
    };

    let removeUploadButtonProps = {
      onClick: this.cancelUpload,
      className: 'button button--outline button--small mbm mrm',
      type: 'button',
    };

    let removeImageButtonProps = {
      onClick: this.handleRemove,
      className: 'button button--outline button--small mbm',
      type: 'button',
    };

    let undoRemoveImageButtonProps = {
      onClick: this.handleUndoRemove,
      className: 'button button--outline button-naked button--small',
      type: 'button',
    };

    const showCancelButton = !isLoading && previewSrc && !directUploadKey;
    const showRemoveUploadButton = !isLoading && previewSrc && directUploadKey;
    const showCropButton = showCropperUI && previewSrc && !directUploadKey;
    const showRemoveImageButton = allowRemove && currentImgSrc && !isRemoved;
    const showUndoRemoveImageButton = isRemoved;

    let cancelButton = showCancelButton ? (
      <button {...cancelButtonProps}>Cancel</button>
    ) : null;

    let removeUploadButton = showRemoveUploadButton ? (
      <button {...removeUploadButtonProps}>Revert</button>
    ) : null;

    let removeImageButton = showRemoveImageButton ? (
      <button {...removeImageButtonProps}>Remove</button>
    ) : null;

    let undoRemoveImageButton = showUndoRemoveImageButton ? (
      <button {...undoRemoveImageButtonProps}>Undo Remove</button>
    ) : null;

    let uploadButtonProps = {
      onClick: this.handleUpload,
      className: 'button button--outline button--small',
      type: 'button',
      disabled: isLoading || !previewSrc,
    };

    let cropButton = showCropButton ? (
      <button {...uploadButtonProps}>Crop</button>
    ) : null;

    let errorMessage = error ? (
      <div className="notification notification--alert notificaton--small stack">
        <div>{error}</div>
      </div>
    ) : null;

    let loader = isLoading ? (
      <i className="fa fa-circle-o-notch fa-spin mrm" />
    ) : null;

    let inputProps = {
      type: 'file',
      accept: 'image/*',
      onChange: this.handleFileChoice.bind(this, null),
      className: 'inputfile',
      id: this.elementId,
      disabled: isLoading,
      ref: input => {
        this.imageInput = input;
      },
    };

    const chosenFileLabelProps = {
      className: 'mts txt-small' + (isLoading ? ' is-disabled' : ''),
    };

    const chosenFileLabel = imageToUpload ? (
      <div {...chosenFileLabelProps}>{imageToUpload.name}</div>
    ) : null;

    const showFileInput = !isLoading;

    const imageUploadButtonProps = {
      htmlFor: this.elementId,
      className: isLoading ? 'is-disabled' : '',
      disabled: isLoading,
    };

    const draggableProps = {
      onDrop: this.handleDrop,
      onDragOver: this.handleDragOver,
      ref: input => {
        this.draggableNode = input;
      },
    };

    const fileInput = showFileInput ? (
      <div className="media mbm">
        <div className="media__obj">
          <input {...inputProps} />
          <label {...imageUploadButtonProps} {...draggableProps}>
            Choose an image
          </label>
        </div>
        <div className="media__body" {...draggableProps}>
          {chosenFileLabel}
        </div>
      </div>
    ) : null;

    const S3UrlHiddenInput = directUploadKey ? (
      <input type="hidden" name={modelUploaderAttr} value={directUploadKey} />
    ) : null;

    const geometryHiddenInput = geometry ? (
      <input
        type="hidden"
        name={modelUploaderGeometryAttr}
        value={sizeToGeometry(geometry)}
      />
    ) : null;

    const removeImageHiddenInput = isRemoved ? (
      <input type="hidden" name={modelUploaderRemoveAttr} value="1" />
    ) : null;

    const showDimensionsHiddenInput =
      includeDimensions &&
      imageDimensions &&
      modelUploaderDimensionsWidthAttr &&
      modelUploaderDimensionsHeightAttr;

    const dimensionsHiddenInput = showDimensionsHiddenInput ? (
      <div>
        <input
          type="hidden"
          name={modelUploaderDimensionsWidthAttr}
          value={imageDimensions.width}
        />

        <input
          type="hidden"
          name={modelUploaderDimensionsHeightAttr}
          value={imageDimensions.height}
        />
      </div>
    ) : null;

    return (
      <div>
        {this.renderPreviewImage()}
        {errorMessage}
        {fileInput}
        {loader}
        {cropButton}
        {cancelButton}
        {removeUploadButton}
        {removeImageButton}
        {dimensionsHiddenInput}
        {undoRemoveImageButton}
        {S3UrlHiddenInput}
        {geometryHiddenInput}
        {removeImageHiddenInput}
      </div>
    );
  }
}

ImageUploader.defaultProps = {
  aspectRatio: NaN,
  maxFileSize: 1500000,
  showCropperUI: false,
  preventDropOnDocument: true,
  fileTypeWhiteList: ['image/png', 'image/jpeg', 'image/gif'],
  confirmDimensions: false,
  allowRemove: true,
  includeDimensions: false,
};

ImageUploader.propTypes = {
  modelUploaderAttr: PropTypes.string.isRequired,
  modelUploaderGeometryAttr: PropTypes.string,
  modelUploaderRemoveAttr: PropTypes.string.isRequired,
  modelUploaderDimensionsWidthAttr: PropTypes.string,
  modelUploaderDimensionsHeightAttr: PropTypes.string,
  aspectRatio: PropTypes.number,
  maxFileSize: PropTypes.number,
  currentImgSrc: PropTypes.string,
  showCropperUI: PropTypes.bool.isRequired,
  preventDropOnDocument: PropTypes.bool.isRequired,
  fileTypeWhiteList: PropTypes.arrayOf(PropTypes.string).isRequired,
  confirmDimensions: PropTypes.bool.isRequired,
  allowRemove: PropTypes.bool.isRequired,
  includeDimensions: PropTypes.bool.isRequired,
};

export default ImageUploader;
