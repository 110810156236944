import 'base-types';
import { Address, AddressIssues, AddressStatus } from './address-types';
import { compact } from 'lodash';

type APIStatus = 'verified' | 'corrected' | 'failed';

interface APIResponse {
  input_index: number;
  candidate_index: number;
  delivery_line_1: string;
  delivery_line_2?: string;
  last_line: string;
  delivery_point_barcode: string;
  components: {
    urbanization: string;
    primary_number: string;
    street_name: string;
    street_predirection: string;
    street_postdirection: string;
    street_suffix: string;
    secondary_number: string;
    secondary_designator: string;
    extra_secondary_number: string;
    extra_secondary_designator: string;
    pmb_designator: string;
    pmb_number: string;
    city_name: string;
    default_city_name: string;
    state_abbreviation: string;
    zipcode: string;
    plus4_code: string;
    delivery_point: string;
    delivery_point_check_digit: string;
  };
  metadata: {
    record_type: 'F' | 'G' | 'H' | 'P' | 'R' | 'S' | '';
    zip_type: 'Unique' | 'Military' | 'POBox' | 'Standard';
    county_fips: string;
    county_name: string;
    carrier_route: string;
    congressional_district: string;
    rdi: string;
    elot_sequence: string;
    elot_sort: string;
    latitude: number;
    longitude: number;
    coordinate_license: number;
    precision:
      | 'Unknown'
      | 'Zip5'
      | 'Zip6'
      | 'Zip7'
      | 'Zip8'
      | 'Zip9'
      | 'Parcel'
      | 'Rooftop';

    time_zone: string;
    utc_offset: number;
    dst: boolean;
  };
  analysis: {
    dpv_match_code: 'Y' | 'N' | 'S' | 'D' | '' | null;
    dpv_footnotes: string;
    dpv_cmra: 'Y' | 'N' | '' | null;
    dpv_vacant: 'Y' | 'N' | '' | null;
    dpv_no_stat: 'Y' | 'N' | '' | null;
    footnotes: string;
  };
}

interface SmartyFootnote {
  value: string;
  definition: string;
}

const correctedCodes: SmartyFootnote[] = [
  {
    value: 'A#',
    definition:
      'Corrected ZIP Code. The address was found to have a different 5-digit ZIP Code than the one submitted. The correct ZIP Code is shown in the zipcode field.',
  },
  {
    value: 'B#',
    definition:
      'Corrected city/state spelling. The spelling of the city name and/or state abbreviation in the submitted address was found to be different than the standard spelling. The standard spellings are shown in the city_name and state_abbreviation fields. ',
  },
  {
    value: 'L#',
    definition:
      'Changed address component. An address component (i.e., directional or suffix only) was added, changed, or deleted in order to achieve a match. ',
  },
  {
    value: 'K#',
    definition:
      'Cardinal rule match. Although the address as submitted is not valid, we were able to find a match by changing the cardinal direction (North, South, East, West). The cardinal direction we used to find a match is found in the components. ',
  },
  {
    value: 'M#',
    definition:
      'Corrected street spelling. The spelling of the street name was changed in order to achieve a match.',
  },
  {
    value: 'N#',
    definition:
      'Fixed abbreviations. The delivery address was standardized. For example, if STREET was in the delivery address, Smarty will return ST as its standard spelling.',
  },
  {
    value: 'T#',
    definition:
      'Multiple response due to magnet street syndrome. The search resulted in a single response; however, the record matched was flagged as having magnet street syndrome, and the input street name components (pre-directional, primary street name, post-directional, and suffix) did not exactly match those of the record. A "magnet street" is one having a primary street name that is also a suffix or directional word, having either a post-directional or a suffix (i.e., 2220 PARK MEMPHIS TN logically matches to a ZIP+4 record 2200-2258 PARK AVE MEMPHIS TN 38114-6610), but the input address lacks the suffix "AVE" which is present on the ZIP+4 record. The primary street name "PARK" is a suffix word. The record has either a suffix or a post-directional present. Therefore, in accordance with CASS requirements, a ZIP+4 Code must not be returned. The multiple response return code is given since a "no match" would prevent the best candidate. ',
  },
  {
    value: 'U#',
    definition:
      'Unofficial city name. The city name in the submitted address is an alternate city name that is not accepted by the US Postal Service. The preferred city name is output in the city_name field.',
  },
  {
    value: 'G#',
    definition:
      'Used addressee data. Information in the addressee input field was determined to be part of the address. It was moved out of the addressee field and incorporated into the street field. ',
  },
  {
    value: 'LL#',
    definition:
      'Flagged address for LACSLink. The input address matched a record that was LACS-indicated, that was submitted to LACSLink for processing. This does not mean that the address was converted; it only means that the address was submitted to LACSLink because the input address had the LACS indicator set.',
  },
  {
    value: 'LI#',
    definition:
      'Flagged address for LACSLink. The input address matched a record that was LACS-indicated, that was submitted to LACSLink for processing. This does not mean that the address was converted; it only means that the address was submitted to LACSLink because the input address had the LACS indicator set.',
  },
  {
    value: 'Q#',
    definition: 'Unique ZIP match. The address has a "unique" ZIP Code ',
  },
  {
    value: 'X#',
    definition: 'Unique ZIP Code. The address has a "unique" ZIP Code',
  },
  {
    value: 'Y#',
    definition:
      'Military match. The address has a military or diplomatic ZIP Code. ',
  },
  {
    value: 'Z#',
    definition:
      'Matched with ZIPMOVE. The ZIPMOVE product shows which ZIP+4 records have moved from one ZIP Code to another. If an input address matches a ZIP+4 record which the ZIPMOVE product indicates has moved, the search is performed again in the new ZIP Code. ',
  },
];

const failedCodes = [
  {
    value: 'C#',
    definition:
      'Invalid city/state/ZIP. The ZIP Code in the submitted address could not be found because neither a valid city and state, nor valid 5-digit ZIP Code was present.',
    field: 'region',
  },
  {
    value: 'D#',
    definition:
      'No ZIP+4 assigned. This address is not present in the USPS data.',
    field: 'base',
  },
  {
    value: 'F#',
    definition:
      'Address not found. The address, exactly as submitted, could not be found in the city, state, or ZIP Code provided. Either the primary number is missing, the street is missing, or the street is too badly misspelled to understand. ',
    field: 'address',
  },
  {
    value: 'H#',
    definition:
      'Missing secondary number. The address as submitted is missing a secondary number (apartment, suite, etc.).',
    field: 'address2',
  },
  {
    value: 'I#',
    definition:
      'Insufficient/ incorrect address data. More than one ZIP+4 Code was found to satisfy the address as submitted. The submitted address did not contain sufficiently complete or correct data to determine a single ZIP+4 Code.',
    field: 'base',
  },
  {
    value: 'J#',
    definition:
      'Dual address. The input contained two addresses. For example: 123 MAIN ST PO BOX 99. ',
    field: 'base',
  },
  {
    value: 'P#',
    definition:
      'Better address exists. The delivery address is matchable, but it is known by another (preferred) name. For example, in New York, NY, AVENUE OF THE AMERICAS is also known as 6TH AVE. An inquiry using a delivery address of 39 6th Avenue would be flagged with Footnote P. ',
    field: 'base',
  },
  {
    value: 'V#',
    definition:
      'Unverifiable city/state. The city and state in the submitted address could not be verified as corresponding to the given 5-digit ZIP Code.',
    field: 'region',
  },
  {
    value: 'W#',
    definition:
      'Invalid delivery address. Street delivery service for this ZIP Code is not provided. The use of a PO Box, General Delivery, or Postmaster for delivery within this ZIP Code is required.',
    field: 'base',
  },
  {
    value: 'E#',
    definition:
      'Same ZIP for multiple. Multiple records were returned, with the same 5-digit ZIP Code.',
    field: 'zip',
  },
  {
    value: 'O#',
    definition:
      'Multiple ZIP+4; lowest used	More than one ZIP+4 Code was found to satisfy the address as submitted. The lowest ZIP+4 add-on may be used to break the tie between the records.',
    field: 'zip',
  },
  {
    value: 'R#',
    definition:
      'No match; EWS: Match soon. The delivery address is not yet matchable, but the US Postal Service Early Warning System file indicates that a match will be available soon.',
    field: 'base',
  },
  {
    value: 'S#',
    definition:
      'Unrecognized secondary address. The secondary information (apartment, suite, etc.) was not recognized.',
    field: 'address2',
  },
];

const responseToStatus = (r: APIResponse[]): AddressStatus => {
  if (r.length === 0 || !r[0].analysis) return AddressStatus.Failed;

  const { dpv_match_code, dpv_vacant, footnotes } = r[0].analysis;

  const matches: any = footnotes ? footnotes.matchAll(/[A-Z]+#/g) : [];

  let footnotePieces: string[] = [];
  for (const match of matches) {
    footnotePieces = [...footnotePieces, match[0]];
  }

  if (footnotePieces.some(r => failedCodes.map(c => c.value).includes(r))) {
    return AddressStatus.Failed;
  } else if (
    footnotePieces.some(r => correctedCodes.map(c => c.value).includes(r))
  ) {
    return AddressStatus.Corrected;
  } else if (dpv_match_code === 'Y' && dpv_vacant === 'N') {
    return AddressStatus.Verified;
  } else if (dpv_match_code === 'S' && dpv_vacant === 'N') {
    return AddressStatus.Corrected;
  } else {
    return AddressStatus.Failed;
  }
};

type APIErrorInstance = string[] | undefined;

const errorsToIssues = (r: APIResponse): AddressIssues => {
  const { footnotes } = r.analysis;

  const matches: any = footnotes.matchAll(/[A-Z]+#/g);
  let footnotePieces: string[] = [];
  for (const match of matches) {
    footnotePieces = [...footnotePieces, match[0]];
  }

  const errorCodes = failedCodes.filter(r => footnotePieces.includes(r.value));

  const ifEmptyUndefined = a => (a.length > 0 ? a : undefined);

  const address = ifEmptyUndefined(
    errorCodes.filter(c => c.field === 'address').map(c => c.definition)
  );
  const address2 = ifEmptyUndefined(
    errorCodes.filter(c => c.field === 'address2').map(c => c.definition)
  );
  const locality = ifEmptyUndefined(
    errorCodes.filter(c => c.field === 'locality').map(c => c.definition)
  );
  const region = ifEmptyUndefined(
    errorCodes.filter(c => c.field === 'region').map(c => c.definition)
  );
  const base = ifEmptyUndefined(
    errorCodes.filter(c => c.field === 'base').map(c => c.definition)
  );

  return { address, address2, locality, region, base };
};

const responseToAddress = (responses: APIResponse[]): Address | null => {
  if (responses.length === 0) return null;
  const { components } = responses[0];
  if (!components) return null;

  const {
    // Custom line 1
    urbanization,
    primary_number,
    street_predirection,
    street_name,
    street_suffix,
    street_postdirection,

    // Custom line 2
    secondary_designator,
    secondary_number,
    extra_secondary_designator,
    extra_secondary_number,
    pmb_designator,
    pmb_number,

    // Last line
    zipcode,
    plus4_code,
    city_name,
    state_abbreviation,
  } = components;

  const address = compact([
    urbanization,
    primary_number,
    street_predirection,
    street_name,
    street_suffix,
    street_postdirection,
  ]).join(' ');

  const address2 = compact([
    secondary_designator,
    secondary_number,
    extra_secondary_designator,
    extra_secondary_number,
    pmb_designator,
    pmb_number,
  ]).join(' ');

  const postalCode = [zipcode, plus4_code].filter(s => s).join('-');

  return {
    address,
    address2,
    locality: city_name,
    region: state_abbreviation,
    postalCode,
    country: 'US',
  };
};

// NOTE: see Smarty docs for details:
//       https://www.smarty.com/docs/cloud/us-street-api

export interface VerifyResponse {
  address?: Address;
  status: AddressStatus;
  issues?: AddressIssues;
}

export default (
  address: Partial<Address>,
  recipient: string | null = null
): Promise<VerifyResponse> => {
  const smartyApiKey = window.OrderApp.smartyApiKey;

  if (!smartyApiKey) {
    return Promise.reject(new Error('missing Smarty API Key'));
  }

  const method = 'GET';

  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Host', 'us-street.api.smartystreets.com');

  const url = new URL('https://us-street.api.smartystreets.com/street-address');

  const params = new URLSearchParams(url.search);

  params.append('key', smartyApiKey);
  params.append('license', 'us-core-cloud');
  params.append('match', 'enhanced');

  if (address.address) params.append('street', address.address);
  if (address.address2) params.append('street2', address.address2);
  if (address.locality) params.append('city', address.locality);
  if (address.region) params.append('state', address.region);
  if (address.postalCode) params.append('zipcode', address.postalCode);
  if (recipient) params.append('addressee', recipient);

  const urlString = `${url.href}?${params}`;
  const requestOptions: any = { method, headers };

  const apiResponse: Promise<APIResponse[]> = window
    .fetch(urlString, requestOptions)
    .then(response => response.json());

  const response: Promise<VerifyResponse> = apiResponse.then(r => {
    let x: VerifyResponse = {
      status: responseToStatus(r),
    };

    const address = responseToAddress(r);
    if (address) x = { ...x, address };

    if (r.length === 0) {
      const issues: AddressIssues = {
        base: ['Your address is either incomplete or unverifiable.'],
      };
      x = { ...x, issues };
    } else if (r[0].analysis && r[0].analysis.footnotes)
      x = { ...x, issues: errorsToIssues(r[0]) };
    return x;
  });

  return response;
};
