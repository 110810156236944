import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

function AddToBirdBankButton(props) {
  const domNode = document.getElementById('line-item-bird-bank-button-root');

  if (!domNode) return null;

  const onClick = e => {
    e.preventDefault();
    props.onClick();
  };

  const isDisabled = props.addressRequired;

  const shipAddressRequiredIcon = (
    <i
      className="fa fa-warning txt-warning mrm"
      title="Please enter a shipping address."
    />
  );

  return ReactDOM.createPortal(
    <div>
      {isDisabled && shipAddressRequiredIcon}
      <button
        title={isDisabled ? 'Please enter a shipping address.' : ''}
        onClick={onClick}
        className="button"
        disabled={isDisabled}
      >
        Add to Bird Bank
      </button>
    </div>,
    domNode
  );
}

AddToBirdBankButton.propTypes = {
  onClick: PropTypes.func,
  addressRequired: PropTypes.bool,
};

export default AddToBirdBankButton;
