import React from 'react';
import PropTypes from 'prop-types';

// This loader serves the same function as Loader.js, minus the margin.
// The idea is that we'll fix the original version and remove this.
// - BD 06/28/2019

const SlimLoader = props =>
  (props.isActive && <i className="fa fa-circle-o-notch fa-spin" />) ||
  (props.children || <span />);

SlimLoader.defaultProps = { isActive: true };
SlimLoader.propTypes = { isActive: PropTypes.bool.isRequired };

export default SlimLoader;
