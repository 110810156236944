import React from 'react';
import Modal from 'react-modal';
import SizeChart from './SizeChart';
import FormatTextWithLineBreaks from './FormatTextWithLineBreaks';

Modal.setAppElement('main');

const DisplayProductDetails = props => {
  const {
    isOpen,
    closeModal,
    productDescription,
    sizeChart,
    productId,
  } = props;

  const imagePrimaryUrl = props.imagePrimary.url;

  const primaryImageUI = (
    <div className="mrm mtm">
      <img
        src={imagePrimaryUrl}
        alt={props.name}
        className="size-chart-photo"
      />
    </div>
  );

  return (
    <Modal
      isOpen={isOpen}
      className="react-modal"
      overlayClassName="react-modal-overlay"
      contentLabel="Product Details Modal"
      closeTimeoutMS={200}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      onRequestClose={closeModal}
    >
      <div className="react-modal-header flex-rows flex-rows--space-b">
        <h3 className="bb-item-headline mvn">{props.name}</h3>
        <button
          className="bb-button-close bb-button-close--blue"
          type="button"
          onClick={closeModal}
        >
          Close
        </button>
      </div>

      <div className="react-modal-body phl">
        {(productDescription || imagePrimaryUrl) && (
          <div className="flex-rows mtm">
            {imagePrimaryUrl && primaryImageUI}
            <div className="mbm">
              {productDescription && (
                <h3 className="bb-item-headline">Description</h3>
              )}
              <FormatTextWithLineBreaks text={productDescription} />
            </div>
          </div>
        )}
        {sizeChart.measurements.length > 0 && (
          <SizeChart productId={productId} sizeChart={sizeChart} />
        )}
      </div>
    </Modal>
  );
};

export default DisplayProductDetails;
