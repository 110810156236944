import React, { useState, useRef, useCallback } from 'react';
import classnames from 'classnames';
import { Post as PostType, EditablePost } from './types';
import { Error } from '../../base-types';
import TextField from '../TextField';
import Loader from '../loader';
import { extractErrorMessage } from '../../fetch-local';
import ErrorBox from '../ErrorBox';
import AttachmentsUploader, { NewUpload } from '../AttachmentsUploader';
import { some } from 'lodash';
import { LoadState } from '../../remote-data-types';
import LightBox from '../LightBox';
import IconSvg from '../IconSvg';
import { Color } from '../../BrandColor';

interface Props {
  post: PostType;
  savePost: (post: EditablePost) => Promise<any>;
  postDidSave: () => void;
  onCancel: () => void;
}

const DEFAULT_SERVER_ERROR = 'Something went wrong, please try again.';

const createEditablePost = (p: PostType): EditablePost => ({
  ...p,
  newAttachments: [],
  removedAttachments: [],
});

export default function EditPostForm(props: Props) {
  const { savePost, postDidSave } = props;
  const messageEl = useRef(null);

  const [post, setPost] = useState<EditablePost>(
    createEditablePost(props.post)
  );
  const [errors, setErrors] = useState<Error[]>([]);
  const [loading, setLoading] = useState(false);
  const [uploads, setUploads] = useState<NewUpload[]>([]);

  const focusMessageField = () => {
    if (messageEl && messageEl.current) {
      // @ts-ignore: Object is possibly 'null'
      messageEl.current.focus();
    }
  };

  const onSave = async () => {
    setLoading(true);
    try {
      await savePost(post);
      setErrors([]);
      setPost(createEditablePost(post));
    } catch (error) {
      const messages: string[] = await extractErrorMessage(
        error,
        DEFAULT_SERVER_ERROR
      );
      setErrors(messages);
    } finally {
      setLoading(false);
      focusMessageField();
      postDidSave();
    }
  };

  const onCancel = () => {
    props.onCancel();
  };

  const notAllSuccesses = some(
    uploads,
    u => u.status.state !== LoadState.Success
  );

  const onUploadsChange = useCallback(us => {
    setUploads(us);
    setPost(np => ({
      ...np,
      newAttachments: us.reduce((accum, curr) => {
        if (curr.status.state !== LoadState.Success) return accum;
        return [...accum, curr.status.data];
      }, []),
    }));
  }, []);

  const disableSubmission = loading || post.message === '' || notAllSuccesses;
  const disableCancel = loading;

  return (
    <AttachmentsUploader key={post.id} onChange={onUploadsChange}>
      {({ buttonView, uploadsView }) => (
        <div
          className={classnames(
            'callout-light',
            'callout-rounded',
            'callout-light--bordered',
            'pam',
            'mtm',
            {
              'callout--dragged-over': false,
            }
          )}
        >
          <ErrorBox errors={errors} />

          <label htmlFor="new-post-message" className="better-label">
            Message
          </label>

          <div className="flex-rows">
            <TextField
              ref={messageEl}
              value={post.message}
              onChange={e => {
                const message = e.target.value;
                setPost(p => ({ ...p, message }));
              }}
              onSubmit={onSave}
              onCancel={onCancel}
              disabled={loading}
            />

            <div className="uploader">{buttonView}</div>
          </div>

          <div className="mtm">
            {post.attachments.map(attachment => (
              <div
                className="flex-rows flex-rows--center-v"
                key={attachment.url}
              >
                {attachment.previewUrls.thumbnail && (
                  <>
                    <LightBox src={attachment.previewUrls.fullsize}>
                      <img
                        src={attachment.previewUrls.thumbnail}
                        alt={attachment.filename}
                        width="30"
                        height="30"
                      />
                    </LightBox>
                    <span
                      className="attachment-title mhm"
                      title={attachment.filename}
                    >
                      {attachment.filename}
                    </span>
                  </>
                )}
                {!attachment.previewUrls.thumbnail && (
                  <>
                    <IconSvg icon="document" color={Color.TealBlue} size={30} />
                    <span
                      className="attachment-title mhm"
                      title={attachment.filename}
                    >
                      {attachment.filename}
                    </span>
                  </>
                )}
                <button
                  className="button-naked"
                  onClick={() => {
                    setPost(p => ({
                      ...p,
                      attachments: p.attachments.filter(
                        ({ id }) => id !== attachment.id
                      ),
                      removedAttachments: [
                        ...p.removedAttachments,
                        attachment.id,
                      ],
                    }));
                  }}
                >
                  <IconSvg icon="close" color={Color.Red} />
                </button>
              </div>
            ))}
            {uploadsView}
          </div>

          <div className="mtm flex-rows">
            <button
              className="button button--small"
              onClick={onSave}
              disabled={disableSubmission}
            >
              Save
            </button>

            <Loader isActive={loading} />

            <button
              className="button-naked txt-small"
              onClick={onCancel}
              disabled={disableCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </AttachmentsUploader>
  );
}
