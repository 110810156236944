$(document).ready(function () {
  $('.tabs').each(function(index) {
    $(this).find('.tab__nav-item:first-child').find('.tab__link').addClass('is-active');
    $(this).find('#front').addClass('is-open');
  });

  $('.tabs').on('click', '.tab__link', function(event) {
    if (!$(this).hasClass('is-active')) {
      event.preventDefault();
      let accordionTabs = $(this).closest('.tabs');
      let tabId = $(this).attr('href');

      accordionTabs.find('.is-open').removeClass('is-open');

      $(this).closest('.tabs').find(tabId).addClass('is-open');
      accordionTabs.find('.is-active').removeClass('is-active');
      $(this).addClass('is-active');
    } else {
      event.preventDefault();
    }
  });
});
