import purchasingOrder from './purchasing-order';
import setupZendeskButtons from './zendesk-button';
import { isTest } from './environment';

export default () => {
  purchasingOrder();
  if (!isTest()) {
    setupZendeskButtons();
  }
};
