$('.dummy-archive-button').on('click', function(e) {
  e.preventDefault();
  $('.dummy-archive-button').hide();
  $('.archive-dropdown').removeClass('archive-hide');
});

$('.archive-confirm').on('click', function(e) {
  e.preventDefault();
  swal(
    {
      title: 'Are You Sure?',
      text: 'You are about to archive this order! This action is permanent!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#FE4438',
      confirmButtonText: 'Archive',
      html: true,
      closeOnConfirm: false,
    },
    function() {
      $('form[class^="archive"]').submit();
    }
  );
});
