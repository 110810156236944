import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import ColorSelect from './color-select';
import { filter } from 'lodash';

const flockingSizes = [
  {
    label: '3" x 3" (9 sq in.)',
    value: '9'
  },
  {
    label: '5" x 5" (25 sq in.)',
    value: '25'
  },
  {
    label: '8" x 8" (64 sq in.)',
    value: '64'
  },
  {
    label: '10" x 10" (100 sq in.)',
    value: '100'
  }
];

class FlockingSelect extends Component {
  static propTypes = {
    prefix: PropTypes.string,
    location: PropTypes.object,
    flockingVinylColorsPmsCodes: PropTypes.array,
    flockingSize: PropTypes.string,
    printColorPmsCodes: PropTypes.arrayOf(PropTypes.string)
  }

  constructor(props) {
    super(props);
    this.state = {
      flockingSize: this.props.flockingSize,
      flockingVinylColorsPmsCodes: this.props.decorationOptions ?
        this.props.decorationOptions.flockingVinylColorsPmsCodes : []
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(name, e) {
    this.setState({ [name]: e.value });
  }

  render(){
    let flockColorsCodes = this.props.flockingVinylColorsPmsCodes;

    let VinylColors = flockColorsCodes ?
      flockColorsCodes.map(pms_code => {
        let color_object = filter(GlobalData.flockingColorOptions, { value: pms_code })[0];
        return color_object ? color_object.label : '';
      }).join(', ') : [];

    let flockColors = this.props.printColorPmsCodes ?
      this.props.printColorPmsCodes.map(pms_code => {
        let color_object = filter(GlobalData.heatPressColorOptions, { value: pms_code })[0];
        return color_object ? color_object.label : '';
      }).join(', ') : [];

    let colorSelect = this.props.locked ?
      (<div>
        <p> Flock Colors: { flockColors } </p>
        <p> Vinyl Colors: { VinylColors } </p>
      </div>) : (<div>
      <div>
        <ColorSelect
          decorationType={'heat_press'}
          name={this.props.prefix + '[flocking_vinyl_colors_pms_codes]'}
          value={flockColorsCodes}
          isPromoProduct={this.props.isPromoProduct}
          allowCustom={GlobalData.allowCustomPms}
          multi={true}
          disabled={this.props.locked}
        />
      </div>

      <div>
        <ColorSelect
          decorationType={'flocking'}
          name={this.props.prefix + '[print_color_pms_codes]'}
          value={this.props.printColorPmsCodes}
          isPromoProduct={this.props.isPromoProduct}
          allowCustom={GlobalData.allowCustomPms}
          multi={true}
          disabled={this.props.locked}
        />
      </div>
      </div>);

    return(
      <div>
        <div>
          <Select
            placeholder="Size"
            name={this.props.prefix + '[flocking_size]'}
            options={flockingSizes}
            onChange={this.handleChange.bind(this, 'flockingSize')}
            value={this.state.flockingSize}
            disabled={this.props.locked}
          />
        </div>

        <div>
          {colorSelect}
        </div>
      </div>
    );
  }
}

export default FlockingSelect;