import React from 'react';
import PropTypes from 'prop-types';

import Revision from './revision';
import Collapsible from './collapsible';

class Revisions extends React.Component {
  static displayName = 'Revisions';

  static propTypes = {
    location_id: PropTypes.number,
    allow_create: PropTypes.bool,
  };

  static defaultProps = {
    allow_create: true,
  };

  state = {
    model: new window.RevisionsModel(this.props.location_id),
    revisions: [],
  };

  componentWillMount() {
    let _this = this;
    this.state.model.subscribe(function() {
      _this.setState({ model: _this.state.model });
    });
  }

  _handleKeyPress = event => {
    if (event.keyCode == 13 && !event.shiftKey) {
      event.preventDefault();
      this._addRevision();
    }
  };

  _onToggle = (revision, complete, cleanupCallback) => {
    this.state.model.toggleRevision(revision, complete, cleanupCallback);
  };

  _revisions = () => {
    let _this = this;
    return this.state.model.revisions.map(function(revision) {
      return (
        <Revision
          key={'location-revision-' + revision.id}
          onToggle={_this._onToggle}
          revision={revision}
        />
      );
    });
  };

  _previouslyCompleted = () => {
    if (this.state.model.previouslyCompleted.length == 0) return;

    let _this = this;

    let revisions = this.state.model.previouslyCompleted.map(function(
      revision
    ) {
      return (
        <Revision
          key={'location-revision-' + revision.id}
          onToggle={_this._onToggle}
          revision={revision}
        />
      );
    });

    return <div className="revisions previously-completed">{revisions}</div>;
  };

  _handleSubmit = event => {
    event.preventDefault();
    this._addRevision();
  };

  _addRevision = () => {
    let newDescriptionDOMNode = this.refs.newDescription;
    if (newDescriptionDOMNode.value == '') return;

    this.state.model.addRevision(
      {
        description: newDescriptionDOMNode.value,
      },
      function() {
        newDescriptionDOMNode.value = '';
      }
    );
  };

  _incompleteRevisionCount = () => {
    return this.state.model.revisions.filter(
      ({ status }) => status === 'incomplete'
    ).length;
  };

  render() {
    let revisions;

    if (this.state.model.revisions.length > 0) {
      revisions = this._revisions();
    } else {
      if (this.state.model.previouslyCompleted.length > 0) {
        revisions = 'No New Revisions';
      } else {
        revisions = 'No Revisions Yet';
      }
    }

    const hint = this.props.allow_create ? (
      ''
    ) : (
      <div className="button-pseudo txt-alert">Revisions are disabled.</div>
    );
    return (
      <div className="location-revisions">
        <h5 className="mtn mbs quaternary-headline">
          Revisions ({this._incompleteRevisionCount()})
        </h5>
        <div className="revisions-section">
          <div className="revisions-checklist">{revisions}</div>
          <Collapsible
            className="completed-revisions-checklist"
            header={
              'Previously Completed (' +
              this.state.model.previouslyCompleted.length +
              ')'
            }
          >
            {this._previouslyCompleted()}
          </Collapsible>
        </div>
        <div className="new-revision row mts">
          <textarea
            ref="newDescription"
            className="col-12 mbs"
            placeholder="Add a Revision"
            type="text"
            rows="2"
            onKeyDown={this._handleKeyPress}
            disabled={!this.props.allow_create}
          />
        </div>
        <div className="row">
          <div className="col-9">{hint}</div>
          <div className="col-3 txt-right">
            <button
              className="button"
              disabled={!this.props.allow_create}
              onClick={this._handleSubmit}
            >
              Add
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Revisions;
