function formIsInvalid() {

  let formIsValid = true;

  if (!window.Modernizr.formvalidation) {
    $('#new_payment').find('[required="required"]').each(function() {
      $(this).removeClass('error');
      if (formIsValid && $(this).is(':visible') && $(this).val().trim() == '') {
        formIsValid = false;
        $(this).addClass('error');
      }
    });
  }
  return !formIsValid;
}


function validatePaymentForm(e) {
  if($('#order_customer_verified').length == 0) {
    return;
  }
  if(formIsInvalid() || !$('#order_customer_verified').is(':checked')) {
    $('.payment-form-container').removeClass('art-verified');
    e.preventDefault();
  }
  else {
    $('.payment-form-container').addClass('art-verified');
  }
}


$(document).on('submit', '#new_braintree_payment', function(e) {
  validatePaymentForm(e);
});

$(document).on('click', '#new_braintree_payment input[type=submit]', function(e) {
  validatePaymentForm(e);
});

$(document).on('change keyup', '#new_braintree_payment input', function(e) {
  validatePaymentForm(e);
});
