import React, { useState } from 'react';
import Modal from 'react-modal';
import IconSvg from './IconSvg';
import { Color } from '../BrandColor';

// NOTE: We were getting an error to use setAppElement to hide
// the app while the modal is open, for use by screenreaders. So
// it's currently being set to `main`.

Modal.setAppElement('.main');

interface Props {
  approvalMessage: string;
}

export default function CollegeLicensingApprovalStatus(props: Props) {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  function handleOpen() {
    setModalIsOpen(true);
  }

  function closeModal() {
    setModalIsOpen(false);
  }

  return (
    <>
      {props.approvalMessage === null || props.approvalMessage.length === 0 ? (
        <span className="label label--spaced label--success">
          College Licensing: Approved
        </span>
      ) : (
        <>
          <button
            type="button"
            className="label label--spaced label--success"
            onClick={handleOpen}
          >
            College Licensing: Approved
          </button>

          <Modal
            isOpen={modalIsOpen}
            className="react-modal"
            overlayClassName="react-modal-overlay"
            contentLabel="Example Modal"
            closeTimeoutMS={200}
          >
            <div className="react-modal-header flex-rows flex-rows--space-b flex-rows--align-c">
              <h4 className="react-modal-header__title">Approval Message</h4>
              <button
                className="button-naked"
                type="button"
                onClick={closeModal}
              >
                <IconSvg icon="close" color={Color.Gray} />
              </button>
            </div>

            <div className="react-modal-body react-modal-content">
              <p>{props.approvalMessage}</p>
            </div>

            <div className="react-modal-footer">
              <div>
                <button
                  type="button"
                  className="button-naked"
                  onClick={closeModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  );
}
