import React from 'react';
import Label from './Label';
import ReviewStatusButton from './ReviewStatusButton';

const LineItemHeader = props => {
  const user = props.meta.currentUser;
  const { data, id } = props;
  const {
    attributes,
    description,
    color,
    affinityReview,
    eligibleForAffinityReview,
  } = data;

  const labels = attributes.map(attr => <Label key={attr} value={attr} />);

  const reviewStatusButton = eligibleForAffinityReview ? (
    <ReviewStatusButton
      canEdit={user.canViewAffinity}
      status={affinityReview ? affinityReview.status : 'not-submitted'}
      toggleReview={e => props.toggleReview(e, id)}
    />
  ) : null;

  return (
    <div className="line-item-header mbl">
      <div className="headline-group mbm">
        <h3 className="headline-sans man">
          {description}

          {color && <span className="txt-muted txt-small pll">{color}</span>}
        </h3>
      </div>

      <div className="flex-rows flex-rows--collapse">
        {labels}
        {reviewStatusButton}
      </div>
    </div>
  );
};

export default LineItemHeader;
