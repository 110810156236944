import React from 'react';
import { humanize } from '../utility';
import uteesSecondaryLogo from '../images/uteesSecondaryLogo.svg';

const BagAndTagLabel = props => {
  const { label } = props;
  const { customer, product, contract } = label;

  const { fullName } = customer;
  const description = product.description;
  const size = `${product.size}`;
  const personalization = product.personalization && (
    <p>{product.personalization}</p>
  );
  const uteesLogo = !contract && (
    <img src={uteesSecondaryLogo} className="label-logo" alt="Utees Logo" />
  );

  if (fullName.length > 0 || description.length > 0 || size.length > 0) {
    return (
      <div className="label-content">
        {uteesLogo}
        <h3>{fullName}</h3>
        <p className="label-description">{description}</p>
        <p className="label-size">
          <strong>{humanize(size)}</strong>
        </p>
        {personalization}
      </div>
    );
  } else {
    return null;
  }
};

export default BagAndTagLabel;
