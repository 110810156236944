export type AttachmentUpdate =
  | AddAttachment
  | RemoveAttachment
  | DontChangeAttachment;

export enum AttachmentUpdateType {
  AddAttachment = 'AddAttachment',
  RemoveAttachment = 'RemoveAttachment',
  DontChangeAttachment = 'DontChangeAttachment',
}

export interface AddAttachment {
  readonly type: AttachmentUpdateType.AddAttachment;
  signedId: string;
}

export interface RemoveAttachment {
  readonly type: AttachmentUpdateType.RemoveAttachment;
}

export interface DontChangeAttachment {
  readonly type: AttachmentUpdateType.DontChangeAttachment;
}
