import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Lightbox from 'react-images';

const BirdBankLineItemImageViewer = ({ images }) => {
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const moveLightboxBy = int => {
    const newIndex = (currentImageIndex + int) % images.length;
    setCurrentImageIndex(newIndex);
  };

  const onImageSelect = e => {
    e.preventDefault();
    setLightboxIsOpen(true);
  };

  const imageElements = images.map(img => (
    <div className="bird-bank-image-thumb" key={img.previewUrl}>
      <a
        className="full-width"
        href="#"
        onClick={onImageSelect}
        key={img.previewUrl}
      >
        <img className="full-width" src={img.previewUrl} />
      </a>
      <p className="man">
        <small>
          <em>{img.processingWarning}</em>
        </small>
      </p>
    </div>
  ));

  const imageSrcs = images.map(img => ({ src: img.fullSizeUrl }));

  return (
    <React.Fragment>
      <Lightbox
        images={imageSrcs}
        isOpen={lightboxIsOpen}
        onClickPrev={() => moveLightboxBy(-1)}
        onClickNext={() => moveLightboxBy(1)}
        onClose={() => setLightboxIsOpen(false)}
        currentImage={currentImageIndex}
      />

      <div className="bird-bank-image-wrapper">{imageElements}</div>
    </React.Fragment>
  );
};

BirdBankLineItemImageViewer.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      previewUrl: PropTypes.string,
      fullSizeUrl: PropTypes.string,
      processingWarning: PropTypes.string,
    })
  ),
};

export default BirdBankLineItemImageViewer;
