import React, { useState, useEffect } from 'react';
import { Id, Permissions } from '../base-types';
import { Task as TaskType } from './TasksApp/types';
import { get, create, destroy, update } from './TasksApp/Store';
import Task from './TasksApp/Task';
import { RemoteData, LoadState } from '../remote-data-types';
import Loader from './loader';
import { snakeCase } from 'lodash';
import AddTaskForm from './TasksApp/AddTaskForm';
import EditTaskForm from './TasksApp/EditTaskForm';
import ErrorBox from './ErrorBox';
import Modal from 'react-modal';
import IconSvg from './IconSvg';
import { Color } from '../BrandColor';

// NOTE: We were getting an error to use setAppElement to hide
// the app while the modal is open, for use by screenreaders. So
// it's currently being set to `main`.

Modal.setAppElement('.main');

interface Props {
  taskableType: string;
  taskableId: Id;
  taskableName: string;
  collectionLabel: string;
  currentUserSalesRepId: number;
}

interface AppState {
  tasks: TaskType[];
  permissions: Permissions;
}

type Result = RemoteData<string, AppState>;

export default function TaskApp(props: Props) {
  const taskAppOwner = props.currentUserSalesRepId === props.taskableId;

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [showAddForm, setShowAddForm] = useState<boolean>(taskAppOwner);
  const [editingTaskId, setEditingTaskId] = useState<Id | null>(null);
  const [viewedNotifications, setViewedNotifications] = useState(false);
  const [notifications, setNotifications] = useState<Object[]>([]);

  const renderNotifications = taskAppOwner
    ? !viewedNotifications && notifications.length > 0
      ? notifications.length
      : null
    : null;

  const collectionUri = `/tasks.json?taskable_type=${snakeCase(
    props.taskableType
  )}&taskable_name=${snakeCase(props.taskableName)}&taskable_id=${
    props.taskableId
  }`;

  const [appState, setAppState] = useState<Result>({
    state: LoadState.NotAsked,
  });

  const collectionLabel = props.collectionLabel || 'tasks';

  const createTask = task =>
    create(collectionUri, task).then(({ tasks, permissions }) => {
      if (tasks.length > 0) {
        if (!taskAppOwner) {
          setViewedNotifications(false);
        }
        setNotifications(tasks.filter(task => task.viewed === false));
      }
      setAppState({ state: LoadState.Success, data: { tasks, permissions } });
    });

  const saveTask = task =>
    update(task).then(({ tasks, permissions }) => {
      setAppState({ state: LoadState.Success, data: { tasks, permissions } });
    });

  const updateTask = task =>
    update(task).then(({ tasks, permissions }) => {
      if (tasks.length > 0) {
        setViewedNotifications(false);
        setNotifications(tasks.filter(task => task.viewed === false));
      }
      setAppState({ state: LoadState.Success, data: { tasks, permissions } });
    });

  let view = <div>not loading anything</div>;

  function handleOpen() {
    setModalIsOpen(true);
  }

  function handleClose() {
    setModalIsOpen(false);
  }

  useEffect(() => {
    setAppState({ state: LoadState.IndeterminateProgressLoading });
    get(collectionUri)
      .then(({ tasks, permissions }) => {
        setAppState({ state: LoadState.Success, data: { tasks, permissions } });
        if (tasks.length > 0) {
          setViewedNotifications(false);
          setNotifications(tasks.filter(task => task.viewed === false));
        }
      })
      .catch(() =>
        setAppState({ state: LoadState.Failure, error: 'Unable to load tasks' })
      );
  }, [collectionUri]);

  if (appState.state === LoadState.IndeterminateProgressLoading) {
    view = <Loader isActive={true} />;
  } else if (appState.state === LoadState.Failure) {
    view = <ErrorBox errors={[appState.error]} />;
  } else if (appState.state === LoadState.Success) {
    const allTasks = appState.data.tasks;
    const tasks = allTasks.filter(task => task.completed === false);
    const completedTasks = allTasks.filter(task => task.completed === true);
    const renderedCompletedTasks = completedTasks.map(task => {
      return (
        <Task
          key={task.id}
          task={task}
          completed={task.completed}
          viewed={task.viewed}
          readonly={task.permissions === Permissions.ReadOnly}
          onEdit={t => setEditingTaskId(t.id)}
          onUpdate={t => updateTask(t)}
          onDestroy={async t => {
            await destroy(t);
            setAppState({
              state: LoadState.Success,
              data: {
                ...appState.data,
                tasks: tasks.filter(rt => rt.id !== t.id),
              },
            });
          }}
        />
      );
    });

    const canCreateNewTask =
      appState.data.permissions === Permissions.ReadWrite;
    view = (
      <>
        {tasks.length === 0 && (
          <p>
            <em className="txt-muted2">
              There are currently no {collectionLabel.toLowerCase()}.
            </em>
          </p>
        )}
        {tasks.map(task =>
          editingTaskId === task.id ? (
            <EditTaskForm
              key={task.id}
              task={task}
              saveTask={saveTask}
              taskDidSave={() => setEditingTaskId(null)}
              onCancel={() => setEditingTaskId(null)}
            />
          ) : (
            <Task
              key={task.id}
              task={task}
              completed={task.completed}
              viewed={task.viewed}
              readonly={task.permissions === Permissions.ReadOnly}
              onEdit={t => setEditingTaskId(t.id)}
              onUpdate={t => updateTask(t)}
              onDestroy={async t => {
                await destroy(t);
                setAppState({
                  state: LoadState.Success,
                  data: {
                    ...appState.data,
                    tasks: tasks.filter(rt => rt.id !== t.id),
                  },
                });
              }}
            />
          )
        )}

        {canCreateNewTask &&
          (showAddForm ? (
            <AddTaskForm
              onCancel={() => setShowAddForm(false)}
              addTask={createTask}
            />
          ) : (
            <button
              onClick={() => setShowAddForm(true)}
              className="button button--secondary button--small mtm"
            >
              Add
            </button>
          ))}

        <details className="mtl stack stack--small">
          <summary>
            <strong>Completed</strong>
          </summary>
          {renderedCompletedTasks}
        </details>
      </>
    );
  }

  return (
    <>
      <button
        type="button"
        className="button button-badge button-badge--alert"
        title="Task List"
        onClick={() => handleOpen()}
        data-badge={renderNotifications}
      >
        {props.collectionLabel}
      </button>

      <Modal
        isOpen={modalIsOpen}
        className="react-modal"
        overlayClassName="react-modal-overlay"
        contentLabel="Action Items"
        closeTimeoutMS={200}
      >
        <div className="react-modal-header flex-rows flex-rows--space-b flex-rows--center-v">
          <h4 className="react-modal-header__title">{props.collectionLabel}</h4>
          <button
            className="button-naked pan"
            type="button"
            onClick={handleClose}
            title="Close modal"
          >
            <IconSvg icon="close" color={Color.Gray} />
          </button>
        </div>

        <div className="react-modal-body react-modal-content">
          <div>{view}</div>
        </div>
      </Modal>
    </>
  );
}
