import React from 'react';
import BagAndTagLabel from './BagAndTagLabel';
import { chunk } from 'lodash';

const BagAndTagLabelSheet = props => {
  const { signups } = props;

  const labels = signups.map((labelObject) => (
    <div key={labelObject.id} className="bag-tag-label">
      <BagAndTagLabel label={labelObject} />
    </div>
  ));

  const groupedLabels = chunk(labels, 15);
  const groupedLablesWithIndices = groupedLabels.map((obj, i) => (
    { pageNum: i, labels: obj }
  ));

  const pages = groupedLablesWithIndices.map((page) => {
    return (
      <div key={page.pageNum} className="bag-tag-label-page">
        {page.labels}
      </div>
    );
  });

  return <div className="bag-tag-sheet-container">{pages}</div>;
};

export default BagAndTagLabelSheet;
