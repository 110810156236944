(function($) {
  $('[data-enabled-by]').each(function(index, element) {
    const triggerSelector = $(element).data('enabled-by');
    const syncUI = () => {
      if ($(triggerSelector).length === 0) return;
      $(element).prop('disabled', !$(triggerSelector)[0].checked);
    };
    syncUI();

    if ($(triggerSelector).is(':radio')) {
      let name = $(triggerSelector).attr('name');
      let allRadios = $(`[name="${name}"]`);
      allRadios.on('change', syncUI);
    } else if ($(triggerSelector).is(':checkbox')) {
      $(triggerSelector).on('change', syncUI);
    }
  });

  $('[data-disabled-by]').each((index, element) => {
    const triggerSelector = $(element).data('disabled-by');
    const syncUI = () => {
      if ($(triggerSelector).length === 0) return;
      $(element).prop('disabled', $(triggerSelector)[0].checked);
    };
    syncUI();

    if ($(triggerSelector).is(':radio')) {
      const name = $(triggerSelector).attr('name');
      const allRadios = $(`[name="${name}"]`);
      allRadios.on('change', syncUI);
    } else if ($(triggerSelector).is(':checkbox')) {
      $(triggerSelector).on('change', syncUI);
    }
  });
})(window.jQuery);
