import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { contrast, hexToRgb } from '../color';
import { trim } from 'lodash';

const strokeThreshold = 1.5;

const buildIconProps = (hexColor, backgroundColor) => {
  if (!hexColor) return null;

  const backgroundRgb = hexToRgb(trim(backgroundColor));
  const colorRba = hexToRgb(trim(hexColor));
  const colorContrast = contrast(backgroundRgb, colorRba);
  const shouldStroke = colorContrast <= strokeThreshold;

  return {
    style: {
      color: hexColor,
    },
    className: classNames({
      'fa': true,
      'fa-tint': true,
      'fa-tint-o': true,
      'fa-fw': true,
      'txt-stroke': shouldStroke,
    }),
  };
};

const Color = props => {
  const { name, hexColor, backgroundColor } = props;

  const iconProps = buildIconProps(hexColor, backgroundColor);
  const icon = iconProps ? <i {...iconProps} /> : null;

  return(
    <span className="card__meta-description">
      {icon}
      {name}
    </span>
  );
};

Color.defaultProps = {
  backgroundColor: '#fff',
};

Color.propTypes = {
  name: PropTypes.string.isRequired,
  hexColor: PropTypes.string,
  backgroundColor: PropTypes.string,
};

export default Color;
