(function() {
  let SelectPayment = function(params) {
    let self = this,
      $node = params.$node,
      onClick = function(e) {
        if (this.value === 'true') {
          $('.braintree-sheet__text').text(
            'Provide backup payment information'
          );
          $('.braintree-heading').text('Backup Payment Method');
          $('[data-backup-payment-method-info]').show();
        } else {
          $('.braintree-sheet__text').text('Payment Methods');
          $('.braintree-heading').text('Payment Methods');
          $('[data-backup-payment-method-info]').hide();
        }
      },
      init = function() {
        $('[data-backup-payment-method-info]').hide();
        $node.click(onClick);
      };

    init();
  };

  $('[data-select-payment] :input').each(function() {
    new SelectPayment({
      $node: $(this),
    });
  });
})();
