import React from 'react';
import { NotificationType, Error } from '../base-types';
import NotificationBox from './NotificationBox';

interface Props {
  errors: Error[];
}

export default function ErrorBox({ errors }: Props) {
  return <NotificationBox type={NotificationType.Alert} messages={errors} />;
}
