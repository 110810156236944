import React, { useState, useEffect, useCallback } from 'react';
import Select from 'react-select';

interface DataValue {
  value: number;
  formatted: string;
  updatedAt?: string;
}

interface Supplier {
  id: number;
  label: string;
  itemManagerPrice?: DataValue;
  price?: DataValue;
  inventory?: DataValue;
}

interface Option extends Supplier {
  value: string;
}

interface OptionProps {
  children: string;
  option: Option;
  className: string;
  isDisabled: boolean;
  isFocused: boolean;
  isSelected: boolean;
  onFocus: (a: Option, any) => any;
  onSelect: (a: Option, any) => any;
}

const SupplierOption = (props: OptionProps) => {
  const handleMouseDown = e => {
    e.preventDefault();
    e.stopPropagation();
    props.onSelect(props.option, e);
  };

  const handleMouseEnter = e => {
    props.onFocus(props.option, e);
  };

  const handleMouseMove = e => {
    if (props.isFocused) return;
    props.onFocus(props.option, e);
  };

  return (
    <div
      className={props.className + ' supplier-dropdown-item phm pvs'}
      onMouseDown={handleMouseDown}
      onMouseEnter={handleMouseEnter}
      onMouseMove={handleMouseMove}
      title={props.option.label}
      role="button"
      tabIndex={-1}
    >
      {props.children}

      {props.option.inventory && (
        <span
          className="txt-success txt-right"
          title={props.option.inventory.updatedAt || ''}
        >
          {props.option.inventory.formatted}
        </span>
      )}

      {props.option.price && (
        <span className="txt-right" title={props.option.price.updatedAt || ''}>
          {props.option.price.formatted}
        </span>
      )}

      {!props.option.inventory && (
        <span className="txt-muted2 txt-right"></span>
      )}

      {!props.option.price && props.option.itemManagerPrice && (
        <span className="txt-right txt-muted2 txt-em">
          {props.option.itemManagerPrice.formatted}
        </span>
      )}
    </div>
  );
};

interface ValueProps {
  children: JSX.Element;
  placeholder: string;
  value: Option;
}

const SupplierValue = ({ value, children }: ValueProps) => {
  return (
    <div className="Select-value" title={value.label}>
      <span className="Select-value-label">{children}</span>
    </div>
  );
};

interface Props {
  formId: string;
  name: string;
  initialSelectedSupplierId: number;
  suppliers: Supplier[];
}

const SupplierSplitSupplierSelect = ({
  formId,
  name,
  suppliers,
  initialSelectedSupplierId,
}: Props) => {
  const [value, setValue] = useState<string | null>(null);
  const options: Option[] = suppliers.map(supplier => {
    return { ...supplier, value: supplier.id.toString() };
  });

  const saveValue = useCallback(
    (value: string | null) => {
      setValue(value);

      const payload = {
        detail: {
          formId,
          supplierId: value,
        },
      };

      window.dispatchEvent(new CustomEvent('supplierSet', payload));
    },
    [formId]
  );

  useEffect(() => {
    if (initialSelectedSupplierId === undefined) return;
    setValue(
      initialSelectedSupplierId ? initialSelectedSupplierId.toString() : null
    );
  }, [initialSelectedSupplierId]);

  const selectId = `${formId}-supplier`;

  return (
    <div className="section txt-left" id={selectId}>
      <Select
        onChange={e => {
          const value = e ? e.value : e;
          saveValue(value);
        }}
        name={name}
        optionComponent={SupplierOption}
        options={options}
        placeholder={'Select Supplier'}
        value={value}
        valueComponent={SupplierValue}
      />
    </div>
  );
};

export default SupplierSplitSupplierSelect;
