import React from 'react';
import $ from 'jquery';

interface Props {
  src: string | null;
  children: React.ReactNode;
}

const defaultOptions = {
  buttons: ['close'],
  type: 'image',
  afterLoad: (instance, current) => {
    const multiplier = 20;
    current.width = current.width * multiplier;
    current.height = current.height * multiplier;
  },
};

export default function LightBox(props: Props) {
  const launchLightBox = () => {
    $.fancybox.open({
      ...defaultOptions,
      src: props.src,
    });
  };

  if (props.src === null) return <>{props.children}</>;

  return (
    <div
      onKeyPress={e => {
        e.preventDefault();
        if (e.charCode === 32) launchLightBox();
      }}
      role="button"
      tabIndex={0}
      onClick={() => launchLightBox()}
      style={{ cursor: 'pointer' }}
    >
      {props.children}
    </div>
  );
}
