const fetch = window.fetch;
const Promise = window.Promise;

export const contains = (list, value) => list.indexOf(value) > -1;

export const containsAtLeastOne = (list, values) =>
  list.reduce(
    (m, item) => m || values.reduce((m2, item2) => m2 || item2 === item, false),
    false
  );

// Credit: https://stackoverflow.com/a/34209399/456337
export const urlWithParameters = (url, params) => {
  const query = Object.keys(params)
    .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
    .join('&');

  return `${url}?${query}`;
};

// Credit: http://stackoverflow.com/a/14919494/456337
export const humanFileSize = bytes => {
  let thresh = 1000;
  if (Math.abs(bytes) < thresh) {
    return `${bytes}B`;
  }
  let units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);

  return `${bytes.toFixed(1)}${units[u]}`;
};

// Ensure that a fetch response is 2XX class, otherwise fail and reject the
// promise: https://github.com/github/fetch#handling-http-error-statuses
export const checkStatus = (errorMessage = null) => {
  return response => {
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else {
      const message = errorMessage || response.statusText;
      const error = new Error(message);
      error.response = response;
      throw error;
    }
  };
};

// Parse JSON of a fetch() response in a Promise pipeline
export const parseJSON = response => response.json();

// https://github.com/react-dropzone/react-dropzone
export const getDataTransferItems = event => {
  let dataTransferItemsList = [];
  if (event.dataTransfer) {
    const dt = event.dataTransfer;
    if (dt.files && dt.files.length) {
      dataTransferItemsList = dt.files;
    } else if (dt.items && dt.items.length) {
      // During the drag even the dataTransfer.files is null but Chrome
      // implements some drag store, which is accesible via dataTransfer.items
      dataTransferItemsList = dt.items;
    }
  } else if (event.target && event.target.files) {
    dataTransferItemsList = event.target.files;
  }
  // Convert from DataTransferItemsList to the native Array
  return Array.prototype.slice.call(dataTransferItemsList);
};

export const uploadFileToS3 = file => {
  const fileType = file.type;

  const signedUrlRequestUrl = urlWithParameters('/image_uploads/signed_url', {
    content_type: fileType,
    file_name: file.name,
  });

  const requestConfig = {
    credentials: 'same-origin',
  };

  return fetch(signedUrlRequestUrl, requestConfig)
    .then(checkStatus('Failed to obtain a signed request URL'))
    .then(parseJSON)
    .then(data => {
      const signedUrl = data.uploadUrl;
      const objectKey = data.objectKey;

      const request = fetch(signedUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': fileType,
        },
        body: file,
      });

      return Promise.all([objectKey, request]);
    })
    .then(values => {
      const check = checkStatus('Failed to upload image to S3');
      check(values[1]);
      return values;
    });
};

// https://stackoverflow.com/a/2901298/456337
export const numberWithThousandsSeparator = number =>
  number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const formatNumberAsStringWithSeparator = (input, f) => {
  const parsedNumber = parseFloat(input);
  if (!isNaN(parsedNumber)) {
    return f(numberWithThousandsSeparator(parsedNumber.toFixed(2)));
  } else {
    return '–';
  }
};

export const formatMoney = input =>
  formatNumberAsStringWithSeparator(input, s => '$' + s);

export const formatPercent = input =>
  formatNumberAsStringWithSeparator(input, s => s + '%');

export const formatPercentV2 = input => {
  const value = input * 100;

  let s = formatNumberAsStringWithSeparator(value, s => s);
  s = s.replace(/0$/, '');
  s = s.replace(/\.0$/, '');

  return s + '%';
};

export const reverse = list => [...list].reverse();

export const makeAction = (type, payload) => ({ type, ...payload });

export const extractValue = target => {
  switch (target.type) {
    case 'checkbox':
      return target.checked;

    case 'number': {
      const parsedNumber = parseFloat(target.value);
      if (isNaN(parsedNumber)) return null;
      return parsedNumber;
    }

    default:
      return target.value;
  }
};

export const getHashParams = () =>
  window.location.hash
    .substr(1)
    .split('&')
    .map(v => v.split('='))
    .reduce((pre, [key, value]) => ({ ...pre, [key]: value }), {});

export const humanize = s => {
  if (!s) return '';
  let r = s.charAt(0).toUpperCase() + s.slice(1);
  return r.replace(/-|_/, ' ');
};

export const validateEmail = email => {
  let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

// https://stackoverflow.com/a/61511955
export const waitForElement = selector => {
  return new Promise(resolve => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver(mutations => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
};
