import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import ColorSelect from './color-select';
import { filter } from 'lodash';
import CheckBoxField from './check-box-field';
import { intersection } from 'lodash';

const GlobalData = window.GlobalData;

const heatPressTypeSelectOptions = [
  { value: 'name_only', label: 'Name' },
  { value: 'number_only', label: 'Number' },
];

const heatPressLetteringStyleSelectOptions = [
  { value: 'varsity', label: 'Varsity' },
  { value: 'pro_block', label: 'Pro Block' },
  { value: 'embroidery', label: 'Embroidery' },
];

class HeatPressSelect extends Component {
  static propTypes = {
    prefix: PropTypes.string,
    location: PropTypes.object,
    decorationOptions: PropTypes.object,
    printColorPmsCodes: PropTypes.arrayOf(PropTypes.string),
  };

  constructor(props) {
    super(props);
    this.state = {
      heat_press_type: this.props.location
        ? this.props.location.heat_press_type
        : '',
      heat_press_lettering_style: this.props.decorationOptions
        ? this.props.decorationOptions.heat_press_lettering_style
        : 'pro_block',
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(name, e) {
    this.setState({ [name]: e.value });
  }

  render() {
    let colorSelectDecorationType =
      this.state.heat_press_lettering_style === 'embroidery'
        ? 'embroidery'
        : 'heat_press';

    let colors = this.props.printColorPmsCodes
      .map(pms_code => {
        let color_object = filter(GlobalData.heatPressColorOptions, {
          value: pms_code,
        })[0];
        return color_object ? color_object.label : '';
      })
      .join(', ');

    let colorSelect = this.props.location.locked ? (
      <p>Colors: {colors}</p>
    ) : (
      <div>
        <ColorSelect
          decorationType={colorSelectDecorationType}
          name={this.props.prefix + '[print_color_pms_codes]'}
          value={this.props.printColorPmsCodes}
          allowCustom={GlobalData.allowCustomPms}
          multi={true}
        />
      </div>
    );

    const isBdl =
      intersection(GlobalData.currentUserRoles, [
        'business_development_leader',
        'admin',
        'uberadmin',
      ]).length > 0;

    let personalizedInput = isBdl && (
      <CheckBoxField
        id="personalized"
        name={this.props.prefix + '[personalized]'}
        label="Personalized?"
        className="mrm"
        checked={this.props.location['personalized']}
      />
    );

    return (
      <div>
        <div>
          <Select
            placeholder="Heat Press Type"
            name={this.props.prefix + '[heat_press_type]'}
            onChange={this.handleChange.bind(this, 'heat_press_type')}
            options={heatPressTypeSelectOptions}
            value={this.state.heat_press_type}
            disabled={
              this.props.locked || this.props.location['has_signup_line_items?']
            }
          />
        </div>

        <div>
          <Select
            placeholder="Lettering Style"
            name={this.props.prefix + '[heat_press_lettering_style]'}
            onChange={this.handleChange.bind(
              this,
              'heat_press_lettering_style'
            )}
            options={heatPressLetteringStyleSelectOptions}
            value={this.state.heat_press_lettering_style}
            disabled={this.props.locked}
          />
        </div>

        <div>{colorSelect}</div>

        {personalizedInput}
      </div>
    );
  }
}

export default HeatPressSelect;
