import React from 'react';
import BirdBankLineItemImageViewer from './BirdBankLineItemImageViewer';

const BirdBankItemImage = ({ birdBankItem, birdBankItemUpdate, editing }) => {
  const { images, reverseImages } = birdBankItem;

  const onReverseImagesChecked = e => {
    const reverseImages = e.target.checked;
    birdBankItemUpdate(birdBankItem, { reverseImages });
  };

  const imageReverseField = images.length > 1 &&
    editing && (
      <div className="input-checkbox">
        <input
          id="reverse-image"
          type="checkbox"
          checked={reverseImages}
          onChange={onReverseImagesChecked}
        />
        <label htmlFor="reverse-image">Swap primary image?</label>
      </div>
    );

  return (
    <div className="bird-bank-item-image-container">
      <div className="bird-bank-item-image-viewer">
        <BirdBankLineItemImageViewer images={images} />
        {imageReverseField}
      </div>
    </div>
  );
};

export default BirdBankItemImage;
