import React from 'react';
import PropTypes from 'prop-types';

const Loader = props =>
  (props.isActive && <i className="fa fa-circle-o-notch fa-spin mrm" />) ||
  (props.children || <span />);

Loader.defaultProps = { isActive: true };
Loader.propTypes = { isActive: PropTypes.bool.isRequired };

export default Loader;
