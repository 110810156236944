import PropTypes from 'prop-types';
import React from 'react';

class LocationFileField extends React.Component {
  static propTypes = {
    onSelect: PropTypes.func
  };

  onSelect = (event) => {
    (this.props.onSelect)(event);
  };

  open = (event) => {
    event.preventDefault();
    this.fileInput.click();
  };

  render() {
    return (
      <div className="image-preview">
        <a href="#" className="add-image" onClick={this.open}>
          <i className="fa fa-plus fa-3x"></i>
          <span>Add</span>
        </a>
        <input
          ref={(input) => { this.fileInput = input; }}
          type="file"
          onChange={this.onSelect}
          multiple={true}
          style={{ display: 'none' }}
        />
      </div>
    );
  }
}

export default LocationFileField;
