import React from 'react';
import Select from 'react-select';
import Loader from '../../loader';
import { useToggle } from '../../custom-hooks';

const SchoolAndOrganizationFields = ({
  schoolOptions,
  organizationOptions,
  schoolId,
  schoolNameIfOther,
  organizationId,
  organizationNameIfOther,
  graduationYear,
  graduationYearOptions,
  chapterPosition,
  chapterPositionOptions,
  onChange,
  onSubmit,
  nationalOrder,
}) => {
  const handleOrganizationSelect = organization =>
    onChange({
      organizationId: organization && organization.value,
    });

  const handleOrganizationCreation = organization => {
    onChange({
      organizationNameIfOther: organization.value,
      organizationId: null,
    });
  };

  const handleSchoolSelect = school => {
    onChange({ schoolId: school && school.value, schoolNameIfOther: null });
  };

  const handleSchoolCreation = school => {
    onChange({ schoolNameIfOther: school.value, schoolId: null });
  };

  const handleSubmit = e => {
    e.preventDefault();
    toggleLoading(true);
    onSubmit(e);
  };

  const handleGraduationYearSelect = graduationYear =>
    onChange({
      graduationYear: graduationYear && graduationYear.value,
    });

  const handleChapterPositionSelect = chapterPosition =>
    onChange({
      chapterPosition: chapterPosition && chapterPosition.value,
    });

  const otherOrganizationOption = organizationNameIfOther
    ? [
        {
          label: organizationNameIfOther,
          value: organizationNameIfOther,
        },
      ]
    : [];
  const otherSchoolOption = schoolNameIfOther
    ? [
        {
          label: schoolNameIfOther,
          value: schoolNameIfOther,
        },
      ]
    : [];

  const [loading, toggleLoading] = useToggle(false);

  const organizationField = nationalOrder ? null : (
    <React.Fragment>
      <label>Organization</label>
      <Select.Creatable
        options={[...organizationOptions, ...otherOrganizationOption]}
        value={organizationId || organizationNameIfOther || undefined}
        onChange={handleOrganizationSelect}
        onNewOptionClick={handleOrganizationCreation}
        clearable={true}
      />
    </React.Fragment>
  );

  return (
    <form onSubmit={handleSubmit}>
      <div className="bb-form-vertical mtm">
        <div className="react-select-form">
          {organizationField}

          <label>Chapter Position</label>
          <Select
            options={chapterPositionOptions}
            value={chapterPosition || undefined}
            onChange={handleChapterPositionSelect}
            clearable={true}
          />

          <label>School</label>
          <Select.Creatable
            options={[...schoolOptions, ...otherSchoolOption]}
            value={schoolId || schoolNameIfOther || undefined}
            onChange={handleSchoolSelect}
            onNewOptionClick={handleSchoolCreation}
            clearable={true}
          />

          <label>Graduation Year</label>
          <Select
            options={graduationYearOptions}
            value={graduationYear || undefined}
            onChange={handleGraduationYearSelect}
            clearable={true}
          />
        </div>

        <button className="bb-button bb-button--small" disabled={loading}>
          Next
        </button>

        <div className="pull-right pts">
          <Loader isActive={loading} />
        </div>
      </div>
    </form>
  );
};

export default SchoolAndOrganizationFields;
