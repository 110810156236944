// Purpose of this function is to insert Company address for pick-up items
(function() {
  function hidePickupValues() {
    $('#order-shipping-fields').hide();
  }

  $(document).ready(function() {
    if (
      $(
        'input[name="order[art_jobs_attributes][0][shipping_method]"]:checked'
      ).val() === 'pick_up_order'
    ) {
      $('#order-shipping-fields').hide();
    }
  });

  $('.shipping-methods').click(function(e) {
    if (
      e.target.id ===
      'order_art_jobs_attributes_0_shipping_method_pick_up_order'
    ) {
      hidePickupValues();
    } else {
      $('#order-shipping-fields').show();
    }
  });
})();
