import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { localGet } from '../fetch-local';

const BirdBankItemSubstitutionField = props => {
  const { birdBankItem, alternateLineItemsUrl, lineItemId } = props;

  const [alternateLineItems, setAlternateLineItems] = useState([
    {
      label: birdBankItem.descriptionWithoutColor,
      value: birdBankItem.lineItemId,
    },
  ]);

  useEffect(
    () => {
      localGet(alternateLineItemsUrl).then(data => {
        setAlternateLineItems(
          data.alternateLineItems.map(alternateLineItem => {
            return {
              label: alternateLineItem.descriptionOfItem,
              value: alternateLineItem.id,
            };
          })
        );
      });
    },
    [alternateLineItemsUrl]
  );

  const handleItemSelected = selectedOption => {
    props.birdBankItemUpdate(birdBankItem, {
      lineItemId: selectedOption.value,
    });
  };

  return (
    <Select
      cache={false}
      options={alternateLineItems}
      defaultOptions
      value={lineItemId}
      onChange={handleItemSelected}
      clearable={false}
    />
  );
};

BirdBankItemSubstitutionField.propTypes = {
  birdBankItemUpdate: PropTypes.func,
  alternateLineItemsUrl: PropTypes.string,
  lineItemId: PropTypes.number,
};

export default BirdBankItemSubstitutionField;
