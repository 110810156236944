$(document).ready(function() {
  $('[data-toggle="dropdown"]').on('click', function() {
    if ($(this).parent('.active').length) {
      $(this)
        .parent()
        .removeClass('active');
    } else {
      $('.nav-dropdown').removeClass('active');
      $(this)
        .parent()
        .addClass('active');
    }

    $(document).on({
      click: function() {
        $('.nav-dropdown').removeClass('active');
      },
    });

    return false;
  });

  /* Clicks within the dropdown won't make
     it past the dropdown itself */
  $('.nav-dropdown__menu').click(function(e) {
    e.stopPropagation();
  });
});
