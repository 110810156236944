export enum Color {
  DarkGray = '#414042',
  Gray = '#999999',
  LightGray = '#cccccc',
  White = '#ffffff',
  TealBlue = '#009cad',
  Yellow = '#e8ad17',
  Red = '#fe4438',
  Green = '#6abf4a',
  Blue = '#75cede', //This is the teal blue used in the marketing site and BB pages
}
