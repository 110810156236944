import React, { forwardRef } from 'react';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => any;
  onSubmit?: () => any;
  onCancel?: () => any;
  readonly?: boolean;
}

function TextField(props: Props, ref: any) {
  const { onSubmit, onCancel, ...rest } = props;
  const readonly: boolean = !!props.readonly;

  function onKeyDown(e) {
    if (onSubmit && e.keyCode === 13) onSubmit();
    if (onCancel && e.keyCode === 27) onCancel();
  }

  if (readonly) {
    return <div>{props.value}</div>;
  }

  return <input {...rest} type="text" onKeyDown={onKeyDown} ref={ref} />;
}

export default forwardRef(TextField);
