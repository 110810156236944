function scrollCommentsToTop(no_animation) {
  let speed = no_animation ? 0 : 100;
  $('#chat-messages').animate({ scrollTop: 0 }, speed);
}

function clearCommentErrors(delay) {
  setTimeout(function() {
    $('.chat-errors').fadeOut('fast', function() {
      $(this).remove();
    });
  }, delay);
}

$(function () {
  window.clearCommentErrors = clearCommentErrors;
  window.scrollCommentsToTop = scrollCommentsToTop;
  window.scrollCommentsToTop(true);
});
