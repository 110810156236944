import React from 'react';

const defaultPersonalization = {
  name: '',
  number: '',
};

export const newDefaultPersonalization = location => ({
  location,
  ...defaultPersonalization,
});

const Personalization = ({ name, number, location, onChange, onBlur }) => {
  const personalizationLimit =
    location.heatPressLetteringStyle === 'embroidery' ? 25 : 15;
  const onNameUpdate = e => {
    const name = e.target.value.toUpperCase();
    if (name.length > personalizationLimit) return;
    onChange({ name });
  };

  const onNumberUpdate = e => {
    const number = e.target.value;
    let reg = /^\d+$/;

    if (!number || (reg.test(number) && 0 <= number && 999 >= number))
      onChange({ number });
  };

  const nameInput = location.heatPressType == 'name_only' && (
    <React.Fragment>
      <label>Name on {location.location}</label>
      <input type="text" value={name} onChange={onNameUpdate} onBlur={onBlur} />
    </React.Fragment>
  );

  const numberInput = location.heatPressType == 'number_only' && (
    <React.Fragment>
      <label>Number on {location.location}</label>
      <input
        type="text"
        pattern="[0-9]*"
        value={number}
        step="1"
        min="0"
        max="999"
        onChange={onNumberUpdate}
        onBlur={onBlur}
      />
    </React.Fragment>
  );

  return (
    <div className="form-compact--small form-light form-light--bordered mbs">
      {nameInput}
      {numberInput}
    </div>
  );
};

export default Personalization;
