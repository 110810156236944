import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { escapeRegExp } from 'lodash';
import Loader from './loader';
import { localGet, localPutJSON } from '../fetch-local';
import DragHandleSmall from './DragHandleSmall';
import FilterSearch from './FilterSearch';
import Notification from './notification';
import WarningIcon from './WarningIcon';

const warningText =
  'This image will not show up on universitytees.com ' +
  "until you've added a primary image.";

const productNotShownOnMarketing = product => product.imagePrimaryUrl === null;

const marketingIndexUrl = '/marketing_products.json';

const getTableStyle = isDraggingOver => ({
  background: isDraggingOver ? 'none' : 'none',
});

const getRowStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  background: isDragging ? '#ECF8F8' : 'none',
  boxShadow: isDragging ? '5px 5px 5px 0px rgba(0,0,0,0.01)' : 'none',
  padding: '0.25rem',
  borderRadius: '5px',
  ...draggableStyle,
});

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default function MarketingProductsManager() {
  const [products, setProducts] = useState(null);
  const [search, setSearch] = useState('');

  useEffect(() => {
    localGet(marketingIndexUrl).then(data => {
      setProducts(data.products);
    });
  }, []);

  function onDragEnd(result) {
    if (!result.destination) return;

    const reorderedProducts = reorder(
      products,
      result.source.index,
      result.destination.index
    );

    localPutJSON(marketingIndexUrl, {
      products: reorderedProducts.reduce(
        (result, product, newIndex) => ({
          ...result,
          [product.id]: { marketing_index: newIndex },
        }),
        {}
      ),
    }).then(data => {
      setProducts(data.products);
    });

    setProducts(reorderedProducts);
  }

  const isSearching = search !== '';
  const isDragDisabled = isSearching;

  const filteredProducts = products
    ? products.filter(product => {
        const text = `${product.name} ${product.brandAndStyle}`;
        const query = escapeRegExp(search);
        return text.match(new RegExp(query, 'gi'));
      })
    : [];

  const notShownProducts = products
    ? products.filter(productNotShownOnMarketing)
    : [];

  return (
    <div className="mtl">
      <Loader isActive={products === null} />

      {products && (
        <div>
          <FilterSearch
            value={search}
            onChange={e => setSearch(e.target.value)}
            onClear={() => setSearch('')}
            small
          />

          <hr />

          <p>
            <small>{products.length} total products</small>
            {notShownProducts.length > 0 && (
              <small>
                , {notShownProducts.length} products not currently shown on
                universitytees.com
              </small>
            )}
          </p>

          {isSearching && (
            <div className="mvl">
              <Notification small type="warning">
                <strong>Please note:</strong> you may not reorder products while
                filtering.
              </Notification>
            </div>
          )}

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getTableStyle(snapshot.isDraggingOver)}
                >
                  {filteredProducts.map((product, index) => (
                    <Draggable
                      key={product.id}
                      draggableId={product.id}
                      index={index}
                      isDragDisabled={isDragDisabled}
                    >
                      {(provided, snapshot) => {
                        const dragHandleProps = isDragDisabled
                          ? { style: { opacity: '0.3', cursor: 'not-allowed' } }
                          : provided.dragHandleProps;

                        const handleTitle = isDragDisabled
                          ? 'You may not reorder products while filtering'
                          : 'Drag this item to preferred position';

                        return (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={getRowStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <div className="flex-rows flex-rows--baseline">
                              <div className="mrm">
                                <DragHandleSmall
                                  dragProps={dragHandleProps}
                                  title={handleTitle}
                                />
                              </div>

                              {productNotShownOnMarketing(product) && (
                                <WarningIcon warningText={warningText} />
                              )}

                              <a href={product.links.edit}>
                                {product.brandAndStyle}
                              </a>

                              <span className="txt-muted">
                                <span>&nbsp;— </span>
                                <span>{product.name}</span>
                              </span>
                            </div>
                          </div>
                        );
                      }}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      )}
    </div>
  );
}
