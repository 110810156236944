import React, { useState } from 'react';
import Table from './Table';
import { filter, find } from 'lodash';
import Select from 'react-select';
import Loader from './loader';
import Notification from './notification';
import {
  localPostJSON,
  localDelete,
  extractErrorMessage,
} from '../fetch-local';

const ExemptionEligibilityManager = props => {
  const { divisions, departments, apparelClasses, states } = props;

  const [division, setDivision] = useState(null);
  const [department, setDepartment] = useState(null);
  const [apparelClass, setApparelClass] = useState(null);
  const [state, setState] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const initialColumns = [
    {
      id: 'division',
      label: 'Division',
      value: ({ division }) => division.name,
      render: ({ division }) => division.name,
      cellProps: { className: 'txt-center' },
    },
    {
      id: 'department',
      label: 'Department',
      value: o => o.department && o.department.name,
      render: o => (o.department ? o.department.name : '-'),
      cellProps: { className: 'txt-center' },
    },
    {
      id: 'apparel_class',
      label: 'Apparel Class',
      value: o => o.apparelClass && o.apparelClass.name,
      render: o => (o.apparelClass ? o.apparelClass.name : '-'),
      cellProps: { className: 'txt-center' },
    },
    {
      id: 'state',
      label: 'State',
      value: o => o.state.label,
      render: o => o.state.label,
      cellProps: { className: 'txt-center' },
    },
    {
      id: 'trash',
      label: '',
      value: () => null,
      render: o => (
        <React.Fragment>
          {o.isLoading ? (
            <Loader isActive={o.isLoading} />
          ) : (
            <i className="fa fa-trash" onClick={() => onDelete(o)} />
          )}
        </React.Fragment>
      ),
      cellProps: { className: 'txt-center' },
    },
  ];

  const onDelete = exemptionEligibility => {
    setExemptionEligibilities(prev =>
      prev.map(a =>
        a.id === exemptionEligibility.id ? { ...a, isLoading: true } : a
      )
    );

    localDelete(exemptionEligibility.links.self)
      .then(() => {
        setExemptionEligibilities(prev =>
          prev.filter(a => a.id !== exemptionEligibility.id)
        );
      })
      .finally(() => {
        setExemptionEligibilities(prev =>
          prev.map(a =>
            a.id === exemptionEligibility.id ? { ...a, isLoading: false } : a
          )
        );
      });
  };

  const [exemptionEligibilities, setExemptionEligibilities] = useState(
    props.exemptionEligibilities
  );
  const [columns, setColumns] = useState(initialColumns);

  const hydratedExemptionEligibilities = exemptionEligibilities.map(
    ({ id, divisionId, departmentId, apparelClassId, state, links }) => {
      const division = find(divisions, { id: divisionId });
      const department = find(departments, { id: departmentId });
      const apparelClass = find(apparelClasses, { id: apparelClassId });
      const stateOption = find(states, { value: state });
      return {
        id,
        division,
        department,
        apparelClass,
        links,
        state: stateOption,
        isLoading: false,
      };
    }
  );

  const divisionOptions = divisions.map(division => ({
    label: division.name,
    value: division.id,
  }));

  const filteredDepartments = filter(departments, { divisionId: division });
  const departmentOptions = filteredDepartments.map(department => ({
    label: department.name,
    value: department.id,
  }));

  const filteredApparelClasses = filter(apparelClasses, {
    departmentId: department,
  });
  const apparelClassOptions = filteredApparelClasses.map(apparelClass => ({
    label: apparelClass.name,
    value: apparelClass.id,
  }));

  const resetForm = () => {
    setDivision(null);
    setDepartment(null);
    setApparelClass(null);
    setState(null);
  };

  const createExemptionEligibility = () => {
    if (isLoading) return;

    setIsLoading(true);

    localPostJSON('/accounting/exemption_eligibilities', {
      exemption_eligibility: {
        division_id: division,
        department_id: department,
        apparel_class_id: apparelClass,
        state: state,
      },
    })
      .then(data => {
        setExemptionEligibilities(prev => [
          ...prev,
          { ...data, isLoading: false },
        ]);
        setError(null);
        resetForm();
      })
      .catch(err =>
        extractErrorMessage(err, 'There was an issue').then(setError)
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  const errorNotification = error ? (
    <div className="pam">
      <Notification small={true} type="alert">
        {error}
      </Notification>
    </div>
  ) : null;

  return (
    <div className="">
      <h2 className="secondary-headline">Add an Item Eligible for Exemption</h2>
      <div className="flex-rows react-select-form">
        <div className="flex-rows__item pvs">
          <Select
            options={divisionOptions}
            onChange={e => {
              setDivision(e && e.value);
              setDepartment(null);
              setApparelClass(null);
            }}
            value={division}
            placeholder="Select a division..."
          />
        </div>
        <div className="flex-rows__item pvs">
          <Select
            options={departmentOptions}
            disabled={!division}
            onChange={e => {
              setDepartment(e && e.value);
              setApparelClass(null);
            }}
            value={department}
            placeholder="Select a department..."
          />
        </div>
        <div className="flex-rows__item pvs">
          <Select
            options={apparelClassOptions}
            disabled={!department}
            onChange={e => setApparelClass(e && e.value)}
            value={apparelClass}
            placeholder="Select an apparel class..."
          />
        </div>
        <div className="flex-rows__item pvs">
          <Select
            options={states}
            value={state}
            onChange={e => setState(e ? e.value : null)}
            placeholder="Select a state..."
          />
        </div>
        <button
          className="button mlm mts"
          onClick={createExemptionEligibility}
          disabled={isLoading}
        >
          {isLoading ? <Loader isActive={isLoading} /> : 'Create'}
        </button>
      </div>
      {errorNotification}

      <hr />

      <h1 className="primary-headline">Exemption-eligible Items</h1>

      <Table
        data={hydratedExemptionEligibilities}
        columns={columns}
        tableProps={{ className: 'table' }}
        onColumnSort={(columnId, sortDirection) =>
          setColumns(prevColumns =>
            prevColumns.map(column => {
              if (column.id === columnId) {
                return { ...column, sort: sortDirection };
              } else {
                return { ...column, sort: null };
              }
            })
          )
        }
      />

      <p>
        <strong>Note:</strong> Marking exempt on this page will only allow items
        to be exempt if the application code has been modified to allow for
        exemptions in that state.
      </p>
    </div>
  );
};

export default ExemptionEligibilityManager;
