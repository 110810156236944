import React, { useState } from 'react';

export default function ExpandDetails(props) {
  const { detailsExpanded } = props;
  const [expanded, setExpanded] = useState(false);
  const buttonClicked = () => setExpanded(e => !e);

  return (
    <>
      {expanded && <>{detailsExpanded}</>}
      <button onClick={buttonClicked} className="button button--small mtl">
        {expanded ? 'Hide Breakdown' : 'See Breakdown'}
      </button>
    </>
  );
}
