import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { localGet, localPostJSON } from '../fetch-local';
import AddToBirdBankButton from './AddToBirdBankButton';
import Modal from 'react-modal';
import {
  capitalize,
  includes,
  reject,
  some,
  sortBy,
  filter,
  isNil,
} from 'lodash';
import classNames from 'classnames';
import AsyncSelect from 'react-select/lib/Async';
import Label from './Label';
import swal from 'sweetalert';

// NOTE: We were getting an error to use setAppElement to hide
// the app while the modal is open, for use by screenreaders. So
// it's currently being set to `main`.

Modal.setAppElement('.main');

export default class AddLineItemsToBirdBank extends Component {
  static propTypes = {
    orderId: PropTypes.number,
    lineItems: PropTypes.arrayOf(PropTypes.object),
    addToBirdBankUrlTemplate: PropTypes.string,
    birdBankSearchUrlTemplate: PropTypes.string,
    addLineItemsToBirdBankEnabled: PropTypes.bool,
    birdBankLineItemMaxQuantity: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      modalIsOpen: false,
      selectedItems: [],
      selectedBirdBank: null,
      birdBankSelectorEnabled: false,
      loading: false,
      canSelectIndShipItems: true,
      canSelectBulkShipItems: true,
      canSelectIndPaymentItems: true,
      canSelectBulkPaymentItems: true,
      canSelectAnotherLineItem: true,
      disableCreateBirdBankButton: true,
      disableSelectBirdBank: true,
      disableConfirmButton: true,
      checkedItemCount: 0,
    };
  }

  addToBirdBankButton = () => {
    if (this.props.lineItems.length === 0) return null;

    return (
      <>
        <AddToBirdBankButton
          onClick={this.onAddToBirdBankClicked}
          addressRequired={this.props.addressRequired}
        />
      </>
    );
  };

  handleItemCheck(lineItemId, e) {
    const checked = e.target.checked;
    this.setState(
      prevState => {
        let selectedItems;

        if (checked) {
          selectedItems = [...prevState.selectedItems, lineItemId];
        } else {
          selectedItems = reject(
            prevState.selectedItems,
            selectedItemId => selectedItemId == lineItemId
          );
        }

        let selectedItemObjects = filter(this.props.lineItems, lineItem =>
          includes(selectedItems, lineItem.id)
        );

        let canSelectIndShipItems =
          selectedItems.length === 0 ||
          some(
            selectedItemObjects,
            selectedItem => selectedItem.data.individualShip
          );

        let canSelectBulkShipItems =
          selectedItems.length === 0 ||
          some(
            selectedItemObjects,
            selectedItem => !selectedItem.data.individualShip
          );

        let canSelectIndPaymentItems =
          selectedItems.length === 0 ||
          some(
            selectedItemObjects,
            selectedItem => selectedItem.data.individualPayment
          );

        let canSelectBulkPaymentItems =
          selectedItems.length === 0 ||
          some(
            selectedItemObjects,
            selectedItem => !selectedItem.data.individualPayment
          );

        return {
          canSelectIndShipItems,
          canSelectBulkShipItems,
          canSelectIndPaymentItems,
          canSelectBulkPaymentItems,
          selectedItems,
        };
      },
      () => this.checkLineItemLimit()
    );
  }
  toggleBirdBankSelector = e => {
    e.preventDefault();

    this.setState(prevState => {
      return {
        birdBankSelectorEnabled: !prevState.birdBankSelectorEnabled,
      };
    });
  };

  loadOptions = inputValue => {
    const searchUrl = this.props.birdBankSearchUrlTemplate.replace(
      '__search__',
      inputValue
    );

    return localGet(searchUrl).then(data => {
      const results = data.map(birdBank => {
        let { closed, lineItemCount, overrideLineItemLimit } = birdBank;
        let cantAddLineItemsToBirdBank =
          !overrideLineItemLimit && !this.state.canSelectAnotherLineItem;
        let label = `${birdBank.jobNumber}: ${birdBank.name}`;

        let disabled = false;
        if (
          cantAddLineItemsToBirdBank ||
          (this.state.canSelectIndShipItems && !birdBank.individualShip) ||
          (this.state.canSelectIndPaymentItems && !birdBank.individualPayment)
        ) {
          disabled = true;
        }
        if (this.state.canSelectBulkShipItems && birdBank.individualShip) {
          disabled = true;
          label += ' - Ind. Ship';
        }
        if (
          this.state.canSelectBulkPaymentItems &&
          birdBank.individualPayment
        ) {
          disabled = true;
          label += ' - Ind. Pay';
        }

        if (closed) label += ' - Closed';

        if (birdBank.expired) label += ' - Expired';

        return {
          value: birdBank.id,
          label,
          disabled,
          closed,
          overrideLineItemLimit,
          lineItemCount,
        };
      });

      return { options: sortBy(results, ['disabled']) };
    });
  };

  handleBirdBankSelected = selectedBirdBank => {
    this.setState(
      {
        selectedBirdBank,
      },
      () => this.checkLineItemLimit()
    );
  };

  onConfirmButtonClicked = e => {
    e.preventDefault();
    if (this.state.loading) return;

    if (this.state.selectedBirdBank.closed) {
      swal(
        {
          title: 'Adding item(s) to a closed Bird Bank',
          text: 'You will not be able to change the price after it is added.',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#FE4438',
          confirmButtonText: 'Confirm',
          html: true,
          closeOnConfirm: false,
        },
        () => {
          this.confirmBirdBankSelection();
        }
      );
    } else {
      this.confirmBirdBankSelection();
    }
  };

  confirmBirdBankSelection = () => {
    this.setState({ loading: true });

    const url = this.props.addToBirdBankUrlTemplate.replace(
      '__bird_bank_id__',
      this.state.selectedBirdBank['value']
    );

    localPostJSON(url, {
      line_item_ids: this.state.selectedItems,
    })
      .then(data => {
        window.location.href = data.birdBankUrl;
      })
      .catch(error => {
        error.response.json().then(({ errors }) => {
          this.setState({ errors, loading: false });
        });
      });
  };

  handleCreateBirdBankClick = e => {
    e.preventDefault();

    if (this.state.loading || this.state.selectedItems.length === 0) return;

    this.setState(
      {
        loading: true,
        errors: [],
      },
      () => {
        localPostJSON(`/orders/${this.props.order.id}/bird_bank`, {
          line_item_ids: this.state.selectedItems,
        })
          .then(data => {
            const { success, birdBankId, editBirdBankUrl, errors } = data;

            if (success) {
              window.location.href = editBirdBankUrl;
            } else {
              // TODO: show the errors
              this.setState({ errors, loading: false });
            }
          })
          .catch(error => {
            error.response.json().then(({ errors }) => {
              this.setState({ errors, loading: false });
            });
          });
      }
    );
  };

  checkLineItemLimit = () => {
    let selectedBirdBank = this.state.selectedBirdBank;
    let hasSelectedBirdBank = !isNil(selectedBirdBank);

    let overrideLineItemLimit =
      (hasSelectedBirdBank && selectedBirdBank.overrideLineItemLimit) || false;

    let selectedBirdBankItemCount = selectedBirdBank
      ? selectedBirdBank.lineItemCount
      : 0;

    let checkedItemCount = this.state.selectedItems.length;
    let isLoading = this.state.loading;
    let noItemsSelected = checkedItemCount === 0;

    let overTheLineItemLimit =
      checkedItemCount + selectedBirdBankItemCount >
        this.props.birdBankLineItemMaxQuantity && !overrideLineItemLimit;

    let canSelectAnotherLineItem = !overTheLineItemLimit;

    let disableCreateBirdBankButton =
      isLoading ||
      noItemsSelected ||
      checkedItemCount > this.props.birdBankLineItemMaxQuantity;

    let disableSelectBirdBank = isLoading || noItemsSelected;

    let disableConfirmButton =
      isLoading ||
      noItemsSelected ||
      !hasSelectedBirdBank ||
      overTheLineItemLimit;

    let errors = !canSelectAnotherLineItem
      ? [
          `You've reached the ${this.props.birdBankLineItemMaxQuantity} item Bird Bank limit. To add another item please have a BDL override this limit.`,
        ]
      : [];

    this.setState({
      errors: errors,
      canSelectAnotherLineItem: canSelectAnotherLineItem,
      disableCreateBirdBankButton: disableCreateBirdBankButton,
      disableSelectBirdBank: disableSelectBirdBank,
      disableConfirmButton: disableConfirmButton,
      checkedItemCount: checkedItemCount,
    });
  };

  showErrors = () => {
    const { errors } = this.state;
    if (errors.length === 0) return null;

    return (
      <React.Fragment>
        {errors.map((error, index) => (
          <p className="txt-alert" key={index} style={{ maxWidth: '560px' }}>
            <i className="fa fa-exclamation-triangle mrs" />
            {error}
          </p>
        ))}
      </React.Fragment>
    );
  };

  addToBirdBankModal = () => {
    const lineItemComponents = this.props.lineItems.map(lineItem => {
      let individualShip;
      let individualPayment;

      if (lineItem.data.individualShip)
        individualShip = <Label value="Ind. Ship" spaced={false} />;

      if (lineItem.data.individualPayment)
        individualPayment = <Label value="Ind. Pay" spaced={false} />;

      let incompatableIndividualShipItems = !some([
        lineItem.data.individualShip && this.state.canSelectIndShipItems,
        !lineItem.data.individualShip && this.state.canSelectBulkShipItems,
      ]);

      let incompatableIndividualPaymentItems = !some([
        lineItem.data.individualPayment && this.state.canSelectIndPaymentItems,
        !lineItem.data.individualPayment &&
          this.state.canSelectBulkPaymentItems,
      ]);

      let disabled =
        !lineItem.data.mayAddToBirdBank ||
        incompatableIndividualShipItems ||
        incompatableIndividualPaymentItems;

      let { description, color } = lineItem.data;

      if (color) {
        color = (
          <span className="txt-muted txt-small plm">{capitalize(color)}</span>
        );
      }

      return (
        <div
          className={classNames({
            'react-modal-line-item': true,
            'react-modal-line-item--disabled': disabled,
          })}
          key={lineItem.id}
        >
          <label className="flex-rows flex-rows--center-v">
            <input
              type="checkbox"
              className="mrm"
              checked={includes(this.state.selectedItems, lineItem.id)}
              onChange={e => this.handleItemCheck(lineItem.id, e)}
              disabled={disabled}
            />
            <img
              className="mrm"
              src={lineItem.data.images[0].previewUrl}
              style={{ height: 40, width: 'auto' }}
            />
            <div>
              <p className="react-modal-line-item__title">
                {capitalize(description)}
                {color}
              </p>
              {individualShip}
              {individualPayment}
            </div>
          </label>
        </div>
      );
    });

    const birdBankItemSelector = this.state.birdBankSelectorEnabled ? (
      <React.Fragment>
        <div className="react-select-form react-modal-select">
          <div className="react-modal-select__dropdown">
            <AsyncSelect
              cache={false}
              loadOptions={this.loadOptions}
              defaultOptions
              value={this.state.selectedBirdBank}
              onChange={this.handleBirdBankSelected}
            />
          </div>
          <button
            href="#"
            className={classNames('button', {
              button: true,
              'is-disabled': this.state.disableConfirmButton,
            })}
            onClick={this.onConfirmButtonClicked}
            disabled={this.state.disableConfirmButton}
          >
            {this.state.loading ? (
              <i className="fa fa-spinner fa-spin" />
            ) : (
              'Confirm'
            )}
          </button>
        </div>
      </React.Fragment>
    ) : null;

    return (
      <Modal
        isOpen={this.state.modalIsOpen}
        className="react-modal"
        overlayClassName="react-modal-overlay"
        onRequestClose={this.closeModal}
        contentLabel="Example Modal"
        closeTimeoutMS={200}
      >
        <div className="react-modal-header flex-rows flex-rows--space-b">
          <h4 className="react-modal-header__title">
            Select the items to add to a Bird Bank
          </h4>
        </div>
        <div className="react-modal-body">{lineItemComponents}</div>
        <div className="react-modal-footer">
          <div className="react-modal-footer__cta-container">
            <div className="react-modal-footer__cta">
              <button
                className={classNames('react-modal-footer__cta-item', {
                  mrm: true,
                  button: true,
                  'is-disabled': this.state.disableCreateBirdBankButton,
                })}
                href="#"
                onClick={this.handleCreateBirdBankClick}
                disabled={this.state.disableCreateBirdBankButton}
              >
                {this.state.loading ? (
                  <i className="fa fa-spinner fa-spin" />
                ) : (
                  'New Bird Bank'
                )}
              </button>
              <button
                className={classNames(
                  'button--secondary react-modal-footer__cta-item mrm',
                  {
                    button: true,
                    'is-disabled': this.state.disableSelectBirdBank,
                  }
                )}
                href="#"
                onClick={this.toggleBirdBankSelector}
                disabled={this.state.disableSelectBirdBank}
              >
                Select Bird Bank
              </button>
            </div>
            <a href="#" className="button-pseudo" onClick={this.closeModal}>
              Cancel
            </a>
          </div>
          {this.showErrors()}
          {birdBankItemSelector}
        </div>
      </Modal>
    );
  };

  onAddToBirdBankClicked = () => {
    this.setState({
      modalIsOpen: true,
    });
  };

  closeModal = e => {
    this.setState({ modalIsOpen: false });
    e.preventDefault();
  };

  render() {
    return (
      <>
        {this.addToBirdBankButton()}
        {this.addToBirdBankModal()}
      </>
    );
  }
}
