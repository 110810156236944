$('.art-revision-popup').on('click', function(e) {
  let salesRepEmail = $('.art-revision-popup').data('salesRepEmail');
  let linkURL = $(this).attr('href');

  e.preventDefault();
  swal(
    {
      title: 'Are you sure?',
      text:
        "<p class='mbm'><strong>" +
        'By proceeding with an art revision your order will no longer be ' +
        'considered a reorder!' +
        '</strong></p>' +
        '<p>' +
        'Reorder status only applies if the artwork and ' +
        'apparel stay unchanged.' +
        '</p>' +
        '<hr>' +
        '<small><strong>Questions?</strong> <br>' +
        'Email: <a href=mailto:' +
        salesRepEmail +
        '>' +
        salesRepEmail +
        '</a><br>',
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Go Back',
      confirmButtonColor: '#FE4438',
      confirmButtonText: 'Proceed with Revision',
      html: true,
      customClass: 'rush-alert',
      closeOnConfirm: false,
    },
    function() {
      window.location.href = linkURL;
    }
  );
});
