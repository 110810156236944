import React, { useState, useRef, useCallback, useEffect } from 'react';
import classnames from 'classnames';
import { NewTask } from './types';
import TextField from '../TextField';
import Loader from '../loader';
import { extractErrorMessage } from '../../fetch-local';
import ErrorBox from '../ErrorBox';
import { Error } from '../../base-types';
import AttachmentsUploader, { NewUpload } from '../AttachmentsUploader';
import { some } from 'lodash';
import { LoadState } from '../../remote-data-types';
import uniqueId from '../../uniqueId';

interface Props {
  addTask: (task: NewTask) => Promise<any>;
  onCancel: () => void;
}

const createNewTask = (): NewTask => ({
  key: uniqueId('task'),
  description: '',
  completed: false,
  viewed: false,
  attachments: [],
});

const DEFAULT_SERVER_ERROR = 'Something went wrong, please try again.';

export default function AddTaskForm(props: Props) {
  const { addTask } = props;
  const descriptionEl = useRef(null);

  const [newTask, setNewTask] = useState<NewTask>(createNewTask());
  const [errors, setErrors] = useState<Error[]>([]);
  const [loading, setLoading] = useState(false);
  const [uploads, setUploads] = useState<NewUpload[]>([]);

  const focusDescriptionField = () => {
    if (descriptionEl && descriptionEl.current) {
      // @ts-ignore: Object is possibly 'null'
      descriptionEl.current.focus();
    }
  };

  useEffect(focusDescriptionField, []);

  const onCreate = async () => {
    setLoading(true);
    try {
      await addTask(newTask);
      setNewTask(createNewTask());
      setErrors([]);
    } catch (error) {
      const descriptions: string[] = await extractErrorMessage(
        error,
        DEFAULT_SERVER_ERROR
      );
      setErrors(descriptions);
    } finally {
      setLoading(false);
      focusDescriptionField();
    }
  };

  const onCancel = () => {
    setNewTask(createNewTask());
    setErrors([]);
    props.onCancel();
  };

  const notAllSuccesses = some(
    uploads,
    u => u.status.state !== LoadState.Success
  );

  const onUploadsChange = useCallback(us => {
    setUploads(us);
    setNewTask(nt => ({
      ...nt,
      attachments: us.reduce((accum, curr) => {
        if (curr.status.state !== LoadState.Success) return accum;
        return [...accum, curr.status.data];
      }, []),
    }));
  }, []);

  const disableSubmission =
    loading || newTask.description === '' || notAllSuccesses;
  const disableCancel = loading;

  return (
    <AttachmentsUploader key={newTask.key} onChange={onUploadsChange}>
      {({ buttonView, uploadsView }) => (
        <div
          className={classnames(
            'callout-light',
            'callout-rounded',
            'callout-light--bordered',
            'pam',
            'mtm',
            {
              'callout--dragged-over': false,
            }
          )}
        >
          <ErrorBox errors={errors} />

          <label htmlFor="new-task-description" className="better-label">
            Description
          </label>

          <div className="flex-rows">
            <TextField
              ref={descriptionEl}
              value={newTask.description}
              onChange={e => {
                const description = e.target.value;
                setNewTask(t => ({ ...t, description }));
              }}
              onSubmit={onCreate}
              onCancel={onCancel}
              disabled={loading}
            />

            <div className="uploader">{buttonView}</div>
          </div>

          <div className="mtm">{uploadsView}</div>

          <div className="mtm">
            <button
              className="button button--small"
              onClick={onCreate}
              disabled={disableSubmission}
            >
              Add
            </button>

            <Loader isActive={loading} />

            <button
              className="button-naked txt-small"
              onClick={onCancel}
              disabled={disableCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </AttachmentsUploader>
  );
}
