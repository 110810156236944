import React, { useState, useEffect, useRef } from 'react';
import { formatMoney } from '../../../utility';
import { useToggle } from '../../custom-hooks';
import dropin from 'braintree-web-drop-in';
import { localGet, localPostJSON } from '../../../fetch-local';
import Loader from '../../loader';
import ReviewAndPayItem from './ReviewAndPayItem';

const ReviewAndPay = ({
  birdBank,
  signup,
  paymentEnabled,
  updateSignupLineItem,
  saveSignupLineItem,
  deleteSignupLineItem,
  editable,
  updateSignup,
  failedToSave,
  errorMessage,
}) => {
  const { signupLineItems, subtotal, salesTax, amountDue } = signup;
  const paymentFormElement = useRef();

  const [
    paymentMethodRequestable,
    togglePaymentMethodRequestable,
  ] = useToggle();

  const [braintreeDropinInstance, setBraintreeDropinInstance] = useState();
  const [loading, toggleLoading] = useToggle(false);

  const [braintreeClientToken, setBraintreeClientToken] = useState();
  const [venmoKey, setVenmoKey] = useState();
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    localGet(signup.links.newPaymentPath).then(data => {
      setBraintreeClientToken(data.clientToken);
      setVenmoKey(data.venmoKey);
    });
  }, []);

  useEffect(() => {
    if (
      !braintreeClientToken ||
      !venmoKey ||
      !paymentFormElement.current ||
      signup['dirty']
    )
      return;

    dropin
      .create({
        authorization: braintreeClientToken,
        card: {
          cardholderName: {
            required: true,
          },
        },
        venmo: {
          allowNewBrowserTab: true,
          profileId: venmoKey,
        },
        paypal: { flow: 'vault' },
        applePay: {
          displayName: 'University Tees, Inc.',
          paymentRequest: {
            total: {
              label: 'University Tees, Inc.',
              amount: signup.amountDue,
            },
            // We recommend collecting billing address information, at minimum
            // billing postal code, and passing that billing postal code with all
            // Apple Pay transactions as a best practice.
            requiredBillingContactFields: ['postalAddress'],
          },
        },
        container: paymentFormElement.current,
      })
      .then(dropinInstance => {
        setBraintreeDropinInstance(dropinInstance);

        dropinInstance.on('paymentMethodRequestable', () => {
          togglePaymentMethodRequestable(true);
        });
        dropinInstance.on('noPaymentMethodRequestable', () => {
          togglePaymentMethodRequestable(false);
        });
      })
      .catch(err => console.error(err));
  }, [braintreeClientToken, venmoKey, paymentFormElement.current]);

  const itemInputs = signupLineItems.map(item => {
    let itemProps = {
      ...item,
      birdBank,
      updateSignupLineItem,
      saveSignupLineItem,
      deleteSignupLineItem,
    };
    return <ReviewAndPayItem key={item.id} {...itemProps} />;
  });

  const { individualShip, individualPayment } = birdBank;

  const buttonText = `${individualPayment ? 'Purchase' : 'Complete Order'}`;

  const { containsMultipleOrders } = signup;

  const shipSeparately = individualShip && containsMultipleOrders && (
    <div className="txt-small txt-warning txt-em">
      Your items may ship separately and arrive at different times
    </div>
  );

  const paymentForm = paymentEnabled && (
    <React.Fragment>
      <div ref={paymentFormElement} />
      <button
        className="bb-button mtm"
        disabled={!paymentMethodRequestable || loading}
      >
        {buttonText}
      </button>
    </React.Fragment>
  );

  const termsOfServiceAgreement = (
    <p className="mvs">
      <small>
        By clicking "{buttonText}" I verify that I’ve read the{' '}
        <a
          href="https://universitytees.com/faqs"
          target="_blank"
          rel="noreferrer"
        >
          terms of service
        </a>{' '}
        and understand the restrictions on the ability to cancel this order.
      </small>
    </p>
  );

  const completeOrderButton = !paymentEnabled && (
    <button className="bb-button mtm">{buttonText}</button>
  );

  const checkoutWithoutPayment = () => {
    toggleLoading(true);
    localPostJSON(signup.links.createPath, {
      signup: {
        no_charge: signup.noCharge,
      },
      complete_checkout: true,
    }).then(data => {
      window.location.href = data.redirectUrl;
    });
  };

  const checkoutWithBraintree = () => {
    braintreeDropinInstance.requestPaymentMethod(
      (requestPaymentMethodErr, payload) => {
        if (requestPaymentMethodErr) {
          togglePaymentMethodRequestable(false);
          return;
        }

        localPostJSON(signup.links.createSignupPaymentPath, {
          total: signup.amountDue,
          sales_tax: signup.salesTax,
          payment_method_nonce: payload.nonce,
        })
          .then(data => {
            window.location.href = data.redirectUrl;
          })
          .catch(error => {
            error.response.json().then(({ messages }) => {
              setErrors(messages);
              toggleLoading(false);
            });
          });
      }
    );
  };

  const handleSubmit = e => {
    e.preventDefault();

    if ((paymentEnabled && !paymentMethodRequestable) || loading) {
      return;
    }

    toggleLoading(true);

    signup.signupLineItems.forEach(signupLineItem => {
      if (!signupLineItem.outOfStock) return;
      deleteSignupLineItem(signupLineItem.id);
    });

    if (paymentEnabled) checkoutWithBraintree();
    else checkoutWithoutPayment();
  };

  const noChargeCheckbox = editable ? (
    <div className="input-checkbox pbl">
      <input
        type="checkbox"
        value={signup.noCharge}
        name="no_charge_signup"
        onChange={e => updateSignup({ noCharge: e.target.checked })}
      />{' '}
      No Charge?
    </div>
  ) : null;

  const totals = paymentEnabled && (
    <React.Fragment>
      <div className="bb-subtotals">
        <div className="bb-totals-item">
          <p className="bb-shopping-bag-subtotal">Subtotal</p>
          <p className="bb-shopping-bag-subtotal">{formatMoney(subtotal)}</p>
        </div>
        <div className="bb-totals-item">
          <p className="bb-shopping-bag-subtotal">Sales Tax</p>
          <p className="bb-shopping-bag-subtotal">{formatMoney(salesTax)}</p>
        </div>
      </div>
      <div className="bb-totals-item mbl">
        <p className="bb-shopping-bag-total phn">Pending Amount</p>
        <p className="bb-shopping-bag-total phn">{formatMoney(amountDue)}</p>
      </div>
    </React.Fragment>
  );

  const failureMessage = failedToSave && (
    <div className="bb-notification bb-notification--warning">
      <p className="man">
        There was a problem saving your information. Please check the fields and
        try again. If it still does not save, reload the page and try again.
      </p>
      {errorMessage && <p>{errorMessage}</p>}
    </div>
  );

  const showErrors = (
    <React.Fragment>
      {errors &&
        errors.map((error, index) => (
          <p className="txt-alert" key={index}>
            <i className="fa fa-exclamation-triangle mrs" />
            {error}
          </p>
        ))}
    </React.Fragment>
  );

  return (
    <div>
      {shipSeparately}
      {itemInputs}
      {failureMessage}

      <form onSubmit={handleSubmit}>
        {totals}

        {noChargeCheckbox}
        {paymentForm}
        {completeOrderButton}
        {!birdBank.isContract && termsOfServiceAgreement}
        {showErrors}

        <div className="pull-right pts">
          <Loader isActive={loading} />
        </div>
      </form>
    </div>
  );
};

export default ReviewAndPay;
