import React from 'react';
import classNames from 'classnames';

export enum LabelTypes {
  ALERT = 'alert',
  WARNING = 'warning',
  SUCCESS = 'success',
  PRIMARY = 'primary',
}

interface Props {
  type?: LabelTypes | null;
  backgroundColor?: string | null;
  small?: boolean;
  value: string;
}

const Label = (props: Props) => {
  const { value, small } = props;
  const t = props.type;

  const labelProps = {
    className: classNames({
      label: true,
      'label--spaced': true,
      'label--alert': t === LabelTypes.ALERT,
      'label--warning': t === LabelTypes.WARNING,
      'label--success': t === LabelTypes.SUCCESS,
      'label--notice': t === LabelTypes.PRIMARY,
      'label--small': small,
    }),
  };

  return <span {...labelProps}>{value}</span>;
};

export default Label;
