import React from 'react';
import Media from './Media';

const PriceField = props => {
  const { children, ...remaining } = props;

  const inputProps = {
    type: 'number',
    max: 999999,
    step: 0.01,
    ...remaining,
  };

  return (
    <Media object={<div className="line-item__price-field-object">$</div>}>
      <input {...inputProps} />
      <div>{children}</div>
    </Media>
  );
};

export default PriceField;
