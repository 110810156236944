import React, { useState } from 'react';
import Modal from 'react-modal';
import IconSvg from './IconSvg';
import { Color } from '../BrandColor';

// NOTE: We were getting an error to use setAppElement to hide
// the app while the modal is open, for use by screenreaders. So
// it's currently being set to `main`.

Modal.setAppElement('.main');

interface HistoryEntry {
  title: string;
  description?: string | null;
  user?: string | null;
  createdAt: string;
}

interface Props {
  licenseHistoryList: HistoryEntry[];
}

export default function CollegeLicensingHistoryModal(props: Props) {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  function handleOpen() {
    setModalIsOpen(true);
  }

  function closeModal() {
    setModalIsOpen(false);
  }

  const historyList = props.licenseHistoryList.map((item, i) => {
    return (
      <li className="mbm pbm border-bottom" key={i}>
        <div className="grid-row grid-row--gap-sm">
          <div className="grid-col-4">
            <strong>{item.title}</strong>
          </div>
          <div className="grid-col-4">
            <small>{item.user}</small>
          </div>
          <div className="grid-col-4 txt-right">
            <small>{item.createdAt}</small>
          </div>
        </div>
        {item.description && (
          <p className="mts mbn txt-muted2">
            <em className="txt-small">{item.description}</em>
          </p>
        )}
      </li>
    );
  });

  if (props.licenseHistoryList.length === 0) return null;

  return (
    <>
      <button className="button-naked" type="button" onClick={handleOpen}>
        <i className="fa fa-info-circle notify mls"></i>
        <span className="is-hidden-visually">View History</span>
      </button>

      <Modal
        isOpen={modalIsOpen}
        className="react-modal"
        overlayClassName="react-modal-overlay"
        contentLabel="Review History"
        closeTimeoutMS={200}
      >
        <div className="react-modal-header flex-rows flex-rows--space-b flex-rows--center-v">
          <h4 className="react-modal-header__title">Review History</h4>
          <button className="button-naked" type="button" onClick={closeModal}>
            <IconSvg icon="close" color={Color.Gray} />
          </button>
        </div>

        <div className="react-modal-body react-modal-content">
          <ul className="list-plain">{historyList}</ul>
        </div>
      </Modal>
    </>
  );
}
