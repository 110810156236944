(function() {
  let CheckAll = function(params) {
    let $node = params.$node,
      onClick = function(e) {
        const target = $node.context.id;
        $node.checked = true;
        $('[name*=' + target + ']').each(function() {
          if (this.checked) {
            this.checked = false;
          } else {
            this.checked = true;
          }
        });
        return false;
      },
      init = function() {
        $node.click(onClick);
      };

    init();
  };

  $('[name^="select_all"]').each(function() {
    new CheckAll({
      $node: $(this),
    });
  });
})();
