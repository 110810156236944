import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Address from '../../AddressBase';

const AddressFields = ({
  address,
  addressTwo,
  city,
  state,
  zip,
  onChange,
  onSubmit,
  failedToSave,
  errorMessage,
  addressVerified,
  toggleAddressVerified,
}) => {
  const handleChange = useCallback(
    changes => {
      const payload = {
        address: changes.addressLine1,
        addressTwo: changes.addressLine2,
        city: changes.city,
        state: changes.state,
        zip: changes.zip,
      };

      onChange(payload);
    },
    [onChange]
  );

  const failureMessage = failedToSave && (
    <div className="bb-notification bb-notification--warning mbs">
      <p className="man">
        There was a problem saving your information. Please check the fields and
        try again. If it still does not save, reload the page and try again.
      </p>
      {errorMessage && <p>{errorMessage}</p>}
    </div>
  );

  const missingFields = !(address && city && state && zip);

  return (
    <div>
      <form>
        <Address
          addressLine1={{ value: address || '', label: 'Street Address' }}
          addressLine2={{ value: addressTwo || '', label: 'Apartment or Unit' }}
          city={{ value: city || '' }}
          state={{ value: state || '' }}
          zip={{ value: zip || '' }}
          onChange={handleChange}
          spacing="small"
          onSubmitForm={(...args) => {
            toggleAddressVerified(true);
            onSubmit(...args);
          }}
          renderSubmit={onSubmitCallback => (
            <>
              {failureMessage}

              <div className="mtl">
                <button
                  className="bb-button bb-button--small"
                  disabled={missingFields}
                  onClick={e => {
                    e.preventDefault();
                    onSubmitCallback();
                  }}
                >
                  Next
                </button>
              </div>
            </>
          )}
        />
      </form>
    </div>
  );
};

AddressFields.propTypes = {
  address: PropTypes.string,
  addressTwo: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
  onChange: PropTypes.func,
};

export default AddressFields;
