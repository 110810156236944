import $ from 'jquery';

export const hiddenBy = () => {
  const dataName = 'hidden-by';

  $(`[data-${dataName}]`).each((_, element) => {
    const triggerSelector = $(element).data(dataName);
    const disableValidationSelector = $(element).data(
      'hidden-by-disable-validation'
    );

    const syncUI = () => {
      if ($(triggerSelector).length === 0) return;
      if ($(triggerSelector)[0].checked) {
        $(element).hide();
        $(disableValidationSelector).attr('formnovalidate', 'formnovalidate');
      } else {
        $(element).show();
        $(disableValidationSelector).removeAttr('formnovalidate');
      }
      $(window).resize();
    };

    syncUI();

    if ($(triggerSelector).is(':radio')) {
      const name = $(triggerSelector).attr('name');
      const allRadios = $(`[name="${name}"]`);
      allRadios.on('change', syncUI);
    } else if ($(triggerSelector).is(':checkbox')) {
      $(triggerSelector).on('change', syncUI);
    }
  });
};

export const shownBy = () => {
  const dataName = 'shown-by';

  $(`[data-${dataName}]`).each((_, element) => {
    const triggerSelector = $(element).data(dataName);

    const syncUI = () => {
      if ($(triggerSelector).length === 0) return;
      if (!$(triggerSelector)[0].checked) $(element).hide();
      else $(element).show();
      $(window).resize();
    };

    syncUI();

    if ($(triggerSelector).is(':radio')) {
      const name = $(triggerSelector).attr('name');
      const allRadios = $(`[name="${name}"]`);
      allRadios.on('change', syncUI);
    } else if ($(triggerSelector).is(':checkbox')) {
      $(triggerSelector).on('change', syncUI);
    }
  });
};
