import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * Filters a list of results by the text entered into the search box.
 */
class FilterSearch extends Component {
  render() {
    const { value, placeholder, onChange, small, onClear } = this.props;

    const inputProps = {
      value,
      placeholder,
      onChange,
      type: 'search',
    };

    const inputClasses = classNames('label-select', { 'field--small': small });

    return (
      <div className="row mbl">
        <div className="col-6 form-light form-light--bordered">
          <input className={inputClasses} {...inputProps} />
        </div>
        {onClear && (
          <div className="col-6">
            <button
              className="mts mlm button button--small button--secondary"
              onClick={this.props.onClear}
            >
              Clear Search
            </button>
          </div>
        )}
      </div>
    );
  }
}

FilterSearch.defaultProps = {
  value: '',
  placeholder: 'Search...',
  small: false,
  showClear: false,
};

FilterSearch.propTypes = {
  small: PropTypes.bool,
  onClear: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};

export default FilterSearch;
