import React, { Component } from 'react';
import { debounce } from 'lodash';
import PriceField from './PriceField';
import Loader from './loader';
import { formatMoney, extractValue, formatPercent } from '../utility';
import Notification from './notification';
import Icon from './Icon';
import { palette } from '../color';
import Media from './Media';

export default class LineItemUnitPriceEditor extends Component {
  constructor(props) {
    super(props);
    this.setPriceDebounced = debounce(props.setPrice, 2000);
  }

  componentWillUnmount() {
    this.setPriceDebounced.cancel();
  }

  onChange = event => {
    const { props } = this;
    const target = event.target;
    const value = extractValue(target);
    const name = target.name;

    const newLineItem = {
      ...props,
      data: {
        ...props.data,
        [name]: value,
      },
    };

    props.onPricingUpdate(newLineItem);
  };

  componentDidUpdate(prevProps) {
    const shouldUpdatePricing =
      prevProps.data.unitPrice !== this.props.data.unitPrice ||
      prevProps.data.isPriceOverridden !== this.props.data.isPriceOverridden;

    if (shouldUpdatePricing) {
      this.setPriceDebounced();
    }
  }

  render() {
    const {
      unitPrice,
      unitPriceMinimum,
      suggestedUnitPrice,
      isPriceOverridden,
      discount,
      totalQuantity,
      promoProduct,
      perPieceCost,
    } = this.props.data;

    const { error, isLoading } = this.props;
    const { currentUser } = this.props.meta;
    const id = `line-item-price-override-${this.props.id}`;
    const loader = isLoading && <Loader />;

    const errorAlert = error && (
      <Notification type="alert" small>
        {error}
      </Notification>
    );

    const userCanViewPricingDetails = currentUser.canViewPricingDetails;

    const suggestedPrice = userCanViewPricingDetails && (
      <small className="txt-muted2">
        <em>Suggested price: {formatMoney(suggestedUnitPrice)}</em>
      </small>
    );

    const absoluteDiscount = Math.abs(discount);

    const trueDiscount = discount >= 0;

    const trueDiscountDisplay = (
      <>
        <small
          className={
            discount >= 12
              ? 'txt-muted2 txt-alert'
              : discount === 0
              ? 'txt-muted2'
              : 'txt-muted2 txt-warning'
          }
        >
          <em>Discount: {formatPercent(absoluteDiscount)}</em>
        </small>
      </>
    );

    const upchargeDisplay = (
      <>
        <small className="txt-muted2 txt-success">
          <em>Upcharge: {formatPercent(absoluteDiscount)}</em>
        </small>
      </>
    );

    const pricingDetails = userCanViewPricingDetails && (
      <>
        {loader}
        {trueDiscount ? trueDiscountDisplay : upchargeDisplay}
      </>
    );

    const displayPricingDetails = !error && (
      <>
        <br />
        {pricingDetails}
      </>
    );

    const userCanEdit =
      (currentUser.canSetPrice && !isPriceOverridden) ||
      (isPriceOverridden && currentUser.canOverridePrice);

    const itemDetailsPresent = promoProduct && perPieceCost > 0;
    const quantityPresent = totalQuantity > 0;

    const warningMessage =
      (itemDetailsPresent || !promoProduct) && !quantityPresent
        ? 'quantity'
        : 'item details';

    const pricingWarning = userCanEdit ? (
      <Media object={<Icon type="warning" color={palette.warning} />}>
        Update {warningMessage} to manage price
      </Media>
    ) : (
      <em>
        <small>Quantity has not yet been set.</small>
      </em>
    );

    const priceOverrideForm = currentUser.canOverridePrice ? (
      <label htmlFor={id} className="mbl">
        <input
          type="checkbox"
          checked={isPriceOverridden}
          id={id}
          name="isPriceOverridden"
          onChange={this.onChange}
          disabled={isLoading}
        />{' '}
        Override Price?
      </label>
    ) : null;

    const pricing = userCanEdit ? (
      <div className="form-compact form-light form-light--bordered">
        <PriceField
          value={unitPrice === null ? '' : unitPrice}
          min={unitPriceMinimum}
          name="unitPrice"
          id={`line-item-unit-price-${this.props.id}`}
          onChange={this.onChange}
          disabled={isLoading}
        >
          {loader}
          {errorAlert}
          {suggestedPrice}
          {displayPricingDetails}
        </PriceField>
      </div>
    ) : (
      <div>
        <dl className="dl-horizontal mbn">
          <dt id={`line-item-item-price-${this.props.id}`}>Item Price</dt>
          <dd className="txt-right">{formatMoney(unitPrice)}</dd>
        </dl>
        {suggestedPrice}
        {displayPricingDetails}
      </div>
    );

    const view =
      (itemDetailsPresent || !promoProduct) && quantityPresent ? (
        <div className="mbm">
          {priceOverrideForm}
          {pricing}
        </div>
      ) : (
        <div>{pricingWarning}</div>
      );

    return <div>{view}</div>;
  }
}
