import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CheckBoxField from './check-box-field';
import { localPutJSON } from '../fetch-local';

class SalesTaxObject extends Component {
  static propTypes = {
    taxObject: PropTypes.object.isRequired,
  };
  constructor(props) {
    super(props);

    this.state = {
      collecting_tax: this.props.taxObject.collecting_tax,
      charges_use_tax: this.props.taxObject.charges_use_tax,
    };
  }

  toggleTax = (taxObject, e) => {
    let taxVal = e.target.checked;
    let collecting_tax = taxVal;
    let sales_tax_id = taxObject.id;

    if (taxObject) {
      localPutJSON(`/sales_taxes/${sales_tax_id}`, {
        sales_tax: {
          collecting_tax: collecting_tax,
        },
      }).then(data =>
        this.setState({ collecting_tax: data.response.collecting_tax })
      );
    }
  };

  toggleUseTax = (taxObject, e) => {
    let useTaxVal = e.target.checked;
    let charges_use_tax = useTaxVal;
    let sales_tax_id = taxObject.id;

    if (taxObject) {
      localPutJSON(`/sales_taxes/${sales_tax_id}`, {
        sales_tax: { charges_use_tax },
      }).then(data =>
        this.setState({ charges_use_tax: data.response.charges_use_tax })
      );
    }
  };

  render() {
    let taxObject = this.props.taxObject;

    return (
      <tr>
        <td>{taxObject.state}</td>
        <td>
          <label>
            <CheckBoxField
              id={'collecting_tax_' + `${taxObject.id}`}
              name={'sales_tax[collecting_tax]'}
              checked={this.state.collecting_tax}
              onChange={this.toggleTax.bind(this, taxObject)}
            />
          </label>
        </td>
        <td>
          <label>
            <CheckBoxField
              id={'charges_use_tax_' + `${taxObject.id}`}
              name={'sales_tax[charges_use_tax]'}
              checked={this.state.charges_use_tax}
              onChange={this.toggleUseTax.bind(this, taxObject)}
            />
          </label>
        </td>
      </tr>
    );
  }
}

export default SalesTaxObject;
