import React, { useState, useEffect } from 'react';
import Promise from 'bluebird';
import { uniqBy } from 'lodash';
import { DirectUpload } from '@rails/activestorage';
import humanSize from '../humanSize';
import readFileData from '../readFileData';
import { RemoteData, LoadState, Loading, NotAsked } from '../remote-data-types';
import checksumForFile from '../checksumForFile';
import Uploader from '../Uploader';
import IconSvg from './IconSvg';
import { Color } from '../BrandColor';

type ErrorMessage = string;
type SignedBlobId = string;

type Result = RemoteData<ErrorMessage, SignedBlobId>;

export interface NewUpload {
  file: File;
  data: string;
  checksum: string;
  status: Result;
}

interface RenderArgs {
  buttonView: React.ReactNode;
  uploadsView: React.ReactNode;
}

interface Props {
  onChange: (uploads: NewUpload[]) => void;
  children?: (args: RenderArgs) => React.ReactNode;
}

const notAsked: NotAsked = {
  state: LoadState.NotAsked,
};

const inProgress = (progress: number): Loading => ({
  state: LoadState.Loading,
  progress,
});

const success = (data: string): Result => ({
  state: LoadState.Success,
  data,
});

const directUploadUri = '/rails/active_storage/direct_uploads';

const onDocumentDragOver = event => event.preventDefault();
const onDocumentDrop = event => event.preventDefault();
// const handleDragOver = event => event.preventDefault();

export default function AttachmentsUploader(props: Props) {
  useEffect(() => {
    document.addEventListener('dragover', onDocumentDragOver, false);
    document.addEventListener('drop', onDocumentDrop, false);
    return () => {
      document.removeEventListener('dragover', onDocumentDragOver);
      document.removeEventListener('drop', onDocumentDrop);
    };
  }, []);

  const [uploads, setUploads] = useState<NewUpload[]>([]);

  const { onChange } = props;

  const setUpload = (upload: NewUpload, f) => {
    setUploads(prevUploads =>
      prevUploads.map(u => (u.checksum === upload.checksum ? f(u) : u))
    );
  };

  useEffect(() => {
    const imagesToDirectUpload = uploads.filter(
      upload => upload.status.state === LoadState.NotAsked
    );

    imagesToDirectUpload.forEach(upload => {
      const delegate = new Uploader(event =>
        setUpload(upload, upload => {
          if (
            [LoadState.Success, LoadState.Failure].includes(upload.status.state)
          )
            return upload;
          return {
            ...upload,
            status: inProgress(event.loaded / event.total),
          };
        })
      );

      const directUpload = new DirectUpload(
        upload.file,
        directUploadUri,
        delegate
      );

      setUpload(upload, upload => ({
        ...upload,
        status: inProgress(0),
      }));

      directUpload.create((error, blob) => {
        if (error) {
          // TODO: handle error
        } else {
          setUpload(upload, upload => ({
            ...upload,
            status: success(blob.signed_id),
          }));
        }
      });
    });
  }, [uploads]);

  useEffect(() => onChange(uploads), [uploads, onChange]);

  const selectFiles = e => {
    const fs: File[] = Array.from(e.target.files);
    const checksumPromises = fs.map(checksumForFile);

    const newUploads = Promise.filter(
      checksumPromises,
      obj =>
        uploads.map(upload => upload.checksum).filter(cs => cs === obj.checksum)
          .length === 0
    ).map(({ file, checksum }) =>
      readFileData(file).then(data => ({
        file,
        data,
        checksum,
        status: notAsked,
      }))
    );

    Promise.all(newUploads).then(us => {
      setUploads(oldUploads =>
        uniqBy([...oldUploads, ...us], ({ checksum }) => checksum)
      );
    });
  };

  const buttonView = (
    <label className="attachment-button mlm">
      <IconSvg icon="clip" color={Color.White} />
      <input type="file" multiple={true} onChange={selectFiles} />
    </label>
  );

  const uploadsView = (
    <>
      {uploads.map(u => (
        <div
          className="upload-item flex-rows flex-rows--center-v"
          key={u.checksum}
        >
          <img src={u.data} width="30" height="30" alt="" />
          <span className="attachment-title mhm" title={u.file.name}>
            {u.file.name}
          </span>
          <button
            onClick={() => {
              setUploads(oldUploads =>
                oldUploads.filter(upload => upload.checksum !== u.checksum)
              );
            }}
            className="button-naked"
          >
            <IconSvg icon="close" color={Color.Red} />
          </button>
        </div>
      ))}
    </>
  );

  const children =
    props.children ||
    (args => (
      <div>
        {args.buttonView}
        {args.uploadsView}
      </div>
    ));

  return <>{children({ buttonView, uploadsView })}</>;
}
