import React, { Component } from 'react';
import { escapeRegExp, find, sortBy } from 'lodash';
import Table, { SORT_ASC } from './Table';
import FilterSearch from './FilterSearch';
import ArtDashboardJobs from './ArtDashboardJobs';
import { localGet, localPutJSON } from '../fetch-local';
import { LOADING, SUCCESS, FAILURE } from './MultiStateLoader';
import { makeAction } from '../utility';

const UPDATE_ARTISTS = 'UPDATE_ARTISTS';
const UPDATE_ARTIST_DETAILS = 'UPDATE_ARTIST_DETAILS';
const UPDATE_ART_JOB = 'UPDATE_ART_JOB';
const UPDATE_FILTER = 'UPDATE_FILTER';
const SORT_COLUMN = 'SORT_COLUMN';
const UPDATE_ARTIST = 'UPDATE_ARTIST';

const artistReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_ART_JOB:
      return {
        ...state,
        relationships: {
          ...state.relationships,
          artJobs: state.relationships.artJobs.map(j => {
            if (j.id === action.artJobId) {
              return { ...j, ...action.data };
            } else return j;
          }),
        },
      };

    default:
      return state;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_ARTIST:
      return {
        ...state,
        data: state.data.map(a => {
          if (a.id === action.artistId) {
            return { ...a, ...action.data };
          } else return a;
        }),
      };

    case UPDATE_ARTISTS:
      return {
        ...state,
        data: state.data.map(a => {
          const updatedArtist = find(action.data, na => na.id === a.id);

          const updatedAttributes = updatedArtist
            ? updatedArtist.attributes
            : {};

          return {
            ...a,
            attributes: {
              ...a.attributes,
              ...updatedAttributes,
            },
          };
        }),
      };

    case UPDATE_ARTIST_DETAILS:
      return {
        ...state,
        data: state.data.map(a => {
          if (a.id === action.artistId) {
            const artist = {
              ...action.artist,
              relationships: {
                ...action.artist.relationships,
                artJobs: sortBy(
                  action.artist.relationships.artJobs.map(j => ({
                    ...j,
                    attributes: {
                      ...j.attributes,
                      artDueDate: Date.parse(j.attributes.artDueDate),
                    },
                  })),
                  j => j.attributes.artDueDate
                ),
              },
            };

            return { ...a, ...artist, isExpanded: true, isLoading: false };
          } else {
            return { ...a, isExpanded: false };
          }
        }),
      };

    case SORT_COLUMN:
      return {
        ...state,
        columns: state.columns.map(column => {
          if (column.id === action.columnId) {
            return { ...column, sort: action.sortDirection };
          } else {
            return { ...column, sort: null };
          }
        }),
      };

    case UPDATE_FILTER:
      return {
        ...state,
        filterQuery: action.filter,
      };

    case UPDATE_ART_JOB:
      return {
        ...state,
        data: state.data.map(a => {
          if (a.id === action.artistId) return artistReducer(a, action);
          else return a;
        }),
      };

    default:
      return state;
  }
};

class ArtDashboard extends Component {
  dispatch(action) {
    this.setState(prevState => reducer(prevState, action));
  }

  constructor(props) {
    super(props);

    this.onColumnSort = this.onColumnSort.bind(this);
    this.onFilterUpdate = this.onFilterUpdate.bind(this);
    this.expandedViewForArtist = this.expandedViewForArtist.bind(this);
    this.loadArtJobsForArtist = this.loadArtJobsForArtist.bind(this);
    this.onArtistChange = this.onArtistChange.bind(this);
    this.onArtTierChange = this.onArtTierChange.bind(this);
    this.collapseArtist = this.collapseArtist.bind(this);
    this.dispatch = this.dispatch.bind(this);

    const columns = [
      {
        id: 1,
        label: 'Artist',
        value: o => o.attributes.name.lastName.toLowerCase(),
        render: o =>
          o.attributes.numberOfRequestedArt +
            o.attributes.numberOfRequestedRevisions >
          0 ? (
            <div>
              <button
                type="button"
                onClick={() =>
                  o.isExpanded
                    ? this.collapseArtist(o.id)
                    : this.loadArtJobsForArtist(o)
                }
                className={
                  'button-naked button-colored pll prn pvn' +
                  (o.isExpanded ? ' icon-expand-open' : ' icon-expand-closed')
                }
              >
                {o.attributes.name.firstName + ' ' + o.attributes.name.lastName}
              </button>
            </div>
          ) : (
            <div>
              <button
                className={
                  'button-naked txt-muted txt-em pll' +
                  (o.isExpanded ? ' icon-expand-open' : '')
                }
                onClick={() =>
                  o.isExpanded ? this.collapseArtist(o.id) : null
                }
              >
                {o.attributes.name.firstName + ' ' + o.attributes.name.lastName}
              </button>
            </div>
          ),
        renderExtra: this.expandedViewForArtist,
        headerCellProps: {
          className: 'txt-left',
        },
        sort: SORT_ASC,
      },
      {
        id: 2,
        label: 'Requested Art',
        value: o => o.attributes.numberOfRequestedArt,
        cellProps: { className: 'txt-center' },
      },
      {
        id: 3,
        label: 'Requested Revisions',
        value: o => o.attributes.numberOfRequestedRevisions,
        cellProps: { className: 'txt-center' },
      },
      {
        id: 4,
        label: 'Blocked',
        value: o => o.attributes.numberOfBlocked,
        cellProps: { className: 'txt-center' },
      },
    ];

    this.state = { data: props.data, columns, filterQuery: '' };
  }

  collapseArtist(artistId) {
    this.dispatch(
      makeAction(UPDATE_ARTIST, {
        artistId,
        data: { isExpanded: false },
      })
    );
  }

  loadArtJobsForArtist(artist) {
    this.dispatch(
      makeAction(UPDATE_ARTIST, {
        artistId: artist.id,
        data: { isLoading: true },
      })
    );

    localGet(artist.links.self).then(data => {
      this.dispatch(
        makeAction(UPDATE_ARTIST_DETAILS, { artistId: artist.id, artist: data })
      );
    });
  }

  onArtTierChange(newArtTier, artJobId, artistId) {
    const artist = find(this.state.data, ({ id }) => id === artistId);
    const artJob = find(
      artist.relationships.artJobs,
      ({ id }) => id === artJobId
    );

    const fetch = localPutJSON(artJob.links.self, {
      art_job: {
        art_tier: newArtTier,
      },
    });

    this.dispatch(
      makeAction(UPDATE_ART_JOB, {
        artistId,
        artJobId,
        data: {
          artTierLoadingState: { type: LOADING },
          operationState: { type: 'NORMAL' },
        },
      })
    );

    fetch
      .then(() => {
        this.dispatch(
          makeAction(UPDATE_ART_JOB, {
            artistId,
            artJobId,
            data: {
              attributes: {
                ...artJob.attributes,
                artTier: newArtTier,
              },
              artTierLoadingState: { type: SUCCESS },
              operationState: { type: 'NORMAL' },
            },
          })
        );
      })
      .catch(error => {
        this.dispatch(
          makeAction(UPDATE_ART_JOB, {
            artistId,
            artJobId,
            data: {
              artTierLoadingState: { type: FAILURE, messsage: error.message },
              operationState: { type: 'NORMAL' },
            },
          })
        );
      });
  }

  onArtistChange(newArtistId, artJobId, artistId, undo = false) {
    const artist = find(this.state.data, ({ id }) => id === artistId);
    const artJob = find(
      artist.relationships.artJobs,
      ({ id }) => id === artJobId
    );

    const fetch = localPutJSON(artJob.links.self, {
      art_job: {
        artist_id: newArtistId,
      },
    });

    this.dispatch(
      makeAction(UPDATE_ART_JOB, {
        artistId,
        artJobId,
        data: {
          attributes: {
            ...artJob.attributes,
            artistId: newArtistId,
          },
          artistLoadingState: { type: LOADING },
          operationState: { type: 'NORMAL' },
        },
      })
    );

    fetch
      .then(() => {
        this.dispatch(
          makeAction(UPDATE_ART_JOB, {
            artistId,
            artJobId,
            data: {
              artistLoadingState: { type: SUCCESS },
              operationState: undo
                ? { type: 'NORMAL' }
                : { type: 'UNDO', artistId },
            },
          })
        );

        localGet(this.props.links.self).then(data => {
          this.dispatch(makeAction(UPDATE_ARTISTS, { data }));
        });
      })
      .catch(error => {
        this.dispatch(
          makeAction(UPDATE_ART_JOB, {
            artistId,
            artJobId,
            data: {
              artistLoadingState: { type: FAILURE, messsage: error.message },
              operationState: { type: 'NORMAL' },
            },
          })
        );
      });
  }

  expandedViewForArtist(o) {
    if (o.isExpanded && o.relationships && o.relationships.artJobs) {
      return (
        <tr key={o.id}>
          <td colSpan="4" className="well">
            <ArtDashboardJobs
              artist={o}
              artists={this.state.data}
              onArtistChange={this.onArtistChange}
              onArtTierChange={this.onArtTierChange}
              key={o.id}
            />
          </td>
        </tr>
      );
    } else if (o.isLoading) {
      return (
        <tr key={o.id}>
          <td colSpan="4" className="well">
            <i className="fa fa-circle-o-notch fa-spin" />
          </td>
        </tr>
      );
    } else {
      return null;
    }
  }

  onColumnSort(columnId, sortDirection) {
    this.dispatch(makeAction(SORT_COLUMN, { columnId, sortDirection }));
  }

  onFilterUpdate(e) {
    this.dispatch(makeAction(UPDATE_FILTER, { filter: e.target.value }));
  }

  render() {
    const data = this.state.data.filter(o => {
      const text =
        o.attributes.name.firstName + ' ' + o.attributes.name.lastName;
      const query = escapeRegExp(this.state.filterQuery);
      return text.match(new RegExp(query, 'gi'));
    });

    const artDashboardProps = {
      ...this.state,
      data,
      onColumnSort: this.onColumnSort,
      tableProps: { className: 'table' },
    };

    return (
      <div>
        <FilterSearch
          value={this.state.filterQuery}
          onChange={this.onFilterUpdate}
        />
        <Table {...artDashboardProps} />
      </div>
    );
  }
}

export default ArtDashboard;
