import React, { FunctionComponent } from 'react';
import ActionMenu from './ActionMenu';
import ArchiveCardIcon, {
  Props as ArchiveCardIconProps,
} from './ArchiveCardIcon';

interface Props extends ArchiveCardIconProps {
  viewOrderPath: string;
  showArchive?: boolean;
  archiveFormPath: string;
}

const OpportunityOrderMenu: FunctionComponent<Props> = ({
  viewOrderPath,
  showArchive = false,
  archiveFormPath,
  archiveReasons = [],
  cardWrapperSelector,
  columnTotalPriceSelector,
  columnType,
  filterAttributes,
}) => {
  return (
    <ActionMenu dropdownRight dropdownSmall>
      <a href={viewOrderPath}>
        <i className="fa fa-arrow-circle-right fa-fw"></i> View order
      </a>

      {showArchive && (
        <ArchiveCardIcon
          archiveFormPath={archiveFormPath}
          archiveReasons={archiveReasons}
          cardWrapperSelector={cardWrapperSelector}
          columnTotalPriceSelector={columnTotalPriceSelector}
          columnType={columnType}
          filterAttributes={filterAttributes}
        />
      )}
    </ActionMenu>
  );
};

export default OpportunityOrderMenu;
