import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import ItemForm from './item-form';
import { debounce } from 'lodash';
import { formatPercentV2, formatMoney, humanize } from '../../utility';

export default function Item({
  item,
  hideCommissioningData,
  fullAccess,
  onRemove,
  onUpdate,
  products,
}) {
  const [isEditing, setIsEditing] = React.useState(false);
  const [actual, setActual] = React.useState(item.actual);
  const maximumDiscountExceeded = useMemo(() => {
    return actual < item.minimumPrice;
  }, [item.minimumPrice, actual]);

  const handleSubmit = updatedItem => {
    onUpdate({ ...item, ...updatedItem, actual });
    setIsEditing(false);
  };

  return (
    <tbody>
      {!isEditing && (
        <>
          <tr>
            <td>
              <span className="responsive-show">Item: </span>
              {item.description}
            </td>
            <td>
              <span className="responsive-show">Suggested: </span>
              {formatMoney(item.suggestedPrice)}
            </td>
            <td>
              <span className="responsive-show">Actual: </span>
              <input
                className="inline-actual"
                onBlur={() => handleSubmit(item)}
                onChange={({ target: { value } }) =>
                  setActual(parseFloat(value))
                }
                type="number"
                step="any"
                min="0"
                required={true}
                value={actual || ''}
              />
            </td>
            <td>
              {!hideCommissioningData ? (
                <>
                  <span className="responsive-show">Commission:</span>
                  {formatMoney(item.cmCommission)}
                </>
              ) : (
                ' '
              )}
            </td>
            <td>
              {(item.actual !== item.suggestedPrice ||
                !hideCommissioningData) && (
                <>
                  <span className="responsive-show">Discount:</span>
                  <span
                    className={maximumDiscountExceeded && ' label label--alert'}
                  >
                    {formatPercentV2(item.discount)}
                  </span>
                </>
              )}
            </td>
            <td>
              <span className="responsive-show">Total Sale: </span>
              {formatMoney(item.totalSale)}
            </td>
            <td className="txt-right">
              <button className="button-naked" onClick={() => onRemove(item)}>
                <i className="fa fa-times-circle txt-alert" />
              </button>
            </td>
            <td className="txt-right">
              <button
                className="button-naked"
                onClick={() => setIsEditing(!isEditing)}
              >
                <i className="fa fa-pencil" />
              </button>
            </td>
          </tr>
          <tr>
            <td className="price-calc-details" colSpan="8">
              <span className="mrm">
                <strong>Quantity: </strong>
                {item.quantity}
              </span>
              <span className="mrm">
                <strong>Bag/Tag: </strong>
                {item.isBagAndTag ? 'Yes' : 'No'}
              </span>
              <span className="mrm">
                <strong>Ind Pay: </strong>
                {item.isIndividualPayment ? 'Yes' : 'No'}
              </span>
              <span className="mrm">
                <strong>Ind. Ship: </strong>
                {item.isIndividualShip ? 'Yes' : 'No'}
              </span>
              <span className="mrm">
                <strong>Brand Tagging: </strong>
                {item.isHeatTagging ? 'Yes' : 'No'}
              </span>
              <span
                className={'mrm ' + (hideCommissioningData ? 'is-hidden' : '')}
              >
                <strong>Shipping Type: </strong>
                {humanize(item.shippingType)}
              </span>
              <span
                className={
                  'mrm ' +
                  (hideCommissioningData || !fullAccess ? 'is-hidden' : '')
                }
              >
                <strong>Base Price: </strong>
                {formatMoney(item.price)}
              </span>
              <span
                className={
                  'mrm ' +
                  (hideCommissioningData || !fullAccess ? 'is-hidden' : '')
                }
              >
                <strong>Margin: </strong>
                {formatPercentV2(item.margin)}
              </span>
            </td>
          </tr>
        </>
      )}
      {isEditing && (
        <tr>
          <td colSpan="7">
            <ItemForm
              item={item}
              products={products}
              onSubmit={handleSubmit}
              submitButtonText="Save"
              isExistingProduct
            />
          </td>
        </tr>
      )}
    </tbody>
  );
}
