import React, { Component } from 'react';
import { sum, get } from 'lodash';
import Notification from './notification';

export default class MinimumQuantityAlert extends Component {
  constructor() {
    super();
    this.minQuantity = 12;
  }

  quantityDifferenceMessage() {
    if (get(this.props, ['order', 'order', 'isBusiness'])) return '';

    const minPiecesOverridden = get(
      this.props,
      ['order', 'order', 'minPiecesOverridden'],
      false
    );

    const lineItems = get(this.props, ['order', 'lineItems']);
    let quantity = undefined;

    if (lineItems) {
      quantity = sum(lineItems.map(lineItem => lineItem.data.totalQuantity));
    }

    if (minPiecesOverridden || !quantity || quantity >= this.minQuantity)
      return '';

    const quantityNeededToOrder = this.minQuantity - quantity;
    const itemsWord = quantityNeededToOrder === 1 ? 'piece' : 'pieces';

    return quantityNeededToOrder + ' ' + itemsWord;
  }

  render() {
    if (!this.quantityDifferenceMessage()) return '';

    return (
      <Notification type="alert">
        The total quantity can't be less than {this.minQuantity}. Add at least
        <strong> {this.quantityDifferenceMessage()} </strong>
        in order to checkout.
      </Notification>
    );
  }
}
