import React, { Component } from 'react';
import { debounce } from 'lodash';
import { localGet } from '../fetch-local';
import ProgressMeter from './ProgressMeter';

const PasswordStrengthMeter = props => {
  const passwordIsStrong = props.strength === 1;

  const label = passwordIsStrong ? (
    <em className="txt-small txt-muted">
      Password is <strong className="txt-success">good to go</strong>.
      <i className="fa fa-check-circle txt-success mlm" aria-hidden="true" />
    </em>
  ) : (
    <em className="txt-small txt-muted">
      Password is <strong className="txt-alert">too weak</strong>
    </em>
  );

  return (
    <React.Fragment>
      <ProgressMeter
        value={props.strength}
        type={passwordIsStrong ? 'success' : 'error'}
        graded
      />
      {label}
    </React.Fragment>
  );
};

export default class PasswordField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      strength: null,
    };
    this.setStrengthDebounced = debounce(this.setStrength, 100);
  }

  componentWillUnmount() {
    this.setStrengthDebounced.cancel();
  }

  handleChange = e => {
    this.setState({ value: e.target.value });
    this.setStrengthDebounced();
  };

  setStrength() {
    const { value } = this.state;

    localGet(`/password_strength?password=${value}`).then(data =>
      this.setState({ strength: data.passwordStrength })
    );
  }

  render() {
    const { strength, value } = this.state;
    const showStrengthMeter = strength !== null && strength > 0;

    return (
      <React.Fragment>
        <input
          type="password"
          name={this.props.name}
          id={this.props.id}
          value={value}
          autoComplete={this.props.autocomplete}
          onChange={this.handleChange}
        />
        {showStrengthMeter && <PasswordStrengthMeter strength={strength} />}
      </React.Fragment>
    );
  }
}
