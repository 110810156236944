import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { includes, some } from 'lodash';

const states = [
  {
    value: 'NY',
    label: 'NY'
  },
  {
    value: 'OH',
    label: 'OH'
  },
];

const LineItemPromoProductStateTaxExemptionEditor = (props) => {
  const { exemptionEligibilities } = props;

  const [selectedStates, setSelectedStates] = useState(
    () => exemptionEligibilities.map(o => o.state)
  );

  const existingExemptionEligibilityInputs = exemptionEligibilities.map(
    ({ id, state }, index) => {
      const prefix = [
        props.prefix,
        '[promo_product_exemption_eligibilities_attributes]',
        '[', index, ']'
      ].join('');

      let removed = !includes(selectedStates, state);
      return (
        <React.Fragment key={`existing-${index}`}>
          <input type="hidden" name={`${prefix}[id]`} value={id} />
          <input type="hidden" name={`${prefix}[state]`} value={state} />
          { removed && 
              <input type="hidden" name={`${prefix}[_destroy]`} value="1" /> }
        </React.Fragment>
      );
    }
  );

  const newExemptionEligibilityInputs = selectedStates.map((state, index) => {
    if (some(exemptionEligibilities, { state: state })) return;

    const prefix = [
      props.prefix,
      '[promo_product_exemption_eligibilities_attributes]',
      '[', index + exemptionEligibilities.length, ']'
    ].join('');

    return <input
      type="hidden"
      name={`${prefix}[state]`}
      value={ state }
      key={`new-${index}`}
    />;
  });

  const handleStateChange = (states) => {
    setSelectedStates(states.map(s => s.value));
  };

  return (
    <React.Fragment>
      {existingExemptionEligibilityInputs}
      {newExemptionEligibilityInputs}

      <label>Eligible for sales tax exemption in:</label>

      <Select
        options={states}
        value={selectedStates}
        multi={true}
        placeholder="Select a state..."
        onChange={handleStateChange}
      />
    </React.Fragment>
  );
};

LineItemPromoProductStateTaxExemptionEditor.propTypes = {
  exemptionEligibilities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      state: PropTypes.string,
    })
  )
};
LineItemPromoProductStateTaxExemptionEditor.defaultProps = {
  exemptionEligibilities: []
};

export default LineItemPromoProductStateTaxExemptionEditor;
