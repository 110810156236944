import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const CheckBoxField = (props) => {
  const {
    className,
    checked,
    valueChecked,
    valueUnchecked,
    name,
    readOnly,
    label,
    onChange,
    id
  } = props;

  const handleOnChange = (e) => {
    (onChange || Function())(e);
  };

  const valueForChecked = () => {
    return checked ? valueChecked : valueUnchecked;
  };

  let disabled = readOnly || props.disabled;
  let hiddenValue = readOnly ? (valueForChecked()) : valueUnchecked;

  return (
    <div className={`input-checkbox inline-block ${className}`}>
      <input
        type="hidden"
        name={name}
        value={hiddenValue}
      />

      <label className={classNames({
        readonly: disabled
      })}>
        <input
          id={id}
          type="checkbox"
          name={name}
          value={valueChecked}
          onChange={handleOnChange}
          disabled={disabled}
          defaultChecked={checked}
        /> {label}
      </label>
    </div>
  );
};

CheckBoxField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  valueChecked: PropTypes.string,
  valueUnchecked: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string
};

CheckBoxField.defaultProps = {
  name: '',
  valueChecked: '1',
  valueUnchecked: '0',
  checked: false,
  readOnly: false,
  className: ''
};

export default CheckBoxField;
