import React, {
  useState,
  ReactNode,
  useEffect,
  useRef,
  MouseEventHandler,
} from 'react';
import classNames from 'classnames';
import IconSvg, { Icon } from './IconSvg';
import { Color } from '../BrandColor';

type VerticalType = 'popoverTop' | 'popoverBottom';
type HorizontalType =
  | 'popoverRight'
  | 'popoverLeft'
  | 'popoverRightCollapse'
  | 'popoverLeftCollapse';

interface Props {
  children: ReactNode;
  icon: Icon;
  vertical?: VerticalType;
  horizontal?: HorizontalType;
}

const Popover = (props: Props) => {
  const { icon, children, horizontal, vertical } = props;
  const popoverRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleClick: MouseEventHandler<HTMLElement> = e => {
    e.preventDefault();
    setIsOpen(v => !v);
  };

  useEffect(() => {
    function clickOutside(e: MouseEvent) {
      if (
        isOpen &&
        popoverRef.current &&
        // @ts-ignore: Object is possibly 'null'
        !popoverRef.current.contains(e.target)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', clickOutside);
    return () => document.removeEventListener('mousedown', clickOutside);
  }, [popoverRef, isOpen]);

  const popoverClass = classNames({
    popover: true,
    stack: true,
    'popover--top': vertical === 'popoverTop',
    'popover--bottom': vertical === 'popoverBottom',
    'popover--right': horizontal === 'popoverRight',
    'popover--left': horizontal === 'popoverLeft',
    'popover--right-collapse': horizontal === 'popoverRightCollapse',
    'popover--left-collapse': horizontal === 'popoverLeftCollapse',
  });

  return (
    <div ref={popoverRef} className="popover-container">
      <button className="button-naked pan d-flex" onClick={handleClick}>
        <IconSvg icon={icon} color={Color.TealBlue} />
      </button>
      {isOpen && (
        <div className={popoverClass}>
          <p>{children}</p>
        </div>
      )}
    </div>
  );
};

export default Popover;
