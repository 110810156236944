import React from 'react';

const Checkbox = (props) => (
  <span>
    <input type="hidden" name={props.name} value="0" />
    <input type="checkbox" value="1" {...props} />
  </span>
);

export default Checkbox;
