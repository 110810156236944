import React, { useState, useEffect, useCallback, useRef } from 'react';
import { localPostJSON } from '../fetch-local';
import Loader from './loader';

interface ManufacturersWarehouse {
  id: number;
  manufacturerId: number;
  supplierId: number;
  warehouseId: number;
  transitTime?: number;
}

interface Warehouse {
  id: number;
  label: string;
  supplierId: number;
}

interface Props {
  orderId: number;
  manufacturerId: number;
  lineItemId?: number;
  warehouses: Warehouse[];
  manufacturersWarehouses: ManufacturersWarehouse[];
}

const BlankArrivalDateGroupSelect = ({
  orderId,
  manufacturerId,
  lineItemId,
  warehouses,
  ...props
}: Props) => {
  const [value, setValue] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const isLineItemLevel: boolean = !!lineItemId;
  const isOrderLevel: boolean = !isLineItemLevel;

  const updateValue = useCallback(
    (value: string) => {
      setValue(value);
      if (!value) return;

      if (isLineItemLevel) {
        window.dispatchEvent(
          new CustomEvent('lineItemBlankArrivalDateSet', {
            detail: {
              lineItemId: lineItemId,
              blankArrivalDate: value,
            },
          })
        );
      } else {
        window.dispatchEvent(
          new CustomEvent('orderBlankArrivalDateSet', {
            detail: {
              orderId: orderId,
              blankArrivalDate: value,
            },
          })
        );
      }
    },
    [isLineItemLevel, lineItemId, orderId]
  );

  useEffect(() => {
    const handleEvent = e => {
      if (e.detail.orderId !== orderId) return;

      const warehouseId = e.detail.warehouseId
        ? e.detail.warehouseId.toString()
        : null;

      const warehouse = warehouses.find(w => w.id === parseInt(warehouseId));
      const supplierId = warehouse ? warehouse.supplierId.toString() : null;

      const manufacturersWarehouse = props.manufacturersWarehouses.find(
        mfw =>
          mfw.warehouseId.toString() === warehouseId &&
          mfw.supplierId.toString() === supplierId &&
          mfw.manufacturerId.toString() === manufacturerId.toString()
      );

      setTimeout(() => {
        localPostJSON(`/orders/${orderId}/calculate_blank_arrival_date`, {
          transit_time: manufacturersWarehouse?.transitTime,
        })
          .then((data: Object) => {
            if (isOrderLevel) updateValue(data['date']);
            if (isLineItemLevel) updateValue(data['date']);
          })
          .finally(setLoading(false));
      }, 2000);
    };
    window.addEventListener('orderWarehouseSet', handleEvent);
    return () => {
      window.removeEventListener('orderWarehouseSet', handleEvent);
    };
  }, [lineItemId, orderId, isLineItemLevel, isOrderLevel, updateValue]);

  useEffect(() => {
    const handleEvent = e => {
      if (!lineItemId) return;
      if (e.detail.lineItemId !== lineItemId) return;

      const warehouseId = e.detail.warehouseId
        ? e.detail.warehouseId.toString()
        : null;

      const warehouse = warehouses.find(w => w.id === parseInt(warehouseId));
      const supplierId = warehouse ? warehouse.supplierId.toString() : null;

      const manufacturersWarehouse = props.manufacturersWarehouses.find(
        mfw =>
          mfw.warehouseId.toString() === warehouseId &&
          mfw.supplierId.toString() === supplierId &&
          mfw.manufacturerId.toString() === manufacturerId.toString()
      );
      setTimeout(() => {
        localPostJSON(`/orders/${orderId}/calculate_blank_arrival_date`, {
          transit_time: manufacturersWarehouse?.transitTime,
        })
          .then((data: Object) => {
            if (isOrderLevel) updateValue(data['date']);
            if (isLineItemLevel) updateValue(data['date']);
          })
          .finally(setLoading(false));
      }, 2000);
    };
    window.addEventListener('lineItemWarehouseSet', handleEvent);
    return () => {
      window.removeEventListener('lineItemWarehouseSet', handleEvent);
    };
  }, [lineItemId]);

  return (
    <div className="section txt-left" ref={containerRef}>
      <div className="media" style={{ gap: '1rem' }}>
        <div className="media--obj" style={{ width: '100%' }}>
          <input
            type="date"
            name="blank_arrival_date"
            id="blank_arrival_date"
            className="fill_col_values purchase-date-select"
            onChange={e => {
              const value = e ? e.target.value.toString() : e;
              updateValue(value);
              if (isLineItemLevel) updateValue(value);
            }}
            value={value || ''}
          />
        </div>
        <div className="media--obj">{loading && <Loader />}</div>
      </div>
    </div>
  );
};

export default BlankArrivalDateGroupSelect;
