import React, { useState, useRef, useCallback, useEffect } from 'react';
import classnames from 'classnames';
import { NewPost } from './types';
import TextField from '../TextField';
import Loader from '../loader';
import { extractErrorMessage } from '../../fetch-local';
import ErrorBox from '../ErrorBox';
import { Error } from '../../base-types';
import AttachmentsUploader, { NewUpload } from '../AttachmentsUploader';
import { some } from 'lodash';
import { LoadState } from '../../remote-data-types';
import uniqueId from '../../uniqueId';

interface Props {
  addPost: (post: NewPost) => Promise<any>;
  onCancel: () => void;
}

const createNewPost = (): NewPost => ({
  key: uniqueId('post'),
  message: '',
  attachments: [],
});

const DEFAULT_SERVER_ERROR = 'Something went wrong, please try again.';

export default function AddPostForm(props: Props) {
  const { addPost } = props;
  const messageEl = useRef(null);

  const [newPost, setNewPost] = useState<NewPost>(createNewPost());
  const [errors, setErrors] = useState<Error[]>([]);
  const [loading, setLoading] = useState(false);
  const [uploads, setUploads] = useState<NewUpload[]>([]);

  const focusMessageField = () => {
    if (messageEl && messageEl.current) {
      // @ts-ignore: Object is possibly 'null'
      messageEl.current.focus();
    }
  };

  useEffect(focusMessageField, []);

  const onCreate = async () => {
    setLoading(true);
    try {
      await addPost(newPost);
      setNewPost(createNewPost());
      setErrors([]);
    } catch (error) {
      const messages: string[] = await extractErrorMessage(
        error,
        DEFAULT_SERVER_ERROR
      );
      setErrors(messages);
    } finally {
      setLoading(false);
      focusMessageField();
    }
  };

  const onCancel = () => {
    setNewPost(createNewPost());
    setErrors([]);
    props.onCancel();
  };

  const notAllSuccesses = some(
    uploads,
    u => u.status.state !== LoadState.Success
  );

  const onUploadsChange = useCallback(us => {
    setUploads(us);
    setNewPost(np => ({
      ...np,
      attachments: us.reduce((accum, curr) => {
        if (curr.status.state !== LoadState.Success) return accum;
        return [...accum, curr.status.data];
      }, []),
    }));
  }, []);

  const disableSubmission =
    loading || newPost.message === '' || notAllSuccesses;
  const disableCancel = loading;

  return (
    <AttachmentsUploader key={newPost.key} onChange={onUploadsChange}>
      {({ buttonView, uploadsView }) => (
        <div
          className={classnames(
            'callout-light',
            'callout-rounded',
            'callout-light--bordered',
            'pam',
            'mtm',
            {
              'callout--dragged-over': false,
            }
          )}
        >
          <ErrorBox errors={errors} />

          <label htmlFor="new-post-message" className="better-label">
            Message
          </label>

          <div className="flex-rows">
            <TextField
              ref={messageEl}
              value={newPost.message}
              onChange={e => {
                const message = e.target.value;
                setNewPost(p => ({ ...p, message }));
              }}
              onSubmit={onCreate}
              onCancel={onCancel}
              disabled={loading}
            />

            <div className="uploader">{buttonView}</div>
          </div>

          <div className="mtm">{uploadsView}</div>

          <div className="mtm">
            <button
              className="button button--small"
              onClick={onCreate}
              disabled={disableSubmission}
            >
              Add Note
            </button>

            <Loader isActive={loading} />

            <button
              className="button-naked txt-small"
              onClick={onCancel}
              disabled={disableCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </AttachmentsUploader>
  );
}
