import React from 'react';
import classNames from 'classnames';

const Media = ({ object, children, center, reversed }) => {
  const mediaProps = {
    className: classNames({
      'media': true,
      'media--center': center,
    }),
  };

  const objectProps = {
    className: classNames({
      'media__obj': true,
      'media__obj--rev': reversed,
    }),
  };

  const bodyProps = {
    className: classNames({
      'media__body': true,
    }),
  };

  return (
    <div {...mediaProps}>
      <div {...objectProps}>
        {object}
      </div>
      <div {...bodyProps}>
        {children}
      </div>
    </div>
  );
};

export default Media;
