import React, { useState } from 'react';
import PropTypes from 'prop-types';


import CustomQuestionManager from './CustomQuestionManager';

const CustomQuestionUncontrolledWrapper = (props) => {
  const {
    editing,
    editingEnabled,
    prefix
  } = props;

  const [questions, setQuestions] = useState(props.questions);

  const onUpdateCustomQuestion = (newQuestion, qIndex) => {
    setQuestions(
      questions.map((question, index) => {
        if (index != qIndex)
          return question;

        return { ...question, question: newQuestion };
      })
    );
  };

  const onAddCustomQuestion = (newQuestion) => {
    setQuestions([...questions, { ...newQuestion }]);
  };

  const onRemoveCustomQuestion = (qIndex) => {
    setQuestions(previousQuestions => {
      if (!previousQuestions[qIndex].id)
        return previousQuestions.filter((_, i) => i !== qIndex);

      return previousQuestions.map((question, index) => {
        if (index == qIndex)
          return { ...question, _destroy: true };
        else
          return { ...question };
      });
    });
  };

  const formInputs = questions.map((question, index) => {
    return (
      <React.Fragment key={index}>
        { question.id && 
          <input
            name={`${prefix}[questions_attributes][${index}][id]`}
            type="hidden"
            value={question.id}
          /> }
        { question._destroy && 
          <input
            name={`${prefix}[questions_attributes][${index}][_destroy]`}
            type="hidden"
            value="1"
          /> }
        { !question._destroy &&
          <input
            type="hidden"
            name={`${prefix}[questions_attributes][${index}][question]`}
            value={question.question}
          /> }
      </React.Fragment>
    );

  });

  return (
    <React.Fragment>
      <CustomQuestionManager
        editing={editing}
        editingEnabled={editingEnabled}
        questions={questions}
        onChange={onUpdateCustomQuestion}
        onRemove={onRemoveCustomQuestion}
        onAdd={onAddCustomQuestion}
      />
      {formInputs}
    </React.Fragment>
  );

};

CustomQuestionUncontrolledWrapper.propTypes = {
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      question: PropTypes.string
    })
  ),
  prefix: PropTypes.string,
  editing: PropTypes.bool,
  onChange: PropTypes.func
};

CustomQuestionUncontrolledWrapper.defaultProps = {
  editing: true
};

export default CustomQuestionUncontrolledWrapper;
