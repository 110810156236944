import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { concat, find, includes, reject } from 'lodash';
import { localPostJSON } from '../fetch-local';

class SizeChartForm extends Component {
  static propTypes = {
    productId: PropTypes.number,
    availableMeasurements: PropTypes.arrayOf(
      PropTypes.shape({ key: PropTypes.string, name: PropTypes.string })
    ),
    selectedMeasurements: PropTypes.arrayOf(PropTypes.string),
    sizes: PropTypes.arrayOf(
      PropTypes.shape({
        size: PropTypes.string,
        measurements: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.string
          })
        )
      })
    )
  };

  static defaultProps = {
    sizes: []
  };

  availableMeasurements() {
    return reject(this.props.availableMeasurements, availableMeasurement =>
      includes(this.state.selectedMeasurements, availableMeasurement['key'])
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      selectedMeasurements: props.selectedMeasurements,
      sizes: props.sizes,
      saving: false
    };
  }

  handleMeasurementChange(e, sizeToUpdate, measurementToUpdate) {
    let newMeasurementValue = e.target.value;

    this.setState(prevState => {
      let sizes = prevState.sizes.map(size => {
        if (size.size !== sizeToUpdate) {
          return size;
        }

        let measurementObjToUpdate = find(size.measurements, measurement => {
          return measurement && measurement['name'] == measurementToUpdate;
        });

        if (measurementObjToUpdate)
          measurementObjToUpdate.value = newMeasurementValue.toString();
        else
          size.measurements = concat(size.measurements, {
            name: measurementToUpdate,
            value: newMeasurementValue.toString()
          });

        return size;
      });

      return { sizes: sizes };
    });
  }

  saveMeasurementData(e) {
    e.preventDefault();

    this.setState({ saving: true });
    localPostJSON(`/products/${this.props.productId}/size_charts`, {
      sizes: this.state.sizes
    }).then(() => {
      this.setState({ saving: false, saved: true }, () => {
        setTimeout(() => {
          window.location = `/products/${this.props.productId}/edit`;
        }, 500);
      });
    });
  }

  sizeRows() {
    return this.props.sizes.map(size => {
      let measurements = this.state.selectedMeasurements.map(measurement => {
        let measurementForSize = find(
          size['measurements'],
          candidateMeasurement => {
            return (
              candidateMeasurement &&
              candidateMeasurement['name'] == measurement
            );
          }
        );
        let measurementForSizeValue;

        if (measurementForSize)
          measurementForSizeValue = measurementForSize['value'];

        return (
          <td key={measurement}>
            <input
              defaultValue={measurementForSizeValue}
              onChange={e =>
                this.handleMeasurementChange(e, size.size, measurement)
              }
            />
          </td>
        );
      });
      return (
        <tr key={size.size}>
          <td key={size.size}>{size.translatedSize}</td>
          {measurements}
          <td key="placeholder" />
        </tr>
      );
    });
  }

  newMeasurementSelector() {
    let options = this.availableMeasurements().map(measurement => (
      <option value={measurement['key']} key={measurement['key']}>
        {measurement['value']}
      </option>
    ));
    return (
      <select onChange={e => this.addSelectedMeasurement(e)}>
        <option>Add a measurement</option>
        {options}
      </select>
    );
  }

  addSelectedMeasurement(e) {
    let newMeasurement = e.target.value;

    this.setState(prevState => {
      return {
        selectedMeasurements: concat(
          prevState.selectedMeasurements,
          newMeasurement
        )
      };
    });
  }

  deselectMeasurement(e, measurementName) {
    e.preventDefault();
    this.setState(prevState => {
      let newSelectedMeasurements = reject(
        prevState.selectedMeasurements,
        selectedMeasurement => selectedMeasurement == measurementName
      );

      let newSizes = prevState.sizes.map(size => {
        let newMeasurements = reject(
          size.measurements,
          measurement =>
            measurement === null || measurement['name'] == measurementName
        );
        return { ...size, measurements: newMeasurements };
      });

      return {
        selectedMeasurements: newSelectedMeasurements,
        sizes: newSizes
      };
    });
  }

  render() {
    let measurementHeadings = this.state.selectedMeasurements.map(
      selectedMeasurement => {
        let measurement = find(
          this.props.availableMeasurements,
          candidateMeasurement =>
            candidateMeasurement['key'] == selectedMeasurement
        );

        let name;

        if (measurement) name = measurement['value'];
        else name = selectedMeasurement;
        return (
          <th key={selectedMeasurement}>
            {name}
            <a onClick={e => this.deselectMeasurement(e, selectedMeasurement)}>
              <i className="fa fa-close mll" />
            </a>
          </th>
        );
      }
    );

    let statusCheck;
    if (this.state.saving)
      statusCheck = <i className="fa fa-circle-o-notch fa-spin mll" />;
    if (this.state.saved) statusCheck = <i className="fa fa-check mll" />;

    return (
      <div>
        <table className="table">
          <thead>
            <tr>
              <th>Size</th>
              {measurementHeadings}
              <th>{this.newMeasurementSelector()}</th>
            </tr>
          </thead>
          <tbody>{this.sizeRows()}</tbody>
        </table>
        <button
          disabled={this.state.saving}
          onClick={e => this.saveMeasurementData(e)}
          className="button pull-right"
        >
          Save
          {statusCheck}
        </button>
      </div>
    );
  }
}

export default SizeChartForm;
