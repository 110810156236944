import React, { useState, useEffect, useCallback } from 'react';
import { localPutJSON, localPostJSON } from '../fetch-local';
import Loader from './loader';

interface ManufacturersWarehouse {
  id: number;
  manufacturerId: number;
  supplierId: number;
  warehouseId: number;
  transitTime?: number;
}

interface Warehouse {
  id: number;
  supplierId: number;
}

interface Props {
  name: string;
  orderId: number;
  lineItemId: number;
  lineItemSizeId: number;
  initialSelectedSupplierId: number;
  initialBlankArrivalDate: string;
  manufacturerId: number;
  warehouses: Warehouse[];
  manufacturersWarehouses: ManufacturersWarehouse[];
}

const BlankArrivalDateSelect = ({
  name,
  orderId,
  lineItemId,
  lineItemSizeId,
  initialSelectedSupplierId,
  initialBlankArrivalDate,
  manufacturerId,
  warehouses,
  ...props
}: Props) => {
  const [value, setValue] = useState<string | null>(
    initialBlankArrivalDate ? initialBlankArrivalDate.toString() : null
  );
  const [loading, setLoading] = useState(false);

  const saveValue = useCallback(
    (value: string | null, persist: boolean = true) => {
      setValue(value);
      if (!persist) return;
      const payload = {
        order: {
          line_items_attributes: {
            0: {
              id: lineItemId,
              line_item_sizes_attributes: {
                0: { id: lineItemSizeId, blank_arrival_date: value },
              },
            },
          },
        },
      };

      localPutJSON(`/orders/purchase_apparel/${orderId}`, payload).finally(() =>
        setLoading(false)
      );
    },
    [lineItemId, lineItemSizeId, orderId]
  );

  useEffect(() => {
    const setOrderBlankArrivalDate = e => {
      if (e.detail.orderId.toString() !== orderId.toString()) return;
      const warehouseId = e.detail.warehouseId
        ? e.detail.warehouseId.toString()
        : null;
      const warehouse = warehouses.find(w => w.id === parseInt(warehouseId));
      const supplierId = warehouse ? warehouse.supplierId.toString() : null;

      const manufacturersWarehouse = props.manufacturersWarehouses.find(
        mfw =>
          mfw.warehouseId.toString() === warehouseId &&
          mfw.supplierId.toString() === supplierId &&
          mfw.manufacturerId.toString() === manufacturerId.toString()
      );
      if (warehouseId === null) return;
      setTimeout(() => {
        localPostJSON(`/orders/${orderId}/calculate_blank_arrival_date`, {
          transit_time: manufacturersWarehouse?.transitTime,
        })
          .then((data: Object) => {
            saveValue(data['date']);
          })
          .finally(setLoading(false));
      }, 2000);
    };
    window.addEventListener('orderWarehouseSet', setOrderBlankArrivalDate);
    return () => {
      window.removeEventListener('orderWarehouseSet', setOrderBlankArrivalDate);
    };
  }, [lineItemId, orderId, saveValue]);

  useEffect(() => {
    const setSizeBlankArrivalDate = e => {
      if (e.detail.lineItemSizeId.toString() !== lineItemSizeId.toString())
        return;
      const warehouseId = e.detail.warehouseId
        ? e.detail.warehouseId.toString()
        : null;
      const warehouse = warehouses.find(w => w.id === parseInt(warehouseId));
      const supplierId = warehouse ? warehouse.supplierId.toString() : null;
      const manufacturersWarehouse = props.manufacturersWarehouses.find(
        mfw =>
          mfw.warehouseId.toString() === warehouseId &&
          mfw.supplierId.toString() === supplierId &&
          mfw.manufacturerId.toString() === manufacturerId.toString()
      );
      if (warehouseId === null) {
        saveValue(null, true);
      } else {
        setTimeout(() => {
          localPostJSON(`/orders/${orderId}/calculate_blank_arrival_date`, {
            transit_time: manufacturersWarehouse?.transitTime,
          })
            .then((data: Object) => {
              saveValue(data['date']);
            })
            .finally(setLoading(false));
        }, 2000);
      }
    };
    window.addEventListener(
      'lineItemSizeWarehouseSet',
      setSizeBlankArrivalDate
    );

    setValue(initialBlankArrivalDate ? initialBlankArrivalDate : null);

    return () => {
      window.removeEventListener(
        'lineItemSizeWarehouseSet',
        setSizeBlankArrivalDate
      );
    };
  }, [initialBlankArrivalDate, lineItemId, lineItemSizeId]);

  useEffect(() => {
    const setItemBlankArrivalDate = e => {
      if (!e.detail.lineItemId) return;
      if (e.detail.lineItemId.toString() !== lineItemId.toString()) return;
      const warehouseId = e.detail.warehouseId
        ? e.detail.warehouseId.toString()
        : null;
      const warehouse = warehouses.find(w => w.id === parseInt(warehouseId));
      const supplierId = warehouse ? warehouse.supplierId.toString() : null;

      const manufacturersWarehouse = props.manufacturersWarehouses.find(
        mfw =>
          mfw.warehouseId.toString() === warehouseId &&
          mfw.supplierId.toString() === supplierId &&
          mfw.manufacturerId.toString() === manufacturerId.toString()
      );
      setTimeout(() => {
        localPostJSON(`/orders/${orderId}/calculate_blank_arrival_date`, {
          transit_time: manufacturersWarehouse?.transitTime,
        })
          .then((data: Object) => {
            saveValue(data['date']);
          })
          .finally(setLoading(false));
      }, 2000);
    };
    window.addEventListener('lineItemWarehouseSet', setItemBlankArrivalDate);
    return () => {
      window.removeEventListener(
        'lineItemWarehouseSet',
        setItemBlankArrivalDate
      );
    };
  }, [lineItemId, saveValue]);

  useEffect(() => {
    const setInlineOrderBlankArrivalDate = e => {
      if (e.detail.orderId.toString() !== orderId.toString()) return;
      const blankArrivalDateStr = e.detail.blankArrivalDate
        ? e.detail.blankArrivalDate.toString()
        : null;
      saveValue(blankArrivalDateStr);
    };
    window.addEventListener(
      'orderBlankArrivalDateSet',
      setInlineOrderBlankArrivalDate
    );
    return () => {
      window.removeEventListener(
        'orderBlankArrivalDateSet',
        setInlineOrderBlankArrivalDate
      );
    };
  }, [orderId, saveValue]);

  useEffect(() => {
    const setInlineItemBlankArrivalDate = e => {
      if (!e.detail.lineItemId) return;
      if (e.detail.lineItemId.toString() !== lineItemId.toString()) return;
      const blankArrivalDateStr = e.detail.blankArrivalDate
        ? e.detail.blankArrivalDate.toString()
        : null;
      saveValue(blankArrivalDateStr);
    };
    window.addEventListener(
      'lineItemBlankArrivalDateSet',
      setInlineItemBlankArrivalDate
    );
    return () => {
      window.removeEventListener(
        'lineItemBlankArrivalDateSet',
        setInlineItemBlankArrivalDate
      );
    };
  }, [lineItemId, saveValue]);

  useEffect(() => {
    const resetBlankArrivalDate = e => {
      if (!e.detail.lineItemSizeId) return;
      if (e.detail.lineItemSizeId.toString() !== lineItemSizeId.toString())
        return;
      if (e.detail.supplierId !== null) return;
      saveValue(null, true);
    };
    window.addEventListener('lineItemSizeSupplierSet', resetBlankArrivalDate);
    return () => {
      window.removeEventListener(
        'lineItemSizeSupplierSet',
        resetBlankArrivalDate
      );
    };
  }, [lineItemSizeId, initialSelectedSupplierId, saveValue]);

  return (
    <div className="section txt-left">
      <div className="media" style={{ gap: '1rem' }}>
        <div className="media--obj" style={{ width: '100%' }}>
          <input
            type="date"
            name="blank_arrival_date"
            id="blank_arrival_date"
            className="fill_col_values purchase-date-select"
            onChange={e => {
              const value = e ? e.target.value.toString() : e;
              saveValue(value);
            }}
            value={value || ''}
          />
        </div>
        <div className="media--obj">{loading && <Loader />}</div>
      </div>
    </div>
  );
};

export default BlankArrivalDateSelect;
