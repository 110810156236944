import moment from 'moment';
import { localGet } from './fetch-local';

const $ = window.$;

const fetchHolidays = () =>
  // If an error is thrown return no holidays and carry on, in practice this
  // shouldn't happen, but if it does we may carry on and let ops handle it. No
  // reason to break the page or show an error
  localGet('/business_days.json').catch(() => ({ data: [] }));

const disabledDates = () =>
  fetchHolidays().then(response => {
    return [1, 7, ...response.data.map(ds => moment(ds).toDate())];
  });

export const setupDateInputs = () => {
  $(function() {
    let $els = $('.datepicker');

    if ($els.length > 0) {
      disabledDates().then(disable => {
        const pickadate_options = {
          format: 'mm/dd/yyyy',
          min: 3,
          disable,
        };

        $els.pickadate(pickadate_options);

        $('.datepicker[data-min]').each(function() {
          let picker = $(this).pickadate('picker');
          let [year, month, day] = $(this)
            .data('min')
            .split('-');
          let min_date = [Number(year), Number(month) - 1, Number(day)];
          picker.set('min', min_date);
        });
      });
    }
  });
};
