import React from 'react';
import Select from 'react-select';

const PrintColorSelect = props => {

  const renderColorValue = (option) => {
    return (
      <span>
        <div
          className="color-preview"
          style={{ backgroundColor: '#' + option.hex_code }}>
        </div>
        {option.label}
      </span>
    );
  };

  const selectRender = props.product ?
    <div className='react-select-form'>
      <Select
        name='print-color-select'
        placeholder='Select Item Color*'
        options={props.colorOptions}
        optionRenderer={renderColorValue}
        onChange={props.onColorChange.bind(this)}
        valueRenderer={renderColorValue}
        value={props.color}
      />
      <small>*Leave blank if all colors are out of stock</small>
    </div> : null;

  return(selectRender);
};

export default PrintColorSelect;
