import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const RadioButtonOption = (props) => {

  const { onChange, className, disabled, name, id, checked, label } = props;

  const handleOptionChange = (e) => {
    (onChange || Function())(e);
  };

  const hiddenInput = disabled ? null : (
    <input type="hidden"
      name={name}
      value="0"
      disabled={disabled}
    />
  );

  return(
    <div className={classNames({
      'input-radio': true,
      [className]: true,
      readonly: disabled
    })}>
      {hiddenInput}
      <label>
        <input type="radio"
          id={id}
          name={name}
          checked={checked}
          onChange={handleOptionChange}
          disabled={disabled}
        />&nbsp;&nbsp;
        {label}
      </label>
    </div>
  );
};

RadioButtonOption.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool
};

RadioButtonOption.defaultProps = {
  name: '',
  checked: false,
  readOnly: false,
  className: ''
};

export default RadioButtonOption;
