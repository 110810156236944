import { isNull, extend } from 'lodash';

let revisions_path = '/api/v1/locations/:location_id/revisions';
let RevisionsModel = RevisionsModelClass;

function RevisionsModelClass(location_id) {
  this.location_id = location_id;
  this.endpoint_path = revisions_path.replace(':location_id', this.location_id);
  this.revisions = [];
  this.previouslyCompleted = [];
  this.onChanges = [];

  this.getRevisions();
}

RevisionsModel.prototype.subscribe = function subscribe(onChange) {
  this.onChanges.push(onChange);
};

RevisionsModel.prototype.inform = function inform(onChange) {
  this.onChanges.forEach(function(cb) { cb(); });
};

RevisionsModel.prototype.getRevisions = function getRevisions() {
  let _this = this;
  $.ajax({
    url: this.endpoint_path,
    dataType: 'json',
    success: function(data) {
      let revisions = data.map(function(revision) {
        if (revision.status == 'complete') {
          revision.previouslyCompleted = true;
        }
        return revision;
      });

      _this.revisions = revisions.filter(function(revision) {
        return !revision.previouslyCompleted;
      });
      _this.previouslyCompleted = revisions.filter(function(revision) {
        return revision.previouslyCompleted;
      });
      _this.inform();
    }
  });
};

RevisionsModel.prototype.addRevision = function addRevision(revision, success) {
  let _this = this;
  $.ajax({
    url: this.endpoint_path,
    method: 'POST',
    data: {
      revision: revision
    },
    dataType: 'json',
    success: function(data) {
      _this.revisions.push(data);
      _this.inform();
      (success || Function())();
    }
  });
};

RevisionsModel.prototype.toggleRevision = function toggleRevision(revisionToToggle, complete, cleanupCallback) {
  let _this = this;

  if (isNull(complete)) {
    complete = !(revisionToToggle.status == 'complete');
  }
  
  $.ajax({
    url: this.endpoint_path+'/'+revisionToToggle.id,
    method: 'PUT',
    data: {
      revision: extend({}, revisionToToggle, { status: complete ? 'complete' : 'incomplete' })
    },
    dataType: 'json',
    success: function(data) {
      if (revisionToToggle.previouslyCompleted) {
        _this.previouslyCompleted = _this.previouslyCompleted.filter(function(revision) {
          return revision !== revisionToToggle;
        });
        _this.revisions.push(revisionToToggle);

      }
      _this.revisions = _this.revisions.map(function(revision) {
        return revision !== revisionToToggle ? revision : data;
      });
      _this.inform();
      (cleanupCallback || Function())();
    }
  });
};

export default RevisionsModel;
