// https://github.com/rails/rails/blob/b2eb1d1c55a59fee1e6c4cba7030d8ceb524267c/activestorage/app/javascript/activestorage/file_checksum.js

import SparkMD5 from 'spark-md5';
import Promise from 'bluebird';

const fileSlice =
  File.prototype.slice ||
  (File.prototype as any).mozSlice ||
  (File.prototype as any).webkitSlice;

class FileChecksum {
  file: File;
  chunkSize: number;
  chunkCount: number;
  chunkIndex: number;
  callback: (error?: string, digest?: string) => any;
  md5Buffer: SparkMD5.ArrayBuffer;
  fileReader: FileReader;

  static create(file: File, callback) {
    const instance = new FileChecksum(file);
    instance.create(callback);
  }

  constructor(file) {
    this.file = file;
    this.chunkSize = 2097152; // 2MB
    this.chunkCount = Math.ceil(this.file.size / this.chunkSize);
    this.chunkIndex = 0;
  }

  create(callback) {
    this.callback = callback;
    this.md5Buffer = new SparkMD5.ArrayBuffer();
    this.fileReader = new FileReader();
    this.fileReader.addEventListener('load', event =>
      this.fileReaderDidLoad(event)
    );
    this.fileReader.addEventListener('error', event =>
      this.fileReaderDidError(event)
    );
    this.readNextChunk();
  }

  fileReaderDidLoad(event) {
    this.md5Buffer.append(event.target.result);

    if (!this.readNextChunk()) {
      const binaryDigest = this.md5Buffer.end(true);
      const base64digest = btoa(binaryDigest);
      this.callback(undefined, base64digest);
    }
  }

  fileReaderDidError(event?: ProgressEvent<FileReader>) {
    this.callback(`Error reading ${this.file.name}`);
  }

  readNextChunk() {
    if (
      this.chunkIndex < this.chunkCount ||
      (this.chunkIndex === 0 && this.chunkCount === 0)
    ) {
      const start = this.chunkIndex * this.chunkSize;
      const end = Math.min(start + this.chunkSize, this.file.size);
      const bytes = fileSlice.call(this.file, start, end);
      this.fileReader.readAsArrayBuffer(bytes);
      this.chunkIndex += 1;
      return true;
    }

    return false;
  }
}

export default (file: File): Promise<{ checksum: string; file: File }> =>
  new Promise((resolve, reject) => {
    FileChecksum.create(file, (error, checksum) => {
      if (checksum) resolve({ checksum, file });
      else if (error) reject(error);
      else reject(new Error('Unkonwn error'));
    });
  });
