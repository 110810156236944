import React, { useCallback, useContext } from 'react';
import LineItemCompositeUploader from './LineItemCompositeUploader';
import { LoadState } from '../../remote-data-types';
import {
  LineItem as DecoratedLineItem,
  ArtSubmissionContext,
} from './ArtSubmissionContext';
import { AttachmentUpdate, AttachmentUpdateType } from '../../attachment-types';
import { Result } from '../../useImageDirectUpload';
import { Permissions } from '../../base-types';
import ErrorBox from '../ErrorBox';
import AutoSaveStatus from '../AutoSaveStatus';

interface Props {
  lineItem: DecoratedLineItem;
  directUploadUrl: string;
}

export default function LineItem(props: Props) {
  const { lineItem, directUploadUrl } = props;
  const { id } = lineItem;
  const { dispatch } = useContext(ArtSubmissionContext);

  const onPrimaryImageChange = useCallback<(r: Result) => any>(
    result => {
      if (result.state !== LoadState.Success) return;
      const payload = {
        type: AttachmentUpdateType.AddAttachment,
        signedId: result.data,
      };

      dispatch({
        type: 'update-line-item-primary-image-attachment',
        id,
        payload,
      });
    },
    [id, dispatch]
  );

  const onSecondaryImageChange = useCallback<(r: Result) => any>(
    result => {
      if (result.state !== LoadState.Success) return;
      const payload = {
        type: AttachmentUpdateType.AddAttachment,
        signedId: result.data,
      };

      dispatch({
        type: 'update-line-item-secondary-image-attachment',
        id,
        payload,
      });
    },
    [id, dispatch]
  );

  return (
    <section className="l-section--bordered" key={lineItem.id}>
      <AutoSaveStatus saveStatus={lineItem.saveStatus} />
      <ErrorBox errors={lineItem.errors} />

      <h3 className="mtn mbm">
        {lineItem.description}{' '}
        <span className="headline-supplement">{lineItem.color}</span>
      </h3>
      <div className="row">
        <div className="col-6 row">
          <div className="col-6">
            <h3 className="better-label mbs">Primary Image</h3>

            <LineItemCompositeUploader
              onChange={onPrimaryImageChange}
              acceptedMIMETypes={
                lineItem.attachmentValidation.allowedContentTypes
              }
              directUploadUrl={directUploadUrl}
              maxSize={lineItem.attachmentValidation.maxSize}
              existingAttachment={
                lineItem.compositePrimaryStatus ===
                AttachmentUpdateType.RemoveAttachment
                  ? null
                  : lineItem.compositePrimary
              }
              existingAttachmentUpdate={lineItem.primaryImageUpdate}
              readonly={lineItem.permissions.images === Permissions.ReadOnly}
            />

            {lineItem.permissions.images === Permissions.ReadWrite &&
              lineItem.compositePrimary &&
              lineItem.compositePrimaryStatus !==
                AttachmentUpdateType.RemoveAttachment &&
              lineItem.primaryImageUpdate.type ===
                AttachmentUpdateType.DontChangeAttachment && (
                <button
                  type="button"
                  title="Remove this image"
                  className="button button--alert button--small mtm"
                  onClick={() => {
                    let payload: AttachmentUpdate = {
                      type: AttachmentUpdateType.RemoveAttachment,
                    };

                    dispatch({
                      type: 'update-line-item-primary-image-attachment',
                      id,
                      payload,
                    });
                  }}
                >
                  Remove
                </button>
              )}
          </div>
          <div className="col-6">
            <h3 className="better-label mbs">Secondary Image</h3>

            <LineItemCompositeUploader
              onChange={onSecondaryImageChange}
              acceptedMIMETypes={
                lineItem.attachmentValidation.allowedContentTypes
              }
              directUploadUrl={directUploadUrl}
              maxSize={lineItem.attachmentValidation.maxSize}
              existingAttachment={
                lineItem.compositeSecondaryStatus ===
                AttachmentUpdateType.RemoveAttachment
                  ? null
                  : lineItem.compositeSecondary
              }
              existingAttachmentUpdate={lineItem.secondaryImageUpdate}
              readonly={lineItem.permissions.images === Permissions.ReadOnly}
            />

            {lineItem.permissions.images === Permissions.ReadWrite &&
              lineItem.compositeSecondaryStatus !==
                AttachmentUpdateType.RemoveAttachment &&
              lineItem.compositeSecondary &&
              lineItem.secondaryImageUpdate.type ===
                AttachmentUpdateType.DontChangeAttachment && (
                <button
                  type="button"
                  title="Remove this image"
                  className="button button--alert button--small mtm"
                  onClick={() => {
                    let payload: AttachmentUpdate = {
                      type: AttachmentUpdateType.RemoveAttachment,
                    };

                    dispatch({
                      type: 'update-line-item-secondary-image-attachment',
                      id,
                      payload,
                    });
                  }}
                >
                  Remove
                </button>
              )}
          </div>
        </div>
      </div>
    </section>
  );
}
