export const setSelectField = (name: string, value: string | null) => {
  const input = document.querySelector(`[name="order[${name}]"]`);

  if (!input) return;

  const descriptor = Object.getOwnPropertyDescriptor(
    window.HTMLSelectElement.prototype,
    'value'
  );

  if (!descriptor) return;

  const nativeInputValueSetter: any = descriptor.set;

  nativeInputValueSetter.call(input, value || '');

  var inputEvent = new Event('change', { bubbles: true });
  input.dispatchEvent(inputEvent);
};

export const setField = (name: string, value: string | null) => {
  const input = document.querySelector(`[name="order[${name}]"]`);

  if (!input) return;

  const descriptor = Object.getOwnPropertyDescriptor(
    window.HTMLInputElement.prototype,
    'value'
  );

  if (!descriptor) return;
  var nativeInputValueSetter: any = descriptor.set;
  nativeInputValueSetter.call(input, value || '');

  var inputEvent = new Event('input', { bubbles: true });
  input.dispatchEvent(inputEvent);
};

export const simulateMouseClick = element => {
  const mouseClickEvents = ['mousedown', 'click', 'mouseup'];
  mouseClickEvents.forEach(mouseEventType =>
    element.dispatchEvent(
      new MouseEvent(mouseEventType, {
        view: window,
        bubbles: true,
        cancelable: true,
        buttons: 1,
      })
    )
  );
};
