import React from 'react';
import { clamp } from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ProgressMeter = props => {
  const width = clamp(props.value, 0, 1.0) * 100;

  const className =  classNames({
    'progress-meter': true,
    'progress-meter--graded': props.graded,
  });

  const innerClassName = classNames({
    'progress': true,
    'progress--success': props.type === 'success',
    'progress--error': props.type === 'error',
  });

  return (
    <div className={className}>
      <div className={innerClassName} style={{ width: `${width}%` }} />
    </div>
  );
};

ProgressMeter.defaultProps = {
  value: 0,
  graded: false,
};

ProgressMeter.propTypes = {
  value: PropTypes.number,
  graded: PropTypes.bool,
  type: PropTypes.oneOf([
    'success',
    'error',
  ]),
};

export default ProgressMeter;
