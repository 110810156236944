import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { uniqueId } from 'lodash';

import Address from './Address';
import { isTest } from '../environment';

type ShippingMethodName = string;
type ShippingMethodLabel = string;

type Value = string | null;
type BooleanValue = boolean | null;

interface Props {
  artJobId: string;
  shipFromAddressId: Value;
  addressLine1: Value;
  addressLine2: Value;
  city: Value;
  state: Value;
  zip: Value;
  country: Value;
  shippingMethod: Value;
  shippingMethods: [ShippingMethodName, ShippingMethodLabel][];
  showSpecialtyShippingOptions: boolean;
  printLabelBox: boolean;
  shipFromDifferentAddress: boolean;
  shipWithAccountNumber: boolean;
  shippingAccountNumber: Value;
  shipFromBusiness: Value;
  shipFromAddressLine1: Value;
  shipFromAddressLine2: Value;
  shipFromCity: Value;
  shipFromState: Value;
  shipFromZip: Value;
  fulfillmentMessage: Value;
  internationalAddress: BooleanValue;
  allowInternational?: boolean;
}

const ShippingMethod = props => {
  const el = document.getElementById('shipping-methods-portal');
  return el && createPortal(props.children, el);
};

export default (props: Props) => {
  const {
    artJobId,
    shipFromAddressId,
    addressLine1,
    addressLine2,
    city,
    state,
    zip,
    shippingMethods,
    internationalAddress,
    country,
  } = props;

  const [shippingMethod, setShippingMethod] = useState(
    props.shippingMethod || ''
  );

  const [printLabelBox, setPrintLabelBox] = useState(props.printLabelBox);
  const [shipFromDifferentAddress, setShipFromDifferentAddress] = useState(
    props.shipFromDifferentAddress
  );
  const [shipWithAccountNumber, setShipWithAccountNumber] = useState(
    props.shipWithAccountNumber
  );
  const [shippingAccountNumber, setShippingAccountNumber] = useState(
    props.shippingAccountNumber || ''
  );
  const [shipFromBusiness, setShipFromBusiness] = useState(
    props.shipFromBusiness || ''
  );

  const thirdPartyAccountNumberUI = (
    <fieldset className="mbn">
      <input
        placeholder="example: 000-000-00-00000"
        type="text"
        name="order[art_jobs_attributes][0][shipping_account_number]"
        value={shippingAccountNumber}
        onChange={e => setShippingAccountNumber(e.target.value)}
      />
    </fieldset>
  );

  const shipFromDifferentAddressUI = (
    <>
      {shipFromAddressId && (
        <input
          type="hidden"
          value={shipFromAddressId}
          name="order[art_jobs_attributes][0][from_address_attributes][id]"
        />
      )}

      <label>Business name</label>
      <input
        placeholder="Business name"
        type="text"
        value={shipFromBusiness}
        onChange={e => setShipFromBusiness(e.target.value)}
        name="order[art_jobs_attributes][0][from_address_attributes][address_1]"
      />
      <Address
        addressLine1={{
          value: props.shipFromAddressLine1 || '',
          name:
            'order[art_jobs_attributes][0][from_address_attributes][address_2]',
          label: 'Shipping address',
        }}
        addressLine2={{
          value: props.shipFromAddressLine2 || '',
          name:
            'order[art_jobs_attributes][0][from_address_attributes][address_3]',
        }}
        city={{
          value: props.shipFromCity || '',
          name:
            'order[art_jobs_attributes][0][from_address_attributes][locality]',
        }}
        state={{
          value: props.shipFromState || '',
          name:
            'order[art_jobs_attributes][0][from_address_attributes][region]',
        }}
        zip={{
          value: props.shipFromZip || '',
          name:
            'order[art_jobs_attributes][0][from_address_attributes][postal_code]',
        }}
        disableVerification={true}
      />
    </>
  );

  const showSpecialtyShippingOptions = shippingMethod === 'specialty_shipping';

  const specialtyShippingOptionsUI = (
    <fieldset className="callout-light">
      <label className="input-checkbox">
        <input
          name="order[art_jobs_attributes][0][ship_with_account_number]"
          type="hidden"
          value={shipWithAccountNumber ? '1' : '0'}
        />
        <input
          type="checkbox"
          checked={shipWithAccountNumber}
          onChange={e => setShipWithAccountNumber(e.target.checked)}
        />{' '}
        Ship using 3rd party account number
      </label>
      {shipWithAccountNumber && thirdPartyAccountNumberUI}

      <label className="input-checkbox">
        <input
          name="order[art_jobs_attributes][0][ship_from_different_address]"
          type="hidden"
          value={shipFromDifferentAddress ? '1' : '0'}
        />
        <input
          type="checkbox"
          checked={shipFromDifferentAddress}
          onChange={e => setShipFromDifferentAddress(e.target.checked)}
        />{' '}
        Ship from a different address
      </label>
      {shipFromDifferentAddress && shipFromDifferentAddressUI}

      <label className="input-checkbox">
        <input
          name="order[art_jobs_attributes][0][print_label_box]"
          type="hidden"
          value={printLabelBox ? '1' : '0'}
        />
        <input
          type="checkbox"
          checked={printLabelBox}
          onChange={e => setPrintLabelBox(e.target.checked)}
        />{' '}
        Print box label
      </label>
    </fieldset>
  );

  const willPickUpOrder = shippingMethod === 'pick_up_order';

  return (
    <>
      {!willPickUpOrder && (
        <Address
          addressLine1={{
            value: addressLine1 || '',
            name: 'customer_address_one',
            label: 'Shipping address',
          }}
          addressLine2={{
            value: addressLine2 || '',
            name: 'customer_address_two',
          }}
          city={{ value: city || '', name: 'customer_city' }}
          state={{ value: state || '', name: 'customer_state' }}
          zip={{ value: zip || '', name: 'customer_zip' }}
          internationalAddress={{
            value: internationalAddress || false,
            name: 'customer_international_address',
          }}
          country={{
            value: country || '',
            name: 'customer_country',
          }}
          scope="order"
          preventSubmitUnlessValidated={!isTest()}
          allowInternational={props.allowInternational}
          allowInternationalToggle={false}
        />
      )}

      {willPickUpOrder && (
        <Address
          addressLine1={{
            value: '2233 W 110th St',
            name: 'customer_address_one',
            label: 'Shipping address',
          }}
          addressLine2={{
            value: '',
            name: 'customer_address_two',
          }}
          city={{ value: 'Cleveland', name: 'customer_city' }}
          state={{ value: 'OH', name: 'customer_state' }}
          zip={{ value: '44102', name: 'customer_zip' }}
          scope="order"
          readOnly={true}
          disableVerification={true}
        >
          <p>{props.fulfillmentMessage} No shipping information is required.</p>
        </Address>
      )}
      <ShippingMethod>
        <input
          type="hidden"
          value={artJobId}
          name="order[art_jobs_attributes][0][id]"
        />

        {shippingMethods.map(([name, label]) => {
          const n = 'order[art_jobs_attributes][0][shipping_method]';
          const id = uniqueId(name);
          return (
            <label className="input-radio shipping-methods" key={id}>
              <input
                type="radio"
                value={name}
                checked={name === shippingMethod}
                onChange={e => setShippingMethod(e.target.value)}
                name={n}
              />
              {label}
            </label>
          );
        })}
        {props.showSpecialtyShippingOptions &&
          showSpecialtyShippingOptions &&
          specialtyShippingOptionsUI}
      </ShippingMethod>
    </>
  );
};
