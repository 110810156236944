import React, { useState, useEffect } from 'react';

function CountDownItem({ number, label }) {
  return (
    <div className="bb-countdown-inner-content">
      <h4 className="bb-countdown-number">{number}</h4>
      <h4 className="bb-countdown-text mbn"> {label}</h4>
    </div>
  );
}

function Countdown({ birdBank }) {
  const [currentTime, setCurrentTime] = useState(Date.now());

  const closeTime = Date.parse(birdBank.willCloseAt);
  const timeRemaining = Math.max(closeTime - currentTime, 0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(Date.now());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  let countdownDays = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
  let countdownHours = Math.floor((timeRemaining / (1000 * 60 * 60)) % 24);
  let countdownMinutes = Math.floor((timeRemaining / (1000 * 60)) % 60);
  let countdownSeconds = Math.floor((timeRemaining / 1000) % 60);
  const daysWord = countdownDays === 1 ? 'Day' : 'Days';
  const hoursWord = countdownHours === 1 ? 'Hour' : 'Hours';
  const minutesWord = countdownMinutes === 1 ? 'Minute' : 'Minutes';
  const secondsWord = countdownSeconds === 1 ? 'Second' : 'Seconds';

  return (
    <div className="bb-countdown-outer-container">
      <div className="bb-countdown-container">
        <CountDownItem number={countdownDays} label={daysWord} />
        <CountDownItem number={countdownHours} label={hoursWord} />
        <CountDownItem number={countdownMinutes} label={minutesWord} />
        <CountDownItem number={countdownSeconds} label={secondsWord} />
      </div>
    </div>
  );
}

export default Countdown;
