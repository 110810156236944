import React from 'react';
import { RemoteData, LoadState } from '../remote-data-types';

type Props = RemoteData<any, any>;

const className = 'progress-bar';

function stateToClassName(state: LoadState): string {
  const prefix = `${className}--`;

  switch (state) {
    case LoadState.Loading:
      return `${prefix}--pending`;

    case LoadState.IndeterminateProgressLoading:
      return `${prefix}--pending`;

    case LoadState.Failure:
      return `${prefix}--error`;

    case LoadState.Success:
      return `${prefix}--complete`;

    default:
      return '';
  }
}

export default function ProgressBar(props: Props) {
  const { state } = props;
  let progress = 0;

  if (props.state === LoadState.Loading) progress = props.progress;

  return (
    <div className={`${className} ${stateToClassName(state)}`}>
      <span style={{ width: `${progress * 100}%` }} />
    </div>
  );
}
