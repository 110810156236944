export type RemoteData<E, D> =
  | NotAsked
  | Loading
  | IndeterminateProgressLoading
  | Failure<E>
  | Success<D>;

export enum LoadState {
  NotAsked = 'NotAsked',
  Loading = 'Loading',
  IndeterminateProgressLoading = 'IndeterminateProgressLoading',
  Failure = 'Failure',
  Success = 'Success',
}

export interface NotAsked {
  readonly state: LoadState.NotAsked;
}

export interface Loading {
  readonly state: LoadState.Loading;
  progress: number;
}

export interface IndeterminateProgressLoading {
  readonly state: LoadState.IndeterminateProgressLoading;
}

export interface Success<D> {
  readonly state: LoadState.Success;
  data: D;
}

export interface Failure<E> {
  readonly state: LoadState.Failure;
  error: E;
}
