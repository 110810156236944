//Handle Nav Change on scroll

let navLogo = $('.js-logo'),
  navDistance = 90,
  $window = $(window);

$window.on('scroll', function() {
  let top = $(document).scrollTop();
  if(top >= navDistance){
    navLogo.removeClass('logo--full');
  } else {

  }
});
