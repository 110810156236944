import React, { useState, useEffect, useMemo } from 'react';
import ProgressBar from './ProgressBar';
import humanSize from '../humanSize';
import useImageDirectUpload, { Result } from '../useImageDirectUpload';
import { LoadState } from '../remote-data-types';
import readFileData from '../readFileData';
import { containsFiles, getDataTransferItems } from '../fileUtility';
import IconSvg from './IconSvg';
import { Color } from '../BrandColor';

interface RenderArgs {
  isDraggingOver: boolean;
  buttonView: React.ReactNode;
  uploadPreview: React.ReactNode;
}

interface Props {
  directUploadUrl: string;
  acceptedMIMETypes: string[];
  maxSize: number;
  onChange: (result: Result) => any;
  children: (args: RenderArgs) => React.ReactNode;
}

const onDocumentDragOver = event => event.preventDefault();
const onDocumentDrop = event => event.preventDefault();
const handleDragOver = event => event.preventDefault();

export default function ImageDirectUploader(props: Props) {
  const { directUploadUrl, acceptedMIMETypes, maxSize, onChange } = props;

  const [file, setFile] = useState<File | null>(null);
  const [imageData, setImageData] = useState<string | null>(null);
  const [dragCounter, setDragCounter] = useState(0);

  useEffect(() => {
    if (!file) return;
    readFileData(file).then(setImageData);
  }, [file]);

  useEffect(() => {
    document.addEventListener('dragover', onDocumentDragOver, false);
    document.addEventListener('drop', onDocumentDrop, false);
    return () => {
      document.removeEventListener('dragover', onDocumentDragOver);
      document.removeEventListener('drop', onDocumentDrop);
    };
  }, []);

  const options = useMemo(
    () => ({
      acceptedMIMETypes,
      maxSize,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...acceptedMIMETypes, maxSize]
  );

  const result = useImageDirectUpload(directUploadUrl, file, options);

  useEffect(() => onChange(result), [onChange, result]);

  const onSelectFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setFile(file);
    }
  };

  let imagePreview: JSX.Element | null = null;

  if (file && imageData && result.state !== LoadState.Failure) {
    const progressBar =
      result.state === LoadState.Loading ? <ProgressBar {...result} /> : null;

    imagePreview = (
      <>
        <div className="flex-rows flex-rows--center-v">
          <img
            src={imageData}
            className="item-preview"
            width="30"
            height="30"
            alt=""
          />
          <span className="attachment-title mhm" title={file.name}>
            {file.name}
          </span>
          <button
            type="button"
            title="Remove this file"
            className="button-naked"
            onClick={() => {
              setFile(null);
              setImageData(null);
            }}
          >
            <IconSvg icon="close" color={Color.Red} />
          </button>
        </div>
        {progressBar}
      </>
    );
  }

  const errorMessage = result.state === LoadState.Failure && file && (
    <div className="notification notification--alert">{result.error}</div>
  );

  const handleDragEnter = event => {
    event.preventDefault();
    setDragCounter(c => c + 1);
  };

  const handleDragLeave = event => {
    event.preventDefault();
    setDragCounter(c => c - 1);
  };

  const handleDrop = event => {
    event.preventDefault();
    event.stopPropagation();

    const rawFileList = getDataTransferItems(event);
    const files = Array.from(rawFileList);

    if (containsFiles(event) && files.length > 0) {
      setFile(files[0]);
    }

    setDragCounter(0);
  };

  const draggableProps = {
    onDrop: handleDrop,
    onDragOver: handleDragOver,
    onDragEnter: handleDragEnter,
    onDragLeave: handleDragLeave,
  };

  const buttonView = (
    <div className="uploader">
      <div className="uploader__panel">
        <label className="attachment-button mlm" {...draggableProps}>
          <IconSvg icon="clip" color={Color.White} />
          <input
            type="file"
            accept={acceptedMIMETypes.join(',')}
            onChange={onSelectFiles}
          />
        </label>
      </div>
    </div>
  );

  const uploadPreview = (
    <div className="uploader__main">
      {imagePreview}
      {errorMessage}
    </div>
  );

  const isDraggingOver = dragCounter > 0;

  return (
    <div className="full-width" {...draggableProps}>
      {props.children({ isDraggingOver, buttonView, uploadPreview })}
    </div>
  );
}
