import PropTypes from 'prop-types';
import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import LocationDecorationTypeSelector from './location-decoration-type-selector';
import LocationEmbroideryThreadCountSelector from './location-embroidery-thread-count-selector';
import LocationSelect from './location-select';
import LocationTackleTwillFields from './location-tackle-twill-fields';
import CustomTagSelect from './custom-tag-select';
import HeatPressSelect from './heat-press-select';
import FlockingSelect from './flocking-select';
import EmbroiderySelect from './embroidery-select';
import ScreenPrintSelect from './screen-print-select';
import SupplierDecoratedSelect from './supplier-decorated-select';
import LocationFile from './location-file';
import LocationFileField from './location-file-field';
import { reject } from 'lodash';

const GlobalData = window.GlobalData;

class Location extends React.Component {
  static propTypes = {
    id: PropTypes.number,
    nonce: PropTypes.string,
    location: PropTypes.string,
    description: PropTypes.string,
    prefix: PropTypes.string,
    art_files: PropTypes.array,
    print_color_pms_codes: PropTypes.arrayOf(PropTypes.string),
    full_error_messages: PropTypes.arrayOf(PropTypes.string),
    artJob: PropTypes.object,
    locked: PropTypes.bool,
    className: PropTypes.string,
    decoration_type: PropTypes.string,
    decoration_style: PropTypes.string,
    locationsModel: PropTypes.object,
    thread_count: PropTypes.number,
    heat_press_type: PropTypes.string,
    enabled: PropTypes.bool.isRequired,
    heat_press_lettering_style: PropTypes.string,
    tackle_twill_foreground_thread_color_pms_code: PropTypes.string,
    tackle_twill_foreground_custom_fabric: PropTypes.string,
    tackle_twill_foreground_stitch_type: PropTypes.string,
    tackle_twill_background_thread_color_pms_code: PropTypes.string,
    tackle_twill_background_custom_fabric: PropTypes.string,
    tackle_twill_background_stitch_type: PropTypes.string,
    tackle_twill_layers: PropTypes.string,
    flocking_size: PropTypes.string,
    cost_per_unit: PropTypes.number,
    additional_run_cost_per_unit: PropTypes.number,
    net_setup_fee: PropTypes.number,
    canSelectNonstandardLocation: PropTypes.bool,
  };

  static defaultProps = {
    enabled: true,
  };

  state = {
    files: this.props.art_files || [],
  };

  isEmbroidery = () => {
    return this.props.decoration_type === 'embroidery';
  };

  isTackleTwill = () => {
    return this.props.decoration_type === 'tackle_twill';
  };

  hideLocationFields = () => {
    let {
      thread_count,
      tackle_twill_letters,
      tackle_twill_layers,
      tackle_twill_foreground_thread_color_pms_code,
      tackle_twill_background_thread_color_pms_code,
    } = this.props;

    return (
      (this.isEmbroidery() && !thread_count) ||
      (this.isTackleTwill() &&
        (!tackle_twill_letters ||
          !tackle_twill_layers ||
          !tackle_twill_foreground_thread_color_pms_code ||
          (tackle_twill_layers > 1 &&
            !tackle_twill_background_thread_color_pms_code)))
    );
  };

  removeImage = index_to_delete => {
    this.setState(function(previousState) {
      return {
        files: reject(previousState.files, function(file, index) {
          return index_to_delete == index;
        }),
      };
    });
  };

  files = () => {
    let _this = this;
    return this.state.files.map(function(file, index) {
      return (
        <LocationFile
          {...file}
          prefix={_this.props.prefix}
          onRemove={_this.removeImage.bind(_this, index)}
          index={index}
          key={'file_field_' + index}
        />
      );
    });
  };

  onFileAdded = file_event => {
    let _this = this;
    for (let i = 0; i < file_event.target.files.length; i++) {
      let file = file_event.target.files[i];
      let reader = new FileReader();
      let web_image_pattern = /image\/(gif|jpeg|png|svg\+xml)/i;
      let preview;
      if (!file.type.match(web_image_pattern)) {
        preview = file.name.split('.').reverse()[0];
      }
      reader.onload = (function(the_file) {
        return function(e) {
          _this.setState({
            files: _this.state.files.concat([
              {
                file_name: the_file.name,
                file_data: e.target.result,
                preview: preview,
              },
            ]),
          });
        };
      })(file);
      reader.readAsDataURL(file);
    }
  };

  render() {
    let { decoration_type, isPromoProduct, enabled } = this.props;

    let decorationType = <LocationDecorationTypeSelector {...this.props} />;

    let threadCount =
      !isPromoProduct && this.isEmbroidery() ? (
        <div key="thread_count">
          <LocationEmbroideryThreadCountSelector {...this.props} />
        </div>
      ) : null;

    let tackleTwillFields =
      decoration_type == 'tackle_twill' ? (
        <div key="tackle_twill_fields">
          <LocationTackleTwillFields {...this.props} />
        </div>
      ) : null;

    let locationSelect =
      isPromoProduct || (!this.hideLocationFields() && decoration_type) ? (
        <div key="location_fields">
          <LocationSelect
            {...this.props}
            hideColorField={this.props.decoration_type === 'tackle_twill'}
          />
        </div>
      ) : null;

    let screenPrintSelect =
      this.props.decoration_type === 'screen_print' ? (
        <ScreenPrintSelect
          prefix={this.props.prefix}
          location={this.props}
          decorationOptions={this.props.decoration_options}
          printColorPmsCodes={this.props.print_color_pms_codes}
        />
      ) : null;

    let embroiderySelect =
      this.props.decoration_type === 'embroidery' ? (
        <EmbroiderySelect
          prefix={this.props.prefix}
          location={this.props}
          decorationOptions={this.props.decoration_options}
          printColorPmsCodes={this.props.print_color_pms_codes}
        />
      ) : null;

    let customTagSelect =
      this.props.decoration_type === 'custom_tag' ? (
        <CustomTagSelect
          prefix={this.props.prefix}
          location={this.props}
          decorationOptions={this.props.decoration_options}
          printColorPmsCodes={this.props.print_color_pms_codes}
        />
      ) : null;

    let heatPressSelect =
      this.props.decoration_type === 'heat_press' ? (
        <HeatPressSelect
          prefix={this.props.prefix}
          location={this.props}
          decorationOptions={this.props.decoration_options}
          printColorPmsCodes={this.props.print_color_pms_codes}
        />
      ) : null;

    let flockingSelect =
      this.props.decoration_type === 'flocking' ? (
        <FlockingSelect
          prefix={this.props.prefix}
          location={this.props}
          decorationOptions={this.props.decoration_options}
          flockingVinylColorsPmsCodes={
            this.props.flocking_vinyl_colors_pms_codes
          }
          flockingSize={this.props.flocking_size}
          printColorPmsCodes={this.props.print_color_pms_codes}
        />
      ) : null;

    let supplierDecoratedSelect =
      this.props.decoration_type === 'supplier_decorated' ? (
        <SupplierDecoratedSelect
          prefix={this.props.prefix}
          location={this.props}
          decorationOptions={this.props.decoration_options}
          printColorPmsCodes={this.props.print_color_pms_codes}
        />
      ) : null;

    let descriptionField = this.props.locked ? (
      <p>{this.props.description}</p>
    ) : (
      <textarea
        name={this.props.prefix + '[description]'}
        className={this.props.locked ? 'disabled' : ''}
        placeholder="Description"
        ref="description"
        defaultValue={this.props.description}
        disabled={this.props.locked}
      />
    );

    const showCostPerUnit =
      this.props.decoration_type === 'lace' ||
      this.props.decoration_type === 'bow' ||
      this.props.decoration_type === 'stitching';

    let costPerUnitField =
      showCostPerUnit &&
      (this.props.locked ? (
        <p>{this.props.cost_per_unit}</p>
      ) : (
        <input
          type="number"
          name={this.props.prefix + '[cost_per_unit]'}
          step=".01"
          placeholder="Cost Per Unit (Ex: 1.25)"
          ref="cost_per_unit"
          defaultValue={this.props.cost_per_unit}
          disabled={this.props.locked}
        />
      ));

    const showNetSetupFeeField =
      this.props.decoration_type === 'supplier_decorated';

    let netSetupFeeField =
      showNetSetupFeeField &&
      (this.props.locked ? (
        <p>{this.props.new_setup_fee}</p>
      ) : (
        <>
          <label htmlFor="net_setup_fee">Net Setup Fee</label>
          <input
            type="number"
            id="net_setup_fee"
            name={this.props.prefix + '[net_setup_fee]'}
            step=".01"
            placeholder="Setup Cost (Ex: 1.25)"
            ref="net_setup_fee"
            defaultValue={this.props.net_setup_fee}
            disabled={this.props.locked}
          />
        </>
      ));

    const showAdditionalRunCostPerUnit =
      this.props.decoration_type === 'supplier_decorated';

    let additionalRunCostPerUnitField =
      showAdditionalRunCostPerUnit &&
      (this.props.locked ? (
        <p>{this.props.additional_run_cost_per_unit}</p>
      ) : (
        <>
          <label htmlFor="additional_run_cost_per_unit">
            Additional Run Cost Per Unit
          </label>
          <input
            id="additional_run_cost_per_unit"
            type="number"
            name={this.props.prefix + '[additional_run_cost_per_unit]'}
            step=".01"
            placeholder="Additional Run Cost Per Unit (Ex: 1.25)"
            ref="additional_run_cost_per_unit"
            defaultValue={this.props.additional_run_cost_per_unit}
            disabled={this.props.locked}
          />
        </>
      ));

    let locationFileRender = GlobalData.reorder ? (
      <div className="location-files">
        <small>No inspirational images are needed for reorders.</small>
      </div>
    ) : (
      <div>
        <div className="location-files">
          <h3 className="files-header">Upload Art Files</h3>
          <div>
            {this.files()}
            <LocationFileField onSelect={this.onFileAdded} />
          </div>
        </div>
        <div>
          <small>
            By uploading this file, I verify that the file and its contents do
            not infringe upon any rights of a third party and that I have
            sufficient rights to permit University Tees to copy, modify and
            create derivative works from the file and its contents.
          </small>
        </div>
      </div>
    );

    let dstFileField = GlobalData.isBusiness && (
      <div className="dst-field">
        <h3 className="files-header">Upload DST</h3>
        <input type="file" name={this.props.prefix + '[dst_file]'} />
      </div>
    );

    return (
      <TransitionGroup>
        <CSSTransition
          classNames="slide-down"
          timeout={{ exit: 100, enter: 300 }}
          className="location"
        >
          <fieldset disabled={!enabled}>
            {decorationType}
            {threadCount}
            {locationSelect}
            {tackleTwillFields}
            {flockingSelect}
            {heatPressSelect}
            {customTagSelect}
            {embroiderySelect}
            {screenPrintSelect}
            {supplierDecoratedSelect}
            {netSetupFeeField}
            {additionalRunCostPerUnitField}
            {descriptionField}
            {costPerUnitField}
            {locationFileRender}
            {dstFileField}
          </fieldset>
        </CSSTransition>
      </TransitionGroup>
    );
  }
}

export default Location;
