import React from 'react';
import Notification from './notification';
import { NotificationType } from '../base-types';

interface Props {
  messages: string[];
  type: NotificationType;
}

export default function NotificationBox({ messages, type }: Props) {
  if (messages.length === 0) return null;

  const body =
    messages.length === 1 ? (
      <p className="mam">{messages[0]}</p>
    ) : (
      <ul>
        {messages.map((e, i) => (
          <li key={i}>{e}</li>
        ))}
      </ul>
    );

  return (
    <Notification type={type} dismissible>
      {body}
    </Notification>
  );
}
