import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { fetchLocationOptions } from '../stores/global-data';
import { find } from 'lodash';

class LocationRenderer extends Component {
  static propTypes = {
    value: PropTypes.string,
    isPromoProduct: PropTypes.bool,
  };

  componentWillMount() {
    fetchLocationOptions().then(() => {
      this.setState({
        locationsLoaded: true,
      });
    });
  }

  _options = () => {
    let { isPromoProduct } = this.props;
    let options = isPromoProduct ? 'promo_locations' : 'apparel_locations';
    return window.GlobalData.locationOptions[options];
  };

  _value = () => {
    if (!window.GlobalData.locationOptions) {
      return this.props.value;
    }

    let matchedLocation = find(this._options(), {
      value: this.props.value,
    });

    if (matchedLocation) {
      return matchedLocation.label;
    } else {
      return this.props.value;
    }
  };

  render() {
    return <span>{this._value()}</span>;
  }
}

export default LocationRenderer;
