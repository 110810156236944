import { useState, useEffect } from 'react';
import {
  RemoteData,
  LoadState,
  Loading,
  Failure,
  Success,
} from './remote-data-types';
import Uploader from './Uploader';
import { DirectUpload } from '@rails/activestorage';
import validateFile from './validateFile';
import { imageMIMETypes } from './mime-types';

interface ProvidedOptions {
  acceptedMIMETypes?: string[];
  maxSize?: number;
}

interface Options {
  acceptedMIMETypes: string[];
  maxSize: number;
}

const defaultOptions = {
  acceptedMIMETypes: imageMIMETypes,
  maxSize: 200000000000,
};

// TODO: ensure the keys are all present via TS
function buildOptions(options: ProvidedOptions | undefined): Options {
  const opts = options || {};
  return { ...defaultOptions, ...opts };
}

type ErrorMessage = string;
type SignedBlobId = string;
export type Result = RemoteData<ErrorMessage, SignedBlobId>;

const buildLoadState = (progress: number): Loading => ({
  state: LoadState.Loading,
  progress: progress,
});

const buildErrorState = (message: string): Failure<ErrorMessage> => ({
  state: LoadState.Failure,
  error: message,
});

const notAsked: Result = {
  state: LoadState.NotAsked,
};

export default function useImageDirectUpload(
  directUploadUrl: string,
  file: File | null,
  opts?: ProvidedOptions
): Result {
  const [remoteData, setRemoteData] = useState<Result>(notAsked);

  const directUploadDidProgress = (event: ProgressEvent) => {
    setRemoteData(previousData => {
      const alreadyComplete = [LoadState.Success, LoadState.Failure].includes(
        previousData.state
      );
      if (alreadyComplete) return previousData;

      return buildLoadState(event.loaded / event.total);
    });
  };

  useEffect(() => {
    if (!file) {
      setRemoteData(notAsked);
      return;
    }

    const options = buildOptions(opts);

    const failure = validateFile(file, options);
    if (failure) {
      setRemoteData(failure);
      return;
    }

    const delegate = new Uploader(directUploadDidProgress);
    const upload = new DirectUpload(file, directUploadUrl, delegate);

    setRemoteData(buildLoadState(0));

    upload.create((error: Error, blob: ActiveStorage.Blob) => {
      if (error) {
        const errorMessage = `There was an issue uploading this file: ${
          error.message
        }`;
        setRemoteData(buildErrorState(errorMessage));
      } else {
        const success: Success<SignedBlobId> = {
          state: LoadState.Success,
          data: blob.signed_id,
        };
        setRemoteData(success);
      }
    });
  }, [directUploadUrl, file, opts]);

  return remoteData;
}
