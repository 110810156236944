if (!window.GlobalData) {
  window.GlobalData = {};
}

const setGlobalData = (key, value) => {
  window.GlobalData[key] = value;
};

const apiGet = url => (
  window.fetch(url, {
    credentials: 'same-origin'
  }).then(
    response => response.json()
  )
);

const fetchAndSetGlobalData = (url, key) => {
  const currentData = getGlobalData(key);

  return currentData ? new Promise(
    resolve => resolve(currentData)
  ) : apiGet(url).then(response => {
    setGlobalData(key, response);
    return response;
  });
};

const getGlobalData = (key) => {
  return window.GlobalData[key];
};

const filterData = (data, decorationType) => data.filter(printColor => (
  printColor.decoration_type == decorationType
));

export const fetchLocationOptions = () => (
  fetchAndSetGlobalData('/api/v1/locations/options', 'locationOptions')
);

export const fetchPrintColorOptions = (orderId) => {
  const currentData = getGlobalData('printColorOptions');

  if (currentData) {
    return new Promise(resolve => resolve(currentData));
  } else {
    const predicate = orderId ? `?order_id=${orderId}` : '';
    const url = `/api/v1/print_colors${predicate}`;

    return apiGet(url).then(d => {
      setGlobalData('heatPressColorOptions', filterData(d, 'heat_press'));
      setGlobalData('embroideryColorOptions', filterData(d, 'embroidery'));
      setGlobalData('printColorOptions', filterData(d, 'screen_print'));
      setGlobalData('tackleTwillColorOptions', filterData(d, 'tackle_twill'));
      setGlobalData('flockingColorOptions', filterData(d, 'flocking'));
      setGlobalData('allDecorationColorOptions', d);
    });
  }
};
