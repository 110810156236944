import { Component } from 'react';

class BFCacheDisable extends Component {
  componentWillMount() {
    window.onpageshow = function(event) {
      if (event.persisted) {
        window.location.reload();
      }
    };
  }

  render() {
    return false;
  }
}

export default BFCacheDisable;
