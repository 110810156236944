import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

class Comment extends React.Component {

  static propTypes = {
    comment: PropTypes.object
  };

  _rawMessage = () => {
    return { __html: this.props.comment.message };
  };

  _attachmentImage = () => {
    if (this.props.comment.attachment_data == null)
      return;

    let attachment_data = this.props.comment.attachment_data;
    let preview;

    if (attachment_data.attachment_meta.content_type.match(/^image/i)) {
      preview = (
        <div className="comment-attachment-preview">
          <a href={attachment_data.attachment_url} className="fancybox">
            <img src={attachment_data.attachment_card_url} width="150" height="150" />
          </a>
        </div>
      );
    }

    return (
      <div className="comment-attachment">
        {preview}
        <a href={attachment_data.attachment_url} className="comment-attachment-download" download>
          Download: {attachment_data.attachment_filename}
        </a>
      </div>
    );
  };

  render() {
    let comment = this.props.comment;
    let created_at = moment(comment.created_at).format('L LT');
    return (
      <div className="comment" key={'comment-'+comment.id}>
        <div className="comment-header">
          <span className="comment-author">
            {comment.author_full_name}
          </span>
          &nbsp;&mdash;&nbsp;
          <span className="comment-date">
            {created_at}
          </span>
        </div>
        <div className="comment-message" dangerouslySetInnerHTML={this._rawMessage()}></div>
        <div className="comment-image">
          {this._attachmentImage()}
        </div>
      </div>
    );
  }
}

export default Comment;
