import React from 'react';
import PropTypes from 'prop-types';

class Collapsible extends React.Component {
  static displayName = 'Collapsible';

  static propTypes = {
    header: PropTypes.string,
    className: PropTypes.string,
    startCollapsed: PropTypes.bool
  };

  static defaultProps = {
    className: '',
    startCollapsed: false
  };

  state = {
    collapsed: this.props.startCollapsed
  };

  _toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };

  _handleClick = (event) => {
    event.preventDefault();
    this._toggle();
  };

  render() {
    let iconClass, bodyClass;
    if (this.state.collapsed) {
      iconClass = 'fa-caret-left';
      bodyClass = 'collapsed';
    }
    else {
      iconClass = 'fa-caret-down';
    }
    return (
      <div className={'collapsible ' + this.props.className}>
        <a href="#" onClick={this._handleClick} className="collapsible-title">
          <i className={'pull-right fa ' + iconClass}></i>
          <h5 className="mbs">{this.props.header}</h5>
        </a>
        <div className={ 'collapsible-body ' + (bodyClass) }>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default Collapsible;
