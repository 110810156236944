const $ = window.$;

// https://gomakethings.com/how-to-check-if-a-browser-supports-native-input-date-pickers/#testing-for-input-type-date-support
// When supported, the date input type will discard any value that’s not part
// of a valid date.
//
// We can use this to test support by creating an input with type="date",
// trying to set it’s value to an invalid date, and checking to see if it was
// discarded or not.
//
// We’ll use document.createElement() to create an input, and setAttribute() to
// give a type of date and a value equal to a. Then, we’ll check if the value
// of the input still equals a or not and return a boolean accordingly.
function dateInputSupported() {
  const input = document.createElement('input');
  const value = 'a';
  input.setAttribute('type', 'date');
  input.setAttribute('value', value);
  return input.value !== value;
}

function dateOrUndefined(value) {
  const parsedDate = Date.parse(value);
  return isNaN(parsedDate) ? undefined : parsedDate;
}

export default function setup() {
  if (dateInputSupported()) {
    return;
  }

  $(function() {
    // .datepicker handled in app/javascripts/date-inputs.js
    $('[type="date"]:not(.datepicker)').each((_index, el) => {
      $(el).pickadate({
        format: 'yyyy-mm-dd',
        max: dateOrUndefined(el.getAttribute('max')),
        min: dateOrUndefined(el.getAttribute('min')),
      });
    });
  });
}
