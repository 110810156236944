import { LoadState, Failure } from './remote-data-types';
import humanSize from './humanSize';
import { humanizeMIMEType } from './mime-types';

interface Options {
  acceptedMIMETypes: string[];
  maxSize: number;
}

type ErrorMessage = string;

const buildErrorState = (message: string): Failure<ErrorMessage> => ({
  state: LoadState.Failure,
  error: message,
});

export default (
  file: File,
  options: Options
): Failure<ErrorMessage> | undefined => {
  const { type, size } = file;

  const { acceptedMIMETypes, maxSize } = options;

  const fileValidations = [
    {
      fn: () => size <= maxSize,
      message: `This image is too big! Please choose a file under ${humanSize(
        maxSize
      )}`,
    },
    {
      fn: () => acceptedMIMETypes.indexOf(type) >= 0,
      message: `Please upload one of the following image types: ${acceptedMIMETypes
        .map(humanizeMIMEType)
        .join(', ')}`,
    },
  ];

  const errorMessages = fileValidations.reduce(
    (r, validator) => [...r, ...(validator.fn() ? [] : [validator.message])],
    []
  );

  if (errorMessages.length > 0) return buildErrorState(errorMessages[0]);
};
