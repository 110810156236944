import React from 'react';

function FormatTextWithLineBreaks({ text }) {
  return (
    <>
      {(text || '').split(/(\r|\n)+/g).map((line, id) => (
        <p key={id}>{line}</p>
      ))}
    </>
  );
}
export default FormatTextWithLineBreaks;
