import c3 from 'c3';
import d3 from 'd3';

let charts = $('[data-chart-sales]');

// Date formatting
let currentMonth = (new Date().getMonth()) + 1;
let currentYear = new Date().getFullYear();
let pastYear = parseInt(currentYear - 1);
let currentMonthYear = currentMonth + '/' + currentYear;
let pastMonthYear = currentMonth + '/' + pastYear;

charts.each(function () {
  let node = $(this);
  let {
    current,
    goal,
    title,
    label
  } = node.data('chart-sales');

  let goalAdjusted = Math.max((current / goal) * 100, 0);
  let goalRounded = Math.round(goalAdjusted * 100) / 100;

  c3.generate({
    bindto: node[0],
    data: {
      columns: [
        [label, goalRounded]
      ],
      type: 'gauge'
    },
    gauge: {
    },
    color: {
      pattern: ['#c31b02', '#e06e2b', '#d5ab3b', '#5cba5c'], // the four color levels for the percentage values.
      threshold: {
        values: [30, 60, 90, 100]
      }
    }
  });
});

let barCharts = $('[data-chart-bar]');

barCharts.each(function () {
  let node = $(this);
  let {
    past,
    current
  } = node.data('chart-bar');

  c3.generate({
    bindto: node[0],
    data: {
      columns: [
        [pastMonthYear, past],
        [currentMonthYear, current]
      ],
      type: 'bar',
      labels: {
        format: d3.format('$,')
      }
    },
    bar: {
      width: {
        ratio: 0.5 // this makes bar width 50% of length between ticks
      }
    },
    color: {
      pattern: ['#68cbda', '#009cad']
    },
    axis: {
      y: {
        tick: {
          format: d3.format('$,')
        }
      },
      x: { show: false }
    }
  });
});
