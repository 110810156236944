import React from 'react';
import Notification from './notification';

export const NOT_ASKED = 'NOT_ASKED';
export const LOADING = 'LOADING';
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';

const viewForState = state => {
  const lookup = {};

  lookup[NOT_ASKED] = null;
  lookup[LOADING] = <i className="fa fa-circle-o-notch fa-spin" />;
  lookup[SUCCESS] = <i className="fa fa-check txt-success" />;
  lookup[FAILURE] = <i className="fa fa-warning txt-alert" />;

  return lookup[state];
};

const MultiStateLoader = props => {
  const { children } = props;

  const state = props.state || { type: NOT_ASKED };

  const error =
    state.type === FAILURE ? (
      <Notification type="alert">{state.message}</Notification>
    ) : null;

  return (
    <div className="media media--center">
      <div className="media__body">
        {children}
        {error}
      </div>
      <div className="media__obj--rev ">{viewForState(state.type)}</div>
    </div>
  );
};

export default MultiStateLoader;
