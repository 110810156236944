import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { localGet } from '../fetch-local';
import Notification from './notification';
import $ from 'jquery';
import { setField, setSelectField, simulateMouseClick } from '../terribleHacks';

type optionalId = number | null;

interface Props {
  schoolName: string;
  customerProfileName: string;
  selectedSchoolId: optionalId;
  selectedCustomerProfileId: optionalId;
  schools: {
    id: number;
    name: string;
  }[];
  links: {
    customerProfiles: string;
  };
  orderPersisted: boolean;
}

interface CustomerProfile {
  id: number;
  name: string | null;
  customerFirstName: string | null;
  customerLastName: string | null;
  address1: string | null;
  address2: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
}

const hackyFormFieldSetting = (customerProfile: CustomerProfile | null) => {
  if (customerProfile) {
    $('#order_customer_first_name')
      .val(customerProfile.customerFirstName)
      .change();
    $('#order_customer_last_name')
      .val(customerProfile.customerLastName)
      .change();

    setField('customer_address_one', customerProfile.address1);
    window.setTimeout(() => {
      const outsideArea = document.getElementById('admin-section');
      if (outsideArea) {
        simulateMouseClick(outsideArea);
      }
    }, 500);

    setField('customer_address_two', customerProfile.address2);
    setField('customer_city', customerProfile.city);
    setSelectField('customer_state', customerProfile.state);
    setField('customer_zip', customerProfile.zip);
  } else {
    $('#order_customer_first_name')
      .val('')
      .change();
    $('#order_customer_last_name')
      .val('')
      .change();
    setField('customer_address_one', '');
    setField('customer_address_two', '');
    setField('customer_city', '');
    setSelectField('customer_state', '');
    setField('customer_zip', '');
  }
};

const SchoolAndCustomerProfileSelect = (props: Props) => {
  const [selectedSchoolId, setSelectedSchoolId] = useState<optionalId>(
    props.selectedSchoolId
  );
  const [selectedCustomerProfileId, setSelectedCustomerProfileId] = useState<
    optionalId
  >(props.selectedCustomerProfileId);

  const [customerProfiles, setCustomerProfiles] = useState<
    CustomerProfile[] | null
  >(null);

  useEffect(() => {
    if (!selectedSchoolId) return;
    let url = props.links.customerProfiles.replace(
      '__school_id__',
      selectedSchoolId.toString()
    );

    if (props.selectedCustomerProfileId) {
      url = url + `?selected=${props.selectedCustomerProfileId}`;
    }

    localGet(url).then(customerProfiles =>
      setCustomerProfiles(customerProfiles)
    );
  }, [
    selectedSchoolId,
    props.links.customerProfiles,
    props.selectedCustomerProfileId,
  ]);

  const schoolSelectProps = {
    name: props.schoolName,
    id: 'school',
    options: props.schools.map(({ id, name }) => ({
      value: id,
      label: name,
    })),
    onChange: id => setSelectedSchoolId(id),
    value: selectedSchoolId,
    placeholder: 'Select a School',
    simpleValue: true,
  };

  const schoolView = (
    <div className="stack stack--x-small" id="school-select">
      <label htmlFor="school">School</label>
      <Select {...schoolSelectProps} />
    </div>
  );

  const customerProfileSelectProps = customerProfiles && {
    name: props.customerProfileName,
    id: 'customer-profile-select',
    options: customerProfiles.map(({ id, name }) => ({
      value: id,
      label: name,
    })),
    onChange: id => {
      setSelectedCustomerProfileId(id);
      const cp = customerProfiles.find(c => c.id === id);
      !props.orderPersisted && cp && hackyFormFieldSetting(cp);
    },
    value: selectedCustomerProfileId,
    placeholder: 'Select a Customer',
    simpleValue: true,
  };

  const editingCustomerProfileWarning = (
    <Notification type="notice" small>
      Editing or selecting a new customer profile after an order has already
      been created will only update the attached customer profile and not the
      order's shipping name or address.
    </Notification>
  );

  const customerProfileView = customerProfiles && (
    <>
      <div id="customer-select" className="stack stack--x-small">
        <label htmlFor="school">Customer Profile</label>
        {props.orderPersisted && editingCustomerProfileWarning}
        {<Select {...customerProfileSelectProps} />}
      </div>
    </>
  );

  const schoolUrl =
    selectedSchoolId && `/schools/${selectedSchoolId}/customer_profiles/new`;

  const customerUrl =
    selectedCustomerProfileId &&
    `/schools/${selectedSchoolId}/customer_profiles/${selectedCustomerProfileId}/edit`;

  const editCustomerView = customerUrl && (
    <a
      href={customerUrl}
      className="button button--secondary"
      target="_blank"
      rel="noreferrer"
    >
      Edit
    </a>
  );

  const addNewCustomerView = schoolUrl && (
    <div>
      <a
        href={schoolUrl}
        className="button button--small button--secondary"
        target="_blank"
        rel="noreferrer"
      >
        Create a New Customer
      </a>
    </div>
  );

  return (
    <div className="stack">
      {schoolView}
      {customerProfileView && (
        <div className="stack stack--small">
          <div className="media media--end">
            <div className="media__body">{customerProfileView}</div>
            <div className="media__obj--rev">{editCustomerView}</div>
          </div>
        </div>
      )}
      {addNewCustomerView}
    </div>
  );
};

export default SchoolAndCustomerProfileSelect;
