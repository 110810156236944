import React, { useState, useEffect } from 'react';
import { localPostJSON } from '../fetch-local';

interface Warehouse {
  id: number;
  supplierId: number;
}

interface ManufacturersWarehouse {
  id: number;
  manufacturerId: number;
  warehouseId: number;
  supplierId: number;
  transitTime?: string;
}

interface Props {
  formId: string;
  orderId: number;
  name: string;
  manufacturerId: number;
  initialBlankArrivalDate: string;
  warehouses: Warehouse[];
  manufacturersWarehouses: ManufacturersWarehouse[];
}

const SupplierSplitBlankArrivalDateSelect = ({
  formId,
  orderId,
  name,
  manufacturerId,
  initialBlankArrivalDate,
  warehouses,
  ...props
}: Props) => {
  const [value, setValue] = useState<string | null>(null);

  useEffect(() => {
    setValue(
      initialBlankArrivalDate ? initialBlankArrivalDate.toString() : null
    );
  }, [setValue, initialBlankArrivalDate]);

  useEffect(() => {
    const handleEvent = e => {
      if (e.detail.formId !== formId) return;
      const warehouseId = e.detail.warehouseId
        ? e.detail.warehouseId.toString()
        : null;
      const warehouse = warehouses.find(w => w.id === e.detail.warehouseId);
      const supplierId = warehouse ? warehouse.supplierId.toString() : null;

      const manufacturersWarehouse = props.manufacturersWarehouses.find(
        mfw =>
          mfw.warehouseId.toString() === warehouseId &&
          mfw.supplierId.toString() === supplierId &&
          mfw.manufacturerId.toString() === manufacturerId.toString()
      );
      setTimeout(() => {
        localPostJSON(`/orders/${orderId}/calculate_blank_arrival_date`, {
          transit_time: manufacturersWarehouse?.transitTime,
        }).then((data: Object) => {
          setValue(data['date']);
        });
      }, 1000);
    };

    window.addEventListener('warehouseSet', handleEvent);

    return () => {
      window.removeEventListener('warehouseSet', handleEvent);
    };
  }, [formId]);

  const selectId = `${formId}-blank-arrival-date`;

  return (
    <div className="section txt-left" id={selectId}>
      <input
        type="date"
        name={name}
        id="blank_arrival_date"
        className="fill_col_values purchase-date-select"
        onChange={e => {
          const value = e ? e.target.value.toString() : e;
          setValue(value);
        }}
        value={value || ''}
      />
    </div>
  );
};

export default SupplierSplitBlankArrivalDateSelect;
