import React from 'react';
import Select from 'react-select';

const ItemSelect = props => {

  const options = props.productOptions;

  const itemOptions = options.map((p) => ({
    value: p.id,
    label: p.brand_and_style
  }));

  return(
    <div className='react-select-form' id='item-select'>
      <Select
        placeholder='Select an Item'
        name='item-select'
        onChange={props.onProductChange.bind(this)}
        options={itemOptions}
        value={props.product}
        matchProp='any'
      />
    </div>
  );

};

export default ItemSelect;
