import PropTypes from 'prop-types';

const EditableField = props => {
  const { editing, renderEdit, renderRead } = props;
  return editing ? renderEdit() : renderRead();
};

EditableField.propTypes = {
  editing: PropTypes.bool,
  renderEdit: PropTypes.func,
  renderRead: PropTypes.func,
};

export default EditableField;
