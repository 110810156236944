import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import IconSvg from './IconSvg';
import { Color } from '../BrandColor';
import Notification from './notification';
import classNames from 'classnames';

// https://hackernoon.com/copying-text-to-clipboard-with-javascript-df4d4988697f

const supportsCopy = document.queryCommandSupported('copy');

function CopyCode({ code, isHidden, buttonClass, isFlex, buttonText }) {
  const [hasCopied, setHasCopied] = useState(false);

  const inputRef = useRef();

  useEffect(() => {
    const timeout = setTimeout(() => setHasCopied(false), 3000);
    return () => clearTimeout(timeout);
  }, [hasCopied]);

  function handleClick() {
    const el = inputRef.current;
    const selected =
      document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0)
        : false;
    el.select();
    document.execCommand('copy');
    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
    }
    setHasCopied(true);
  }

  return (
    <>
      <div
        className={classNames({
          'flex-rows flex-rows--center-v flex-gap-x-sm': isFlex,
          'copy-link': true,
        })}
      >
        <input
          readOnly
          value={code}
          className={classNames({
            'is-hidden-visually-positioned': isHidden,
            code: true,
          })}
          type="text"
          ref={inputRef}
        />

        {supportsCopy && (
          <button
            className={buttonClass}
            onClick={handleClick}
            type="button"
            title="Copy to clipboard"
          >
            <div className="flex-rows flex-rows--center-v">
              <span>{buttonText}</span>
              <span className="mls">
                <IconSvg icon="copy" color={Color.White} size={16} />
              </span>
            </div>
          </button>
        )}
      </div>
      {hasCopied && (
        <Notification float type="success">
          Copied to clipboard!
        </Notification>
      )}
    </>
  );
}

CopyCode.propTypes = {
  code: PropTypes.string.isRequired,
};

export default CopyCode;
