import React from 'react';
import { formatMoney, formatPercent, formatPercentV2 } from '../utility';
import Media from './Media';
import Icon from './Icon';
import { palette } from '../color';
import { isNumber } from 'lodash';

const LineItemTotalBreakdown = props => {
  const { data } = props;
  const {
    totalPrice,
    totalPriceWithTax,
    commission,
    commissionRate,
    commissionEnabled,
    grossProfit,
    actualProfitMargin,
    unitPrice,
    salesTax,
    showTaxPerItem,
    unitPriceWithTax,
    overSizedQuantity,
    overSizedPrice,
    overSizedPriceWithTax,
    promoProduct,
    perPieceCost,
    isPriceOverridden,
    totalQuantity,
    priceOnBirdBank,
  } = data;

  const { meta } = props;
  const { currentUser } = meta;
  const itemDetailsPresent = promoProduct && perPieceCost > 0;
  const quantityPresent = totalQuantity > 0;

  const birdBankPrice = priceOnBirdBank ? (
    <React.Fragment>
      <dt>Bird Bank Price:</dt>
      <dd className="txt-right">{priceOnBirdBank}</dd>
    </React.Fragment>
  ) : null;

  const profitInfo =
    (itemDetailsPresent || !promoProduct) && currentUser.canViewProfitMargin ? (
      <React.Fragment>
        <dt>Gross Profit:</dt>
        <dd className="txt-right">{formatMoney(grossProfit)}</dd>

        <dt>Profit Margin:</dt>
        <dd className="txt-right">{formatPercent(actualProfitMargin * 100)}</dd>
      </React.Fragment>
    ) : null;

  const commissionInfo =
    commissionEnabled &&
    (itemDetailsPresent || !promoProduct) &&
    currentUser.canViewCommission ? (
      isNumber(commission) ? (
        <>
          <dt className="txt-muted2">
            <em>
              Commission{' '}
              {commissionRate !== null ? (
                <>at {formatPercentV2(commissionRate)}</>
              ) : (
                ''
              )}
              :
            </em>
          </dt>
          <dd className="txt-muted2 txt-right">
            <em>{formatMoney(commission)}</em>
          </dd>
        </>
      ) : (
        <Media object={<Icon type="warning" color={palette.warning} />}>
          Update price to see commission
        </Media>
      )
    ) : null;

  const taxPerInfo =
    (itemDetailsPresent || !promoProduct) && showTaxPerItem ? (
      <React.Fragment>
        <dt>
          <span data-tooltip="Sales tax is an estimation">Tax Per Item:</span>
        </dt>
        <dd className="txt-right">
          <span data-tooltip="Sales tax is an estimation">
            {formatMoney(salesTax)}
          </span>
        </dd>
      </React.Fragment>
    ) : null;

  const overSizedInfo =
    overSizedQuantity > 0 ? (
      <React.Fragment>
        <dt>
          <span data-tooltip="Each item of size 2XL or larger adds a $2 fee from our manufacturing partners, plus sales tax (if any)">
            Size Fee:
          </span>
        </dt>
        <dd className="txt-right">
          <span data-tooltip="Each item of size 2XL or larger adds a $2 fee from our manufacturing partners, plus sales tax (if any)">
            {showTaxPerItem
              ? formatMoney(overSizedPriceWithTax)
              : formatMoney(overSizedPrice)}
          </span>
        </dd>
      </React.Fragment>
    ) : null;

  const unitPriceWarning =
    ((currentUser.canSetPrice && !isPriceOverridden) ||
      (isPriceOverridden && currentUser.canOverridePrice)) &&
    !unitPrice ? (
      <Media object={<Icon type="warning" color={palette.warning} />}>
        Update price to see pricing details
      </Media>
    ) : (
      <em>
        <small>Unit Price has not yet been set.</small>
      </em>
    );

  const unitPriceView = isNumber(unitPrice) ? (
    <dl className="dl-grid">
      {overSizedInfo}
      {taxPerInfo}
      {birdBankPrice}
      <dt>Total Per Item:</dt>
      <dd className="txt-right">
        {showTaxPerItem
          ? formatMoney(unitPriceWithTax)
          : formatMoney(unitPrice)}
      </dd>
      <dt>Total:</dt>
      <dd className="txt-right">
        {showTaxPerItem
          ? formatMoney(totalPriceWithTax)
          : formatMoney(totalPrice)}
      </dd>
      {commissionInfo}

      {profitInfo}
    </dl>
  ) : (
    unitPriceWarning
  );

  if (quantityPresent) {
    return <div>{unitPriceView}</div>;
  } else {
    return null;
  }
};

export default LineItemTotalBreakdown;
