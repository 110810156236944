import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

const SelectOrOther = createReactClass({
  propTypes: {
    selectInputName: PropTypes.string,
    otherInputName: PropTypes.string,
    selectOptions: PropTypes.array,
    selectOptionOther: PropTypes.string,
    selectValue: PropTypes.string,
    otherValue: PropTypes.string,
    selectPrompt: PropTypes.string,
  },

  getInitialState: function() {
    return {
      value: this.props.selectValue || '',
      otherValue: this.props.otherValue,
      otherInputShown: this.props.selectValue == this.props.selectOptionOther,
    };
  },

  prompt: function() {
    if (this.props.selectPrompt) {
      return (
        <option value="" disabled>
          {this.props.selectPrompt}
        </option>
      );
    }
  },

  options: function(options) {
    const _this = this;
    return options.map(function(option) {
      return option[1].constructor === Array ? (
        <optgroup label={option[0]} key={'optgroup-' + option[0]}>
          {_this.options(option[1])}
        </optgroup>
      ) : (
        <option key={'option-' + option[0]} value={option[0]}>
          {option[1]}
        </option>
      );
    });
  },

  otherInput: function() {
    if (this.state.otherInputShown) {
      return (
        <input
          type="text"
          className="other-text-input"
          placeholder="Explain"
          name={this.props.otherInputName}
          value={this.props.otherValue}
        />
      );
    } else {
      return <input type="hidden" name={this.props.otherInputName} value="" />;
    }
  },

  selectClassName: function() {
    let classes = ['select-or-other'];
    if (this.state.otherInputShown) {
      classes.push('other-shown');
    }
    return classes.join(' ');
  },

  update: function(event) {
    let value = event.target.value;
    this.setState({
      value: value,
      otherInputShown: value == this.props.selectOptionOther,
    });
  },

  render: function() {
    return (
      <div className={this.selectClassName()}>
        <div className="select-container label-select">
          <select
            onChange={this.update}
            value={this.state.value}
            name={this.props.selectInputName}
          >
            {this.prompt()}
            {this.options(this.props.selectOptions)}
          </select>
        </div>
        {this.otherInput()}
      </div>
    );
  },
});

export default SelectOrOther;
