import React, { useState, useEffect, useCallback } from 'react';
import className from 'classnames';

interface Option {
  key: string | number;
}

interface Props<T> {
  value: string;
  options: (T & Option)[];
  renderOption: (x: T) => any;
  renderInput: (params) => any;
  onChange: (s: string) => any;
  onSelect: (x: T) => any;
  showSuggestions: boolean;
  name?: string;
}

export default <T,>(props: Props<T>) => {
  const {
    value,
    onChange,
    onSelect,
    options,
    renderOption,
    renderInput,
    showSuggestions,
  } = props;

  const [currentSelectionIndex, setCurrentSelectionIndex] = useState<
    null | number
  >(null);

  const inputParams = {
    'aria-autocomplete': 'list',
    type: 'text',
    value,
    onChange: e => onChange(e.target.value),
  };

  const handleUserKeyPress = useCallback(
    event => {
      if (!showSuggestions) return;
      if (event.keyCode === 38) {
        setCurrentSelectionIndex(i => {
          if (i === null || i === 0) return options.length - 1;
          else return i - 1;
        });
      } else if (event.keyCode === 40) {
        setCurrentSelectionIndex(i => {
          if (i === null) return 0;
          else return (i + 1) % options.length;
        });
      } else if (event.keyCode === 13) {
        if (currentSelectionIndex === null) return;
        event.preventDefault();
        onSelect(options[currentSelectionIndex]);
      }
    },
    [showSuggestions, options, onSelect, currentSelectionIndex]
  );

  useEffect(() => {
    if (!showSuggestions || options.length === 0) {
      setCurrentSelectionIndex(null);
    }
  }, [showSuggestions, options]);

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  return (
    <>
      {renderInput(inputParams)}

      {showSuggestions && options.length > 0 && (
        <div className="autocomplete__dropdown">
          {options.map((option, i) => (
            <button
              key={option.key}
              className={className({
                autocomplete__button: true,
                'autocomplete__button--active': i === currentSelectionIndex,
              })}
              style={{ display: 'block' }}
              type="button"
              onClick={() => onSelect(option)}
            >
              {renderOption(option)}
            </button>
          ))}
        </div>
      )}
    </>
  );
};
