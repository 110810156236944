import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import DragHandle from './DragHandle';
import EditableField from './EditableField';
import { getHashParams } from '../utility';
import { localPostJSON, localPatchJSON } from '../fetch-local';
import { modalConfirm } from '../modal';
import classNames from 'classnames';
import { includes, isEmpty } from 'lodash';
import BirdBankPriceFields from './BirdBankPriceFields';
import BirdBankItemImage from './BirdBankItemImage';
import BirdBankItemDetail from './BirdBankItemDetail';
import BirdBankItemSubstitutionField from './BirdBankItemSubstitutionField';
import ActionMenu from './ActionMenu';
import Icon from './Icon';

export default class BirdBankItemForm extends Component {
  static propTypes = {
    itemSubstitutionEnabled: PropTypes.bool,
    editingEnabled: PropTypes.bool,
    paymentsProcessing: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      loading: false,
      errors: [],
    };
  }

  onEditButtonClick = e => {
    e.preventDefault();
    if (this.props.birdBankItem.lineItem) {
      this.setState({
        editing: true,
      });
    } else {
      const { meta } = this.props.birdBankItem;
      window.location.href = meta.editUrl;
    }
  };

  onSaveButtonClick = e => {
    e.preventDefault();

    if (!this.birdBankItemIsValid()) return false;

    this.setState(
      {
        editing: false,
        loading: true,
        errors: [],
      },
      () => {
        this.saveBirdBankItem();
      }
    );
  };

  onCancelClick = e => {
    e.preventDefault();
    this.setState({ editing: false });
  };

  birdBankItemIsValid = () => {
    const {
      upperPrice,
      lowerPrice,
      priceFixed,
      variant,
      showImprintColors,
    } = this.props.birdBankItem;
    if (isEmpty(upperPrice)) return false;

    if (!priceFixed && isEmpty(lowerPrice)) return false;

    if (showImprintColors && !variant) return false;

    return true;
  };

  saveBirdBankItem = () => {
    const {
      fixedPrice,
      priceFixed,
      upperPrice,
      lowerPrice,
      reverseImages,
      lineItemId,
      meta,
      variant,
      questions,
    } = this.props.birdBankItem;
    localPatchJSON(meta.updateUrl, {
      bird_bank_item: {
        fixed_price: fixedPrice,
        price_fixed: priceFixed,
        upper_price: upperPrice,
        lower_price: lowerPrice,
        reverse_images: reverseImages,
        line_item_id: lineItemId,
        variant: variant,
        questions_attributes: questions,
      },
    })
      .then(data => {
        this.setState({
          loading: false,
        });
        this.props.birdBankItemUpdate(this.props.birdBankItem, data);
      })
      .catch(error => {
        error.response.json().then(({ errors }) => {
          this.setState({
            errors,
            loading: false,
            editing: true,
          });
        });
      });
  };

  onRemoveButtonClick = e => {
    e.preventDefault();
    const { meta, removalWarning, removalText } = this.props.birdBankItem;

    modalConfirm({
      title: `${removalWarning}`,
      // text: `The following dimensions were found for this file:
      //                 ${width}in x ${height}in. Is this correct?`,
      confirmLabel: `${removalText}`,
      cancelLabel: 'Cancel',
    }).then(wasConfirmed => {
      if (!wasConfirmed) return;

      localPostJSON(meta.removeUrl).then(() =>
        this.props.removeBirdBankItem(this.props.birdBankItem)
      );
    });
  };

  render() {
    const {
      birdBankItem,
      index,
      voidingEnabled,
      refundingEnabled,
    } = this.props;
    const { lineItem, meta } = birdBankItem;
    const { removalText, removalIcon, itemRefundable } = birdBankItem;
    const signupLink = lineItem && (
      <a href={meta.editUrl}>
        <Icon type="arrow-circle-right" color="inherit" /> View Signups
      </a>
    );
    const orderLink = lineItem && (
      <a href={meta.orderUrl}>
        <Icon type="arrow-circle-right" color="inherit" /> View Order
      </a>
    );

    const newItems = getHashParams()['new_items'];
    const newItemIds = newItems && newItems.split(',');

    const isNew =
      newItemIds && includes(newItemIds, birdBankItem.id.toString());

    const removalEnabled =
      (itemRefundable ? refundingEnabled : voidingEnabled) ||
      birdBankItem.quantity === 0;

    const removeButton = !this.props.paymentsProcessing && removalEnabled && (
      <button onClick={this.onRemoveButtonClick}>
        <Icon type={removalIcon} color="inherit" />
        {removalText}
      </button>
    );

    return (
      <Draggable
        key={birdBankItem.id}
        draggableId={birdBankItem.id}
        index={index}
      >
        {provided => (
          <div
            className={classNames({
              'bird-bank-item': true,
              'bird-bank-item-new': isNew,
            })}
            ref={provided.innerRef}
            {...provided.draggableProps}
          >
            <DragHandle {...provided.dragHandleProps} />

            <div className="bird-bank-item__content">
              <div className="bird-bank-item-header flex-rows flex-rows--space-b flex-gap-x-sm">
                <div className="full-width">
                  <h3 className="headline-sans man react-select-form">
                    <EditableField
                      editing={
                        this.state.editing && this.props.itemSubstitutionEnabled
                      }
                      renderEdit={() => (
                        <BirdBankItemSubstitutionField
                          birdBankItem={birdBankItem}
                          alternateLineItemsUrl={meta.alternateLineItemsUrl}
                          lineItemId={birdBankItem.lineItemId}
                          birdBankItemUpdate={this.props.birdBankItemUpdate}
                        />
                      )}
                      renderRead={() => (
                        <React.Fragment>
                          {birdBankItem.descriptionWithoutColor}
                        </React.Fragment>
                      )}
                    />
                  </h3>
                </div>
                <div>
                  <ActionMenu dropdownRight>
                    {this.props.editingEnabled && (
                      <button
                        onClick={e => {
                          this.onEditButtonClick(e);
                        }}
                      >
                        <Icon type="pencil" color="inherit" /> Edit
                      </button>
                    )}
                    {signupLink}
                    {orderLink}
                    {removeButton}
                  </ActionMenu>
                </div>
              </div>
              <div className="bird-bank-item__body">
                <BirdBankItemImage
                  birdBankItem={birdBankItem}
                  birdBankItemUpdate={this.props.birdBankItemUpdate}
                  editing={this.state.editing}
                />
                <div className="bird-bank-detail-container">
                  <BirdBankItemDetail
                    birdBankItem={birdBankItem}
                    birdBankItemUpdate={this.props.birdBankItemUpdate}
                    editing={this.state.editing}
                  />
                  <BirdBankPriceFields
                    birdBankItem={birdBankItem}
                    editing={this.state.editing}
                    birdBankItemUpdate={this.props.birdBankItemUpdate}
                    errors={this.state.errors}
                  />
                </div>
              </div>
              <EditableField
                editing={this.state.editing}
                renderEdit={() => (
                  <div className="bird-bank-edit-actions">
                    <button
                      className={classNames({
                        button: true,
                        'button--full-res': true,
                        'is-disabled': !this.birdBankItemIsValid(),
                      })}
                      onClick={this.onSaveButtonClick}
                      disabled={!this.birdBankItemIsValid()}
                    >
                      Save
                    </button>
                    <button
                      className="button-naked button-colored button-pseudo button-pseudo--res mvn"
                      onClick={this.onCancelClick}
                    >
                      Cancel
                    </button>
                  </div>
                )}
                renderRead={() => null}
              />
            </div>
          </div>
        )}
      </Draggable>
    );
  }
}
