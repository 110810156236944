import React, { useState } from 'react';
import { localPostJSON } from '../fetch-local';
import Loader from './loader';

export default function PublishImageDraft({ url, returnUrl }) {
  const [isLoading, setIsLoading] = useState(false);

  const publish = () => {
    setIsLoading(true);
    localPostJSON(url, {})
      .then(() => (window.location = returnUrl))
      .catch(() => setIsLoading(false));
  };

  return (
    <div className="mtl">
      <Loader isActive={isLoading}>
        <button
          onClick={publish}
          type="button"
          className="button button--success"
        >
          Publish Draft
        </button>
      </Loader>
    </div>
  );
}
