import React, { useState, Fragment } from 'react';
import { localPostJSON } from '../fetch-local';
import Loader from './loader';
import Notification from './notification';

function AffinitySubmissionButton({ disabled, url, showSubmitAsNewDesign }) {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(null);
  const [submitAsNewDesign, setSubmitAsNewDesign] = useState(false);

  const submit = () => {
    setLoading(true);
    setStatus(null);
    const payload = {
      affinity_submission: { force_new_design: submitAsNewDesign },
    };

    localPostJSON(url, payload)
      .then(() => {
        setStatus({
          type: 'success',
          message: 'Your submission was successful.',
        });
      })
      .catch(() => {
        setStatus({
          type: 'alert',
          message: 'Something went wrong.',
        });
      })
      .finally(() => {
        setLoading(false);
        window.location.reload(true);
      });
  };

  const showButton = !status || status.type !== 'success';

  return (
    <Fragment>
      {showButton && (
        <Fragment>
          <button
            className="button button--full-res mbm"
            type="button"
            disabled={disabled || loading}
            onClick={() => submit()}
          >
            Submit to Affinity
            {submitAsNewDesign && <span> as new design</span>}
          </button>

          {showSubmitAsNewDesign && (
            <div className="input-checkbox">
              <label htmlFor="affinity-submit-as-new-design">
                Submit as new design?
              </label>

              <input
                type="checkbox"
                checked={submitAsNewDesign}
                onChange={e => setSubmitAsNewDesign(e.target.checked)}
                id="affinity-submit-as-new-design"
              />
            </div>
          )}
        </Fragment>
      )}

      <Loader isActive={loading} />

      {status && (
        <div className="mtl">
          <Notification small={true} type={status.type}>
            {status.message}
          </Notification>
        </div>
      )}
    </Fragment>
  );
}

export default AffinitySubmissionButton;
