import React from 'react';
import { Post as PostType } from './types';
import Timestamp, { dateTitle } from './Timestamp';
import LightBox from '../LightBox';
import ActionMenu from '../ActionMenu';
import IconSvg from '../IconSvg';
import { Color } from '../../BrandColor';

interface Props {
  post: PostType;
  onDestroy: (p: PostType) => Promise<void>;
  onEdit: (p: PostType) => void;
  readonly: boolean;
}

const editedAtTitle = ({ updatedAt }: PostType): string =>
  `Edited on ${dateTitle(updatedAt)}`;

export default function Post(props: Props) {
  const { post, readonly, onDestroy, onEdit } = props;

  return (
    <div className="post">
      <div className="post__header">
        <strong>
          {post.user.firstName} {post.user.lastName}
        </strong>

        <div className="post__meta">
          {post.isEdited ? (
            <small className="mhs">
              <span className="mrm">
                <strong className="txt-warning" title={editedAtTitle(post)}>
                  Edited
                </strong>
              </span>
              <Timestamp date={post.createdAt} />
            </small>
          ) : (
            <small className="mhs">
              <Timestamp date={post.createdAt} />
            </small>
          )}

          {!readonly && (
            <ActionMenu dropdownRight dropdownSmall>
              <button onClick={() => onEdit(post)}>Edit</button>
              <button
                onClick={() => {
                  if (window.confirm('Are you sure?')) {
                    onDestroy(post);
                  }
                }}
              >
                Delete
              </button>
            </ActionMenu>
          )}
        </div>
      </div>
      <p className="post__body">{post.message}</p>
      <div>
        {post.attachments.map(attachment => (
          <div
            key={attachment.url}
            className="flex-rows flex-rows--center-v mbs"
          >
            {attachment.previewUrls.thumbnail ? (
              <>
                <LightBox src={attachment.previewUrls.fullsize}>
                  <img
                    className="item-preview"
                    src={attachment.previewUrls.thumbnail}
                    alt=""
                    width="30"
                    height="30"
                  />
                </LightBox>
                <span
                  className="attachment-title mhm"
                  title={attachment.filename}
                >
                  {attachment.filename}
                </span>
                <a href={attachment.url}>
                  <IconSvg icon="download" color={Color.TealBlue} size={24} />
                </a>
              </>
            ) : (
              <>
                <IconSvg icon="document" color={Color.TealBlue} size={30} />
                <span
                  className="attachment-title mhm"
                  title={attachment.filename}
                >
                  {attachment.filename}
                </span>
                <a href={attachment.url}>
                  <IconSvg icon="download" color={Color.TealBlue} size={24} />
                </a>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
