import React from 'react';
import Loader from './loader';
import { LoadState } from '../remote-data-types';
import Notification from './notification';

interface Props {
  saveStatus: LoadState;
}

export default function AutoSaveStatus(props: Props): JSX.Element {
  switch (props.saveStatus) {
    case LoadState.Failure:
      return (
        <Notification float type="alert" key="failure" dismissible>
          Failed to save
        </Notification>
      );
    case LoadState.NotAsked:
      return <></>;
    case LoadState.IndeterminateProgressLoading:
    case LoadState.Loading:
      return (
        <Notification float type="success" key="loading">
          Autosaving <Loader isActive={true} />
        </Notification>
      );
    case LoadState.Success:
      return (
        <Notification float type="success" key="success" autoDismiss>
          Saved
        </Notification>
      );
  }
}
