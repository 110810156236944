import React from 'react';
import Icon from './Icon';

export default function WarningIcon(props) {
  let wrapProps = {
    style: { cursor: 'help' },
  };

  if (props.warningText) {
    wrapProps = { ...wrapProps, title: props.warningText };
  }

  return (
    <div {...wrapProps}>
      <Icon type="warning" color="#E8AD17" />
      &nbsp;
    </div>
  );
}
