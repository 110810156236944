(function() {
  let SelectPrice = function(params) {
    let self = this,
      $node = params.$node,
      onClick = function(e) {
        if(this.value == 'true'){
          $('[data-range-price]').hide();
          $('[data-fixed-price]').show();
        }else{
          $('[data-range-price]').show();
          $('[data-fixed-price]').hide();
        }
      },
      init = function() {
        if ($('#bird_bank_item_price_fixed_false').prop('checked') == true){
          $('[data-range-price]').show();
          $('[data-fixed-price]').hide();
        }else{
          $('[data-range-price]').hide();
          $('[data-fixed-price]').show();
        }
        $node.click(onClick);
      };

    init();
  };

  $('[data-set-price] :input').each(function() {
    new SelectPrice({
      $node: $(this)
    });
  });
}());

