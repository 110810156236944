import React from 'react';

const defaultAnswer = {
  answer: '',
};

export const newDefaultAnswer = question => ({ question, ...defaultAnswer });

const CustomAnswer = ({ answer, question, onChange, onBlur }) => {
  const onAnswerChange = e => {
    const newAnswer = e.target.value;
    onChange({ answer: newAnswer });
  };
  const answerInput = (
    <input
      type="text"
      value={answer}
      onChange={onAnswerChange}
      onBlur={onBlur}
    />
  );

  return (
    <div className="form-compact--small form-light form-light--bordered mbl">
      <label>{question.question}</label>
      {answerInput}
    </div>
  );
};

export default CustomAnswer;
