(function($) {

  $(document).on('keyup change', '.size-input', function() {
    let lineItemEl = $(this).closest('.line-item-detail');
    let sizeInputs = $(lineItemEl).find('.size-input').toArray();

    let total = sizeInputs.reduce((memo, sizeInput) => {
      let value = (parseInt($(sizeInput).val()) || 0);
      return memo + value;
    }, 0);

    $(lineItemEl).find('.line-item-quantity .value').text(total);
  });

})(window.jQuery);
