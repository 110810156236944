import React from 'react';

const FreeLineItemSize = props => {
  const { size, freeQuantity } = props;

  return (
    <React.Fragment>
      <dt>
        <em className="txt-muted2">{size} (free)</em>
      </dt>
      <dd className="txt-right">
        <em className="txt-muted2">{freeQuantity}</em>
      </dd>
    </React.Fragment>
  );
};

export default FreeLineItemSize;
