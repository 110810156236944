import React from 'react';

const LineItemSize = props => {
  const { size, quantity } = props;

  return (
    <React.Fragment>
      <dt>{size}</dt>
      <dd className="txt-right">{quantity}</dd>
    </React.Fragment>
  );
};

export default LineItemSize;
