import React, { FunctionComponent, useRef, useState } from 'react';
import Modal from 'react-modal';
import IconSvg from './IconSvg';
import { Color } from '../BrandColor';
import { localDelete } from '../fetch-local';

// NOTE: We were getting an error to use setAppElement to hide
// the app while the modal is open, for use by screenreaders. So
// it's currently being set to `main`.

Modal.setAppElement('.main');

export interface Props {
  ButtonComponent?: FunctionComponent;
  archiveFormPath: string;
}

const DefaultArchiveButton: FunctionComponent = () => {
  return (
    <button type="button" className="button-naked pan" title="Archive order">
      <IconSvg icon="archive" color={Color.Gray}></IconSvg>
    </button>
  );
};

const ArchiveOrderIcon: FunctionComponent<Props> = ({
  ButtonComponent = DefaultArchiveButton,
  archiveFormPath,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  function handleOpen() {
    setModalIsOpen(true);
  }

  function handleClose() {
    setModalIsOpen(false);
  }

  function handleArchive() {
    localDelete(archiveFormPath)
      .then(() => {
        window.location.reload();
      })
      .catch(error => {
        console.error(error);
      });
  }

  return (
    <>
      <div onClick={handleOpen} ref={ref}>
        <ButtonComponent />
      </div>

      <Modal
        isOpen={modalIsOpen}
        className="react-modal"
        overlayClassName="react-modal-overlay"
        contentLabel="Approval Modal"
        closeTimeoutMS={200}
      >
        <div className="react-modal-header flex-rows flex-rows--space-b flex-rows--align-c">
          <h4 className="react-modal-header__title">Confirm Archive</h4>
          <button
            className="button-naked"
            type="button"
            onClick={handleClose}
            title="Close modal"
          >
            <IconSvg icon="close" color={Color.Gray} />
          </button>
        </div>

        <div className="react-modal-body react-modal-content">
          <p>Are you sure you want to archive this opportunity?</p>
        </div>

        <div className="react-modal-footer">
          <div className="react-modal-footer__cta">
            <button
              onClick={handleArchive}
              className="button button--alert"
              type="button"
            >
              Archive
            </button>

            <button
              type="button"
              className="button-naked"
              onClick={handleClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ArchiveOrderIcon;
