import React, { Component } from 'react';
import PropTypes from 'prop-types';
const humanize = require('string-humanize');

class FulfillmentOrder extends Component {
  static propTypes = {
    order: PropTypes.object.isRequired,
  };
  constructor(props) {
    super(props);

    this.state = {
      complete: this.props.order.complete ? true : false,
    };
  }

  render() {
    let order = this.props.order;
    let greekFlag = order.greek ? 'YES' : '-';
    let extraTimeFlag = order.extra_time ? 'YES' : '-';
    let hangTagsFlag = order.hang_tags ? 'Hang tags' : null;
    let fulfillmentAttrs = order.fulfillment_attrs.map(
      function(attr, index) {
        return <span>{ (index ? ', ' : '') + humanize(attr)}</span>;
      }.bind(this)
    );

    let fulfillmentAttrsList = [];
    fulfillmentAttrsList.push(
        <span>{fulfillmentAttrs}</span>
      )
    if (order.hang_tags && order.fulfillment_attrs.length === 0) {
      fulfillmentAttrsList.push(
        <span>{hangTagsFlag}</span>
      )}
    else if (order.hang_tags && order.fulfillment_attrs.length > 0) {
      fulfillmentAttrsList.push(
        <span>, {hangTagsFlag}</span>
      )
    }

    let fulfillmentDateParts = order.fulfillment_date.split('-');
    let fulfillmentDate = `${fulfillmentDateParts[1]}-${
      fulfillmentDateParts[2]
    }-${fulfillmentDateParts[0]}`;

    let invoiceDate;
    if (order.invoice_date) {
      let invoiceDateParts = order.invoice_date.split('-');
      invoiceDate = `${invoiceDateParts[1]}-${invoiceDateParts[2]}-${
        invoiceDateParts[0]
      }`;
    } else {
      invoiceDate = '-';
    }

    return (
      <tr>
        <td className="txt-left txt-nowrap">{invoiceDate}</td>
        <td className="txt-left">
          <a
            href={`${this.props.order.company.domain}/orders/${
              this.props.order.id
            }`}
          >
            {order.job_number}
          </a>
        </td>
        <td className="txt-left">{fulfillmentDate}</td>
        <td className="txt-left">
          {fulfillmentAttrsList}
        </td>
        <td className="txt-left">{order.fulfillment_pieces_count}</td>
        <td className="txt-left">{extraTimeFlag}</td>
        <td className="txt-left">{greekFlag}</td>
        <td className="txt-left">{order.fulfillment_comments}</td>
        <td className="txt-left">
          <input
            type="checkbox"
            id={`order-${order.id}`}
            value={order.id}
            name="fulfillment[order_ids][]"
            className="mrm"
            defaultChecked={this.state.complete}
          />
        </td>
      </tr>
    );
  }
}

export default FulfillmentOrder;
