// https://stackoverflow.com/a/25095250/456337
export const containsFiles = (event: DragEvent): boolean => {
  if (event.dataTransfer && event.dataTransfer.types) {
    for (let i = 0; i < event.dataTransfer.types.length; i += 1) {
      if (event.dataTransfer.types[i] === 'Files') {
        return true;
      }
    }
  }

  return false;
};

// https://github.com/react-dropzone/react-dropzone
export const getDataTransferItems = (
  // event: (DragEvent | React.ChangeEvent<HTMLInputElement>)
  event?: DragEvent | React.ChangeEvent<HTMLInputElement>
): File[] => {
  let dataTransferItemsList: FileList | null | DataTransferItemList = null;

  if (!event) return [];

  if ((event as DragEvent).dataTransfer) {
    const dt = (event as DragEvent).dataTransfer;
    if (dt && dt.files && dt.files.length) {
      dataTransferItemsList = dt.files;
    } else if (dt && dt.items && dt.items.length) {
      // During the drag event the dataTransfer.files is null but Chrome
      // implements some drag store, which is accesible via dataTransfer.items
      dataTransferItemsList = dt.items;
    }
  } else if (event.target && (event.target as HTMLInputElement).files) {
    dataTransferItemsList = (event.target as HTMLInputElement).files;
  }

  // Convert from DataTransferItemsList to the native Array
  if (!DataTransferItemList) return [];
  return Array.prototype.slice.call(dataTransferItemsList);
};
