import React, { useState, useEffect } from 'react';
import Lightbox from 'react-images';

export default function ItemImage(props) {
  const images = props.images;
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [currentImageIndex, setImageIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  useEffect(() => {
    setSelectedImage(null);
    setImageIndex(0);
  }, [images]);

  const moveLightboxBy = int => {
    const newIndex = (currentImageIndex + int) % images.length;
    setImageIndex(newIndex);
  };

  const gotoPrevious = () => {
    moveLightboxBy(-1);
  };

  const gotoNext = () => {
    moveLightboxBy(1);
  };

  const onImageSelect = e => {
    e.preventDefault();
    setLightboxIsOpen(true);
  };

  const imageToShow = selectedImage || images[0].fullSizeUrl;

  const imageToggle = images.map(({ fullSizeUrl }) => (
    <button
      type="button"
      key={fullSizeUrl}
      onClick={() => setSelectedImage(fullSizeUrl)}
      className="bb-image-switcher"
    >
      Image Toggle
    </button>
  ));

  const imageSrcs = images.map(img => ({ src: img.fullSizeUrl }));

  return (
    <React.Fragment>
      <Lightbox
        images={imageSrcs}
        isOpen={lightboxIsOpen}
        onClose={() => setLightboxIsOpen(false)}
        onClickPrev={gotoPrevious}
        onClickNext={gotoNext}
        currentImage={currentImageIndex}
      />

      <a className="bb-checkout-image" href="#" onClick={onImageSelect}>
        <img src={imageToShow} />
      </a>

      <div className="bb-image-switcher-container">
        {images.length > 1 && imageToggle}
      </div>
    </React.Fragment>
  );
}
