import React, { useState, useEffect, useMemo } from 'react';
import ItemImage from './ItemImage';
import ItemColor from './ItemColor';
import { filter, find, includes, map, some } from 'lodash';
import classNames from 'classnames';
import { localPostJSON } from '../../fetch-local';
import Personalization, { newDefaultPersonalization } from './Personalization';
import CustomAnswer, { newDefaultAnswer } from './CustomAnswer';
import { useToggle } from '../custom-hooks';
import SlimLoader from '../SlimLoader';
import IconSvg from '../IconSvg';
import DisplayProductDetails from '../DisplayProductDetails';
import { Color } from 'BrandColor';

export default function ItemGroup(props) {
  const { name, items, signup, birdBank, setSignup, enabled } = props;
  const [selectedItemId, setSelectedItem] = useState(items[0].id);
  const selectedItem = find(items, { id: selectedItemId });
  const [selectedSize, setSize] = useState();
  const [quantity, setQuantity] = useState('1');
  const [personalizations, setPersonalizations] = useState([]);
  const [customAnswers, setCustomAnswers] = useState([]);
  const [detailsIsOpen, setDetailsIsOpen] = useState(false);
  const [isAdding, toggleIsAdding] = useToggle(false);
  const locationIds = useMemo(() => {
    const filteredHeatPressLocations = filter(selectedItem.heatPressLocations, {
      personalized: true,
    });
    return map(filteredHeatPressLocations, 'id');
  }, [selectedItemId]);

  const resetCustomAnswers = () => {
    setCustomAnswers(
      selectedItem.questions.map(question => newDefaultAnswer(question))
    );
  };
  const resetPersonalizations = () => {
    const filteredHeatPressLocations = filter(selectedItem.heatPressLocations, {
      personalized: true,
    });
    setPersonalizations(
      filteredHeatPressLocations.map(location =>
        newDefaultPersonalization(location)
      )
    );
  };

  useEffect(() => resetPersonalizations(), [locationIds]);

  useEffect(() => resetCustomAnswers(), [selectedItemId]);

  const onColorSelect = item => {
    setSelectedItem(item.id);
  };

  const onSizeSelect = e => {
    setSize(e.target.value);
  };

  const addQuantity = e => {
    e && setQuantity(e.target.value);
  };

  const openDetails = e => {
    e.preventDefault();
    setDetailsIsOpen(true);
  };

  const addToBag = e => {
    e.preventDefault();

    if (!selectedSize) return;

    toggleIsAdding(true);

    const signupParams = {
      signup: {
        bird_bank_id: birdBank.id,
        signup_line_items_attributes: [
          {
            quantity: quantity,
            size: selectedSize,
            bird_bank_item_id: selectedItem.id,
            answers_attributes: customAnswers.map(({ answer, question }) => ({
              answer,
              question_id: question.id,
            })),
            personalizations_attributes: personalizations.map(
              ({ name, number, location }) => ({
                name,
                number,
                location_id: location.id,
              })
            ),
          },
        ],
      },
    };

    if (signup.id) {
      signupParams.signup['id'] = signup.id;
      signupParams.signup['check_stamp'] = signup.updatedAt;
    }

    localPostJSON(signup.links.createPath, signupParams).then(({ signup }) => {
      setSignup(signup);
      setQuantity('1');
      setSize('');
      setSelectedItem(items[0].id);
      resetCustomAnswers();
      resetPersonalizations();
      toggleIsAdding(false);
    });
  };

  const selectedImage = (
    <ItemImage key={selectedItem} images={selectedItem.images} />
  );

  const itemColorSelector = items.map(item => (
    <ItemColor
      key={item.id}
      item={item}
      isColorSelected={selectedItem === item}
      onColorSelect={() => onColorSelect(item)}
    />
  ));

  const sizeOptionElements = selectedItem.translatedSizes.map(size => (
    <option
      key={size.value}
      value={size.value}
      disabled={includes(selectedItem.outOfStockSizes, size.label)}
    >
      {includes(selectedItem.outOfStockSizes, size.label)
        ? `${size.label} - out of stock`
        : size.label}
    </option>
  ));

  const sizeSelect = (
    <div className="label-select">
      <select
        value={selectedSize}
        onChange={onSizeSelect}
        className="size-select"
      >
        <option value="">Size</option>
        {sizeOptionElements}
      </select>
    </div>
  );

  const enterQuantity = (
    <input
      type="number"
      placeholder="Quantity"
      id={`${selectedItemId}`}
      value={quantity}
      onChange={addQuantity}
      disabled={selectedItem.colorOutOfStock}
      className={
        selectedItem.colorOutOfStock
          ? `disabled-view size-quantity-${selectedItemId}`
          : `size-quantity-${selectedItemId}`
      }
    />
  );

  const anyPersonalizationsBlank = some(
    personalizations,
    personalization => !(personalization.name || personalization.number)
  );
  const anyAnswersBlank = some(
    customAnswers,
    customAnswer => !customAnswer.answer
  );

  const addToBagButtonDisabled =
    selectedItem.colorOutOfStock ||
    !quantity ||
    parseInt(quantity) < 1 ||
    !selectedSize ||
    anyPersonalizationsBlank ||
    anyAnswersBlank ||
    isAdding;

  const fbCartTracking = () => {
    try {
      fbq('track', 'AddToCart');
    } catch (error) {
      console.log('No tracking code detected.');
    }
  };

  const addToBagButton = enabled && (
    <button
      onClick={fbCartTracking}
      disabled={addToBagButtonDisabled}
      className={classNames(
        'bb-button',
        'bb-button--full',
        `add-to-bag-button-${selectedItemId}`
      )}
    >
      <SlimLoader isActive={isAdding}>Add To Bag</SlimLoader>
    </button>
  );

  let outOfStockVerbiage;
  if (selectedItem.itemOutOfStock) {
    outOfStockVerbiage = 'Item is Out of Stock';
  } else if (selectedItem.colorOutOfStock) {
    outOfStockVerbiage = 'Color is Out of Stock';
  }

  const outOfStockAlert = outOfStockVerbiage && (
    <em className="bb-alert-text disabled-admin-view">{outOfStockVerbiage}</em>
  );

  const handlePersonalizationUpdate = (changes, pIndex) => {
    setPersonalizations(prevousPersonalizations =>
      prevousPersonalizations.map((personalization, index) =>
        index === pIndex ? { ...personalization, ...changes } : personalization
      )
    );
  };

  const personalizationsFields = personalizations.map(
    (personalization, index) => (
      <Personalization
        key={index}
        onChange={e => handlePersonalizationUpdate(e, index)}
        {...personalization}
      />
    )
  );

  const handleCustomAnswerUpdate = (changes, aIndex) => {
    setCustomAnswers(previousCustomAnswers =>
      previousCustomAnswers.map((customAnswer, index) =>
        index === aIndex ? { ...customAnswer, ...changes } : customAnswer
      )
    );
  };

  const {
    universityTees,
    productDescription,
    productId,
    customItem,
    imagePrimary,
    sizeChart,
  } = selectedItem;

  const displayPrice =
    birdBank.showPricingInfo &&
    (selectedItem.priceFixed ? (
      <h4 className="bb-sub-headline inline">{selectedItem.amount}</h4>
    ) : (
      <>
        <h4 className="bb-sub-headline mbn">{selectedItem.amount}</h4>
        <span data-tooltip="Prices vary depending on how many people order. The higher the final quantity, the lower the final price may be.">
          <IconSvg icon="info" color={Color.Blue} />
        </span>
      </>
    ));

  const answerFields = customAnswers.map((customAnswer, index) => (
    <CustomAnswer
      key={index}
      onChange={e => handleCustomAnswerUpdate(e, index)}
      {...customAnswer}
    />
  ));

  const displayVariant = selectedItem.variant && (
    <h4 className="bb-sub-headline txt-upcase">{selectedItem.variant}</h4>
  );

  const displayFreeShipping = birdBank.individualShip && (
    <div className="label bb-label--notice inline mlm">Shipping Included</div>
  );

  const displayProductInfo =
    !customItem &&
    universityTees &&
    (sizeChart.measurements.length > 0 ||
      imagePrimary.url ||
      !!productDescription);

  return (
    <form onSubmit={addToBag} className="bb-grid__item">
      <div className="bb-grid__item-main">
        <div className="bb-image-wrapper">{selectedImage}</div>
        <div className="item-description">
          <h3 className="bb-item-headline">
            {name} - {selectedItem.color}
          </h3>
          {displayProductInfo && (
            <div>
              <DisplayProductDetails
                isOpen={detailsIsOpen}
                closeModal={() => {
                  setDetailsIsOpen(false);
                }}
                productDescription={productDescription}
                productId={productId}
                name={name}
                imagePrimary={imagePrimary}
                sizeChart={sizeChart}
              />
              <button
                className="bb-button--pseudo txt-small pan mtn mbs"
                onClick={openDetails}
              >
                Product Information Available
              </button>
            </div>
          )}
          {displayVariant}
          <div className="flex-rows">
            <div className="flex-rows flex-rows--center-v">{displayPrice}</div>
            <div>{displayFreeShipping}</div>
          </div>
        </div>

        <div className="bb-color-swatches mtm">{itemColorSelector}</div>

        {outOfStockAlert}

        <div className="bb-form-grid form-compact--small form-light form-light--bordered">
          {sizeSelect}
          {enterQuantity}
        </div>
      </div>
      {personalizationsFields}
      {answerFields}
      <div className="bb-grid__item-cta">{addToBagButton}</div>
    </form>
  );
}
