import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Loader from './loader';
import { localGet, localPostJSON, localDelete } from '../fetch-local';
import Icon from './Icon';
import { Color } from '../BrandColor';

interface Props {
  links: {
    self: string;
  };
  canCreate: boolean;
  canDestroy: boolean;
  userId: number;
  customerProfilePresent: boolean;
}

interface InvitableURIs {
  bird_bank_admin: string;
  bird_bank_organizer: string;
  sales_rep: string;
  manufacturer: string;
  business_development_leader: string;
}

interface Data {
  fullName: string;
  invitations: Invitation[];
  roles: Role[];
  links: {
    invitables: InvitableURIs;
  };
}

interface Role {
  id: string;
  name: string;
  invitablesUri?: string;
}

interface Invitable {
  id: number;
  recordType: string;
  description: string | null;
  links: { self: string };
}

interface Invitation {
  id: number;
  role: string;
  invitable?: Invitable;
  claimedOn: string;
  links: { self: string };
}

interface AvailableInvitable {
  id: number;
  recordType: string;
  description: string;
}

export default function InvitationManager(props: Props) {
  const [invitations, setInvitations] = useState<Invitation[] | null>(null);
  const [roles, setRoles] = useState<Role[] | null>(null);
  const [selectedRoleId, setSelectedRoleId] = useState<string | undefined>(
    undefined
  );
  const [invitables, setInvitables] = useState<AvailableInvitable[] | null>(
    null
  );
  const [selectedInvitable, setSelectedInvitable] = useState<
    number | undefined
  >(undefined);
  const [creating, setCreating] = useState(false);
  const [loadingInvitables, setLoadingInvitables] = useState(false);

  const loading = invitations === null;
  const noInvitations = invitations !== null && invitations.length === 0;
  const someInvitations = invitations !== null && invitations.length > 0;
  const { canCreate, canDestroy, links, userId } = props;

  // Fetch data on mount
  useEffect(() => {
    localGet(links.self).then((data: Data) => {
      setInvitations(data.invitations);
      setRoles(data.roles);
    });
  }, [links.self]);

  // Fetch invitables for non-system roles
  useEffect(() => {
    const selectedRole =
      roles && selectedRoleId && roles.find(r => r.id === selectedRoleId);

    setInvitables(null);
    setSelectedInvitable(undefined);

    if (!selectedRole || !selectedRole.invitablesUri) return;

    setLoadingInvitables(true);
    localGet(selectedRole.invitablesUri)
      .then(data => {
        setInvitables(data.data);
      })
      .finally(() => setLoadingInvitables(false));
  }, [roles, selectedRoleId]);

  const invitableOptions =
    invitables &&
    invitables.map(i => ({
      value: i.id,
      label: i.description,
    }));

  const roleOptions =
    roles &&
    roles.map(r => ({
      value: r.id,
      label: r.name,
    }));

  const onDestroy = url => {
    localDelete(url).then((data: Data) => {
      setSelectedRoleId(undefined);
      setInvitations(data.invitations);
      setRoles(data.roles);
    });
  };

  const onSubmit = () => {
    let body: any = {
      user_id: userId,
      role: selectedRoleId,
    };

    if (selectedInvitable) {
      const invitable =
        invitables && invitables.find(i => i.id === selectedInvitable);

      body = invitable
        ? {
            ...body,
            invitable_id: invitable.id,
            invitable_type: invitable.recordType,
          }
        : {};
    }

    setCreating(true);

    localPostJSON('/admin/invitations', body)
      .then((data: Data) => {
        setSelectedRoleId(undefined);
        setInvitations(data.invitations);
        setRoles(data.roles);
      })
      .finally(() => {
        setCreating(false);
      });
  };

  const currentRole =
    roles && selectedRoleId && roles.find(r => r.id === selectedRoleId);

  const allowCreate =
    currentRole && (!currentRole.invitablesUri || selectedInvitable);
  const disableAddRole = !allowCreate;

  const customerProfileWarning = props.customerProfilePresent && (
    <p className="txt-small txt-warning mvn">
      <i className="fa fa-exclamation-triangle mrs"></i>
      This user has a customer profile. Proceed with caution.
    </p>
  );

  return (
    <div className="stack stack--large">
      {loading && <Loader />}
      {noInvitations && (
        <p className="txt-small txt-muted2">
          This user has not been invited to any entities.
        </p>
      )}
      {someInvitations && (
        <div className="scrollable-vertical">
          <table className="table">
            <thead className="table--header-new">
              <tr className="txt-left">
                <th>Role</th>
                <th>ID</th>
                <th>Type</th>
                <th>Description</th>
                <th>Claimed On</th>
              </tr>
            </thead>
            <tbody>
              {invitations &&
                invitations.map(invitation => (
                  <tr key={invitation.id}>
                    <td>
                      <strong>
                        <code>{invitation.role}</code>
                      </strong>
                    </td>
                    <td>
                      {invitation.invitable ? (
                        <code>{invitation.invitable.id}</code>
                      ) : (
                        <span className="txt-muted2">—</span>
                      )}
                    </td>
                    <td>
                      {invitation.invitable ? (
                        <code>{invitation.invitable.recordType}</code>
                      ) : (
                        <span className="txt-muted2">—</span>
                      )}
                    </td>
                    <td>
                      {invitation.invitable &&
                      invitation.invitable.description ? (
                        <a href={invitation.invitable.links.self}>
                          {invitation.invitable.description}
                        </a>
                      ) : (
                        <span className="txt-muted2">—</span>
                      )}
                    </td>
                    <td>{invitation.claimedOn}</td>
                    {canDestroy && (
                      <td>
                        <button
                          className="button-naked"
                          onClick={e => {
                            onDestroy(invitation.links.self);
                          }}
                        >
                          <Icon type="trash" color={Color.Red} />
                        </button>
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
      {roles && canCreate && (
        <div className="grid-row">
          <div className="grid-col-5 stack">
            <div className="stack stack--small">
              <label htmlFor="roles">Role</label>
              {customerProfileWarning}
              <Select
                options={roleOptions}
                onChange={({ value }) => {
                  setSelectedRoleId(value);
                }}
                value={selectedRoleId}
                id="roles"
                placeholder={'Select a role'}
              />
            </div>
            <div>
              {invitables && invitableOptions && (
                <Select
                  options={invitableOptions}
                  onChange={({ value }) => {
                    setSelectedInvitable(value);
                  }}
                  value={selectedInvitable}
                  placeholder={'Select an option'}
                />
              )}
              {loadingInvitables && <Loader />}
            </div>
            <div>
              {creating ? (
                <Loader />
              ) : (
                <button
                  className="button button-primary"
                  onClick={e => {
                    onSubmit();
                  }}
                  disabled={disableAddRole}
                >
                  Add Role
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
