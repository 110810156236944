import swal from 'sweetalert';
const Promise = window.Promise;

interface Options {
  title: string;
  text: string;
  confirmLabel: string;
  cancelLabel: string;
}

export const modalConfirm = (options: Options): Promise<boolean> => {
  const { title, text, confirmLabel, cancelLabel } = options;

  const swalOptions = {
    title,
    text,
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#FE4438',
    confirmButtonText: confirmLabel,
    cancelButtonText: cancelLabel,
    html: true,
    closeOnConfirm: true,
  };

  return new Promise(resolve => {
    swal(swalOptions, (wasConfirmed: boolean) => {
      resolve(wasConfirmed);
    });
  });
};
