import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { intersection } from 'lodash';

import Comment from './comment';
const GlobalData = window.GlobalData;

class Comments extends React.Component {
  static propTypes = {
    location_id: PropTypes.number,
  };

  state = {
    model: new window.CommentModel(this.props.location_id),
    comments: [],
    loading: false,
    file_name: null,
    file_uri: null,
    file_type: null,
  };

  componentWillMount() {
    let _this = this;
    this.state.model.subscribe(function() {
      _this.setState({ model: _this.state.model });
    });
  }

  componentDidUpdate() {
    let commentsNode = ReactDOM.findDOMNode(this.refs.comments);
    commentsNode.scrollTop = commentsNode.scrollHeight;
  }

  _handleKeyPress = event => {
    if (event.keyCode == 13 && !event.shiftKey) {
      event.preventDefault();
      this._addComment();
    }
  };

  _handleSubmit = event => {
    event.preventDefault();
    this._addComment();
  };

  _addComment = () => {
    let _this = this;
    let newMessageNode = this.refs.newMessage;
    if (newMessageNode.value == '') {
      this.setState({ noMessage: true });
      newMessageNode.focus();
      return;
    } else {
      this.setState({ noMessage: false });
    }

    this.setState({ loading: true });

    let newComment = {
      message: newMessageNode.value,
    };
    if (this.state.file_name) {
      newComment['attachment_data_filename'] = this.state.file_name
        ? this.state.file_name
        : '';
      newComment['attachment_data_uri'] = this.state.file_uri
        ? this.state.file_uri
        : '';
    }
    this.state.model.addComment(newComment, function() {
      newMessageNode.value = '';
      _this.setState({
        file_name: null,
        file_uri: null,
        file_type: null,
        loading: false,
      });
      newMessageNode.focus();
    });
  };

  _comments = () => {
    if (this.state.model.comments.length == 0) {
      return <div className="comments-empty">No Comments Yet</div>;
    }

    return this.state.model.comments.map(function(comment) {
      return <Comment comment={comment} key={'comment-' + comment.id} />;
    });
  };

  _onDrop = files => {
    let _this = this;
    let fileName = files[0].name;
    let fileType = files[0].type;
    let reader = new FileReader();
    reader.onload = (function(file, name) {
      return function(e) {
        _this.setState({
          file_name: fileName,
          file_uri: e.target.result,
          file_type: fileType,
        });
      };
    })(files[0], null);
    reader.readAsDataURL(files[0]);
  };

  _onOpenClick = event => {
    event.preventDefault();
    this.refs.dropzone.open();
  };

  _clearFile = event => {
    event.preventDefault();
    this.setState({
      file_name: null,
      file_uri: null,
      file_type: null,
    });
  };

  render() {
    let _this = this;
    let file, fileName, icon;

    if (this.state.file_name) {
      let iconClass = this.state.file_type.match(/^image/)
        ? 'fa-file-image-o'
        : 'fa-file-word-o';
      if (this.state.file_type.match(/^image/))
        icon = <i className={'fa fa-file-image-o'} />;
      else icon = <i className={'fa fa-file-word-o'} />;

      file = (
        <a
          href="#"
          onClick={this._clearFile}
          className="button button--muted new-comment-file-attached-button"
        >
          <i className="fa fa-check" /> {icon}
        </a>
      );
      fileName = this.state.file_name;
    } else {
      file = (
        <a
          href="#"
          onClick={this._onOpenClick}
          className="button-pseudo txt-muted"
        >
          <i className="fa fa-2x fa-paperclip" />
        </a>
      );
      fileName = ' ';
    }
    if (
      GlobalData.postCheckoutOrBlocked &&
      intersection(GlobalData.currentUserRoles, [
        'uberadmin',
        'artist',
        'admin',
      ]).length == 0
    ) {
      return (
        <div className="comments-container">
          <h5 className="mtn mbs quaternary-headline">
            Comments ({this.state.model.comments.length})
          </h5>
          <div className="comments" ref="comments">
            {this._comments()}
          </div>
        </div>
      );
    } else {
      return (
        <div className="comments-container">
          <h5 className="mtn mbs quaternary-headline">
            Comments ({this.state.model.comments.length})
          </h5>
          <div className="comments" ref="comments">
            {this._comments()}
          </div>
          <Dropzone
            onDrop={this._onDrop}
            disableClick={true}
            ref="dropzone"
            className="comment-file"
            activeClassName="comment-file-active"
          >
            <div>
              <div className="new-comment row mts">
                <textarea
                  ref="newMessage"
                  className={
                    'col-12 mbs ' + (this.state.noMessage ? 'invalid' : '')
                  }
                  placeholder="Add a Comment"
                  type="text"
                  rows="2"
                  onKeyDown={this._handleKeyPress}
                  disabled={this.state.loading}
                />
              </div>
              <div className="row">
                <div className="col-3">{file}</div>
                <div className="col-6">
                  <div className="button button--full button-naked txt-normal txt-truncate mln mrn pln prn">
                    {fileName}
                  </div>
                </div>
                <div className="col-3 txt-right">
                  <button
                    className="button"
                    onClick={this._handleSubmit}
                    disabled={this.state.loading}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </Dropzone>
        </div>
      );
    }
  }
}

export default Comments;
