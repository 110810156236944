import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import PersonalInformationFields from './PersonalInformationFields';
import AddressFields from './AddressFields';
import SchoolAndOrganizationFields from './SchoolAndOrganizationFields';
import ReviewAndPay from './ReviewAndPay';
import { validateEmail } from '../../../utility';
import { useToggle } from '../../custom-hooks';
import { some } from 'lodash';

const Checkout = ({
  birdBank,
  signup,
  onChange,
  onChangeSignupLineItem,
  saveSignupLineItem,
  onDeleteSignupLineItem,
  onClose,
  modalIsOpen,
  onSubmitStep,
  schoolOptions,
  organizationOptions,
  graduationYearOptions,
  chapterPositionOptions,
  updateAnswer,
  editable,
  failedToSave,
  errorMessage,
}) => {
  const {
    firstName,
    lastName,
    email,
    phone,
    address,
    addressTwo,
    city,
    noCharge,
    state,
    zip,
    schoolId,
    schoolNameIfOther,
    organizationId,
    organizationNameIfOther,
    graduationYear,
    chapterPosition,
    answers,
  } = signup;

  const {
    individualPayment,
    isCollegiate,
    nationalOrder,
    questions,
    requestSchoolOrgInfo,
  } = birdBank;

  const [currentStep, setCurrentStep] = useState(0);
  const [addressVerified, toggleAddressVerified] = useToggle(
    firstName &&
      lastName &&
      email &&
      phone &&
      address &&
      city &&
      state &&
      zip &&
      validateEmail(email)
  );

  useEffect(() => {
    if (signup.dirty) {
      onSubmitStep(
        () => {},
        () => setCurrentStep(0)
      );
    }
  }, [currentStep]);

  const saveAndNext = (onSuccess, onFailure) => {
    onSubmitStep(() => nextStep(onSuccess, onFailure));
  };

  const personalInformationForm = () => (
    <PersonalInformationFields
      {...{
        firstName,
        lastName,
        email,
        phone,
        onChange,
        questions,
        answers,
        updateAnswer,
        failedToSave,
        errorMessage,
      }}
      onSubmit={saveAndNext}
    />
  );

  const addressForm = () => (
    <AddressFields
      {...{
        address,
        addressTwo,
        city,
        state,
        zip,
        onChange,
        failedToSave,
        addressVerified,
        toggleAddressVerified,
      }}
      onSubmit={saveAndNext}
    />
  );

  const schoolAndOrgInfoForm = () => (
    <SchoolAndOrganizationFields
      {...{
        schoolId,
        schoolNameIfOther,
        organizationId,
        organizationNameIfOther,
        graduationYear,
        chapterPosition,
        onChange,
        schoolOptions,
        organizationOptions,
        graduationYearOptions,
        chapterPositionOptions,
        failedToSave,
        nationalOrder,
      }}
      onSubmit={saveAndNext}
    />
  );

  const reviewAndPayForm = () => (
    // TODO only show payment info if individual pay
    <ReviewAndPay
      {...{ birdBank, signup, failedToSave }}
      paymentEnabled={!noCharge && birdBank.individualPayment}
      updateSignupLineItem={onChangeSignupLineItem}
      saveSignupLineItem={saveSignupLineItem}
      deleteSignupLineItem={onDeleteSignupLineItem}
      editable={editable}
      updateSignup={onChange}
      failedToSave={failedToSave}
    />
  );

  const steps = [
    {
      name: 'Personal Information',
      completed:
        !!firstName && !!lastName && !!email && validateEmail(email) && !!phone,
      show: true,
      render: personalInformationForm,
    },
    {
      name: 'Address',
      completed:
        firstName &&
        lastName &&
        email &&
        phone &&
        address &&
        city &&
        state &&
        zip &&
        validateEmail(email) &&
        addressVerified,
      show: birdBank.individualShip,
      render: addressForm,
      skippable: !birdBank.individualShip,
    },
    {
      name: 'School & Org Info',
      completed:
        firstName &&
        lastName &&
        email &&
        phone &&
        address &&
        city &&
        state &&
        zip &&
        organizationId &&
        schoolId &&
        graduationYear &&
        chapterPosition &&
        validateEmail(email),
      show: requestSchoolOrgInfo,
      render: schoolAndOrgInfoForm,
      skippable: true,
    },
    {
      name: individualPayment ? 'Review & Pay' : 'Review',
      completed: false,
      show: true,
      render: reviewAndPayForm,
    },
  ];

  const nextStep = () => {
    setCurrentStep(previousStep => previousStep + 1);
  };

  const filteredSteps = steps.filter(({ show }) => show);

  const allPreviousStepsCompleted = !some(
    steps.slice(0, currentStep),
    ({ completed, skippable }) => !completed && !skippable
  );

  const renderedSteps =
    allPreviousStepsCompleted &&
    filteredSteps.map(({ name, completed, render }, index) => {
      const enabled =
        index == 0 ||
        filteredSteps[index - 1].completed ||
        (filteredSteps[index - 1]['skippable'] &&
          filteredSteps[index - 2].completed);

      const skipped = !completed && currentStep > index;

      return (
        <section className="bb-checkout-section" key={name}>
          <header className="bb-checkout-section-header">
            <h3 className="bb-item-headline mvn">
              {enabled && !failedToSave ? (
                <a
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    enabled && setCurrentStep(index);
                  }}
                >
                  {name}
                </a>
              ) : (
                <span className="bb-muted">{name}</span>
              )}
            </h3>
            <div className="step-number">
              {completed || skipped ? (
                <i className="icon icon-checkmark">Success</i>
              ) : (
                <span className="bb-circle-num">{index + 1}</span>
              )}
            </div>
          </header>
          {currentStep == index && <React.Fragment>{render()}</React.Fragment>}
        </section>
      );
    });

  const incompleteErrorMessage = !allPreviousStepsCompleted && (
    <section className="bb-checkout-section" key={name}>
      <header className="bb-checkout-section-header">
        <h3 className="bb-item-headline mvn">Error!</h3>
      </header>
      <div
        className={classNames(
          'bb-form-vertical',
          'form-compact--small',
          'form-light',
          'form-light--bordered',
          'mtm'
        )}
      >
        <p>
          Something went wrong. This page will automatically reload. Please try
          again.
        </p>
      </div>
    </section>
  );

  useEffect(() => {
    if (!allPreviousStepsCompleted)
      setTimeout(() => {
        window.location.reload();
      }, 3000);
  }, [allPreviousStepsCompleted]);

  return (
    <Modal
      isOpen={modalIsOpen}
      className="react-modal bb-checkout-modal"
      overlayClassName="react-modal-overlay"
      contentLabel="Example Modal"
      closeTimeoutMS={200}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      onRequestClose={onClose}
    >
      <div className="react-modal-header flex-rows flex-rows--space-b">
        <h1 className="bb-item-headline mvn">Checkout</h1>
        <button
          className="bb-button-close bb-button-close--blue"
          type="button"
          onClick={onClose}
        >
          Close
        </button>
      </div>
      <div className="react-modal-body">
        {renderedSteps}
        {incompleteErrorMessage}
      </div>
    </Modal>
  );
};

Checkout.propTypes = {
  onChange: PropTypes.func,
};

export default Checkout;
