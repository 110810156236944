import React, { FunctionComponent } from 'react';
import ActionMenu from './ActionMenu';
import ArchiveOpportunityIcon, {
  Props as ArchiveOpportunityIconProps,
} from './ArchiveOpportunityIcon';
import { localPostJSON } from '../fetch-local';

interface Props extends ArchiveOpportunityIconProps {
  viewOpportunityPath: string;
  editOpportunityPath: string;
  newOrderPath: string;
  showOrder?: boolean;
  viewOrderPath: string;
  showPastOrder?: boolean;
  viewPastOrderPath: string;
  showArchive?: boolean;
  showEdit?: boolean;
  unarchiveFormPath: string;
  status: string;
}

const ArchiveButton: FunctionComponent = () => {
  return (
    <button type="button">
      <i className="fa fa-archive fa-fw"></i> Archive
    </button>
  );
};

const OpportunityMenu: FunctionComponent<Props> = ({
  viewOpportunityPath,
  archiveFormPath,
  editOpportunityPath,
  newOrderPath,
  viewOrderPath,
  showOrder = false,
  showArchive = false,
  showEdit = false,
  unarchiveFormPath,
  status,
  showPastOrder,
  viewPastOrderPath,
}) => {
  function handleUnarchive() {
    localPostJSON(unarchiveFormPath, {})
      .then(() => {
        window.location.reload();
      })
      .catch(console.error);
  }

  return (
    <ActionMenu dropdownRight dropdownSmall>
      <a href={viewOpportunityPath}>
        <i className="fa fa-arrow-circle-right fa-fw"></i> View
      </a>

      {showEdit && (
        <a href={editOpportunityPath}>
          <i className="fa fa-edit fa-fw"></i> Edit
        </a>
      )}

      {showOrder && (
        <a href={viewOrderPath}>
          <i className="fa fa-arrow-circle-right fa-fw"></i> View order
        </a>
      )}

      {showArchive && status !== 'archived' && (
        <ArchiveOpportunityIcon
          ButtonComponent={ArchiveButton}
          archiveFormPath={archiveFormPath}
        />
      )}

      {showArchive && status === 'archived' && (
        <button onClick={handleUnarchive} type="button">
          <i className="fa fa-archive fa-fw"></i> Unarchive
        </button>
      )}
    </ActionMenu>
  );
};

export default OpportunityMenu;
