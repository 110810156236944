import React from 'react';
import { Color } from '../BrandColor';

export type Icon =
  | 'clip'
  | 'grip'
  | 'more'
  | 'upload'
  | 'ellipsis'
  | 'document'
  | 'close'
  | 'warning'
  | 'info'
  | 'download'
  | 'check'
  | 'copy'
  | 'redo'
  | 'archive'
  | 'bruno';

interface Props {
  color?: Color;
  icon: Icon;
  size?: number;
}

const icons = {
  clip:
    'M2.3514375,14.5669375 C0.5255,12.6829063 0.55953125,9.64471875 2.39475,7.76753125 L8.94975,1.0625 C10.334625,-0.354125 12.5857188,-0.35425 13.9707188,1.0625 C15.3422813,2.4654375 15.3439375,4.72903125 13.9707188,6.133625 L8.2566875,11.97275 C7.32371875,12.9270312 5.79940625,12.9137187 4.88225,11.9415625 C3.99865625,11.005 4.027,9.52053125 4.927625,8.59928125 L9.41959375,4.0106875 C9.61278125,3.813375 9.92934375,3.81 10.1266563,4.00315625 L10.8410625,4.7025 C11.0384063,4.8956875 11.04175,5.21225 10.8485938,5.4095625 L6.35709375,9.99771875 C6.20296875,10.155375 6.19346875,10.4173438 6.33684375,10.5693438 C6.47346875,10.7141563 6.68825,10.7165625 6.82709375,10.5745 L12.541125,4.73540625 C13.1540313,4.10846875 13.1540313,3.08775 12.5407813,2.46046875 C11.941125,1.847125 10.979625,1.8468125 10.3797188,2.46046875 L3.8246875,9.16546875 C2.73834375,10.2767188 2.72159375,12.0754688 3.78746875,13.17525 C4.85028125,14.2719063 6.56825,14.2732813 7.63303125,13.1841875 L13.0099063,7.68421875 C13.2029375,7.48675 13.5195,7.4831875 13.7169688,7.67621875 L14.431875,8.37509375 C14.6293438,8.568125 14.6329375,8.8846875 14.439875,9.08215625 L9.063,14.5820937 C7.20125,16.4864062 4.18978125,16.4638437 2.3514375,14.5669375 Z',
  grip:
    'M15.5,9 L0.5,9 C0.225,9 0,9.225 0,9.5 L0,10.5 C0,10.775 0.225,11 0.5,11 L15.5,11 C15.775,11 16,10.775 16,10.5 L16,9.5 C16,9.225 15.775,9 15.5,9 Z M15.5,5 L0.5,5 C0.225,5 0,5.225 0,5.5 L0,6.5 C0,6.775 0.225,7 0.5,7 L15.5,7 C15.775,7 16,6.775 16,6.5 L16,5.5 C16,5.225 15.775,5 15.5,5 Z',
  more:
    'M8,0 C12.418278,0 16,3.581722 16,8 C16,12.418278 12.418278,16 8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,0 8,0 Z M8,2 C4.6862915,2 2,4.6862915 2,8 C2,11.3137085 4.6862915,14 8,14 C11.3137085,14 14,11.3137085 14,8 C14,4.6862915 11.3137085,2 8,2 Z M8,7 C8.55277778,7 9,7.44722222 9,8 C9,8.55277778 8.55277778,9 8,9 C7.44722222,9 7,8.55277778 7,8 C7,7.44722222 7.44722222,7 8,7 Z M11,7 C11.5527778,7 12,7.44722222 12,8 C12,8.55277778 11.5527778,9 11,9 C10.4472222,9 10,8.55277778 10,8 C10,7.44722222 10.4472222,7 11,7 Z M5,7 C5.55277778,7 6,7.44722222 6,8 C6,8.55277778 5.55277778,9 5,9 C4.44722222,9 4,8.55277778 4,8 C4,7.44722222 4.44722222,7 5,7 Z',
  upload:
    'M9.25,11.999414 L6.75,11.999414 C6.334375,11.999414 6,11.66499 6,11.2493041 L6,5.99853494 L3.259375,5.99853494 C2.703125,5.99853494 2.425,5.32656151 2.81875,4.93275382 L7.571875,0.175807003 C7.80625,-0.0586023343 8.19062496,-0.0586023343 8.425,0.175807003 L13.18125,4.93275382 C13.575,5.32656151 13.296875,5.99853494 12.740625,5.99853494 L10,5.99853494 L10,11.2493041 C10,11.66499 9.66562496,11.999414 9.25,11.999414 Z M16,11.7493774 L16,15.2498901 C16,15.665576 15.665625,16 15.25,16 L0.75,16 C0.334375,16 0,15.665576 0,15.2498901 L0,11.7493774 C0,11.3336915 0.334375,10.9992675 0.75,10.9992675 L5,10.9992675 L5,11.2493041 C5,12.2150705 5.784375,12.9995605 6.75,12.9995605 L9.25,12.9995605 C10.215625,12.9995605 11,12.2150705 11,11.2493041 L11,10.9992675 L15.25,10.9992675 C15.665625,10.9992675 16,11.3336915 16,11.7493774 Z M12.125,14.4997802 C12.125,14.1559799 11.84375,13.8746887 11.5,13.8746887 C11.15625,13.8746887 10.875,14.1559799 10.875,14.4997802 C10.875,14.8435806 11.15625,15.1248718 11.5,15.1248718 C11.84375,15.1248718 12.125,14.8435806 12.125,14.4997802 Z M14.125,14.4997802 C14.125,14.1559799 13.84375,13.8746887 13.5,13.8746887 C13.15625,13.8746887 12.875,14.1559799 12.875,14.4997802 C12.875,14.8435806 13.15625,15.1248718 13.5,15.1248718 C13.84375,15.1248718 14.125,14.8435806 14.125,14.4997802 Z',
  ellipsis:
    'M10.3225806,8.32258065 C10.3225806,9.60645161 9.28387097,10.6451613 8,10.6451613 C6.71612903,10.6451613 5.67741935,9.60645161 5.67741935,8.32258065 C5.67741935,7.03870968 6.71612903,6 8,6 C9.28387097,6 10.3225806,7.03870968 10.3225806,8.32258065 Z M13.6774194,6 C12.3935484,6 11.3548387,7.03870968 11.3548387,8.32258065 C11.3548387,9.60645161 12.3935484,10.6451613 13.6774194,10.6451613 C14.9612903,10.6451613 16,9.60645161 16,8.32258065 C16,7.03870968 14.9612903,6 13.6774194,6 Z M2.32258065,6 C1.03870968,6 0,7.03870968 0,8.32258065 C0,9.60645161 1.03870968,10.6451613 2.32258065,10.6451613 C3.60645161,10.6451613 4.64516129,9.60645161 4.64516129,8.32258065 C4.64516129,7.03870968 3.60645161,6 2.32258065,6 Z',
  document:
    'M9,4.25 L9,0 L2.75,0 C2.334375,0 2,0.334375 2,0.75 L2,15.25 C2,15.665625 2.334375,16 2.75,16 L13.25,16 C13.665625,16 14,15.665625 14,15.25 L14,5 L9.75,5 C9.3375,5 9,4.6625 9,4.25 Z M14,3.809375 L14,4 L10,4 L10,0 L10.190625,0 C10.390625,0 10.58125,0.078125 10.721875,0.21875 L13.78125,3.28125 C13.921875,3.421875 14,3.6125 14,3.809375 Z',
  close:
    'M8,0 C3.58064516,0 0,3.58064516 0,8 C0,12.4193548 3.58064516,16 8,16 C12.4193548,16 16,12.4193548 16,8 C16,3.58064516 12.4193548,0 8,0 Z M11.9225806,10.1 C12.0741935,10.2516129 12.0741935,10.4967742 11.9225806,10.6483871 L10.6451613,11.9225806 C10.4935484,12.0741935 10.2483871,12.0741935 10.0967742,11.9225806 L8,9.80645161 L5.9,11.9225806 C5.7483871,12.0741935 5.50322581,12.0741935 5.3516129,11.9225806 L4.07741935,10.6451613 C3.92580645,10.4935484 3.92580645,10.2483871 4.07741935,10.0967742 L6.19354839,8 L4.07741935,5.9 C3.92580645,5.7483871 3.92580645,5.50322581 4.07741935,5.3516129 L5.35483871,4.07419355 C5.50645161,3.92258065 5.7516129,3.92258065 5.90322581,4.07419355 L8,6.19354839 L10.1,4.07741935 C10.2516129,3.92580645 10.4967742,3.92580645 10.6483871,4.07741935 L11.9258065,5.35483871 C12.0774194,5.50645161 12.0774194,5.7516129 11.9258065,5.90322581 L9.80645161,8 L11.9225806,10.1 Z',
  warning:
    'M16,8 C16,12.4192581 12.4179677,16 8,16 C3.58203226,16 0,12.4192581 0,8 C0,3.58332258 3.58203226,0 8,0 C12.4179677,0 16,3.58332258 16,8 Z M8,9.61290323 C7.18048387,9.61290323 6.51612903,10.2772581 6.51612903,11.0967742 C6.51612903,11.9162903 7.18048387,12.5806452 8,12.5806452 C8.81951613,12.5806452 9.48387097,11.9162903 9.48387097,11.0967742 C9.48387097,10.2772581 8.81951613,9.61290323 8,9.61290323 Z M6.59119355,4.27916129 L6.83048387,8.66625806 C6.84167742,8.87154839 7.01141935,9.03225806 7.217,9.03225806 L8.783,9.03225806 C8.98858065,9.03225806 9.15832258,8.87154839 9.16951613,8.66625806 L9.40880645,4.27916129 C9.42090323,4.05741935 9.24435484,3.87096774 9.02229032,3.87096774 L6.97767742,3.87096774 C6.7556129,3.87096774 6.57909677,4.05741935 6.59119355,4.27916129 L6.59119355,4.27916129 Z',
  info:
    'M8,0 C3.58203226,0 0,3.58332258 0,8 C0,12.4192581 3.58203226,16 8,16 C12.4179677,16 16,12.4192581 16,8 C16,3.58332258 12.4179677,0 8,0 Z M8,3.5483871 C8.74825806,3.5483871 9.35483871,4.15496774 9.35483871,4.90322581 C9.35483871,5.65148387 8.74825806,6.25806452 8,6.25806452 C7.25174194,6.25806452 6.64516129,5.65148387 6.64516129,4.90322581 C6.64516129,4.15496774 7.25174194,3.5483871 8,3.5483871 Z M9.80645161,11.7419355 C9.80645161,11.9557097 9.63312903,12.1290323 9.41935484,12.1290323 L6.58064516,12.1290323 C6.36687097,12.1290323 6.19354839,11.9557097 6.19354839,11.7419355 L6.19354839,10.9677419 C6.19354839,10.7539677 6.36687097,10.5806452 6.58064516,10.5806452 L6.96774194,10.5806452 L6.96774194,8.51612903 L6.58064516,8.51612903 C6.36687097,8.51612903 6.19354839,8.34280645 6.19354839,8.12903226 L6.19354839,7.35483871 C6.19354839,7.14106452 6.36687097,6.96774194 6.58064516,6.96774194 L8.64516129,6.96774194 C8.85893548,6.96774194 9.03225806,7.14106452 9.03225806,7.35483871 L9.03225806,10.5806452 L9.41935484,10.5806452 C9.63312903,10.5806452 9.80645161,10.7539677 9.80645161,10.9677419 L9.80645161,11.7419355 Z',
  download:
    'M16,8 C16,12.4193548 12.4193548,16 8,16 C3.58064516,16 0,12.4193548 0,8 C0,3.58064516 3.58064516,0 8,0 C12.4193548,0 16,3.58064516 16,8 Z M11.3677419,7.06774194 L9.03225806,9.50322581 L9.03225806,3.61290323 C9.03225806,3.18387097 8.68709677,2.83870968 8.25806452,2.83870968 L7.74193548,2.83870968 C7.31290323,2.83870968 6.96774194,3.18387097 6.96774194,3.61290323 L6.96774194,9.50322581 L4.63225806,7.06774194 C4.33225806,6.75483871 3.83225806,6.7483871 3.52580645,7.05483871 L3.17419355,7.40967742 C2.87096774,7.71290323 2.87096774,8.20322581 3.17419355,8.50322581 L7.4516129,12.783871 C7.75483871,13.0870968 8.24516129,13.0870968 8.54516129,12.783871 L12.8258065,8.50322581 C13.1290323,8.2 13.1290323,7.70967742 12.8258065,7.40967742 L12.4741935,7.05483871 C12.1677419,6.7483871 11.6677419,6.75483871 11.3677419,7.06774194 Z',
  check:
    'M16,8 C16,12.4182903 12.4182903,16 8,16 C3.58170968,16 0,12.4182903 0,8 C0,3.58170968 3.58170968,0 8,0 C12.4182903,0 16,3.58170968 16,8 Z M7.07464516,12.2359355 L13.010129,6.30045161 C13.2116774,6.09890323 13.2116774,5.77209677 13.010129,5.57054839 L12.2802258,4.84064516 C12.0786774,4.63906452 11.751871,4.63906452 11.5502903,4.84064516 L6.70967742,9.68122581 L4.44970968,7.42125806 C4.24816129,7.21970968 3.92135484,7.21970968 3.71977419,7.42125806 L2.98987097,8.15116129 C2.78832258,8.35270968 2.78832258,8.67951613 2.98987097,8.88106452 L6.34470968,12.2359032 C6.54629032,12.4374839 6.87306452,12.4374839 7.07464516,12.2359355 L7.07464516,12.2359355 Z',
  copy:
    'M11,14 L11,15.25 C11,15.6642187 10.6642187,16 10.25,16 L1.75,16 C1.33578125,16 1,15.6642187 1,15.25 L1,3.75 C1,3.33578125 1.33578125,3 1.75,3 L4,3 L4,12.25 C4,13.2149688 4.78503125,14 5.75,14 L11,14 Z M11,3.25 L11,0 L5.75,0 C5.33578125,0 5,0.33578125 5,0.75 L5,12.25 C5,12.6642187 5.33578125,13 5.75,13 L14.25,13 C14.6642187,13 15,12.6642187 15,12.25 L15,4 L11.75,4 C11.3375,4 11,3.6625 11,3.25 Z M14.7803438,2.28034375 L12.7196562,0.21965625 C12.579006,0.0790132859 12.3882481,0 12.1893438,0 L12,0 L12,3 L15,3 L15,2.81065625 C15,2.61175194 14.9209867,2.42099396 14.7803438,2.28034375 L14.7803438,2.28034375 Z',
  redo:
    'M8.01467742,0 C10.1523871,0.00385171326 12.0932903,0.846238125 13.5262581,2.21565637 L14.6783548,1.06356053 C15.1660968,0.575851248 16,0.921270322 16,1.6110117 L16,5.93549205 C16,6.36307235 15.6533871,6.70968497 15.2258065,6.70968497 L10.9013226,6.70968497 C10.2115806,6.70968497 9.86616129,5.87578242 10.353871,5.38804088 L11.7006452,4.04126779 C10.7050323,3.10904274 9.41674194,2.59265606 8.04806452,2.58084962 C5.06748387,2.5551077 2.55506452,4.96720252 2.58083871,8.04671614 C2.60529032,10.9680363 4.97367742,13.4193569 8,13.4193569 C9.32667742,13.4193569 10.5805484,12.9458735 11.5686774,12.0788419 C11.7216774,11.9446162 11.9527419,11.9527775 12.0966774,12.0966806 L13.3760968,13.3760989 C13.5332581,13.5332601 13.5254839,13.7894857 13.3605484,13.9384533 C11.942,15.2197748 10.062129,16 8,16 C3.58174194,16 0,12.4182932 0,8.00007102 C0,3.58688106 3.60148387,-0.00789021268 8.01467742,0 Z',
  bruno:
    'M9.95946669,15.2185794 L11.4946442,16 L10.1980552,16 L9.60335465,15.697299 L9.95946669,15.2185794 Z M8.66287771,15.2185794 L10.1980552,16 L8.11168826,16 L8.66287771,15.2185794 Z M8.99239157,11.2060498 L9.95946669,15.2185794 L8.33040047,11.5666179 L8.99239157,11.2060498 Z M10.8171134,2.43717649 L13.8814695,3.12561148 L16,2.73490116 L14.5112247,4.31992251 L12.3303908,6.6418622 L13.0680566,8.98605304 L8.017,9.444 L9.83999171,9.27944123 L13.0680566,8.98605304 L8.33040047,11.5666179 L7.64087157,10.9759316 L8.66287771,15.2185794 L6.06124327,9.62287674 L3.85626864,9.82327929 L8.40687023,4.99463715 L10.8171134,2.43717649 Z M3.53470532,0 L6.24027504,7.29361479 L1,2.1953285 L3.53470532,0 Z M5.4829497,1.5627702 L6.24027504,7.29361479 L4.11437217,1.5627702 L5.4829497,1.5627702 Z',
  archive:
    'M1,14 C1,14.553125 1.446875,15 2,15 L14,15 C14.553125,15 15,14.553125 15,14 L15,5 L1,5 L1,14 Z M6,7.375 C6,7.16875 6.16875,7 6.375,7 L9.625,7 C9.83125,7 10,7.16875 10,7.375 L10,7.625 C10,7.83125 9.83125,8 9.625,8 L6.375,8 C6.16875,8 6,7.83125 6,7.625 L6,7.375 Z M15,1 L1,1 C0.446875,1 0,1.446875 0,2 L0,3.5 C0,3.775 0.225,4 0.5,4 L15.5,4 C15.775,4 16,3.775 16,3.5 L16,2 C16,1.446875 15.553125,1 15,1 Z',
  note:
    ' M16,11.2898024 L16,1.71433163 C16,0.767520559 15.2325,0 14.2857143,0 L1.71428571,0 C0.7675,0 0,0.767520559 0,1.71433163 L0,14.2856684 C0,15.2324794 0.7675,16 1.71428571,16 L11.2899286,16 C11.7445845,16 12.180618,15.8193795 12.5021071,15.4978794 L15.4978929,12.5020134 C15.8193843,12.1805157 16,11.7444705 16,11.2898024 Z M11.4285714,14.1470218 L11.4285714,11.428449 L14.1470714,11.428449 L11.4285714,14.1470218 Z M14.2857143,1.71433163 L14.2857143,9.71411734 L10.5714286,9.71411734 C10.0980357,9.71411734 9.71428571,10.0978776 9.71428571,10.5712832 L9.71428571,14.2856684 L1.71428571,14.2856684 L1.71428571,1.71433163 L14.2857143,1.71433163 Z',
};

const IconSvg = (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    viewBox="0 0 24 24"
  >
    <path
      fill={props.color || Color.Gray}
      fillRule="evenodd"
      d={icons[props.icon]}
      transform="translate(4 4)"
    />
  </svg>
);

export default IconSvg;
