import React from 'react';
import IconSvg from './IconSvg';

const DragHandleSmall = props => {
  const title = props.title || 'Drag this item to preferred position';

  return (
    <div className="reorder-handle" {...props.dragProps}>
      <div title={title}>
        <IconSvg icon="grip" />
      </div>
    </div>
  );
};

export default DragHandleSmall;
