import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import classNames from 'classnames';
import { isEmpty, escapeRegExp } from 'lodash';

const GlobalData = window.GlobalData;

let noResults = false;

const newOptionCreator = option => {
  const inputValue = option.label.toUpperCase();

  return {
    label: `Add PMS(${inputValue})`,
    value: inputValue,
    labelKey: inputValue,
    valueKey: inputValue,
  };
};

const promptTextCreator = label => label;

class ColorSelect extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    value: PropTypes.array,
    onChange: PropTypes.func,
    allowCustom: PropTypes.bool,
    customCollectionName: PropTypes.string,
    decorationType: PropTypes.string,
    isPromoProduct: PropTypes.bool,
    multi: PropTypes.bool,
    placeholder: PropTypes.string,
    className: PropTypes.string,
  };

  static defaultProps = {
    allowCustom: false,
    isPromoProduct: false,
    className: '',
    multi: true,
  };

  state = {
    selectedColors: this.props.value || [],
  };

  renderColorOptions = option => {
    const pms_label = (
      <span className="hide-on-select">(PMS {option.pms})</span>
    );

    const swatchClasses = classNames({
      'color-preview': true,
      'color-preview-cmyk': option.label == 'CMYK',
    });

    const swatchStyles = {
      backgroundColor: `#${option.hex}`,
    };

    return (
      <span>
        <div className={swatchClasses} style={swatchStyles} />
        {option.label} {option.pms ? pms_label : null}
      </span>
    );
  };

  filterOption = (option, filterString) => {
    let a = [option.label, `PMS ${option.pms}`, `#${option.hex}`];
    return a.reduce(
      (memo, attribute) =>
        memo ||
        (attribute &&
          attribute.toLowerCase().indexOf(filterString.toLowerCase()) !== -1),
      false
    );
  };

  normalizePMS = value => {
    return value.replace(/^pms\s*/i, '');
  };

  onColorSelected = selectedOptions => {
    const opts = this.props.multi
      ? selectedOptions
      : selectedOptions
      ? [selectedOptions]
      : [];

    this.setState(
      {
        selectedColors: opts.map(option => this.normalizePMS(option.value)),
      },
      () => {
        const fn = this.props.onChange || (() => {});
        fn(this.state.selectedColors);
      }
    );
  };

  selectedColors = () => {
    let hiddenFieldName = this.props.name;
    if (this.props.multi) hiddenFieldName = hiddenFieldName + '[]';

    if (this.state.selectedColors.length > 0)
      return this.state.selectedColors.map(
        function(color, index) {
          return (
            <input
              type="hidden"
              key={hiddenFieldName + '[' + index + ']'}
              name={hiddenFieldName}
              value={color || ''}
            />
          );
        }.bind(this)
      );
    else {
      return <input type="hidden" name={hiddenFieldName} value="" />;
    }
  };

  isValidNewOption = option => {
    return noResults && !isEmpty(option.label);
  };

  filterOptions = (options, filter) => {
    const filteredOptions = options.filter(option => {
      const text = option.label + ' ' + option.pms;
      const query = escapeRegExp(filter);
      return text.match(new RegExp(query, 'gi'));
    });

    // NOTE: this is HEINOUS I know, but here we are. Until react-select lets
    // you add the new option to the bottom of the options list instead of the
    // top, this is my hack to only show it if there are zero results
    noResults = filteredOptions.length === 0;

    return filteredOptions;
  };

  renderColorValue = color => {
    const swatchClasses = classNames({
      media__obj: true,
      'color-preview': true,
      'color-preview-cmyk': color.label == 'CMYK',
    });

    const swatchStyles = {
      backgroundColor: `#${color.hex}`,
    };

    return (
      <div className="media media--center">
        <div className={swatchClasses} style={swatchStyles} />
        <div className="media__body">
          {color.label}
          <span className="hide-on-select">(PMS {color.pms})</span>
        </div>
      </div>
    );
  };

  decorationTypeColors = () => {
    let standardOptions = (decorationType => {
      switch (decorationType) {
        case 'embroidery':
          return GlobalData.embroideryColorOptions;
        case 'heat_press':
          return GlobalData.heatPressColorOptions;
        case 'flocking':
          return GlobalData.flockingColorOptions;
        case 'tackle_twill':
          return GlobalData.tackleTwillColorOptions;
        default:
          return GlobalData.printColorOptions;
      }
    })(this.props.decorationType);

    let customOptions = this.state.selectedColors.map(val => ({
      value: val,
      label: val,
    }));

    if (this.props.decorationType == 'tackle_twill')
      return [...standardOptions];
    else
      return [...standardOptions, ...customOptions];
  };

  render() {
    const {
      allowCustom,
      decorationType,
      isPromoProduct,
      multi,
      className,
      placeholder,
      id,
    } = this.props;

    let medium;
    switch (decorationType) {
      case 'heat_press':
        medium = 'Heat Press Color';
        break;
      case 'embroidery':
        medium = 'Select Thread Color';
        break;
      case 'flocking':
        medium = 'Select Flock Color';
        break;
      case 'custom_tag':
        medium = 'Select Custom Tag Color';
        break;
      case 'tackle_twill':
        medium = 'Select Fabric Color';
        break;
      default:
        medium = 'Select Ink Color';
        break;
    }

    if (isPromoProduct) {
      medium = 'Select Print Color';
    }

    if (multi) {
      medium += 's';
    }

    const selectProps = {
      options: this.decorationTypeColors(),
      optionRenderer: this.renderColorOptions,
      multi: multi,
      filterOption: this.filterOption,
      className: 'color-select ' + className,
      searchPromptText: 'Start typing to search',
      onChange: this.onColorSelected,
      placeholder: placeholder || medium,
      valueRenderer: this.renderColorValue,
      value: this.state.selectedColors.join(','),
    };

    const createProps = {
      ...selectProps,
      newOptionCreator: newOptionCreator,
      promptTextCreator: promptTextCreator,
      isValidNewOption: this.isValidNewOption,
      filterOptions: this.filterOptions,
    };

    return (
      <div id={id || 'color-select'}>
        {allowCustom ? (
          <Select.Creatable {...createProps} />
        ) : (
          <Select {...selectProps} />
        )}
        {this.selectedColors()}
      </div>
    );
  }
}

export default ColorSelect;
