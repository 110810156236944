import PropTypes from 'prop-types';
import React from 'react';
import createReactClass from 'create-react-class';
import CheckBoxField from './check-box-field';
import { intersection } from 'lodash';

const LocationEmbroideryThreadCountSelector = createReactClass({
  displayName: 'LocationEmbroideryThreadCountSelector',

  props: {
    nonce: PropTypes.string,
    locationsModel: PropTypes.object,
    prefix: PropTypes.string,
    decoration_style: PropTypes.string,
  },

  setActualThreadCount: function setThreadCount(thread_count) {
    this.props.locationsModel.updateLocation(this.props.nonce, {
      actual_thread_count: thread_count,
    });
    console.log(this.props.actual_thread_count);
  },

  setThreadCount: function setThreadCount(thread_count, event) {
    event.preventDefault();
    this.props.locationsModel.updateLocation(this.props.nonce, {
      thread_count: thread_count,
    });
  },

  activeStyles: function activeStyles(thread_count) {
    if (thread_count == this.props.thread_count) {
      return {
        lineHeight: 0,
        outlineWidth: '3px',
        outlineColor: '#68cbda',
        outlineStyle: 'solid',
      };
    }
  },

  render: function render() {
    const showActualThreadCount =
      intersection(GlobalData.currentUserRoles, ['uberadmin', 'finance'])
        .length > 0;

    let hidden_input;
    if (this.props.thread_count) {
      hidden_input = (
        <input
          type="hidden"
          name={this.props.prefix + '[thread_count]'}
          value={this.props.thread_count}
        />
      );
    }
    return (
      <div>
        {hidden_input}
        <h4 className="mbn">What will your design look like most?</h4>
        <p className="txt-muted2 txt-small mtn">
          (In terms of detail / complexity)
        </p>
        <div
          className={
            'row mbm ' +
            (this.props.thread_count ? '' : 'embroidery-thread-count-none')
          }
        >
          <div className="col-5 txt-center">
            <a
              href="#"
              className={
                'embroidery-sample greek-letters ' +
                (this.props.thread_count == 5000 ? 'active' : '')
              }
              onClick={this.setThreadCount.bind(this, 5000)}
            ></a>
          </div>
          <div className="col-5 txt-center">
            <a
              href="#"
              className={
                'embroidery-sample embroidered-logo ' +
                (this.props.thread_count == 10000 ? 'active' : '')
              }
              onClick={this.setThreadCount.bind(this, 10000)}
            ></a>
          </div>
        </div>
        <div
          className={
            'row mbm ' +
            (this.props.thread_count ? '' : 'embroidery-thread-count-none')
          }
        >
          <div className="col-5 txt-center">
            <a
              href="#"
              className={
                'embroidery-sample crest ' +
                (this.props.thread_count == 15000 ? 'active' : '')
              }
              onClick={this.setThreadCount.bind(this, 15000)}
            ></a>
          </div>
          <div className="col-5 txt-center">
            <a
              href="#"
              className={
                'embroidery-sample stitch_count ' +
                (this.props.thread_count == 20000 ? 'active' : '')
              }
              onClick={this.setThreadCount.bind(this, 20000)}
            ></a>
          </div>
        </div>

        <CheckBoxField
          className={'mbm ' + (this.props.thread_count ? '' : 'is-hidden')}
          name={this.props.prefix + '[decoration_style]'}
          valueUnchecked="normal"
          valueChecked="puff_embroidery"
          label="Puff Embroidery"
          checked={this.props.decoration_style == 'puff_embroidery'}
        />

        {showActualThreadCount && (
          <label>
            Actual Stitch Count
            <input
              type="text"
              name={this.props.prefix + '[actual_thread_count]'}
              onChange={e => {
                this.setActualThreadCount(e.target.value);
              }}
              value={this.props.actual_thread_count}
            />
          </label>
        )}
      </div>
    );
  },
});

export default LocationEmbroideryThreadCountSelector;
