import React from 'react';
import { Task as TaskType } from './types';
import Timestamp from '../PostsApp/Timestamp';
import LightBox from '../LightBox';
import ActionMenu from '../ActionMenu';
import IconSvg from '../IconSvg';
import { Color } from '../../BrandColor';

interface Props {
  task: TaskType;
  onDestroy: (t: TaskType) => Promise<void>;
  onEdit: (t: TaskType) => void;
  onUpdate: (t: TaskType) => void;
  readonly: boolean;
  completed: boolean;
  viewed: boolean;
}

export default function Task(props: Props) {
  const { task, readonly, onDestroy, onEdit, onUpdate } = props;

  return (
    <div className="task">
      <div className="task__header">
        <strong>
          {task.user.firstName} {task.user.lastName}
        </strong>
        <div className="task__meta">
          <small className="mhs">
            <Timestamp date={task.createdAt} />
          </small>

          {!readonly && (
            <ActionMenu dropdownRight dropdownSmall>
              <button onClick={() => onEdit(task)}>Edit</button>
              <button
                onClick={() => {
                  if (window.confirm('Are you sure?')) {
                    onDestroy(task);
                  }
                }}
              >
                Delete
              </button>
            </ActionMenu>
          )}
        </div>
      </div>

      <div>
        <div className="flex-rows flex-rows--center-v flex-gap-x-sm">
          <input
            type="checkbox"
            id={`${task.id}`}
            onChange={async e => {
              await onUpdate({
                ...task,
                completed: e.target.checked,
                viewed: e.target.checked,
              });
            }}
            checked={task.completed}
          />

          <label htmlFor={`${task.id}`} className="txt-normal">
            {task.completed ? (
              <span className="txt-muted2">{task.description}</span>
            ) : (
              task.description
            )}
          </label>
        </div>
      </div>

      <div>
        {task.attachments.map(attachment => (
          <div
            key={attachment.url}
            className="flex-rows flex-rows--center-v mbs"
          >
            {attachment.previewUrls.thumbnail ? (
              <>
                <LightBox src={attachment.previewUrls.fullsize}>
                  <img
                    className="item-preview"
                    src={attachment.previewUrls.thumbnail}
                    alt=""
                    width="30"
                    height="30"
                  />
                </LightBox>
                <span
                  className="attachment-title mhm"
                  title={attachment.filename}
                >
                  {attachment.filename}
                </span>
                <a href={attachment.url}>
                  <IconSvg icon="download" color={Color.TealBlue} size={24} />
                </a>
              </>
            ) : (
              <>
                <IconSvg icon="document" color={Color.TealBlue} size={30} />
                <span
                  className="attachment-title mhm"
                  title={attachment.filename}
                >
                  {attachment.filename}
                </span>
                <a href={attachment.url}>
                  <IconSvg icon="download" color={Color.TealBlue} size={24} />
                </a>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
