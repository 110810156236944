$(window).on('load', function() {
  if ($('.bulk-ship-fields').hasClass('is-hidden')) {
    window.signupLiveAddress.deactivate('shipping');
  }

  $('.bb-shipping-type-input').on('click', function() {
    if ($(this).val() == 'true') {
      $('.bulk-ship-fields').addClass('is-hidden');
      window.signupLiveAddress.deactivate('shipping');
    } else {
      $('.bulk-ship-fields').removeClass('is-hidden');
      window.signupLiveAddress.activate('shipping');
      $(window).resize();
    }
  });
});
