import React, { useState } from 'react';
import Modal from 'react-modal';
import IconSvg from './IconSvg';
import { Color } from '../BrandColor';

// NOTE: We were getting an error to use setAppElement to hide
// the app while the modal is open, for use by screenreaders. So
// it's currently being set to `main`.

Modal.setAppElement('.main');

interface Props {
  exceptions: string | null;
}

export default function CollegeLicensingExceptionsModal(props: Props) {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  function handleOpen() {
    setModalIsOpen(true);
  }

  function closeModal() {
    setModalIsOpen(false);
  }

  if (props.exceptions === null || props.exceptions === '') return null;

  return (
    <>
      <button className="button-naked" type="button" onClick={handleOpen}>
        <i className="fa fa-info-circle notify mls"></i>
        <span className="is-hidden-visually">View Exceptions</span>
      </button>

      <Modal
        isOpen={modalIsOpen}
        className="react-modal"
        overlayClassName="react-modal-overlay"
        contentLabel="Review History"
        closeTimeoutMS={200}
      >
        <div className="react-modal-header flex-rows flex-rows--space-b flex-rows--center-v">
          <h4 className="react-modal-header__title">Licensing Exemptions</h4>
          <button className="button-naked" type="button" onClick={closeModal}>
            <IconSvg icon="close" color={Color.Gray} />
          </button>
        </div>

        <div className="react-modal-body react-modal-content">
          {props.exceptions}
        </div>
      </Modal>
    </>
  );
}
