const replacer = (match, p1, p2, p3) => {
  let out = '';
  out += `(${p1}`;

  if (p2 != undefined) {
    out += ') ';
    out += p2;
  }

  if (p3 != undefined) {
    out += '-';
    out += p3;
  }

  return out;
};

export const formatPhoneNumber = (number) => {
  if (!number)
    return;
  let telNumber = number.replace(/[^0-9]/g, '').substring(0,10);

  let newTelNumber = telNumber.replace(
    /([0-9]{1,3})([0-9]{1,3})?([0-9]{1,4})?/,
    replacer //'($1) $2-$3'
  );

  return newTelNumber;
};
