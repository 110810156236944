import React, { useState, useEffect, useMemo } from 'react';
import ProgressBar from '../ProgressBar';
import useImageDirectUpload, { Result } from '../../useImageDirectUpload';
import { LoadState } from '../../remote-data-types';
import readFileData from '../../readFileData';
import { containsFiles, getDataTransferItems } from '../../fileUtility';
import classnames from 'classnames';
import { Attachment } from '../../base-types';
import LightBox from '../LightBox';
import { AttachmentUpdate, AttachmentUpdateType } from '../../attachment-types';

interface Props {
  directUploadUrl: string;
  acceptedMIMETypes: string[];
  maxSize: number;
  onChange: (r: Result) => any;
  existingAttachment?: Attachment | null;
  existingAttachmentUpdate: AttachmentUpdate;
  readonly?: boolean;
}

const onDocumentDragOver = event => event.preventDefault();
const onDocumentDrop = event => event.preventDefault();
const handleDragOver = event => event.preventDefault();

export default function LineItemCompositeUploader(props: Props) {
  const {
    directUploadUrl,
    acceptedMIMETypes,
    maxSize,
    onChange,
    readonly,
    existingAttachmentUpdate,
  } = props;

  const [file, setFile] = useState<File | null>(null);
  const [imageData, setImageData] = useState<string | null>(null);
  const [dragCounter, setDragCounter] = useState(0);

  const isDraggingOver = dragCounter > 0;

  const existingAttachment =
    props.existingAttachment &&
    existingAttachmentUpdate.type !== AttachmentUpdateType.RemoveAttachment
      ? props.existingAttachment
      : null;

  useEffect(() => {
    if (!file) return;
    readFileData(file).then(setImageData);
  }, [file]);

  useEffect(() => {
    if (existingAttachmentUpdate.type !== AttachmentUpdateType.RemoveAttachment)
      return;
    setFile(null);
    setImageData(null);
  }, [existingAttachmentUpdate]);

  useEffect(() => {
    document.addEventListener('dragover', onDocumentDragOver, false);
    document.addEventListener('drop', onDocumentDrop, false);
    return () => {
      document.removeEventListener('dragover', onDocumentDragOver);
      document.removeEventListener('drop', onDocumentDrop);
    };
  }, []);

  const options = useMemo(
    () => ({
      acceptedMIMETypes,
      maxSize,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...acceptedMIMETypes, maxSize]
  );

  const result = useImageDirectUpload(directUploadUrl, file, options);

  useEffect(() => onChange(result), [onChange, result]);

  const onSelectFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setFile(file);
    }
  };

  const progressBar = file && result.state === LoadState.Loading && (
    <ProgressBar {...result} />
  );

  const imagePreview =
    (file && imageData) ||
    (existingAttachment && existingAttachment.previewUrls.fullsize);

  const errorMessage = result.state === LoadState.Failure && file && (
    <div className="notification notification--alert">{result.error}</div>
  );

  const handleDragLeave = event => {
    event.preventDefault();
    setDragCounter(c => c - 1);
  };

  const handleDragEnter = event => {
    event.preventDefault();
    setDragCounter(c => c + 1);
  };

  const handleDrop = event => {
    event.preventDefault();
    event.stopPropagation();

    const rawFileList = getDataTransferItems(event);
    const files = Array.from(rawFileList);

    if (containsFiles(event) && files.length > 0) {
      setFile(files[0]);
    }

    setDragCounter(0);
  };

  const draggableProps = readonly
    ? {}
    : {
        onDrop: handleDrop,
        onDragOver: handleDragOver,
        onDragEnter: handleDragEnter,
        onDragLeave: handleDragLeave,
      };

  const uploaderClass = classnames('upload-area', {
    'upload-area--dragged-over': isDraggingOver,
    'upload-area--file': file || existingAttachment,
  });

  let uploaderStyle: any = {
    cursor: readonly ? 'default' : 'pointer',
  };

  if (imagePreview) {
    uploaderStyle = {
      ...uploaderStyle,
      backgroundImage: `url("${imagePreview}")`,
    };
  }

  const isNotShowingImage = !imagePreview;

  const disableUploadImageButton =
    result.state === LoadState.Loading ||
    result.state === LoadState.IndeterminateProgressLoading;

  const uploadImageButton = !readonly && (
    <div className="uploader">
      <div className="uploader__panel">
        <label
          className={classnames({
            'attachment-button': true,
            'attachment-button--small': true,
            'attachment-button--secondary': true,
            mtm: true,
            'is-disabled': disableUploadImageButton,
          })}
          {...draggableProps}
        >
          {existingAttachment ? 'Replace' : 'Upload'} Image
          <input
            type="file"
            accept={acceptedMIMETypes.join(',')}
            onChange={onSelectFiles}
            disabled={disableUploadImageButton}
          />
        </label>
      </div>
    </div>
  );

  const dragAndDropLabel = isNotShowingImage && (
    <span style={{ pointerEvents: 'none' }}>
      {!readonly
        ? 'Drag file to upload'
        : 'Composite has not been uploaded yet'}
    </span>
  );

  return (
    <div {...draggableProps}>
      <div className="uploader">
        <div className="uploader__panel">
          <LightBox src={imagePreview ? imagePreview : null}>
            <label
              className={uploaderClass}
              {...draggableProps}
              style={uploaderStyle}
            >
              {dragAndDropLabel}
            </label>
          </LightBox>
        </div>

        <div className="uploader__main">
          {progressBar}
          {uploadImageButton}
          {errorMessage}
        </div>
      </div>
    </div>
  );
}
