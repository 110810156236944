import React from 'react';
import ShoppingBagItem from './ShoppingBagItem';
import Modal from 'react-modal';
import { formatMoney } from '../../utility';
import { filter, find, some } from 'lodash';
import classNames from 'classnames';

Modal.setAppElement('main');

const ShoppingBagModal = props => {
  const {
    total,
    isOpen,
    closeModal,
    items,
    birdBankItems,
    onChangeSignupLineItem,
    onDeleteSignupLineItem,
    saveSignupLineItem,
    birdBank,
    failedToSave,
    minimumNotMetForAnAttachedOrder,
    isCollegiate,
  } = props;

  const { individualPayment } = birdBank;

  const bagItems =
    items.length > 0 ? (
      items.map(item => {
        const birdBankItem = find(birdBankItems, { id: item.birdBankItemId });
        return (
          <ShoppingBagItem
            key={item.id}
            {...{
              item,
              birdBank,
              birdBankItem,
              onChangeSignupLineItem,
              onDeleteSignupLineItem,
              saveSignupLineItem,
            }}
          />
        );
      })
    ) : (
      <h3 className="phl txt-center">Your shopping bag is empty.</h3>
    );

  const anyItemsRangedPricing = some(items, item => {
    const birdBankItem = find(birdBankItems, { id: item.birdBankItemId });
    return !birdBankItem.priceFixed;
  });

  const description = individualPayment && anyItemsRangedPricing && (
    <div className="txt-center">
      <p>
        <em>
          Final item prices are based on sign-up quantity. We will authorize a
          payment for the max amount, but will adjust as more people sign up.
        </em>
      </p>
    </div>
  );

  const minimumNotMetWarning = minimumNotMetForAnAttachedOrder && isCollegiate && (
    <div className="bb-notification bb-notification--warning">
      <p>
        Just a heads up! The order minimum has not been met for this group order
        quite yet. In the rare case it's not met by the time this Bird Bank
        closes, your order will be automatically cancelled and we'll notify you
        by email.
      </p>
    </div>
  );

  const totals = individualPayment && (
    <div className="bb-totals-item mtm">
      <p className="bb-shopping-bag-total">Subtotal</p>
      <p className="bb-shopping-bag-total">{formatMoney(total)}</p>
    </div>
  );

  const inStockItemCount = filter(items, { outOfStock: false }).length;

  const checkoutButton = (
    <a
      href="#"
      className={classNames('bb-button', 'bb-button--full', 'mtl', {
        'bb-button--disabled': inStockItemCount == 0,
      })}
      disabled={inStockItemCount == 0}
      onClick={e => {
        e.preventDefault();

        if (inStockItemCount == 0) return;

        props.onCheckoutButtonClick();
      }}
    >
      Checkout
    </a>
  );

  const failureMessage = failedToSave && (
    <div className="bb-notification bb-notification--warning">
      <p className="man">
        There was a problem saving your information. Please check the fields and
        try again. If it still does not save, reload the page and try again.
      </p>
    </div>
  );

  if (isOpen) {
    return (
      <Modal
        isOpen={isOpen}
        className="react-modal"
        overlayClassName="react-modal-overlay"
        contentLabel="Shopping Bag Modal"
        closeTimeoutMS={200}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={closeModal}
      >
        <div className="react-modal-header flex-rows flex-rows--space-b">
          <h1 className="bb-item-headline mvn">Shopping Bag</h1>
          <button
            className="bb-button-close bb-button-close--blue"
            type="button"
            onClick={closeModal}
          >
            Close
          </button>
        </div>
        <div className="react-modal-body">
          {bagItems}
          {failureMessage}
          {totals}
        </div>
        <div className="react-modal-footer bb-shopping-modal-footer">
          <div className="stack">
            {description}
            {minimumNotMetWarning}
          </div>
          <div className="bb-cta">
            <div>{checkoutButton}</div>
            <div>
              <button
                className="bb-button bb-button--inverse bb-button--full mtl"
                type="button"
                onClick={closeModal}
              >
                Keep Shopping
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  } else {
    return null;
  }
};

export default ShoppingBagModal;
