const Promise = window.Promise;

const DEFAULT_PPI = 72;

const pixelDimensionsToInches = ({ width, height }, ppi) => ({
  height: (height / ppi).toFixed(2),
  width: (width / ppi).toFixed(2),
});

export const getImageURIContents = file => {
  let reader = new FileReader();

  const promise = new Promise((resolve, reject) => {
    reader.onload = e => resolve(e.target.result);
    reader.onabort = () => {
      reject(Error('File reading operation aborted'));
    };
  });

  reader.readAsDataURL(file);

  return promise;
};

const getImageDimensionsInInches = (file, ppi) =>
  getImageURIContents(file).then(
    imgURIContents =>
      new Promise(resolve => {
        const img = document.createElement('img');

        img.onload = function() {
          resolve(
            pixelDimensionsToInches(
              {
                height: img.height,
                width: img.width,
              },
              ppi
            )
          );
        };

        img.src = imgURIContents;
      })
  );

const getSwatchNames = file => {
  let reader = new FileReader();

  const promise = new Promise((resolve, reject) => {
    reader.onload = e => {
      const svgContents = e.target.result;
      const parser = new DOMParser();
      const doc = parser.parseFromString(svgContents, 'image/svg+xml');
      const swatchNodes = doc.documentElement.querySelectorAll(
        'SwatchGroups Colorants Seq li'
      );

      let swatchNames = [];

      const prefixRegex = /^(SP|PANTONE)\s+/i;
      const badCharacters = /[.-]*/;

      swatchNodes.forEach(n => {
        const swatchName = n.querySelector('swatchName');
        if (swatchName && swatchName.innerHTML) {
          let name = swatchName.innerHTML;
          const matchesName = name.match(prefixRegex);
          name = name.replace(prefixRegex, '');
          name = name.replace(badCharacters, '');

          if (matchesName) {
            swatchNames.push(name);
          }
        }
      });

      resolve(swatchNames);
    };

    reader.onabort = () => {
      reject(Error('File reading operation aborted'));
    };
  });

  reader.readAsText(file);

  return promise;
};

const getSVGDimensionsInInches = (file, ppi) => {
  let reader = new FileReader();

  const promise = new Promise((resolve, reject) => {
    reader.onload = e => {
      const svgContents = e.target.result;
      const parser = new DOMParser();
      const doc = parser.parseFromString(svgContents, 'image/svg+xml');
      let viewbox = doc.documentElement.getAttribute('viewBox');

      if (viewbox) {
        viewbox = viewbox.split(' ').map(val => parseFloat(val));
        const width = viewbox[2];
        const height = viewbox[3];
        resolve(
          pixelDimensionsToInches(
            {
              height,
              width,
            },
            ppi
          )
        );
      } else {
        reject(Error('No viewbox present in SVG'));
      }
    };

    reader.onabort = () => {
      reject(Error('File reading operation aborted'));
    };
  });

  reader.readAsText(file);

  return promise;
};

export const isFileAnSVG = file => file.type.match(/^image\/svg/);

export const isFileAnImage = file =>
  !isFileAnSVG(file) && file.type.match(/^image\//);

export const getDimensionsInInches = (file, ppi = DEFAULT_PPI) => {
  if (isFileAnSVG(file)) {
    return getSVGDimensionsInInches(file, ppi);
  } else if (isFileAnImage(file)) {
    return getImageDimensionsInInches(file, ppi);
  } else {
    return Promise.reject("Can't get dimensions because file is not an image");
  }
};

export const getSwatches = file => {
  if (isFileAnSVG(file)) {
    return getSwatchNames(file);
  } else {
    return Promise.reject("Can't get swatches because file is not an SVG");
  }
};

export const isFileAtOrUnderSize = (file, size) =>
  file && size && file.size && file.size <= size;
