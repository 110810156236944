import React from 'react';

export default function ContactInfo({ title, email, name }) {
  if (!email && !name) return null;
  return (
    <React.Fragment>
      <h3 className="bb-item-headline mtn">{title}</h3>
      <p>
        {name} <br />
        <a href={`mailto: ${email}`}>{email}</a>
      </p>
    </React.Fragment>
  );
}
