import React from 'react';
import LineItemSize from './LineItemSize';
import FreeLineItemSize from './FreeLineItemSize';
import { sum } from 'lodash';

const LineItemSizes = props => {
  const { data } = props;
  const { sizes, totalQuantity } = data;

  let paidSizes = sizes
    .filter(size => size.quantity > 0)
    .map(size => <LineItemSize key={size.size} {...size} />);

  let freeSizes = sizes
    .filter(size => size.freeQuantity > 0)
    .map(size => <FreeLineItemSize key={size.size} {...size} />);

  const freeQuantities = sizes.map(size => size.freeQuantity);
  const paidQuantities = sizes.map(size => size.quantity);

  const totalFreeQuantity = sum(freeQuantities);
  const totalPaidQuantity = sum(paidQuantities);

  const freeQuantityView =
    totalFreeQuantity > 0 ? (
      <React.Fragment>
        <dl className="dl-grid">
          {freeSizes}
          <dt>
            <em className="txt-muted2">Free Quantity</em>
          </dt>
          <dd className="txt-right">
            <em className="txt-muted2">{totalFreeQuantity}</em>
          </dd>
        </dl>
      </React.Fragment>
    ) : null;

  const paidQuantityTitle =
    sizes.length > 0 && totalFreeQuantity > 0 ? (
      <React.Fragment>
        <dt>Paid Quantity</dt>
        <dd className="txt-right">{totalPaidQuantity}</dd>
      </React.Fragment>
    ) : null;

  const paidQuantityView =
    sizes.length > 0 && totalPaidQuantity > 0 ? (
      <dl className="dl-grid">
        {paidSizes}
        {paidQuantityTitle}
      </dl>
    ) : null;

  const totalQuantityView =
    totalQuantity > 0 ? (
      <dl className="dl-grid">
        <dt>Total Quantity</dt>
        <dd className="txt-right">{totalQuantity}</dd>
      </dl>
    ) : null;

  if (
    sizes.length > 0 ||
    totalFreeQuantity > 0 ||
    totalPaidQuantity > 0 ||
    totalQuantity > 0
  ) {
    return (
      <div className="line-item-size">
        {paidQuantityView}
        {freeQuantityView}
        {totalQuantityView}
      </div>
    );
  } else {
    return null;
  }
};

export default LineItemSizes;
