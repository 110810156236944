import React, { Component } from 'react';
import PropTypes from  'prop-types';
import FulfillmentOrder from './FulfillmentOrder';


class FulfillmentSchedulerForm extends Component {
  static propTypes = {
    orders: PropTypes.arrayOf(PropTypes.object)
  }
  constructor(props) {
    super(props);
  }

  render(){
    let orderNodes = this.props.orders.map((order, index) => (
      <FulfillmentOrder
        key={'fulfillment-scheduling-order'+ index}
        order={order}
      />
    ));

    return(
      <table className='table--responsive'>
        <thead>
          <tr>
            <th>Invoice Date</th>
            <th>Order #</th>
            <th>Packaging Date</th>
            <th>Job Type</th>
            <th>Quantity</th>
            <th>Extra Time</th>
            <th>Greek</th>
            <th>Notes</th>
            <th>Complete</th>
          </tr>
        </thead>
        <tbody>
          <input type='hidden' name='fulfillment[order_ids][]' value=''/>
          {orderNodes}
        </tbody>
      </table>
    );
  }

}

export default FulfillmentSchedulerForm;
