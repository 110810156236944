import React from 'react';
import { compact } from 'lodash';

export const joinJSX = (
  parts: React.ReactNodeArray,
  separator: string | JSX.Element = ' '
) =>
  (
    compact(parts).reduce(
      (res: null | React.ReactNodeArray, el: React.ReactNode) =>
        res === null ? [el] : [...res, separator, el],
      null
    ) || []
  ).map((el, i) => <React.Fragment key={i}>{el}</React.Fragment>);

export const wrapBoldIfDiff = (v1, v2) =>
  !v1 || !v2 || v1 === v2 ? v2 : <strong>{v2}</strong>;

export const simulateReactMouseClick = (element: Element) => {
  const mouseClickEvents = ['mousedown', 'click', 'mouseup'];
  mouseClickEvents.forEach(mouseEventType =>
    element.dispatchEvent(
      new MouseEvent(mouseEventType, {
        view: window,
        bubbles: true,
        cancelable: true,
        buttons: 1,
      })
    )
  );
};
