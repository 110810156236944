import React from 'react';
import { joinJSX } from '../reactUtilities';

interface Props {
  address?: string | null;
  address2?: string | null;
  locality?: string | null;
  region?: string | null;
  postalCode?: string | null;
}

const AddressView = ({
  address,
  address2,
  locality,
  region,
  postalCode,
}: Props): JSX.Element => {
  return (
    <address>
      {joinJSX(
        [
          joinJSX([address, address2], ', '),
          joinJSX([joinJSX([locality, region], ', '), postalCode]),
        ],
        <br />
      )}
    </address>
  );
};

export default AddressView;
