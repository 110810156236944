import { formatMoney } from './utility';

(function() {
  let SumBulkPayments = function(params) {
    let $node = params.$node,
      onChange = function() {
        let sum = 0.0;

        let selectedInvoices =
          document.querySelectorAll('.bulk-payment-select:checked');

        selectedInvoices.forEach(function(el) {
          sum += parseFloat(el.dataset.amount);
        });

        let sumElement = document.getElementById('bulk-payment-amount');
        sumElement.innerHTML = formatMoney(sum);
      },

      init = function() {
        $node.onchange = onChange;
      };

    init();
  };

  document.querySelectorAll('input.bulk-payment-select').forEach(function(el) {
    new SumBulkPayments({
      $node: el
    });
  });
}());

