import 'base-types';
import { Notifier, INotice } from '@airbrake/browser';

const ignorableMessageTexts = [
  '__gCrWeb',
  'zE',
  'Invalid pointer',
  'undefined is not an object (evaluating \'document.getElementsByClassName("docs-homescreen-gb-container")[0].style\')',
  'The network connection was lost.', // Safari specific, when network connection drops
  'The Internet connection appears to be offline.', // same as above?
  'Failed to fetch', // I believe this is the same as above, but for Chrome
  'cancelled', // More network noise
  'operation timed out', // Even more network noise
];

const backtraceContainsText = (notice: INotice, text: string): boolean => {
  if (!notice.errors) return false;
  return !!notice.errors.find(error =>
    error.backtrace.find(({ file }) => file.includes(text))
  );
};

const isComingFromSafariExtension = (notice: INotice): boolean =>
  backtraceContainsText(notice, 'safari-extension://');

const isComingFromChromeExtension = (notice: INotice): boolean =>
  backtraceContainsText(notice, 'chrome-extension://');

const isComingFromBrowserExtension = (notice: INotice): boolean => {
  const tests: { (notice: INotice): boolean }[] = [
    isComingFromSafariExtension,
    isComingFromChromeExtension,
  ];
  return tests.some(test => test(notice));
};

const containsIgnorableText = (notice: INotice): boolean => {
  if (!(notice.errors && notice.errors.length > 0)) return false;
  const message = notice.errors[0].message;

  for (let i = 0; i < ignorableMessageTexts.length; i++) {
    if (message.indexOf(ignorableMessageTexts[i]) !== -1) return true;
  }
  return false;
};

const isIE11 = (notice: INotice): boolean => {
  if (!notice.context || !notice.context.userAgent) return false;

  // https://stackoverflow.com/a/19987665
  const regex: RegExp = /(?:\b(MS)?IE\s+|\bTrident\/7\.0;.*\s+rv:)(\d+)/;

  const userAgentString = notice.context.userAgent;
  return regex.test(userAgentString);
};

const initializeAirbrake = () => {
  const airbrakeConfig = window.OrderApp.airbrakeConfig;

  if (!airbrakeConfig) {
    console.warn('No Airbrake configuration found, will not initialize');
    return;
  }

  const { projectKey, environment } = airbrakeConfig;
  const projectId = parseInt(airbrakeConfig.projectId);

  const airbrake = new Notifier({ projectId, projectKey, environment });

  airbrake.addFilter(notice => {
    if (isIE11(notice)) return null;
    if (isComingFromBrowserExtension(notice)) return null;
    if (containsIgnorableText(notice)) return null;
    return notice;
  });
};

initializeAirbrake();
