(function() {
  let SelectCustomer = function(params) {
    let self = this,
      $node = params.$node,
      onClick = function(e) {
        if(this.value == 'true'){
          $('[data-new-customer]').hide();
          $('[data-existing-customer]').show();
        }else{
          $('[data-existing-customer]').hide();
          $('[data-new-customer]').show();
        }

        $(window).resize();
      },
      init = function() {
        $('[data-new-customer]').hide();
        $('[data-existing-customer]').show();
        $node.click(onClick);
      };

    init();
  };

  $('[data-select-customer] :input').each(function() {
    new SelectCustomer({
      $node: $(this)
    });
  });
}());
