$('.item-proof-popup').on('click', function(e) {
  const updateItemProofButton = $(this);
  const actionPath = updateItemProofButton.data('action-path');

  e.preventDefault();

  swal(
    {
      title: 'Are you sure?',
      text:
        "<p class='mbl'>" +
        '<strong>This button is to request line item updates ONLY!</strong></p>' +
        "<p class='mbl'>If you need to change anything about the design (including ink colors), please proceed with a standard revision.</p>" +
        "<small class='txt-small txt-muted2'>Note: The ability to request additional art " +
        'revisions will be disabled until new proofs ' +
        'have been uploaded.</small>',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#FE4438',
      confirmButtonText: 'Continue',
      html: true,
      customClass: 'rush-alert',
      closeOnConfirm: false,
    },
    () => {
      const form = $('<form>', {
        method: 'POST',
        action: actionPath,
      });

      // NOTE: This is needed to submit to the update-item-proofs action in
      //       locations controller.
      const csrfToken = $('meta[name="csrf-token"]').attr('content');
      form.append(
        $('<input>', {
          type: 'hidden',
          name: 'authenticity_token',
          value: csrfToken,
        })
      );

      $('body').append(form);
      form.trigger('submit');
    }
  );
});
