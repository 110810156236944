(function($) {
  $('[data-bind-value-to]').each(function(index, element) {
    const trigger_selector = $(element).data('bind-value-to');
    if ($(trigger_selector).val() == $(element).val()) {
      $(trigger_selector).on('keyup change', function() {
        $(element).val($(this).val());
        $(element).trigger('change');
      });
    }
  });
})(window.jQuery);
