import React from 'react';
import affinityPng from '../images/affinity.png';

export default function ReviewStatusButton(props) {
  const { canEdit, toggleReview, status } = props;

  const reviewStatus = status ? status.replace(/-/g, ' ') : 'Affinity';

  const handleToggle = value => {
    toggleReview(value);
  };

  if (canEdit) {
    return (
      <button
        className={`bb-affinity bb-${status} bb-affinity--spaced`}
        onClick={handleToggle}
      >
        <span className="bb-affinity__inner">
          <img
            src={affinityPng}
            height="20"
            width="20"
            className="mrs"
            alt=""
          />
          {reviewStatus}
        </span>
      </button>
    );
  } else {
    return null;
  }
}
