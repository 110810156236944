import React from 'react';
import AffinitySubmissionButton from './AffinitySubmissionButton';
import Modal from 'react-modal';
import DOMPurify from 'dompurify';
import affinityPng from '../images/affinity.png';

export default function LineItemArtReview(props) {
  const { toggleReview, id } = props;
  const {
    affinityReview,
    translatedAffinityStatus,
    syncTime,
    externalUrl,
    canLicense,
    readyForSubmission,
    isDisabled,
    submissionLink,
    reviewOpen,
  } = props.data;

  const syncedAt =
    affinityReview && affinityReview.synced_at ? (
      <p>
        <strong>Last Affinity Update: </strong>
        <span title={`${affinityReview.synced_at}`}>{syncTime}</span>
      </p>
    ) : null;

  let externalLink;
  if (externalUrl) {
    externalLink = (
      <a href={`${externalUrl}`} target="_blank" rel="noopener noreferrer">
        View this design in the Affinity Gateway
      </a>
    );
  } else if (!externalUrl && !affinityReview) {
    externalLink = 'This order has not yet been sent to Affinity.';
  }

  const affinitySubmissionButton =
    canLicense && readyForSubmission ? (
      <AffinitySubmissionButton
        disabled={isDisabled}
        url={submissionLink}
        showSubmitAsNewDesign={
          affinityReview ? affinityReview.external_id : null
        }
      />
    ) : null;

  const closeReview = e => {
    e.preventDefault();
    toggleReview(false, id);
  };

  const message = affinityReview ? (
    <div className="well--system-text mtn">
      {DOMPurify.sanitize(affinityReview.message, {
        FORBID_TAGS: ['p', 'span', 'style', 'br'],
      })}
    </div>
  ) : null;

  if (reviewOpen) {
    return (
      <Modal
        isOpen={!!reviewOpen}
        className={`react-modal line-item-${id}`}
        overlayClassName="react-modal-overlay"
        contentLabel="Affinity Review Modal"
        closeTimeoutMS={200}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={closeReview}
      >
        <div className="well">
          <div className="media media--center mbm">
            <div className="media__obj mrs">
              <img src={affinityPng} height="22" width="22" alt="" />
            </div>

            <div className="media__body">
              <h2 className="tertiary-headline mbn">Affinity</h2>
            </div>

            <button
              className="close-button close-button-small"
              type="button"
              onClick={closeReview}
            >
              Close
            </button>
          </div>

          <div className="txt-small">
            {translatedAffinityStatus}
            {message}
            {syncedAt}
            <p>{externalLink}</p>
            {affinitySubmissionButton}
          </div>
        </div>
      </Modal>
    );
  } else {
    return null;
  }
}
