import React, { useState, useEffect, useRef, MouseEventHandler } from 'react';
import IconSvg from './IconSvg';
import classNames from 'classnames';

interface Props {
  children: React.ReactNode;
  buttonText?: string;
  dropdownSmall?: boolean;
  dropdownTop?: boolean;
  dropdownBottom?: boolean;
  dropdownRight?: boolean;
  dropdownLeft?: boolean;
  dropdownRightCollapse?: boolean;
  dropdownLeftCollapse?: boolean;
  testingHook?: string;
}

export default function ActionMenu(props: Props) {
  const {
    buttonText,
    dropdownSmall,
    dropdownTop,
    dropdownBottom,
    dropdownRight,
    dropdownLeft,
    dropdownRightCollapse,
    dropdownLeftCollapse,
    testingHook,
  } = props;
  const menuRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggle: MouseEventHandler<HTMLElement> = e => {
    e.preventDefault();
    setIsOpen(v => !v);
  };

  useEffect(() => {
    function clickOutside(e: MouseEvent) {
      if (
        isOpen &&
        menuRef.current &&
        // @ts-ignore: Object is possibly 'null'
        !menuRef.current.contains(e.target)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', clickOutside);
    return () => document.removeEventListener('mousedown', clickOutside);
  }, [menuRef, isOpen]);

  const listClasses = classNames({
    'button-dropdown-menu': true,
    'button-dropdown-menu--open': isOpen,
    'button-dropdown-menu--small': dropdownSmall,
    'button-dropdown-menu--top': dropdownTop,
    'button-dropdown-menu--bottom': dropdownBottom,
    'button-dropdown-menu--right': dropdownRight,
    'button-dropdown-menu--left': dropdownLeft,
    'button-dropdown-menu--right-collapse': dropdownRightCollapse,
    'button-dropdown-menu--left-collapse': dropdownLeftCollapse,
  });

  let children = Array.isArray(props.children)
    ? props.children
    : [props.children];

  return (
    <div ref={menuRef} className="button-dropdown-container mtn">
      <button
        className="button-naked pan d-flex"
        onClick={toggle}
        title={buttonText}
        data-testing-selector={testingHook}
      >
        <span className="is-hidden-visually">{buttonText}</span>
        <IconSvg icon="more" />
      </button>

      <ul className={listClasses}>
        {children.map((child, i) => (
          <li key={i} className="button-dropdown-menu__action-item">
            {child}
          </li>
        ))}
      </ul>
    </div>
  );
}
